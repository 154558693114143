<template>
  <div id="designerListContainer">
    <div style="width: 100%; padding-right: 15px">
      <div
        style="
          padding: 10px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="font-weight: bold; color: #336699; font-size: 20px">
          {{ title }}
        </div>

        <div class="info-filter-area">
          <input
            type="text"
            style="width: 240px"
            class="form-control"
            placeholder="姓名精准查询"
            v-model="searchFilter.name"
          />
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;搜索
          </button>
        </div>
      </div>
      <ul
        class="nav nav-pills"
        style="border-bottom: 1px solid lightgray; padding-bottom: 10px"
      >
        <li v-for="(value,index) in $bf.DESIGNER_TYPE" :key="index" role="presentation" :id="'menuTab'+index" @click="changeType('menuTab'+index,value)"><a href="#">{{value}}</a></li>
        <!-- <li role="presentation" id="menuTab1" @click="changeType('menuTab1','建造师')"><a href="#">建造师</a></li>
        <li role="presentation" id="menuTab2" @click="changeType('menuTab2','景观设计师')"><a href="#">景观设计师</a></li>
        <li role="presentation" id="menuTab3" @click="changeType('menuTab3','家具设计师')"><a href="#">家具设计师</a></li>
        <li role="presentation" id="menuTab4" @click="changeType('menuTab4','工业设计师')"><a href="#">工业设计师</a></li>
        <li role="presentation" id="menuTab5" @click="changeType('menuTab5','空间设计师')"><a href="#">空间设计师</a></li>
        <li role="presentation" id="menuTab6" @click="changeType('menuTab6','室内设计师')"><a href="#">室内设计师</a></li>
        <li role="presentation" id="menuTab7" @click="changeType('menuTab7','其他')"><a href="#">其他</a></li> -->
      </ul>
      <div class="info-list-content">
        <div v-if="listData.records.length > 0">
          <div
            v-for="(value, index) in listData.records"
            :key="index"
            class="list_group_item"
          >
            <div>
              <img
                :alt="value.name"
                class="list-group-member-img"
                :src="
                  value.userEntity.img
                    ? $bf.getHeadImgUrl(value.userEntity.img)
                    : '../../../public/static/defaultpic.gif'
                "
              />
            </div>
            <div class="list-group-item-content">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  padding-bottom: 5px;
                "
              >
                <div class="title">{{ value.userEntity.name }}</div>
                <span class="label label-primary">{{ value.type }}</span>
              </div>
              <p>单位：{{ value.userEntity.enterprise }}</p>
              <p>职位：{{ value.userEntity.job }}</p>
            </div>
            <div class="desc">
              <p style="padding: 5px">简介：{{ value.desc }}</p>
            </div>
          </div>
        </div>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
export default {
  name: "designerListPage",
  components: {
    basePagination,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      title: "设计师库",
      listData: [],
      designerInfo: null,
      searchFilter: {
        name: "",
        type: "",
      },
      pageData: {}
    };
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#designerListContainer"));
      this.search(1);
      this.changeType('menuTab0','建筑师')
      // this.getDesignerStatus()
    },
    //  getDesignerStatus() {
    //   var _this = this;
    //   this.$service.get("/designer/status").then(function (e) {
    //     _this.designerInfo = e.data.data.designerEntity;
    //   });
    // },
    search(page) {
      var _this = this;
      var json = {
        pageNo: page,
        pageSize: 10,
        state: 1,
        name: this.searchFilter.name,
        type: this.searchFilter.type
      };
      this.$service.post("/designer/list", json).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
      });
    },
    changeType(id,type) {
      this.$("li[id^=menuTab]").removeClass('active')
      this.$("#"+id).addClass('active')
      this.searchFilter.type = type
      this.search(1)
    },
  },
};
</script>
<style lang="postcss" scoped>
#designerListContainer {
  width: 1400px;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.info-filter-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* text-align: right; */
  padding: 10px 0px;
}
.info-list-content {
  padding-top: 15px;
}
.list_group_item {
  display: flex;
  width: 100%;
  border-bottom: 1px dashed lightgray;
  padding: 8px 0px;
}

.list-group-item-img {
  width: 180px;
  height: 120px;
}
.list-group-member-img {
  width: 120px;
  height: 160px;
}

.list-group-item-content {
  width: 300px;
  padding-left: 10px;
}

.list-group-item-content .title {
  font-size: 18px;
  font-weight: bold;
  padding-right: 5px;
  color: cornflowerblue;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.list-group-item-content .create_time {
  font-size: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  color: grey;
  white-space: nowrap;
}

.list_group_item .desc {
  font-size: 16px;
  border-left: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  /* height: 100%; */
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
</style>
