<template>
    <div style="display:flex;flex-direction:row;">
        <ul class="nav nav-pills nav-stacked" style="padding-bottom: 10px">
            <li v-for="(value,index) in enrollRecords" :key="index" :id="'myEnrollResultItem'+index" role="presentation" 
            :class="index==0?'active':''"
            style="
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            "
            @click="selectProject(index)"><a>{{value.enrollNo}}</a></li>
        </ul>
        <div v-if="enrollRecord.matchEnrollScoreEntities.length>0" style="width:100%">
            <div class="enroll-grid" >
                <div class="enroll-grid-item info-tag">申请的项目</div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">报名编号</div>
                </div>
                <div class="enroll-grid-item">
                    {{ enrollRecord.enrollNo }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">申请人</div>
                </div>
                <div class="enroll-grid-item">
                    {{ enrollRecord.applyUserName }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">作品名称</div>
                </div>
                <div class="enroll-grid-item three-col">
                    <div  v-if="enrollRecord.workName">{{ enrollRecord.workName }}</div>
                    <div  v-if="enrollRecord.workName">{{ enrollRecord.enWorkName }}</div>
                    <div  v-if="!enrollRecord.workName" style="color:red">未提交</div>
                </div>
                <div class="enroll-grid-item title">
                        <div style="display:flex;flex-direction:row">工程名称</div>
                </div>
                <div class="enroll-grid-item three-col">
                    {{enrollRecord.projectName}}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">工程规模</div>
                </div>
                <div class="enroll-grid-item">
                    {{enrollRecord.projectSize}}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">结构形式</div>
                </div>
                <div class="enroll-grid-item">
                    {{enrollRecord.projectStructure}}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">工程时间</div>
                </div>
                <div class="enroll-grid-item">
                    <div style="display:flex;flex-direction:row">
                        <div style="white-space:nowrap">开始时间</div>
                            <div>{{enrollRecord.projectStartDate}}</div>
                    </div>
                    <div style="display:flex;flex-direction:row">
                        <div style="white-space:nowrap">竣工时间</div>
                        <div>{{enrollRecord.projectEndDate}}</div>
                    </div>
                </div>
                <div class="enroll-grid-item title" >
                    <div style="display:flex;flex-direction:row">建造费用</div>
                </div>
                <div class="enroll-grid-item">
                    <div style="display:flex;flex-direction:row">
                        <div>{{ enrollRecord.projectCost }}</div>
                        <div style="white-space:nowrap;text-align: center;">万元</div>
                    </div>
                </div>
            </div>
            <div class="enroll-grid" v-for="(data,dIndex) in enrollRecord.matchEnrollScoreEntities" :key="dIndex">
                <div class="enroll-grid-item info-tag">第{{data.order}}轮评选</div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">评选方式</div>
                </div>
                <div class="enroll-grid-item">
                    {{ data.type }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">评选地点</div>
                </div>
                <div class="enroll-grid-item">
                    {{ data.location?data.location: '-' }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">得分</div>
                </div>
                <div class="enroll-grid-item three-col" style="color:cornflowerblue;font-weight: bold;">
                    {{ data.grade?data.grade:'-' }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">评价1</div>
                </div>
                <div class="enroll-grid-item three-col"
                style="color:cornflowerblue;font-weight: bold;">
                    {{ data.comment1 }}
                </div>
                <div class="enroll-grid-item title"
                >
                    <div style="display:flex;flex-direction:row">评价2</div>
                </div>
                <div class="enroll-grid-item three-col"
                style="color:cornflowerblue;font-weight: bold;">
                    {{ data.comment2 }}
                </div>
            </div>
        </div>
        <div v-else class="no-data-text" style="width:100%">查询暂无数据</div>
    </div>
</template>
<script>
export default{
    name: 'matchResultPage',
    data(){
        return {
            enrollRecords:[],
            enrollRecord:{
                matchEnrollScoreEntities:[]
            }
        }
    },
    mounted(){
        this.getRecords()
    },
    methods: {
        getRecords(){
            var _this = this
            this.$service.get('/match/my-record').then(function(e){
                _this.enrollRecords = e.data.data
                if(_this.enrollRecords.length>0) {
                    _this.selectProject(0)
                }                
            })
        },
        selectProject(index){
            this.$('li[id^=myEnrollResultItem]').removeClass('active')
            this.$('#myEnrollResultItem'+index).addClass('active')
            this.enrollRecord = this.enrollRecords[index]
            this.enrollRecord.index = index
        },
    }
}
</script>

<style scoped>
.enroll-grid {
    display:grid;
    grid-template-columns: [menu-items] 180px auto auto auto;
    grid-template-rows: auto auto auto;
}
.enroll-grid-item{
    padding:5px 10px;
    text-align: left;
    border: 1px solid lightgray
}
.enroll-grid-item.info-tag {
	font-size:16px;
	font-weight:bold;
    grid-column: 1 / 5;    
    text-align: center;
    background-color: cornsilk;
    color:cornflowerblue
}
.enroll-grid-item.title {
    background-color: whitesmoke;
    /* width:180px */
}
.enroll-grid-item.three-col {
    grid-column: 2 / 5;
}
</style>