<template>
  <div id="activityListContainer">
    <page-nav></page-nav>
    <div class="info-list-container">
      <div
        style="
          padding: 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid lightgray;
        "
      >
        <div style="font-weight: bold; color: #336699; font-size: 20px">
          {{ pageJson.title }}
        </div>
        <div class="info-filter-area">
          <input
            type="text"
            style="width: 240px"
            class="form-control"
            placeholder="标题模糊查询"
            v-model="searchFilter.title"
          />
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;搜索
          </button>
        </div>
      </div>
    </div>
    <div class="info-list-content">
      <div v-if="listData.records.length > 0">
        <!-- <div v-if="this.searchFilter.type == 4"> -->
        <div
          class="act_list_item"
          v-for="(value, index) in listData.records"
          :key="index"
        >
          <!-- <div class="act_banner thumbnail" href="#" > -->
          <img
            class="img"
            v-if="!value.img"
            :alt="value.title"
            :src="value.img"
          />
          <img
            class="img"
            v-else
            alt="广东省城市建筑学会"
            src="../../../public/static/defaultpic.gif"
          />
          <!-- </div>                 -->
          <div class="content">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div class="title">&nbsp;{{ value.title }}</div>

              <div>
                <span v-if="value.isMember == 1" class="label label-success"
                  >仅会员</span
                >&nbsp;
                <a style="cursor: pointer" @click="toDetail(value)">[详情]</a>
              </div>
            </div>
            <div class="desc">{{ value.desc }}</div>
            <div class="time">
              <div>
                报名时间：{{ value.enrollStartTime }}-{{ value.enrollEndTime }}
              </div>
              <div>发布时间：{{ value.createTime }}</div>
            </div>
            <!-- <div style="text-align:right;padding-top: 10px;">
                            <span v-if="value.state==1" class="label label-success">已报名</span>&nbsp;{{value.sendTime}}
                            </div> -->
          </div>
        </div>
      </div>
      <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    </div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import pageNav from "../base/pageNav.vue";
export default {
  name: "activityListPage",
  components: {
    basePagination,
    pageNav,
    // noticeModal
  },
  data() {
    return {
      pageNavs: [],
      pageJson: {},
      searchFilter: {
        title: "",
      },
      listData: [],
      data: {},
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.$bf.calHeight(this, this.$("#activityListContainer"));
      this.searchFilter = {
        // id: 0,
        type: 1,
        title: null,
      };

      // var urlPath = this.$route.name
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.pageNavs = this.$bf.calPageNavs(this.pageJson.prefix);
      //   this.searchFilter.type = this.pageJson.type;
      this.search(1);
    },
    search(page) {
      var _this = this;
      var url = "/activity/list";
      var body = {};
      body.pageNo = page;
      body.pageSize = 10;
      //   body.type = this.searchFilter.type;
      body.title = this.searchFilter.title ? this.searchFilter.title : null;
      body.state = 0;
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.activityPagination.render(page, e.data.data.total);
        // _this.initPagination(e.data.data)
      });
    },
    toDetail(value) {
      this.$router.push({
        name: "activityDetailPage",
        params: { actNo: value.actNo },
      });
    },
  },
};
</script>
<style scoped>
#activityListContainer {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.info-list-container {
  width: 1400px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.info-filter-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* text-align: right; */
  padding: 10px 0px;
}
.info-list-content {
  padding-top: 15px;
  width: 1400px;
}

.act_list_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px dashed lightgray;
}

.act_list_item .img {
  width: 120px;
  height: 90px;
}

.act_list_item .content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}

.act_list_item .content .title {
  font-size: 16px;
  color: cornflowerblue;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.act_list_item .content .time {
  color: grey;
  width: 100%;
  text-align: right;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.act_list_item .content .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  word-break: break-all;
  font-size: 16px;
  color: gray;
}
</style>
