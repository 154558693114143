<template>
  <div id="membersListContainer">
    <div v-if="listData.records.length > 0">
      <div
        v-for="(value, index) in listData.records"
        :key="index"
        class="list_group_item"
      >
        <div>
          <img
            v-if="value.img"
            :alt="value.title"
            :class="
              $route.name != 'membersShowListPage'
                ? 'list-group-item-img'
                : 'list-group-member-img'
            "
            :src="$bf.getImgUrl(value.img)"
          />
          <img
            v-else
            class="list-group-item-img"
            alt="广东省城市建筑学会"
            src="../../../public/static/defaultpic.gif"
          />
        </div>
        <div class="list-group-item-content">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
            "
          >
            <div class="title">{{ value.title }}</div>
            <div class="create_time">
              <div>{{ value.createTime }}</div>
              &nbsp;<span
                v-if="value.isMember"
                class="label label-success"
                style="text-align: center"
                >会员</span
              >&nbsp;
              <div
                style="
                  color: cornflowerblue;
                  cursor: pointer;
                  white-space: nowrap;
                "
                @click="toDetail(value.id)"
              >
                [详情]
              </div>
            </div>
          </div>
          <p class="desc">{{ value.desc }}</p>
        </div>
      </div>
    </div>
    <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>

    <base-pagination :data="listData.records" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
export default {
  name: "membersListPage",
  data() {
    return {
      listData: {},
      pageJson: null,
    };
  },
  components: {
    basePagination,
  },
  mounted() {
    this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
    this.search(1);
  },
  watch: {
    $route: function () {
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.search(1);
    },
  },
  methods: {
    search(page) {
      var _this = this;
      //   this.paginationData.pageNo = page;
      var url = "/artical/list";
      var body = {};
      body.pageNo = page;
      body.pageSize = 10;
      body.type = this.pageJson.type;
      //   body.type = this.searchFilter.type;
      //   body.title = this.searchFilter.title ? this.searchFilter.title : null;
      body.state = 0;
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.data = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
        // _this.initPagination(e.data.data)
      });
    },
    toDetail(id) {
      console.log(this.pageJson);
      this.$router.push({
        name: this.pageJson.detailPage,
        params: { id: id },
      });
    },
  },
};
</script>
<style scoped>
#membersListContainer {
  padding: 0px 10px;
}
.list_group_item {
  display: flex;
  width: 100%;
  border-bottom: 1px dashed lightgray;
  padding: 8px 0px;
}

.list-group-item-img {
  width: 180px;
  height: 120px;
}
.list-group-member-img {
  width: 120px;
  height: 160px;
}
.list-group-item-content {
  width: 100%;
  padding-left: 10px;
}

.list-group-item-content .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  color: cornflowerblue;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.list-group-item-content .create_time {
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  color: grey;
  white-space: nowrap;
}

.list-group-item-content .desc {
  font-size: 16px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
