<template>
  <div class="bp-container">
    <div class="bp-item">
      <nav style="display: flex;align-items: center;">
        <div style="text-align: center; color: #337ab7; font-size: 14px">
          共{{ data.total }}条
        </div>
        <ul class="pagination" style="padding: 0px 20px">
          <li :class="isLeftEnd ? 'disabled' : ''" style="cursor: pointer"
            @click="!isLeftEnd ? emitMethod(data.current - 1) : ''">
            <a aria-label="Previous"><span aria-hidden="true">&laquo;</span></a>
          </li>
          <li v-for="(value, index) in pageNos" :key="index" :class="value.value" style="cursor: pointer">
            <a @click="emitMethod(value.key)">{{ value.key }}<span class="sr-only">(current)</span></a>
          </li>
          <li :class="isRightEnd ? 'disabled' : ''" style="cursor: pointer"
            @click="!isRightEnd ? emitMethod(data.current + 1) : ''">
            <a aria-label="Previous"><span aria-hidden="true">&raquo;</span></a>
          </li>
        </ul>
        <div class="btn-group">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            第{{ data.current }}页 <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" style="max-height: 120px;overflow-y: auto;">
            <li v-for="(value, index) in totalPage" :key="index" @click="emitMethod(value)" style="cursor: pointer;">
              <a>第{{ value }}页</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "basePagination",
  data() {
    return {
      maxShowPage: 5,
      isLeftEnd: true,
      isRightEnd: true,
      currentPageNo: 0
    };
  },
  props: {
    data: {
      default: function () {
        // 后端使用了baomidou的分页，所以沿用他们的分页结构
        return { current: 1, total: 0, size: 10 };
      },
    },
  },
  watch: {
    'data.pageNo': function () {
      this.isLeftEnd = this.data.current == 1;
      this.isRightEnd = this.data.current == this.totalPage;
      this.currentPageNo = this.data.current
    }
  },
  computed: {
    totalPage: function () {
      if (!this.data.total || this.data.total == 0) {
        return 1;
      } else {
        return (
          parseInt(this.data.total / this.data.size) +
          (this.data.total % this.data.size == 0 ? 0 : 1)
        );
      }
    },
    pageNos: function () {
      var nos = [];
      var startPage =
        this.data.current <= 3
          ? 1
          : this.totalPage - this.data.current <= 2
            ? this.totalPage - this.maxShowPage + 1
            : this.data.current - 2;
      var endPage = Math.min(startPage + this.maxShowPage - 1, this.totalPage);
      for (var i = startPage; i <= endPage; i++) {
        var map = { key: i, value: "" };
        if (i == this.data.current) {
          map.value = "active";
        }
        nos.push(map);
      }
      return nos;
    },
  },
  methods: {
    emitMethod(pageNo) {
      this.$emit("method", parseInt(pageNo));
    },
  },
};
</script>
<style scoped>
.bp-container {
  width: 100%;
  padding: 15px 20px;
}

.bp-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bp-item-cell {
  /* padding:5px; */
  border-radius: 5px;
  border: 1px solid lightgray;
  text-align: center;
  margin: 0px 5px;
  cursor: pointer;
}

/* .active {
  background: cornflowerblue;
  color: white;
  border: 1px solid lightblue;
} */
.bp-page-no {
  width: 20px;
  height: 20px;
  margin: 5px;
  text-align: center;
}
</style>