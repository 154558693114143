<template>
  <div>
    <div id="activityAdminContainer">
      <div style="
          text-align: left;
          font-size: large;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        ">
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group">
            <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
            <input type="number" class="form-control" placeholder="按活动id查询" v-model="searchFilter.id" />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
            <input type="text" class="form-control" placeholder="按活动标题模糊查询" v-model="searchFilter.title" />
          </div>
          <div class="input-group">
            <button type="button" class="btn btn-primary" style="margin-left: 10px" @click="search(1)">
              <span class="glyphicon glyphicon-search"></span>&nbsp;查找
            </button>
            <button type="button" class="btn btn-warning" style="margin-left: 10px" @click="toUpdate(null)">
              新建
            </button>
          </div>
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered">
          <thead>
            <th>Id</th>
            <th>活动编号</th>
            <th>活动名称</th>
            <th>活动描述</th>
            <!-- <th>活动时间</th>                     -->
            <th>报名时间</th>
            <th>条件</th>
            <th>总报名人数</th>
            <th>总金额</th>
            <th>最近更新人</th>
            <!-- <th>状态</th> -->
            <th>操作</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <!-- {{value}} -->
              <td>{{ value.id }}</td>
              <td style="white-space: nowrap">{{ value.actNo }}</td>
              <td style="white-space: nowrap">
                <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 200px;">{{
                  value.title }}</div>
              </td>
              <td>
                <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 500px;">{{ value.desc }}
                </div>
              </td>
              <!-- <td>{{value.startTime}} 至 {{value.endTime}}</td> -->
              <td style="white-space: nowrap">
                <div>{{ value.enrollStartTime }}</div>
                <div>{{ value.enrollEndTime }}</div>
              </td>
              <td style="text-align: left">
                <div>
                  <span v-if="value.isMember == 1" class="label label-success" style="text-align: center">仅限会员</span>
                  <span v-else class="label label-default" style="text-align: center">不限会员</span>
                </div>
                <div>
                  <span v-if="value.fee > 0" class="label label-success" style="text-align: center">报名费用¥{{ value.feeStr
                  }}</span>
                </div>
              </td>
              <td>{{ value.enrollNum }}</td>
              <td>{{ value.totalFee }}</td>
              <td>{{ value.updateByStr }}</td>
              <!-- <td>{{value.statusStr}}</td> -->
              <td>
                <div class="btn-group" role="group" aria-label="...">
                  <!-- <button v-if="value.state == '下线'" type="button" class="btn btn-primary" @click="changeState(value.id,value.state, 0)">上线</button>
                                <button v-if="value.state == '上线'" type="button" class="btn btn-danger" @click="changeState(value.id,value.state, 1)">下线</button>                                 -->
                  <button type="button" class="btn btn-danger" @click="toUpdate(value.actNo)"
                    :disabled="isOnEnroll(value.enrollStartTime)" :title="isOnEnroll(value.enrollStartTime) ? '活动开始后不支持编辑' : ''
                      ">
                    编辑
                  </button>
                  <button type="button" class="btn btn-primary" @click="outputUser(value)">
                    导出用户
                  </button>
                  <button type="button" class="btn btn-default" @click="toEnrollList(value.actNo)">
                    报名记录
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#activityAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
export default {
  name: "activityAdminPage",
  components: {
    basePagination,
  },
  data() {
    return {
      searchFilter: {
        id: "",
        title: "",
      },
      pageInfo: {
        adminPage: "",
        editPage: "",
        detailPage: "",
        createPage: "",
      },
      listData: {
        records: []
      },
      title: "",
      data: {}
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.pageInfo.adminPage = this.$route.name;
      this.title = "活动管理";
      this.pageInfo.createPage = "activityCreatePage";
      this.pageInfo.editPage = "activityEditPage";
      this.search(1);
    },
    search(page) {
      var _this = this;
      var url = "/activity/list";
      var body = {
        pageNo: page,
        pageSize: 10,
        id: this.searchFilter.id == "" ? null : this.searchFilter.id,
        title: this.searchFilter.title == "" ? null : this.searchFilter.title,
      };
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.data = e.data.data
        // _this.$refs.actPagination.render(page, e.data.data.total);
      });
    },
    changeState(id, oldState, newState) {
      var _this = this;
      var url = "/artical/changeState";
      var body = {
        id: id,
        oldState: oldState,
        newState: newState,
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.search(1);
      });
    },
    toDetail(id) {
      this.$router.push({ name: this.pageInfo.detailPage, params: { id: id } });
    },
    toUpdate(actNo) {
      if (actNo == null) {
        this.$router.push({ name: this.pageInfo.createPage });
      } else {
        this.$router.push({
          name: this.pageInfo.editPage,
          params: { actNo: actNo },
        });
      }
    },
    toEnrollList(actNo) {
      this.$router.push({
        name: "actEnrollAdminPage",
        params: { actNo: actNo },
      });
    },
    isOnEnroll(enrollStartTime) {
      var now = new Date();
      var startTime = new Date(enrollStartTime)
      return startTime < now;
    },
    outputUser(value) {
      var _this = this;
      var body = {
        actNo: value.actNo,
      };
      var url = "/activity/outputMember";
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        var path = _this.$bf.getExcelUrl(e.data.data);
        window.open(path, "_blank");
        // _this.search(1)
      });
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>