<template>
    <div>
        <!-- <div class="msg_filter-area">  -->
        <!-- <div class="msg_title">消息通知</div> -->
        <baseModal id="msgModal" confirmBtnStr="确认" >
            
            <template #title>消息通知</template>
            <template #contentDom>
                {{ currentMsg.content }}
            </template>
            <template #footer>
                <button
              type="button"
              class="btn btn-primary"
              @click="isRead"
            >
              我知道了
            </button>
            </template>
        </baseModal>
        <ul class="nav nav-pills msg_filter-area">
            <li role="presentation" class="active" @click="changeType($event, null)"><a href="#">全部</a></li>
            <li role="presentation" @click="changeType($event, 0)"><a href="#">未读</a></li>
            <li role="presentation" @click="changeType($event, 1)"><a href="#">已读</a></li>
        </ul>
        <div v-if="searchFilter.isRead == 0" style="color:red">注：点击即可已读消息</div>
        <div class="msg_list" v-if="data.records && data.records.length > 0">
            <div class="msg_list_item" v-for="(value, index) in data.records" :key="index"
                :style="value.isRead == 0 ? 'cursor:pointer' : ''" @click="clickItem(value)">
                <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%">
                    <div>{{ value.title }}</div>
                    <div style="display:flex;flex-direction:row;color:gray">
                        {{ value.createTime }}
                        <div v-if="value.isRead == 0" class="msg_list_item_tag">新！</div>
                    </div>

                </div>
                <div class="msg_list_item_text">{{ value.contentStr }}
                </div>
            </div>
        </div>
        <div v-else class="no-data-text">查询暂无数据</div>
        <base-pagination :data="data" @method="search"></base-pagination>
    </div>
</template>
<script>
import basePagination from '../base/pagination.vue'
import baseModal from '../base/baseModal.vue'
export default {
    name: "personalMsgSubPage",
    components: {
        basePagination,
        baseModal
    },
    data() {
        return {
            searchFilter: {
                isRead: null
            },
            data: {
            },
            paginationData: {
                pageNo: 1,
                maxPage: 1,
                maxShowPage: 5,
                pageSize: 10,
                data: [],
                isLeftEnd: false,
                isRightEnd: false
            },
            currentMsg: {}
        }
    },
    mounted() {
        this.search(1)
    },
    methods: {
        changeType(event, state) {
            this.$("li[role=presentation]").removeClass('active')
            this.$(event.target).parent().addClass('active')
            this.searchFilter.isRead = state
            this.search(1)
        },
        search(page) {

            var body = {
                pageNo: page,
                pageSize: 5,
                isRead: this.searchFilter.isRead
            }
            var _this = this
            this.$service.post('/push/records', body).then(function (e) {
                _this.data = e.data.data
                // _this.$refs.msgSubPagination.render(page,e.data.data.total)
            })
        },
        clickItem(value) {
            this.currentMsg = value
            this.$("#msgModal").modal("show");
            // if (value.isRead == 0) {
            //     this.$service.get('/push/read?id=' + value.id).then(function (e) {
            //         _this.search(1)
            //     })
            // }
        },
       async isRead() {
            var _this = this
            if (this.currentMsg.isRead == 0) {
              await  this.$service.get('/push/read?id=' + this.currentMsg.id).then(function (e) {
                    
                })
            }
            this.$("#msgModal").modal("hide");
            this.search(1)
        }

    }
}
</script>
<style scoped>
#personalMsgSubContainer {
    padding: 0px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.msg_title {
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px dashed grey;
}

.msg_filter-area {
    width: 100%;
    /* padding: 0px 20px; */
    /* display: flex; */
    white-space: nowrap;
    margin: 15px 0px;
}

.msg_list {
    border: 1px solid lightgray;
    border-radius: 5px;
    /* padding: 0px 15px; */
    margin: 10px 0px;
    width: 100%;
}

.msg_list_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    border-bottom: 1px solid lightgray
}

.msg_list_item_text {
    color: gray;
    width: 100%
}

.msg_list_item_tag {
    color: red;
    font-weight: bold;
    text-align: center;
    padding: 0px 5px;
}

.no_bottom_border {
    border-bottom: unset;
}

.no-data-text {
    padding: 20px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: lightslategrey;
}
</style>
