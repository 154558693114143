import $ from 'jquery'
import router from '../router/index'

const local_path = "file:///E:/TestProject/building_association_web/";
// const SERVER_URL = "http://127.0.0.1:9070"
const SERVER_URL = "https://www.guas.org.cn"
//const STATIC_URL = "http://10.81.196.143:888"
// const STATIC_URL = "http://120.77.169.70:888"
// const STATIC_URL = "http://120.77.169.70:9080"
const STATIC_URL = "https://www.guas.org.cn"
const STATIC_IMG_URL = STATIC_URL + "/static/img/"
const STATIC_IMG_HEAD_URL = STATIC_URL + "/static/head/"
const STATIC_EXCEL_URL = STATIC_URL + "/static/excel/"
const EDITOR_UPLOAD_URL = SERVER_URL + '/api/utils/uploadImg'

const SUB_MENU =
    [
        {
            "name": "首页",
            "page": "capitalPage",
            "data": []
        },
        {
            "name": "新闻动态",
            "page": null,
            "data": [
                {
                    "name": "行业动态",
                    "prefix": "industry",
                    "page": "industryListPage",
                    "detail": "industryDetailPage",
                    "admin": "industryAdminPage"
                },
                {
                    "name": "学会新闻",
                    "prefix": "information",
                    "page": "informationListPage",
                    "detail": "informationDetailPage",
                    "admin": "informationAdminPage"
                },
                {
                    "name": "通知公告",
                    "prefix": "notify",
                    "page": "notifyListPage",
                    "detail": "notifyDetailPage",
                    "admin": "notifyAdminPage"
                },
                {
                    "name": "媒体报道",
                    "prefix": "media",
                    "page": "mediaListPage",
                    "detail": "mediaDetailPage",
                    "admin": "mediaAdminPage"
                },
                {
                    "name": "政策法规",
                    "prefix": "policy",
                    "page": "policyListPage",
                    "detail": "policyDetailPage",
                    "admin": "policyAdminPage"
                },
                {
                    "name": "党史党建",
                    "prefix": "party",
                    "page": "partyListPage"
                },
            ]
        },
        {
            "name": "学术交流",
            "page": null,
            "data": [
                {
                    "name": "学术会议",
                    "prefix": "scholarship",
                    "page": "scholarshipListPage"
                },
                {
                    "name": "学术动态",
                    "prefix": "scholarshipInfo",
                    "page": "scholarshipInfoListPage"
                },
                {
                    "name": "课题研究",
                    "prefix": "subjectResearch",
                    "page": "subjectResearchListPage"
                },
                {
                    "name": "专家智库",
                    "prefix": "specialist",
                    "page": "specialistListPage"
                },
                {
                    "name": "会议报名系统",
                    "prefix": "activity",
                    "page": "activityListPage"
                }
            ]
        },
        {
            "name": "学会奖项",
            "page": "",
            "data": [
                {
                    "name": "BIM大赛",
                    "page": "matchPage",
                    "detail": ""
                },
                {
                    "name": "数建杯",
                    "page": "",
                    "detail": ""
                },
                {
                    "name": "建筑信息化案例征集",
                    "prefix": "case",
                    "page": "caseListPage"
                }
            ]
        },
        {
            "name": "科技服务",
            "page": null,
            "data": [
                {
                    "name": "人才培养",
                    "prefix": "train",
                    "page": "trainListPage"
                },
                {
                    "name": "团体标准",
                    "prefix": "teamStandard",
                    "page": "teamStandardListPage"
                }
            ]
        },
        {
            "name": "科普展览",
            "prefix": "science",
            "page": "scienceListPage",
            "data": []
        },
        {
            "name": "科技培训",
            "page": null,
            "data": [
                {
                    "name": "培训动态",
                    "prefix": "trainStatus",
                    "page": "trainStatusListPage"
                },
                {
                    "name": "古建今谈",
                    "prefix": "newTopic",
                    "page": "newTopicListPage"
                },
                {
                    "name": "小筑画匠",
                    "prefix": "painter",
                    "page": "painterListPage"
                },
                {
                    "name": "培训课程",
                    "prefix": "trainCourse",
                    "page": "trainCourseListPage"
                },
                {
                    "name": "专题讲座",
                    "prefix": "subjectLecture",
                    "page": "subjectLectureListPage"
                },
                {
                    "name": "研讨研究",
                    "prefix": "study",
                    "page": "studyListPage"
                },
                {
                    "name": "论坛沙龙",
                    "prefix": "forum",
                    "page": "forumListPage"
                },
                {
                    "name": "证书查询",
                    "page": "certificateSearchPage"
                }
            ]
        },
        {
            "name": "会员之家",
            "page": null,
            "data": [
                {
                    "name": "会员单位",
                    "prefix": "enterprise",
                    "page": "enterpriseListPage"
                },
                {
                    "name": "会员行规行约",
                    "prefix": "memberRule",
                    "page": "memberRuleDetailPage",
                    "id": 187
                },
                {
                    "name": "会员公告",
                    "prefix": "memberNotice",
                    "page": "memberNoticeListPage"
                },
                {
                    "name": "入会须知",
                    "prefix": "memberAnnounce",
                    "page": "memberAnnounceDetailPage",
                    "id": 189
                },
                {
                    "name": "会员权益",
                    "prefix": "memberRights",
                    "page": "memberRightsDetailPage",
                    "id": 17
                },
                {
                    "name": "会费标准",
                    "prefix": "memberFeeRule",
                    "page": "memberFeeRuleDetailPage",
                    "id": 188
                },
                {
                    "name": "会员登录",
                    "prefix": null,
                    "page": "loginPage"
                },
                {
                    "name": "会员风采",
                    "prefix": "membersShow",
                    "page": "membersShowListPage"
                }
            ]
        },
        {
            "name": "设计师俱乐部",
            "page": null,
            "data": [
                {
                    "name": "申请入库",
                    "prefix": "designer",
                    "page": "designerApplyPage"
                },
                {
                    "name": "设计师库",
                    "prefix": "designerList",
                    "page": "designerListPage"
                },
                {
                    "name": "设计师动态",
                    "prefix": "designerNews",
                    "page": "designerNewsListPage"
                }
            ]
        },
        {
            "name": "关于学会",
            "page": null,
            "data": [
                {
                    "name": "学会简介",
                    "prefix": "associationInfo",
                    "page": "associationInfoDetailPage",
                    "id": 90
                },
                {
                    "name": "章程条例",
                    "prefix": "regulate",
                    "page": "regulateDetailPage",
                    "id": 89
                }, {
                    "name": "秘书处",
                    "prefix": "secretariat",
                    "page": "secretariatDetailPage",
                    "id": 88
                }, {
                    "name": "理事会",
                    "prefix": "council",
                    "page": "councilListPage"
                }, {
                    "name": "学会会讯",
                    "prefix": "associationNews",
                    "page": "associationNewsListPage"
                }, {
                    "name": "分支机构",
                    "prefix": "branch",
                    "page": "branchListPage",
                }, {
                    "name": "工作委员会",
                    "prefix": "commission",
                    "page": "commissionListPage"
                }, {
                    "name": "学会刊物",
                    "prefix": "publication",
                    "page": "publicationListPage",

                }
            ]
        }
    ]

const DESIGNER_TYPE=[
    "建筑师",
    "室内设计师",
    "软装搭配师",
    "工业设计师",
    "家居设计师",
    "景观园林设计师",
    "品牌策划师",
    "其他"
]

const ARTICAL_TYPE = {
    "information": 1,
    "industry": 2,
    "party": 3,
    "activity": 4,
    "notify": 5,
    "media": 6,
    "policy": 7,
    "enterprise": 8,
    "scholarship": 9,
    "scholarshipInfo": 10,
    "subjectResearch": 11,
    "train": 12,
    "teamStandard": 13,
    "trainStatus": 14,
    "newTopic": 15,
    "subjectLecture": 16,
    "specialist": 17,
    "trainCourse": 18,
    "study": 19,
    "forum": 20,
    "associationInfo": 21,
    "regulate": 22,
    "secretariat": 23,
    "council": 24,
    "associationNews": 25,
    "branch": 26,
    "commission": 27,
    "publication": 28,
    "membersShow": 29,
    "science": 30,
    "painter": 31,
    "memberFeeRule": 32,
    "memberAnnounce": 33,
    "memberNotice": 34,
    "memberRule": 35,
    "memberCompany": 36,
    "designerNews": 37,
    "memberRights": 38,
    "case": 39
}

function menuFilter(menus, filterList) {
    var temp = []
    for (var menu in menus) {
        if (filterList.indexOf(menus[menu].name) > -1) {
            continue
        } else {
            temp.push(menus[menu])
        }
    }
    return temp
}


function calPageNavs(prefix) {
    for (var index in SUB_MENU) {
        if (SUB_MENU[index].prefix == prefix) {
            return [
                {
                    name: SUB_MENU[index].name,
                    page: SUB_MENU[index].page
                }
            ]
        }
        for (var index1 in SUB_MENU[index].data) {
            if (SUB_MENU[index].data[index1].prefix == prefix) {
                // console.log(SUB_MENU[index].data[index1].page)
                return [
                    {
                        name: SUB_MENU[index].name,
                        page: SUB_MENU[index].page,
                    },
                    {
                        name: SUB_MENU[index].data[index1].name,
                        page: SUB_MENU[index].data[index1].page
                    }
                ]

            }
        }
    }
    return []
}

function getArticalName(prefixName) {
    // console.log(prefixName)
    for (var index in SUB_MENU) {
        if (SUB_MENU[index].prefix == prefixName) {
            return SUB_MENU[index].name
        }
        for (var index1 in SUB_MENU[index].data) {
            if (SUB_MENU[index].data[index1].prefix == prefixName) {

                return SUB_MENU[index].data[index1].name

            }
        }
    }
    return ""
}

function decodeArticalPrefix(pageName) {
    // 输入相关页面获取对应文章的前缀
    var name = pageName.replace('ListPage', '')
    name = name.replace('DetailPage', '')
    name = name.replace('AdminPage', '')
    name = name.replace('CreatePage', '')
    name = name.replace('EditPage', '')
    name = name.replace('ApplyPage', '')
    var prefixName = ARTICAL_TYPE[name]
    // console.log(calPageNavs(prefixName))
    var json = {
        title: getArticalName(name),
        prefix: name,
        type: prefixName,
        listPage: name + 'ListPage',
        detailPage: name + 'DetailPage',
        adminPage: (prefixName <= 23 && prefixName >= 21) || prefixName == 32 || prefixName == 33 || prefixName == 35 || prefixName == 38 ? null : name + 'AdminPage',
        createPage: name + 'CreatePage',
        editPage: name + 'EditPage',
    }
    return json
}

function getArticalType(pageName) {
    var name = pageName.replace('ListPage', '')
    name = name.replace('DetailPage', '')
    return ARTICAL_TYPE[name]
}

function getArticalPrefix(type) {
    for (var index in ARTICAL_TYPE) {
        if (ARTICAL_TYPE[index] == type) {
            return index
        }
    }
    return null
}

function toUrl(pageName) {
    window.location.replace(local_path + pageName);
}

function calHeight(_this, dom) {
    dom.css('min-height', document.body.clientHeight - _this.$('.root-bottom-footer')[0].offsetHeight - _this.$('.root-top-menu')[0].offsetHeight + 'px')
    // var _this = this

    window.addEventListener('resize', function () {
        dom.css('min-height', document.body.clientHeight - _this.$('.root-bottom-footer')[0].offsetHeight - _this.$('.root-top-menu')[0].offsetHeight + 'px')
    })
}

function dj(dom, show_num) {
    draw(dom, show_num);
}

function draw(dom, show_num) {
    var canvas_width = dom.clientWidth;
    var canvas_height = dom.clientHeight;
    var canvas = dom; //获取到canvas的对象，演员
    var context = canvas.getContext("2d"); //获取到canvas画图的环境，演员表演的舞台
    canvas.width = canvas_width;
    canvas.height = canvas_height;
    var sCode =
        "A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0,q,w,e,r,t,y,u,i,o,p,a,s,d,f,g,h,j,k,l,z,x,c,v,b,n,m";
    var aCode = sCode.split(",");
    var aLength = aCode.length; //获取到数组的长度

    for (var i = 0; i <= 3; i++) {
        var randomIndex = Math.floor(Math.random() * aLength); //获取到随机的索引值
        var deg = Math.random() * 30 * Math.PI / 180; //产生0~30之间的随机弧度
        var txt = aCode[randomIndex]; //得到随机的一个内容
        show_num[i] = txt;
        var x = 10 + i * 20; //文字在canvas上的x坐标
        var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";

        context.translate(x, y);
        context.rotate(deg);

        context.fillStyle = randomColor();
        context.fillText(txt, 0, 0);

        context.rotate(-deg);
        context.translate(-x, -y);
    }
    for (var j = 0; j <= 5; j++) { //验证码上显示线条
        context.strokeStyle = randomColor();
        context.beginPath();
        context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
        context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
        context.stroke();

    }
    for (var k = 0; k <= 30; k++) { //验证码上显示小点
        context.strokeStyle = randomColor();
        context.beginPath();
        var dotX = Math.random() * canvas_width;
        var dotY = Math.random() * canvas_height;
        context.moveTo(dotX, dotY);
        context.lineTo(dotX + 1, dotY + 1);
        context.stroke();

    }
}
function randomColor() { //得到随机的颜色值
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    return "rgb(" + r + "," + g + "," + b + ")";
}

function warningAlert(msg) {
    $("#warningTips").slideToggle()
    $("#warningTips .tips-text").text(msg)
    setTimeout(
        function () {
            $("#warningTips").slideToggle()
        }, 3000
    );
}
function sucAlert(msg) {
    $("#sucTips").slideToggle()
    $("#sucTips").text(msg)
    setTimeout(
        function () {
            $("#sucTips").slideToggle()
        }, 3000
    );
}

function isMobilePhone(value) {
    return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value) && value.length == 11
}
function isMail(value) {
    return /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(value)
}
function isAccount(value) {
    return /^\w+$/.test(value) && value.length >= 4
}
function checkName(value) {
    return /[\u4e00-\u9fa5]/.test(value) && value.length < 8
}
function checkIdNo(value) {
    return 16 < value.length < 18
}
// 脱敏身份证
function encodeIdNo(value) {
    var encode = ""
    for (var index = 0; index < value.length; index++) {
        if (index < 3) {
            encode += value[index]
            continue
        }
        if (value.length - index <= 4) {
            encode += value[index]
            continue
        }
        encode += "*"
    }
    return encode
}
function goToInformationPage(_this, name, title, type) {
    router.push({
        name: name,
        params: { title: title, type: type }
    }).then(function (e) {
        _this.$children[0].search(1)
    })
}

function getImgUrl(fileName) {
    return STATIC_IMG_URL + fileName
}
function getHeadImgUrl(fileName) {
    return STATIC_IMG_HEAD_URL + fileName
}

function getExcelUrl(fileName) {
    return STATIC_EXCEL_URL + fileName
}

function checkImg(file) {
    if (file.size > 1000 * 1000) {
        return "图片文件不能大于1M"
    }
    return null
}

function activeLoading(str) {
    // console.log(str)
    if (str == 'hide') {
        $('#loadingContainer')[0]["__vue__"].hide()
    }
    if (str == 'show') {
        $('#loadingContainer')[0]["__vue__"].active()
    }
}
function dtFormatter(dateTime) {
    var value = dateTime.split(" ")
    return value[0] + 'T' + value[1]
}

function timestampToDate(timestamp) {
    // console.log(timestamp)
    if (timestamp != null) {
        return timestamp.split(" ")[0]
    }
    return ""
}

const baseFunc = {
    toUrl: toUrl,
    draw: draw,
    dj: dj,
    warningAlert: warningAlert,
    sucAlert: sucAlert,
    goToInformationPage: goToInformationPage,
    getImgUrl: getImgUrl,
    calHeight: calHeight,
    checkImg: checkImg,
    STATIC_IMG_URL: STATIC_IMG_URL,
    EDITOR_UPLOAD_URL: EDITOR_UPLOAD_URL,
    DESIGNER_TYPE:DESIGNER_TYPE,
    SERVER_URL: SERVER_URL,
    activeLoading: activeLoading,
    getExcelUrl: getExcelUrl,
    dtFormatter: dtFormatter,
    getHeadImgUrl: getHeadImgUrl,
    isMobilePhone: isMobilePhone,
    isMail: isMail,
    isAccount: isAccount,
    checkName: checkName,
    checkIdNo: checkIdNo,
    encodeIdNo: encodeIdNo,
    SUB_MENU: SUB_MENU,
    calPageNavs: calPageNavs,
    // calDetailNavs: calDetailNavs,
    ARTICAL_TYPE: ARTICAL_TYPE,
    getArticalType: getArticalType,
    getArticalPrefix: getArticalPrefix,
    decodeArticalPrefix: decodeArticalPrefix,
    menuFilter: menuFilter,
    timestampToDate: timestampToDate
}
export default baseFunc