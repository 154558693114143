<template>
  <div style="padding: 20px">
    <notice-modal :id="'specialistEditConfirmModal'" @confirm="submit()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认修改吗？</template>
    </notice-modal>         
    <form role="form" style="padding-bottom: 20px" id="informationEditForm">
      <div class="form-group" style="width: 30%">
        <label for="exampleInputName2">专家真名</label>
        <input
          type="text"
          class="form-control"
          placeholder="专家名称"
          maxlength="20"
          v-model="specialistInfo.name"
        />
      </div>
      <div class="form-group" style="width: 30%">
        <label for="exampleInputName2">所在行业</label>
        <input
          type="text"
          class="form-control"
          placeholder="所在行业"
          maxlength="50"
          v-model="specialistInfo.industry"
        />
      </div>
      <div class="form-group" style="width: 60%">
        <label for="exampleInputName2">工程领域</label>
        <input
          type="text"
          class="form-control"
          placeholder="工程领域"
          maxlength="100"
          v-model="specialistInfo.territory"
        />
      </div>
      <div class="form-group" style="width: 60%">
        <label for="exampleInputName2">所在单位</label>
        <input
          type="text"
          class="form-control"
          placeholder="所在单位"
          maxlength="100"
          v-model="specialistInfo.company"
        />
      </div>
      <div class="form-group" style="width: 60%">
        <label for="exampleInputName2">职务</label>
        <input
          type="text"
          class="form-control"
          placeholder="职务"
          maxlength="50"
          v-model="specialistInfo.job"
        />
      </div>
      <div class="form-group" style="width: 60%">
        <label for="exampleInputName2">职称</label>
        <input
          type="text"
          class="form-control"
          placeholder="职称"
          maxlength="50"
          v-model="specialistInfo.title"
        />
      </div>
      <div class="form-group" style="width: 200px">
        <label for="exampleInputName2">专家类别</label>
        <select type="text" class="form-control" v-model="specialistInfo.type">
          <option
            :value="data"
            :key="index"
            v-for="(data, index) in specialistType"
          >
            {{ data }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label
          for="exampleInputFile"
          style="white-space: nowrap"
          >专家头像</label
        >
        <input
          type="file"
          name="upload"
          ref="bannerFile"
          @change="uploadBanner"
        />
      </div>
      <div class="form-group">
        <div>
          <img
            style="width: 120px; height: 180px"
            v-if="specialistInfo.img != ''"
            :src="$bf.getImgUrl(specialistInfo.img)"
            name="img"
          />
        </div>
      </div>
      <div class="form-group" style="width: 100%">
        <label for="exampleInputName2">专家简介</label>
        <textarea
          type="text"
          class="form-control"
          placeholder="专家简介"          
          maxlength="500"
          style="height:300px"
          v-model="specialistInfo.desc"
        />
      </div>
      <div class="form-group" style="width: 100%">
        <label for="exampleInputName2">从业经历</label>
        <textarea
          type="text"
          class="form-control"
          placeholder="从业经历"
          name="desc"
          maxlength="500"
          style="height:300px"
          v-model="specialistInfo.experience"
        />
      </div>
      <div class="form-group" style="width: 100%">
        <label for="exampleInputName2">成果业绩</label>
        <textarea
          type="text"
          class="form-control"
          placeholder="成果业绩"
          name="desc"
          maxlength="500"
          style="height:300px"
          v-model="specialistInfo.achievement"
        />
      </div>
      <div class="form-group" style="width: 100%">
        <label for="exampleInputName2">社会兼职</label>
        <textarea
          type="text"
          class="form-control"
          placeholder="社会兼职"
          name="desc"
          maxlength="500"
          style="height:300px"
          v-model="specialistInfo.partTime"
        />
      </div>
      <div class="btn-div">
        <button type="button" class="btn btn-primary" @click="showModal">
          保存
        </button>
        <button class="btn btn-default" @click="backToPage()">取消</button>
        <button class="btn btn-danger" @click="clear">清空</button>
      </div>
    </form>
  </div>
</template>
<script>
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "specialistEditPage",
  components: {
    noticeModal,
  },
  data() {
    return {
      specialistInfo: {
        id: 0,
        name: "",
        type: "",
        company: "",
        job: "",
        territory: "",
        title: "",
        img: "",
      },
      paginInfo: {
        prePage: "",
        currentPage: "",
      },
      pageJson: {},
      specialistType: [],
    };
  },

  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    // var _this =this
    this.init();
    this.getType();
  },
  methods: {
    init() {
      this.specialistInfo.id = this.$route.params.id;
      if (this.specialistInfo.id > 0) {
        var _this = this;
        this.$service
          .get("/specialist/detail?id=" + this.specialistInfo.id)
          .then(function (e) {
            _this.specialistInfo = e.data.data;
          });
      }
    },
    submit() {
      if (
        this.specialistInfo.name == "" ||
        this.specialistInfo.name == null ||
        this.specialistInfo.type == "" ||
        this.specialistInfo.type == null ||
        this.specialistInfo.company == "" ||
        this.specialistInfo.company == null ||
        this.specialistInfo.industry == "" ||
        this.specialistInfo.industry == null ||
        this.specialistInfo.job == "" ||
        this.specialistInfo.job == null ||
        this.specialistInfo.territory == "" ||
        this.specialistInfo.territory == null ||
        this.specialistInfo.title == "" ||
        this.specialistInfo.title == null
      ) {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
        // if (this.specialistInfo.img == "") {
        //   this.$bf.warningAlert("图片不能为空");
        //   return;
        // }
      var body = {};
      var _this = this;
      body["name"] = this.specialistInfo.name;
      body["title"] = this.specialistInfo.title;
      body["company"] = this.specialistInfo.company;
      body["industry"] = this.specialistInfo.industry;
      body["job"] = this.specialistInfo.job;
      body["territory"] = this.specialistInfo.territory;
      body["type"] = this.specialistInfo.type;
      body["img"] = this.specialistInfo.img;
      body["desc"] = this.specialistInfo.desc;
      body["experience"] = this.specialistInfo.experience;
      body["achievement"] = this.specialistInfo.achievement;
      body["partTime"] = this.specialistInfo.partTime;
      // body['updateById'] = this.$store.getters.userId
      if (this.specialistInfo.id > 0) {
        body["id"] = this.specialistInfo.id;
        this.$service.post("/specialist/update", body).then(function (e) {
          _this.content = "";
          _this.backToPage();
        });
      } else {
        this.$service.post("/specialist/create", body).then(function (e) {
          _this.content = "";
          _this.backToPage();
        });
      }
    },
    clear() {
      this.specialistInfo.experience = "";
      this.specialistInfo.achievement = "";
      this.specialistInfo.part_time = "";
    },
    backToPage() {
      // console.log(this.pageJson.adminPage)
      this.$router.push({ name: "specialistAdminPage" });
    },
    getType() {
      var _this = this;
      var url = "/config/get?key=SPECIALIST_TYPE";
      this.$service.get(url).then(function (e) {
        _this.specialistType = eval(e.data.data.value);
        if (_this.specialistType.length > 0) {
          _this.specialistInfo.type = _this.specialistType[0];
        }
      });
    },
    uploadBanner() {
      // 每次上传都要清空数据
      var file = this.$refs.bannerFile.files[0];
      var reason = this.$bf.checkImg(file);
      if (reason != null) {
        this.$bf.warningAlert(reason);
        this.$refs.bannerFile.value = "";
        this.specialistInfo.img = "";
        return;
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      var _this = this;
      this.$service.post("/utils/upload", formData).then(function (e) {
        _this.specialistInfo.img = e.data.data.name;
        _this.$refs.bannerFile.value = "";
      });
    },
    showModal() {
      this.$("#specialistEditConfirmModal").modal("show");
    },
  },
};
</script>
<style scoped>
.btn-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  padding: 15px;
}
</style>

