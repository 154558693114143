<template>
  <div>
    <notice-modal :id="'userChangeStateModal'" @confirm="changeState()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认修改用户状态吗？</template>
    </notice-modal>     
    <notice-modal :id="'cancelUserMemberModal'" @confirm="cancelMember()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认撤销用户的会员吗？</template>
    </notice-modal>     
    <notice-modal :id="'userChangeRoleModal'" @confirm="changeRole()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认修改用户角色吗？</template>
    </notice-modal>         
    <apply-member-modal
      :id="'applyMemberModal'"
      :data="modalData"
      @confirm="addMember"
    ></apply-member-modal>    
    <div id="userAdminContainer">
      <div
        style="
          text-align: left;
          font-size: large;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group" style="text-align: center; padding: 0px 10px">
            <select
              class="form-control"
              v-model="searchFilter.type"
              @change="search(1)"
            >
              <option value="null">全部</option>
              <option value="0">普通用户</option>
              <option value="1">内部成员</option>
              <option value="3">评分员</option>
              <option value="2">管理员</option>
            </select>
            <!-- <div class="radio-inline">
                            <label>
                                <input type="radio" name="optionsRadios" value="1" checked @click="changeType(null)" >
                                显示全部用户
                            </label>
                        </div>
                        <div class="radio-inline">
                            <label>
                                <input type="radio" name="optionsRadios" value="2" @click="changeType(1)">
                                仅显示内部用户
                            </label>
                        </div> -->
          </div>
          <div class="form-group">
            <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
            <input
              type="text"
              class="form-control"
              placeholder="根据手机号码搜索"
              v-model="searchFilter.phone"
              @blur="search(1)"
            />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
            <input
              type="text"
              class="form-control"
              placeholder="根据名字搜索"
              v-model="searchFilter.name"
              @blur="search(1)"
            />
          </div>
          <!-- <div class="input-group" >
                    <button type="button" class="btn btn-primary" style="margin-left:10px" @click="search(1)">
                        <span class="glyphicon glyphicon-search"></span>&nbsp;查找</button>                                   
                     </div> -->
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered">
          <thead>
            <th>Id</th>
            <th>账号</th>
            <th>姓名</th>
            <th>电话</th>
            <th>邮件</th>
            <th>角色</th>
            <th>状态</th>
            <th>会员状态</th>
            <th>操作</th>
            <th>角色变更</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.account }}</td>
              <td v-if="value.name">{{ value.name }}</td>
              <td v-else>-</td>
              <td>{{ value.phone }}</td>
              <td>{{ value.mail }}</td>
              <td>
                {{ value.typeStr }}
                <!-- <span v-if="value.type ==0" class="label label-default">{{value.typeStr}}</span>
                            <span v-if="value.type ==1" class="label label-success">{{value.typeStr}}</span>
                            <span v-if="value.type ==2" class="label label-danger">{{value.typeStr}}</span>
                            <span v-if="value.type ==3" class="label label-danger">{{value.typeStr}}</span> -->
              </td>
              <td>{{ value.stateStr }}</td>
              <td>
                <div class="btn-group" role="group">
                  <!-- <button v-if="value.state == '禁用' && value.id != $store.getters.userId" type="button" class="btn btn-primary" @click="changeState(value.id, 0)">启用</button> -->
                  <button
                    v-if="
                      value.state == '1' && value.id != $store.getters.userId
                    "
                    type="button"
                    class="btn btn-primary"
                    @click="showModal('userChangeStateModal', value.id, 0, -1)"
                  >
                    启用
                  </button>
                  <!-- <button v-if="value.state == '正常' && value.id != $store.getters.userId" type="button" class="btn btn-danger" @click="changeState(value.id, 1)">禁用</button>                                 -->
                  <button
                    v-if="
                      value.state == '0' && value.id != $store.getters.userId
                    "
                    type="button"
                    class="btn btn-danger"
                    @click="showModal('userChangeStateModal', value.id, 1, -1)"
                  >
                    禁用
                  </button>
                </div>
              </td>
              <td>
                <button
                  v-if="!value.isMember"
                  type="button"
                  class="btn btn-primary"
                  @click="showMemberModal(value.id)"
                >
                  设置成会员
                </button>
                <button v-else type="button" class="btn btn-danger"
                @click="showCancelModal(value.id)"
                >
                  撤销会员
                </button>
              </td>
              <td>
                <div
                  class="btn-group"
                  role="group"
                  v-if="$store.getters.userInfo.role == '1'"
                >
                  <button
                    v-if="
                      value.id != $store.getters.userId &&
                      (value.roleId == '1' || value.roleId == '3')
                    "
                    type="button"
                    class="btn btn-default"
                    @click="showModal('userChangeRoleModal', value.id, -1, 0)"
                  >
                    设置成普通用户
                  </button>
                  <!-- <button v-if="value.id != $store.getters.userId && value.roleId != '1'" type="button" class="btn btn-warning" @click="showModal('userChangeRoleModal', value.id, -1, 1)">设置成内部成员</button> -->
                  <!-- <button v-if="value.id != $store.getters.userId && value.roleId != '2'" type="button" class="btn btn-danger" @click="showModal('userChangeRoleModal', value.id, -1, 2)">设置成管理员</button>-->
                  <button
                    v-if="
                      value.id != $store.getters.userId && value.roleId == '1'
                    "
                    type="button"
                    class="btn btn-primary"
                    @click="showModal('userChangeRoleModal', value.id, -1, 3)"
                  >
                    设置成评分员
                  </button>
                </div>
                <div
                  class="btn-group"
                  role="group"
                  v-if="$store.getters.userInfo.role == '2'"
                >
                  <button
                    v-if="
                      value.id != $store.getters.userId &&
                      (value.roleId == '1' || value.roleId == '3')
                    "
                    type="button"
                    class="btn btn-default"
                    @click="showModal('userChangeRoleModal', value.id, -1, 0)"
                  >
                    设置成普通用户
                  </button>
                  <button
                    v-if="
                      value.id != $store.getters.userId &&
                      (value.roleId == '0' || value.roleId == '3')
                    "
                    type="button"
                    class="btn btn-warning"
                    @click="showModal('userChangeRoleModal', value.id, -1, 1)"
                  >
                    设置成内部成员
                  </button>
                  <!-- <button v-if="value.id != $store.getters.userId && value.roleId != '2'" type="button" class="btn btn-danger" @click="showModal('userChangeRoleModal', value.id, -1, 2)">设置成管理员</button>-->
                  <button
                    v-if="
                      value.id != $store.getters.userId && value.roleId == '0'
                    "
                    type="button"
                    class="btn btn-primary"
                    @click="showModal('userChangeRoleModal', value.id, -1, 3)"
                  >
                    设置成评分员
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#userAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
import applyMemberModal from "../base/applyMemberModal.vue";
export default {
  name: "userAdminPage",
  components: {
    basePagination,
    noticeModal,
    applyMemberModal,
  },
  data() {
    return {
      searchFilter: {
        id: null,
        name: null,
        type: null,
      },
      updateInfo: {
        id: 0,
        state: 0,
        type: 0,
      },
      pageInfo: {
        adminPage: "",
        editPage: "",
        detailPage: "",
        createPage: "",
      },
      listData: [],
      title: "",
      data: {},
      modalData: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.title = "用户管理";
      this.search(1);
    },
    search(page) {
      var _this = this;
      var body = {
        pageNo: page,
        pageSize: 10,
        phone: this.searchFilter.phone ? this.searchFilter.phone : null,
        name: this.searchFilter.name ? this.searchFilter.name : null,
        type: this.searchFilter.type,
      };
      var url = "/account/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.userPagination.render(page,e.data.data.total)
      });
    },
    changeState() {
      var _this = this;
      var url = "/admin/changeState";
      var body = {
        id: this.updateInfo.id,
        state: this.updateInfo.state,
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.search(1);
      });
    },
    changeRole() {
      var _this = this;
      var url = "/admin/admin";
      var body = {
        id: this.updateInfo.id,
        type: this.updateInfo.type,
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.search(1);
      });
    },
    changeType(type) {
      this.searchFilter.type = type;
    },
    showModal(domId, id, state, type) {
      this.updateInfo.id = id;
      this.updateInfo.state = state;
      this.updateInfo.type = type;
      this.$("#" + domId).modal("show");
    },
    showMemberModal(userId) {
      this.modalData = {
        userId: userId,
        type: "1",
        year: "1",
      };
      this.$("#applyMemberModal").modal("show");
    },
    showCancelModal(userId) {
      this.modalData = {
        userId: userId,
      };
      this.$("#cancelUserMemberModal").modal("show");
    },
    addMember() {
      var _this = this;
      var json = {
        userId: this.modalData.userId,
        type: this.modalData.type,
        year: this.modalData.year,
      };
      // console.log(json)
      this.$service.post("/member/create", json).then(function (e) {
        _this.$("#applyMemberModal").modal("hide");
        _this.search(1);
      });
    },
    cancelMember() {
      var _this = this;
      var json = {
        userId: this.modalData.userId,
      };
      this.$service.post("/member/cancel", json).then(function (e) {
        _this.$("#cancelUserMemberModal").modal("hide");
        _this.search(1);
      });
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>