<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
          <h4 class="modal-title" style="text-align: center; font-weight: bold">
            {{ !skuInfo ? "活动报名" : skuInfo.name }}
          </h4>
        </div>
        <div class="modal-body" style="text-align: center">
          <form
            role="form"
            class="form-horizontal"
            style="width: 100%"
            v-if="skuInfo"
          >
            <img
              style="
                height: 100px;
                border-radius: 10px;
                border: solid 1px lightgray;
              "
              src="../../../public/static/alipay_logo.jpg"
            />
          </form>
          <form
            role="form"
            class="form-horizontal"
            style="width: 100%"
            v-if="skuInfo"
          >
            <div
              class="form-group"
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              本次活动报名费用：
              <div style="color: cornflowerblue">{{ skuInfo.feeStr }}元</div>
            </div>
          </form>
          <form
            role="form"
            class="form-horizontal"
            style="width: 100%"
            v-if="!skuInfo"
          >
            <div
              class="form-group"
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              是否确认参加本次活动
            </div>
          </form>
        </div>
        <div class="modal-footer" style="text-align: center">
          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            @click="closeMethod"
          >
            关闭
          </button>
          <button
            v-if="skuInfo"
            type="button"
            class="btn btn-primary"
            @click="pay()"
          >
            支付
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="submitMethod"
          >
            报名
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "applyActivityModal",
  data() {
    return {
      title: "活动报名",
      skuInfo: {},
    };
  },
  props: {
    id: {},
    data: {
      id: 0,
    },
    deviceWay: {
      default: function () {
        return "WEBPAY";
      },
    },
  },
  watch: {
    "data.id": function () {
      // console.log(this.data)
      if (this.data.id != 0) {
        this.getSku();
      }
    },
  },
  methods: {
    closeMethod() {
      this.data.id = 0;
      this.$(this).modal("hide");
    },
    submitMethod() {
      this.data.id = 0;
      this.$emit("pay", this.userInfo);
    },
    pay() {
      // console.log(this.deviceWay)
      this.$router.push({
        name: "payOrderPage",
        params: {
          skuNo: this.skuInfo.skuNo,
          userId: this.$store.getters.userInfo.id,
          deviceWay: this.deviceWay
        },
      });
    },
    getSku() {
      var _this = this;
      var json = {
        id: this.data.id,
      };
      this.$service.post("/activity/config", json).then(function (e) {
        _this.skuInfo = e.data.data;
        console.log(_this.skuInfo);
      });
    },
  },
};
</script>
<style scoped>
.qrcode {
  display: inline-block;
}
</style>
