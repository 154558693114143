<template>
  <div>
    <div id="fileAdminContainer">
      <upload-file-modal id="uploadFileModal"></upload-file-modal>
      <notice-modal :id="'infoAdminOnlineModal'" @confirm="changeState()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
    </notice-modal>          
      <div
        style="
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              placeholder="根据id搜索"
              v-model="searchFilter.id"
            />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <select
              class="form-control"
              placeholder="文件名称模糊查询"
              v-model="searchFilter.type"
            >
              <option value="全部">全部</option>
              <option value="图片">图片</option>
              <option value="视频">视频</option>
              <option value="文件">文件</option>
            </select>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;查找
          </button>
          <button
            type="submit"
            class="btn btn-warning"
            style="margin-left: 10px"
            @click="$('#uploadFileModal').modal('show')"
          >
            上传文件
          </button>
        </form>
      </div>
      <div>
        <table
          v-if="listData.records.length != 0"
          class="table table-bordered"
          style="font-size: 14px"
        >
          <thead>
            <th>Id</th>
            <th>名称</th>
            <th>类型</th>
            <th>链接</th>
            <th>描述</th>
            <th>创建时间</th>
            <th>更新时间</th>
            <!-- <th>操作</th> -->
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.name }}

                <img v-if="value.type=='图片'" :src="value.link" />
              </td>
              <td>{{ value.type }}</td>
              <td>{{ value.link }}</td>
              <td>{{ value.desc }}</td>
              <td>{{ value.createTime }}</td>
              <td>{{ value.updateTime }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
import uploadFileModal from "../base/uploadFileModal.vue";
export default {
  name: "fileAdminPage",
  components: {
    basePagination,
    noticeModal,
    uploadFileModal,
  },
  data() {
    return {
      searchFilter: {
        id: null,
        // type: 1,
        name: null,
        type: "全部",
      },
      listData: [],
      title: "文件管理",
      data: {},
      //   pageJson: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  methods: {
    init() {
      //   this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.searchFilter = {
        id: null,
        type: "全部",
        name: null,
      };
      this.search(1);
    },
    search(page) {
      var _this = this;
      var body = {};
      body.id = this.searchFilter.id ? this.searchFilter.id : null;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.type =
        this.searchFilter.type == "全部" ? null : this.searchFilter.type;
      body.pageNo = page;
      body.pageSize = 10;
      //   body.type = this.pageJson.type;
      var url = "/file/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.filePagination.render(page, e.data.data.total);
      });
    },
    create() {
      this.$router.push({ name: this.pageJson.createPage });
    },
    update(id) {
      this.$router.push({ name: this.pageJson.editPage, params: { id: id } });
    },
    showModal(domId, value, newState) {
      this.$("#" + domId).modal("show");
      this.changeStateParams.id = value.id;
      this.changeStateParams.oldState = value.state;
      this.changeStateParams.newState = newState;
      this.changeStateParams.type = value.type;
    },
  },
};
</script>
<style scoped>
#fileAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>