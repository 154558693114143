<template>
  <div id="designersListContainer">
    <div v-for="(value, index) in data" :key="index" class="card">
      <div class="card-title">
        <div>{{ index }}</div>
        <div style="font-weight: 0px">查看更多</div>
      </div>
      <div class="card-container">
        <div
          class="card-item"
          v-for="(value1, index1) in value"
          :key="index1"
          :style="index1 == 0 ? '' : 'margin-left:15px'"
        >
          <img class="card-img" :src="$bf.getImgUrl(value1.img)" />
          <div class="card-info">
            <p>姓名：{{ value1.name }}</p>
            <p style="text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;" :title="value1.enterprise">单位：{{ value1.enterprise }}</p>
            <p>职务：{{ value1.job }}</p>
          </div>
          <div class="card-bt">查看信息</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "designersListPage",
  mounted() {
    this.$bf.calHeight(this, this.$("#designersListContainer"));
    this.getDesigners();
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getDesigners() {
      var _this = this;
      this.$service.get("/designer/sorts").then(function (e) {
        _this.data = e.data.data;
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
#designersListContainer {
  width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}
.card {
  background: #336699;
  border: 1px solid #336699;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* flex:1 */
}
.card-title {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: whitesmoke;
  padding: 10px;
  font-size: 14px;
}
.card-item {
  text-align: center;
  border: 1px solid lightgray;
  /* padding: 10px; */
  background: white;
  flex: 1;
}
.card-img {
  width: 100px;
  height: 140px;
  background: lightgray;
  margin: 10px;
}
.card-info {
  padding: 10px;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom:unset;
  /* justify-content: flex-start; */
}
.card-bt {
    background: #336699;
    color: white;
    padding: 10px 0px;
    cursor: pointer;
    font-size:16px;
}
</style>
