<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <h4 class="modal-title" style="text-align:center;font-weight:bold">{{title}}</h4>
            </div>
            <div class="modal-body" style="text-align:center">
                <form role="form" class="form-horizontal" style="width:100%">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-sm-2">原密码</label>
                        <div class="col-sm-6">
                            <input type="password" class="form-control"
                            name="name"
                            v-model="data.password">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1"  class="col-sm-2">新密码</label>
                        <div class="col-sm-6">
                            <input type="password" class="form-control"
                            name="account"
                            v-model="data.newPassword">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1"  class="col-sm-2">再次确认新密码</label>
                        <div class="col-sm-6">
                            <input type="password" class="form-control"
                            name="mail"
                            v-model="data.newPassword2">
                        </div>
                    </div>
                    </form>
            </div>
            <div class="modal-footer" style="text-align:center">
                <button type="button" class="btn btn-primary" @click="updateMethod">修改</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal -->
</div>
</template>
<script>
export default {
    name:"modifyPWModal",
    data (){
        return {
            title: "用户密码修改"
        }
    },
    props:{
        id: {},
        contentHtml: {
            default: ''
        },        
        data: {
            default: ""
        }
    },
    mounted() {
        // console.log(this.$('#modalBodyContent'))
        // this.$('#modalBodyContent').append(this.contentHtml)
    },
    methods: {
        closeMethod() {                
            this.$(this).modal('hide')
        },
        updateMethod() {
            this.$emit('update', this.userInfo)           
        }
        // fillContent(content) {
        //     this.$('#modalBodyContent').append(content)
        // }
    }
}
</script>
<style scoped>

</style>
