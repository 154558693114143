<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title" style="text-align:center;font-weight:bold">{{ title }}</h4>
                </div>
                <div class="modal-body" style="text-align:center" v-if="data">
                    <form role="form" class="form-horizontal" style="width:100%">
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="col-sm-2">发票类型</label>
                            <div class="col-sm-6">
                                <select class="form-control" v-model="data.type" disabled="true">
                                    <option value=1>增值税专用发票</option>
                                    <option value=2>增值税普通发票</option>
                                    <option value=3>增值税电子发票</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">纳税人识别号</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" v-model="data.identification">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">地址</label>
                            <div class="col-sm-6">
                                <input type="text" disabled="true" class="form-control" v-model="data.address">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">电话</label>
                            <div class="col-sm-6">
                                <input type="text" disabled="true" class="form-control" v-model="data.phone">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">开户行</label>
                            <div class="col-sm-6">
                                <input type="text" disabled="true" class="form-control" v-model="data.bank">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">开户账号</label>
                            <div class="col-sm-6">
                                <input type="text" disabled="true" class="form-control" v-model="data.bankNo">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="col-sm-2">是否邮寄</label>
                            <div class="col-sm-6">
                                <select class="form-control" disabled="true" v-model="data.isSend">
                                    <option value=1>是</option>
                                    <option value=0>否</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" v-if="data.isSend == 1">
                            <label for="exampleInputPassword1" class="col-sm-2">邮寄地址</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.sendAddress">
                            </div>
                        </div>
                        <div class="form-group" v-if="data.isSend == 1">
                            <label for="exampleInputPassword1" class="col-sm-2">收件人</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.receiptor">
                            </div>
                        </div>
                        <div class="form-group" v-if="data.isSend == 1">
                            <label for="exampleInputPassword1" class="col-sm-2">收件电话</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.sendPhone">
                            </div>
                        </div>
                        <div class="form-group" v-if="data && data.state == 1 && data.isSend == 1">
                            <label for="exampleInputPassword1" class="col-sm-2">快递单号</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.sendNo">
                            </div>
                        </div>
                        <div class="form-group" v-if="data && data.state == 1">
                            <label for="exampleInputPassword1" class="col-sm-2">发票号</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.receiptNo">
                            </div>
                        </div>
                        <div class="form-group" v-if="data && data.state == -1">
                            <label for="exampleInputPassword1" class="col-sm-2">驳回理由</label>
                            <div class="col-sm-6">
                                <textarea type="text" class="form-control" disabled="true" name="mail"
                                    v-model="data.reason">
                                    </textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer" style="text-align:center">
                    <!-- <button type="button" class="btn btn-primary" @click="reject">驳回</button> -->
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal -->
    </div>
</template>
<script>
export default {
    name: "receiptInfoModal",
    data() {
        return {
            title: "发票信息"
        }
    },
    props: {
        id: {},
        data: {
            default: {
                type: ""
            }
        }
    },
    mounted() {
        // this.init()
        // console.log(this.recordId)
    },
    methods: {
        init() {
            console.log(this.recordId)
            var _this = this
            this.$service.get("/receipt/info?id=" + this.recordId).then(function (e) {
                _this.data = e.data.data
            })
        },
        closeMethod() {
            this.$(this).modal('hide')
        }
    }
}
</script>
