<template>
  <div>
    <div id="specialistAdminContainer">
      <notice-modal :id="'specialistAdminOnlineModal'" @confirm="changeState()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
      </notice-modal>
      <div style="
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        ">
        &nbsp;{{ pageJson.title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group">
            <input type="number" class="form-control" placeholder="根据id搜索" v-model="searchFilter.id" />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input type="text" class="form-control" placeholder="姓名模糊查询" v-model="searchFilter.name" />
          </div>
          <div class="form-group">
            <select class="form-control" placeholder="状态" v-model="searchFilter.state">
              <option value="null">全部状态</option>
              <option value="0">上线</option>
              <option value="-1">下线</option>
            </select>
          </div>
          <button type="button" class="btn btn-primary" style="margin-left: 10px" @click="search(1)">
            <span class="glyphicon glyphicon-search"></span>&nbsp;查找
          </button>
          <button type="submit" class="btn btn-warning" style="margin-left: 10px" @click="create(0)">
            新建
          </button>
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered" style="font-size: 14px">
          <thead>
            <th>编号</th>
            <th>专家名称</th>
            <th>专家类别</th>
            <th>工程领域</th>
            <th>所在行业</th>
            <th>工作单位</th>
            <th>职务</th>
            <th>职称</th>
            <th>状态</th>
            <th>操作</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.name }}</td>
              <td>{{ value.type }}</td>
              <td>{{ value.territory }}</td>
              <td>{{ value.industry }}</td>
              <td>{{ value.company }}</td>
              <td>{{ value.job }}</td>
              <td>{{ value.title }}</td>
              <td>{{ value.stateStr }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="...">
                  <button v-if="value.state == '-1'" type="button" class="btn btn-primary"
                    @click="showModal('specialistAdminOnlineModal', value, 0)">
                    上线
                  </button>
                  <button v-if="value.state == '0'" type="button" class="btn btn-danger"
                    @click="showModal('specialistAdminOnlineModal', value, -1)">
                    下线
                  </button>
                  <button type="button" class="btn btn-default" @click="update(value.id)">
                    编辑
                  </button>
                  <!-- <button v-if="searchFilter.type!= 4" type="button" class="btn btn-primary" @click="toDetail(value.id)">预览</button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#specialistAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "specialistAdminPage",
  components: {
    basePagination,
    noticeModal,
  },
  data() {
    return {
      searchFilter: {
        id: null,
        type: 1,
        name: null,
        state: null,
      },
      changeStateParams: {
        id: 0,
        oldState: "",
        newState: "",
        type: "",
      },
      pageInfo: {
        adminPage: "",
        editPage: "",
        detailPage: "",
        createPage: "",
      },
      listData: {},
      title: "",
      pageJson: null,
      data: {},
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  methods: {
    init() {
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.searchFilter = {
        id: null,
        type: 1,
        name: null,
        state: null,
      };
      this.search(1);
    },
    search(page) {
      var _this = this;
      var body = {};
      body.id = this.searchFilter.id ? this.searchFilter.id : null;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.pageNo = page;
      body.pageSize = 10;
      // body.type = this.pageJson.type
      body.state =
        this.searchFilter.state == "" ? null : this.searchFilter.state;
      var url = "/specialist/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
      });
    },
    changeState() {
      var _this = this;
      var body = {
        id: this.changeStateParams.id,
        oldState: this.changeStateParams.oldState,
        newState: this.changeStateParams.newState,
      };
      var url = "/specialist/changeState";
      this.$service.post(url, body).then(function (e) {
        // _this.sucAlert(e.data.msg)
        _this.search(1);
      });
    },
    create() {
      this.$router.push({ name: this.pageJson.createPage });
    },
    update(id) {
      this.$router.push({ name: this.pageJson.editPage, params: { id: id } });
    },
    showModal(domId, value, newState) {
      this.$("#" + domId).modal("show");
      this.changeStateParams.id = value.id;
      this.changeStateParams.oldState = value.state;
      this.changeStateParams.newState = newState;
      this.changeStateParams.type = value.type;
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>