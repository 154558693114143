<template>
    <div id="certificateContainer">
        <div id="certificateFilter">
            <img style="width: 90px; height: 90px" alt="广东省城市建筑学会" src="../../../public/static/assiation_logo.jpg" />
            <h2 style="font-weight:bold;letter-spacing: 5px;">广东省城市建筑学会证书查询</h2>
            <div style="width:100%;padding:20px;display: flex;justify-content: space-around;">
                <!-- <div class="input-group" style="padding: 0px 10px;">
                    <select class="form-control" v-model="searchParams.type">
                        <option value="1">证书编号</option>
                        <option value="2">公司名称</option>
                    </select>
                </div>
                <div class="input-group" style="width:60%">
                    <input type="text" class="form-control" placeholder="请输入证书相关内容" v-model="searchParams.value">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="search(1)">查询证书</button>
                    </span>
                </div> -->
                <div class="input-group" style="width:30%">
                    <span class="input-group-addon">证书编号</span>
                    <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="请输入证书编号"
                        v-model="searchParams.certNo">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="search(1)">搜索</button>
                    </span>
                </div>
                <div class="input-group" style="width:30%">
                    <span class="input-group-addon">公司名称</span>
                    <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="请输入公司名称"
                        v-model="searchParams.companyName">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="search(1)">搜索</button>
                    </span>
                </div>
                <div class="input-group" style="width:30%">
                    <span class="input-group-addon">报名编号</span>
                    <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="请输入报名编号"
                        v-model="searchParams.enrollNo">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="search(1)">搜索</button>
                    </span>
                </div>
            </div>
            <div id="certificateTips">为保证个人用户隐私，请输入完整的搜索信息，暂不支持模糊搜索</div>
            <div id="certificateResultTips">* 说明：如果结果为空，表明此库中没有此信息，未查到证书的可能原因是：刚参加考试， 还未发证。如有疑问请致：网站管理员</div>
        </div>
        <div id="certResultTable">
            <table class="table table-striped table-bordered" v-if="listData.records && listData.records.length > 0">
                <thead>
                    <th>比赛名称</th>
                    <th>证书编号</th>
                    <th>证书类型</th>
                    <th>组别</th>
                    <th>作品名称</th>
                    <th>名次</th>
                    <th>任职单位</th>
                    <th>获奖人</th>
                    <th>获奖单位</th>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in listData.records" :key="index">
                        <td>{{ value.title }}</td>
                        <td>{{ value.certNo }}</td>
                        <td>{{ value.typeStr }}</td>
                        <td>
                            <div>{{ value.group }}</div>
                            <div>{{ value.enGroup }}</div>
                        </td>
                        <td>
                            <div>{{ value.workName }}</div>
                            <div>{{ value.enWorkName }}</div>
                        </td>
                        <td>
                            <div>{{ value.prize }}</div>
                            <div>{{ value.enPrize }}</div>
                        </td>
                        <td>
                            <div>{{ value.company }}</div>
                            <div>{{ value.enCompany }}</div>
                        </td>
                        <td>
                            <div>{{ value.userName }}</div>
                            <div>{{ value.enUserName }}</div>
                        </td>
                        <td>
                            <div>{{ value.enterprise }}</div>
                            <div>{{ value.enEnterprise }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="no-data-text">查询暂无数据</div>
        </div>
        <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
</template>
<script>
import basePagination from '../base/pagination.vue'
export default {
    name: 'certificateSearchPage',
    components: {
        basePagination,
        // noticeModal
    },
    data() {
        return {
            searchParams: {
                certNo: null,
                enrollNo: null,
                companyName: null,
                value: "",
                type: "1" // 1.按用户搜索，2.按证书码搜索
            },
            listData: {},
            pageData: {}
        }
    },
    mounted() {
        this.$bf.calHeight(this, this.$('#certificateContainer'))
    },
    methods: {
        search(page) {
            var url = "/cert/search"
            var _this = this
            var body = {
                pageNo: page,
                pageSize: 5
            }
            if (!this.searchParams.certNo && !this.searchParams.companyName && !this.searchParams.enrollNo) {
                this.$bf.warningAlert("请输入对应的搜索条件")
                return
            }
            body.certNo = this.searchParams.certNo?this.searchParams.certNo:null
            body.companyName = this.searchParams.companyName?this.searchParams.companyName:null
            body.enrollNo = this.searchParams.enrollNo?this.searchParams.enrollNo:null
            this.$service.post(url, body).then(function (e) {
                _this.listData = e.data.data
            })
        },
        changeType(type) {
            this.searchParams.type = type
        }
    }
}
</script>
<style scoped>
#certificateContainer {
    width: 100%;
    min-height: 100%;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('../../../public/static/certBg.png'); */
}

#certificateTips {
    font-size: small;
    color: lightslategray;
}

#certificateFilter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#certResultTable {
    width: 70%;
    /* padding: 50px; */
    font-size: small;
    /* height:100% */
}

#certificateResultTips {
    font-size: small;
    color: red;
}

.no-data-text {
    padding: 20px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: lightslategrey;
}
</style>
