<template>
  <div>
    <div id="bannerAdminContainer">
      <notice-modal :id="'infoAdminOnlineModal'" @confirm="changeState()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
      </notice-modal>
      <div
        style="
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group" style="padding: 0px 10px">
            <select
              class="form-control"
              placeholder="状态"
              v-model="searchFilter.state"
            >
              <option value="null">全部状态</option>
              <option value="0">上线</option>
              <option value="1">下线</option>
            </select>
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <select
              class="form-control"
              placeholder="类型"
              v-model="searchFilter.type"
            >
              <option value="null">全部类型</option>
              <option value="2">左侧1号图</option>
              <option value="7">右侧3号图</option>
              <option value="1">焦点图</option>
              <option value="4">顶部banner</option>
              <option value="3">右侧1号图</option>
              <option value="5">右侧2号图</option>
              <option value="6">腰部banner</option>
            </select>
          </div>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              placeholder="根据id搜索"
              v-model="searchFilter.id"
            />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input
              type="text"
              class="form-control"
              placeholder="标题模糊查询"
              v-model="searchFilter.title"
            />
          </div>
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;查找
          </button>
          <button
            type="submit"
            class="btn btn-warning"
            style="margin-left: 10px"
            @click="toUpdate(0)"
          >
            新建
          </button>
        </form>
      </div>
      <div>
        <table
          v-if="listData.records.length != 0"
          class="table table-bordered"
          style="font-size: 14px"
        >
          <thead>
            <th>Id</th>
            <th>对内标题</th>
            <th>图片</th>
            <th>类型</th>
            <th>最近更新人</th>
            <th>创建时间</th>
            <th>更新时间</th>
            <th>状态</th>
            <th>操作</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.title }}</td>
              <td>{{ value.img }}</td>
              <td>{{ value.typeStr }}</td>
              <td>{{ value.updateByStr }}</td>
              <td>{{ value.createTime }}</td>
              <td>{{ value.updateTime }}</td>
              <td>{{ value.stateStr }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="...">
                  <button
                    v-if="value.state == '1'"
                    type="button"
                    class="btn btn-primary"
                    @click="showModal( value, 0)"
                  >
                    上线
                  </button>
                  <button
                    v-if="value.state == '0'"
                    type="button"
                    class="btn btn-danger"
                    @click="showModal(value, 1)"
                  >
                    下线
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    @click="toUpdate(value.id)"
                  >
                    编辑
                  </button>
                  <!-- <button v-if="searchFilter.type!= 4" type="button" class="btn btn-primary" @click="toDetail(value.id)">预览</button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#bannerAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "bannerAdminPage",
  components: {
    basePagination,
    noticeModal,
  },
  data() {
    return {
      searchFilter: {
        id: "",
        type: null,
        title: "",
        state: null,
      },
      changeStateParams: {
        id: 0,
        oldState: "",
        newState: "",
        type: "",
      },
      pageInfo: null,
      currentPage: 1,
      listData: {},
      title: "banner管理",
      data: {},
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "searchFilter.type": function () {
      this.search(1)
    },
    "searchFilter.state": function () {
      this.search(1)
    }
  },
  methods: {
    init() {
      this.pageInfo = this.$bf.decodeArticalPrefix(this.$route.name);
      this.search(1);
    },
    search(page) {
      var _this = this;
      this.currentPage = page;
      var body = {};
      body.id = this.searchFilter.id ? this.searchFilter.id : null;
      body.title = this.searchFilter.title ? this.searchFilter.title : null;
      body.type = this.searchFilter.type ? this.searchFilter.type : null;
      body.pageNo = page;
      body.pageSize = 10;
      body.state = this.searchFilter.state ? this.searchFilter.state : null;
      var url = "/advert/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
      });
    },
    changeState() {
      var _this = this;
      var body = {
        id: this.changeStateParams.id,
        oldState: this.changeStateParams.oldState,
        newState: this.changeStateParams.newState,
        type: this.changeStateParams.type,
      };
      var url = "/advert/changeState";

      this.$service.post(url, body).then(function (e) {
        _this.search(_this.currentPage);
      });
    },
    toDetail(id) {
      this.$router.push({ name: this.pageInfo.detailPage, params: { id: id } });
    },
    toUpdate(id) {
      if (id <= 0) {
        this.$router.push({ name: this.pageInfo.createPage });
      } else {
        this.$router.push({ name: this.pageInfo.editPage, params: { id: id } });
      }
    },
    showModal(value, newState) {
      this.$("#infoAdminOnlineModal").modal("show");
      this.changeStateParams.id = value.id;
      this.changeStateParams.oldState = value.state;
      this.changeStateParams.newState = newState;
      this.changeStateParams.type = value.type;
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>