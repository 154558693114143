<template>
    <div class="match_container">
        <page-nav :current="match.name"></page-nav>
        <div class="info-detail-container">
            <div style="width:100%">
                <div class="information_title">
                    {{ match.name }}
                </div>
                <div class="info-info-detail">发布时间：{{ match.createTime }}
                    <div style="display:flex;flex-direction:row;width:100%;justify-content: center;">
                        <div style="font-weight:bold">比赛时间：</div>{{ match.startTime }} 至 {{ match.endTime }}
                    </div>
                </div>
            </div>
            <div style="display:flex;flex-direction:column;width:100%;justify-content: center;align-items: center;">
                <div style="font-weight:bold;color:cornflowerblue;padding-bottom:10px">报名时间：{{ match.enrollStartTime }} 至
                    {{ match.enrollEndTime }}</div>
                <button style="width:200px" v-if="match.enrollState !== 'END_UP_ENROLL'"
                    :disabled="match.enrollState == 'UN_ENROLL'" type="button" class="btn btn-primary"
                    @click="jumpToEnrollPage()">
                    {{ match.enrollState == 'UN_ENROLL' ? '未到报名时间' : '前往报名' }}</button>
                <button style="width:200px" v-else type="button" class="btn btn-primary" @click="jumpToEnrollPage()">
                    '进入比赛'</button>
            </div>
            <div class="information_detail" id="infoContentDetail">
            </div>
            <div style="display:flex;flex-direction:column;width:100%;justify-content: center;align-items: center;">
                <div style="font-weight:bold;color:cornflowerblue;padding-bottom:10px">报名时间：{{ match.enrollStartTime }} 至
                    {{ match.enrollEndTime }}</div>
                <button style="width:200px" v-if="match.enrollState !== 'END_UP_ENROLL'"
                    :disabled="match.enrollState == 'UN_ENROLL'" type="button" class="btn btn-primary"
                    @click="jumpToEnrollPage()">
                    {{ match.enrollState == 'UN_ENROLL' ? '未到报名时间' : '前往报名' }}</button>
                <button style="width:200px" v-else type="button" class="btn btn-primary" @click="jumpToEnrollPage()">
                    '进入比赛'</button>
            </div>
        </div>
    </div>
</template>
<script>
// import noticeModal from '../base/noticeModal.vue'
import pageNav from "../base/pageNav.vue";
export default {
    name: 'matchDetailPage',
    components: {
        pageNav
    },
    data() {
        return {
            title: '',
            match: {}
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$bf.calHeight(this, this.$('.match_container'))
            this.getDetail()
            // this.matchNo = this.$route.params.matchNo
        },
        getDetail() {
            var _this = this
            var url = 'match/detail?matchNo=' + this.$route.params.matchNo
            this.$('#infoContentDetail').empty()
            if (this.$route.params.matchNo) {
                this.$service.get(url).then(function (e) {
                    _this.match = e.data.data
                    if (e.data.code == 405) {
                        _this.$router.push({ name: 'unMemberPage' })
                    }
                    _this.$('#infoContentDetail').append(_this.match.content)
                })
            }
        },
        jumpToEnrollPage() {
            // var now = new Date()
            // var start = new Date(this.match.enrollStartTime)
            // var end = new Date(this.match.enrollEndTime)
            // if (!(start < now && now < end)) {
            //     this.$bf.warningAlert("还没有到报名时间")
            //     return
            // }
            this.$router.push({ name: 'matchPage' })
        }
    }
}
</script>
<style scoped>
.match_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    /* padding:20px; */
    height: 100%;
    width: 100%;
    background: white;
}

.information_detail {
    font-size: 16px;
    padding: 15px 30px;
    line-height: 26px;
    width: 100%;
    letter-spacing: 1px;
    word-spacing: 1px;
    line-height: 20x
        /* border-bottom: 1px solid lightgray; */
}

.info-detail-container {
    width: 1400px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

.information_title {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 20px;
}

.info-info-detail {
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: grey;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}</style>