<template>
  <div id="specialistListPage">
    <page-nav></page-nav>
    <div style="width: 1400px">
      <div
        style="
          padding: 10px 0px;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid lightgray;
        "
      >
        <div style="font-weight: bold; color: #336699; font-size: 20px">
          专家智库
        </div>
        <div class="info-filter-area">
          <select type="text" class="form-control" v-model="searchFilter.type">
            <option value="">全部类型</option>
            <option
              v-for="(data, index) in specialistType"
              :key="index"
              :value="data"
            >
              {{ data }}
            </option>
          </select>
          <input
            type="text"
            style="width: 240px; margin-left: 10px"
            class="form-control"
            placeholder="专家名称"
            v-model="searchFilter.name"
          />
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;搜索
          </button>
        </div>
      </div>
      <div
        class="row"
        style="
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          padding-top: 20px;
        "
      >
        <div
          style="padding: 0px 10px; width: 280px"
          v-for="(data, index) in specialistData.records"
          :key="index"
        >
          <div class="thumbnail" style="width: 100%">
            <div
              style="
                width: 100%;
                height: 100%;
                text-align: center;
                background: #336699;
              "
            >
              <img
                style="padding: 5px 0px; height: 180px; width: 120px"
                v-if="data.img"
                :alt="data.name"
                :src="$bf.getImgUrl(data.img)"
              />
              <img
                style="padding: 5px 0px; height: 180px; width: 140px"
                v-else
                alt="广东省城市建筑学会"
                src="../../../public/static/defaultpic.gif"
              />
            </div>
            <div class="caption">
              <p style="font-weight: bold; font-size: 18px">
                {{ data.name }}
              </p>
              <div style="font-size: 14px">
                <p>专家类别：{{ data.type }}</p>
                <p>行&nbsp;&nbsp;业：{{ data.industry }}</p>
                <p>领&nbsp;&nbsp;域：{{ data.territory }}</p>
                <p>职&nbsp;&nbsp;称：{{ data.title }}</p>
                <p style="border-bottom: 1px dashed lightgrey"></p>
                <p>所在单位：{{ data.company }}</p>
                <p>职&nbsp;&nbsp;务：{{ data.job }}</p>
              </div>
              <div style="display: flex; justify-content: center">
                <a
                  class="btn btn-primary"
                  role="button"
                  @click="toDetail(data.id)"
                  >更多信息</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="specialistData.records.length == 0" class="no-data-text">
        查询暂无数据
      </div>
      <base-pagination :data="specialistData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import pageNav from "../base/pageNav.vue";
export default {
  name: "specialistListPage",
  components: {
    basePagination,
    pageNav,
  },
  data() {
    return {
      specialistType: [],
      specialistData: [],
      searchFilter: {
        name: "",
        type: "",
      },
      pageNavs: [],
      pageJson: {},
      data: {},
    };
  },
  mounted() {
    this.init();
    this.getType();
    this.search(1);
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#specialistListPage"));
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.pageNavs = this.$bf.calPageNavs(this.pageJson.prefix);
    },
    getType() {
      var _this = this;
      var url = "/config/get?key=SPECIALIST_TYPE";
      this.$service.get(url).then(function (e) {
        _this.specialistType = eval(e.data.data.value);
      });
    },
    search(page) {
      var _this = this;
      var url = "/specialist/list";
      var json = {
        pageNo: page,
        pageSize: 10,
        state: 0,
        type: this.searchFilter.type == "" ? null : this.searchFilter.type,
        name: this.searchFilter.name == "" ? null : this.searchFilter.name,
      };
      this.$service.post(url, json).then(function (e) {
        _this.specialistData = e.data.data
      });
    },
    toDetail(id) {
      this.$router.push({ name: "specialistDetailPage", params: { id: id } });
    },
  },
};
</script>
<style scoped>
#specialistListPage {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.info-filter-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* text-align: right; */
  padding: 10px 0px;
}
.thumbnail {
  box-shadow: 1px 1px 15px 0px lightgray;
  padding: 0px;
}
.thumbnail:hover {
  box-shadow: 1px 1px 15px 10px lightgray;
}
</style>
