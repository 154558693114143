<template>
    <div id="payOrderContainer">
        <div>请勿关闭当前页面，正在唤起支付，请在支付页面完成支付操作...</div>
        <div style="font-size:14px;color:red">完成支付后会自动跳转</div>
    </div>
</template>
<script>
export default {
    name : 'payOrderPage',
    data(){
        return {
            skuNo: "",
            userId: 0,
            timer:null,
            order: null,
            isTimeout: false
        }
    },
    mounted(){
        // console.log(this.$route.params)
        this.$bf.calHeight(this,this.$('#payOrderContainer'))
        this.skuNo = this.$route.params.skuNo    
        this.userId =  this.$route.params.userId
        this.device = this.$route.params.deviceWay
    },
    watch:{
        "skuNo": {
            handler(newval,oldval){
                if(newval) {
                    this.createOrder()
                }                
            }
        }
    },
    methods:{
        createOrder(){
            var _this = this            
            var json = {
                skuNo: this.skuNo,
                deviceWay: this.device
            }
            if(this.userId) {
                json.userId = this.userId
            }
            this.$service.post("/order/create",json).then(function (e){
                _this.order = e.data.data
                window.location.replace(e.data.data.alipayUrl + '?' +_this.order.alipayResponseBody)    
                // _this.startInterval()
            })
        } 
    }
}
</script>
<style scoped>
#payOrderContainer {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-size: 18px;
    font-weight:bold;
    color:cornflowerblue
}
</style>

