<template>
  <ol class="breadcrumb pathNav" style="margin-bottom:unset">
    <li>
      <a class="item" @click="clickNavItem('capitalPage')">首页</a>
    </li>
    <li v-for="(data, index) in pageNavs" :key="index">
      <a class="item" @click="clickNavItem(data.page)">{{ data.name }}</a>
    </li>
    <li v-if="current">
      <a class="item">{{ current }}</a>
    </li>
  </ol>
</template>
<script>
export default {
  name: "pageNav",
  data() {
    return {
      pageInfo: {},
      pageNavs: [],
    };
  },
  props: {    
    current: {
      default: "",
    },
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {      
      this.pageInfo = this.$bf.decodeArticalPrefix(this.$route.name);
      this.pageNavs = this.$bf.calPageNavs(this.pageInfo.prefix);      
    },
    clickNavItem(pageName) {
      if (pageName) {
        this.$router.push({ name: pageName });
      }
    },
  },
};
</script>
<style scoped>
.pathNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  padding: 8px;
  background: cornsilk;
  color: gray;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
}
.pathNav .item {
  cursor: pointer;
}
.pathNav .item:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>