<template>
  <div id="capitalPageContainer">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 1050px;
      "
    >
      <div
        class="content-col"
        style="width: 350px; border-left: 1px solid lightgray"
      >
        <div class="industry_plane module-plane" style="padding-top: 0px">
          <div class="heading_title">
            <div class="text">行业动态</div>
            <div
              class="more"
              @click="$router.push({ name: 'industryListPage' })"
            >
              更多
            </div>
          </div>
          <div v-for="(value, index) in industryData" :key="index">
            <div v-if="index == 0" class="first_item">
              <img
                style="height: 100px; width: 160px; background-color: lightgray"
                :alt="value.title"
                :src="
                  value.img
                    ? $bf.getImgUrl(value.img)
                    : '../../public/static/defaultpic.gif'
                "
              />
              <div class="content">
                <div
                  class="title"
                  style="-webkit-line-clamp: 2; white-space: unset"
                  :title="value.title"
                  @click="toDetail('industry', value.id)"
                >
                  {{ value.title }}
                </div>
                <div
                  class="desc"
                  style="-webkit-line-clamp: 3"
                  v-if="index == 0"
                >
                  {{ value.desc }}
                </div>
                <div class="create_time">
                  {{ $bf.timestampToDate(value.createTime) }}
                </div>
              </div>
            </div>
            <div v-else class="item">
              <div
                class="title"
                @click="toDetail('industry', value.id)"
                :title="value.title"
              >
                {{ value.title }}
              </div>
              <div class="create_time">
                {{ $bf.timestampToDate(value.createTime) }}
              </div>
            </div>
          </div>
        </div>
        <img
          class="home-side-banner"
          :alt="banner && banner[2] ? banner[2].title : ''"
          :style="
            banner && banner[2]
              ? 'cursor:pointer;border-top: 1px solid lightgray;'
              : '../../public/static/defaultpic.gif'
          "
          :src="
            banner && banner[2]
              ? $bf.getImgUrl(banner[2].img)
              : '../../public/static/defaultpic.gif'
          "
          @click="imgClick(banner[2])"
        />

        <div class="party_plane module-plane">
          <div class="heading_title">
            <div class="text">党史党建</div>
            <div class="more" @click="$router.push({ name: 'partyListPage' })">
              更多
            </div>
          </div>
          <div v-for="(value, index) in partyData" :key="index">
            <div v-if="index == 0" class="first_item">
              <img
                style="height: 100px; width: 160px; background-color: lightgray"
                :alt="value.title"
                :src="
                  value.img
                    ? $bf.getImgUrl(value.img)
                    : '../../public/static/defaultpic.gif'
                "
              />
              <div class="content">
                <div
                  class="title"
                  style="-webkit-line-clamp: 2; white-space: unset"
                  :title="value.title"
                  @click="toDetail('industry', value.id)"
                >
                  {{ value.title }}
                </div>
                <div
                  class="desc"
                  style="-webkit-line-clamp: 3"
                  v-if="index == 0"
                >
                  {{ value.desc }}
                </div>
                <div class="create_time">
                  {{ $bf.timestampToDate(value.createTime) }}
                </div>
              </div>
            </div>
            <div v-else class="item">
              <div class="title" @click="toDetail('party', value.id)">
                {{ value.title }}
              </div>
              <div class="create_time">
                {{ $bf.timestampToDate(value.createTime) }}
              </div>
            </div>
          </div>
        </div>
        <div class="law_plane module-plane">
          <div class="heading_title">
            <div class="text">政策法规</div>
            <div class="more" @click="$router.push({ name: 'policyListPage' })">
              更多
            </div>
          </div>
          <div v-for="(value, index) in policyData" :key="index" class="item">
            <div>
              <div
                class="title"
                @click="toDetail('policy', value.id)"
                :title="value.title"
              >
                {{ value.title }}
              </div>
              <div class="desc" style="-webkit-line-clamp: 2">
                {{ value.desc }}
              </div>
            </div>
            <div class="create_time">
              {{ $bf.timestampToDate(value.createTime) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="content-col"
        style="
          width: 700px;
          border-left: 1px solid lightgray;
          border-right: 1px solid lightgray;
        "
      >
        <div
          id="carousel-example-generic"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators" style="bottom: 0px">
            <li
              data-target="#carousel-example-generic"
              :data-slide-to="index"
              v-for="(data, index) in mainBanners"
              :key="index"
              :class="index == 0 ? 'active' : ''"
            ></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div
              :class="index == 0 ? ' item active' : 'item'"
              v-for="(data, index) in mainBanners"
              :key="index"
              style="cursor: pointer"
              @click="imgClick(data)"
            >
              <img
                v-if="data.img"
                class="main-banner"
                :alt="data.title"
                :src="$bf.getImgUrl(data.img)"
              />
              <div
                class="carousel-caption"
                style="
                  font-size: 20px;
                  font-weight: bold;
                  background-color: rgba(0, 0, 0, 0.4);
                  bottom: 0px;
                  text-align: center;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                  right: unset;
                  left: unset;
                  padding-left: 30px;
                  padding-right: 30px;
                "
              >
                {{ data.title }}
              </div>
            </div>
          </div>
          <a
            class="left carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="prev"
          >
            <span
              class="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="right carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="next"
          >
            <span
              class="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="information_plane" style="padding-top: 10px">
          <div class="heading_title">
            <div class="text">学会新闻</div>
            <div
              class="more"
              @click="$router.push({ name: 'informationListPage' })"
            >
              更多
            </div>
          </div>
          <div
            v-for="(value, index) in informationData"
            :key="index"
            class="item"
          >
            <img
              style="
                min-width: 160px;
                min-height: 100px;
                max-width: 160px;
                max-height: 100px;
              "
              :alt="value.title"
              :src="
                value.img
                  ? $bf.getImgUrl(value.img)
                  : '../../public/static/defaultpic.gif'
              "
            />
            <div style="padding-left: 10px; width: 100%; height: 100px">
              <div
                class="title"
                style="white-space: unset"
                @click="toDetail('information', value.id)"
                :title="value.title"
              >
                {{ value.title }}
              </div>
              <div class="desc" style="-webkit-line-clamp: 3">
                {{ value.desc }}
              </div>
              <div class="create_time">
                发布于：{{ $bf.timestampToDate(value.createTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content-col"
        style="width: 350px; border-right: 1px solid lightgray"
      >
        <div class="main_page_plane">
          <div
            style="
              border: 1px solid lightgrey;
              padding: 10px 20px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              cursor: pointer;
            "
            @click="$router.push({ name: 'applyMemberPage' })"
          >
            <img
              style="width: 90px; height: 90px"
              alt="广东省城市建筑学会"
              src="../../public/static/assiation_logo.jpg"
            />
            <div style="font-size: 20px; font-weight: bold">会员申请</div>
          </div>
        </div>
        <div class="main_page_plane" style="padding-top: 10px">
          <div class="heading_title">
            <div class="text">通知公告</div>
            <div class="more" @click="$router.push({ name: 'notifyListPage' })">
              更多
            </div>
          </div>
          <div id="rollMainContent" class="roll_main_content">
            <div class="roll_inner_content">
              <div
                v-for="(value, index) in notifyData"
                :key="index"
                class="list_item"
              >
                <div style="width: 100%">
                  <div
                    class="title"
                    :title="value.title"
                    @click="toDetail('notify', value.id)"
                  >
                    {{ value.title }}
                  </div>
                  <div class="desc" style="-webkit-line-clamp: 2">
                    {{ value.desc }}
                  </div>
                </div>
                <div class="create_time">
                  {{ $bf.timestampToDate(value.createTime) }}
                </div>
              </div>
              <div class="divider-group">
                <div class="divider"></div>
                <div class="text">最新通告</div>
                <div class="divider"></div>
              </div>
              <div
                v-for="(value, index) in notifyData"
                :key="'second' + index"
                class="list_item"
              >
                <div style="width: 100%">
                  <div
                    class="title"
                    :title="value.title"
                    @click="toDetail('notify', value.id)"
                  >
                    {{ value.title }}
                  </div>
                  <div class="desc" style="-webkit-line-clamp: 2">
                    {{ value.desc }}
                  </div>
                </div>
                <div class="create_time">
                  {{ $bf.timestampToDate(value.createTime) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="specialist_plane"
          style="padding-top: 20px; border-bottom: 1px solid lightgrey"
        >
          <div class="heading_title">
            <div class="text">专家智库</div>
            <div
              class="more"
              @click="$router.push({ name: 'specialistListPage' })"
            >
              更多
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            "
          >
            <div
              v-for="(value, index) in specialistData"
              :key="index"
              class="item"
              style="flex-direction: column; text-align: center"
            >
              <img
                :src="$bf.getImgUrl(value.img)"
                :alt="value.name"
                style="height: 120px; width: 80px"
              />
              <div
                class="title"
                style="padding-top: 5px"
                @click="toDetail('specialist', value.id)"
              >
                {{ value.name }}
              </div>
            </div>
          </div>
        </div>
        <img
          class="home-side-banner"
          v-if="banner && banner[3]"
          :alt="banner && banner[3] ? banner[3].title : ''"
          :style="banner && banner[3] ? 'cursor:pointer' : ''"
          :src="banner && banner[3] ? $bf.getImgUrl(banner[3].img) : ''"
          @click="imgClick(banner[3])"
        />
        <img
          class="home-side-banner"
          v-if="banner && banner[5]"
          :alt="banner && banner[5] ? banner[5].title : ''"
          :style="banner && banner[5] ? 'cursor:pointer' : ''"
          :src="banner && banner[5] ? $bf.getImgUrl(banner[5].img) : ''"
          @click="imgClick(banner[5])"
        />
        <img
          class="home-side-banner"
          v-if="banner && banner[7]"
          :alt="banner && banner[7] ? banner[7].title : ''"
          :style="banner && banner[7] ? 'cursor:pointer' : ''"
          :src="banner && banner[7] ? $bf.getImgUrl(banner[7].img) : ''"
          @click="imgClick(banner[7])"
        />
      </div>
    </div>
    <img
      class="home-middle-banner"
      :alt="banner && banner[6] ? banner[6].title : ''"
      :style="banner && banner[6] ? 'cursor:pointer' : ''"
      :src="banner && banner[6] ? $bf.getImgUrl(banner[6].img) : ''"
      @click="imgClick(banner[6])"
    />
    <div
      style="
        width: 1400px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-left: 1px lightgray solid;
        border-right: 1px lightgrey solid;
      "
    >
      <div class="members_plane">
        <div class="heading_title">
          <div class="text">会员风采</div>
          <div
            class="more"
            @click="$router.push({ name: 'membersShowListPage' })"
          >
            更多
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-top: 5px;
          "
        >
          <div
            v-for="(value, index) in membersData"
            :key="index"
            class="item"
            style="flex-direction: column; text-align: center"
          >
            <img
              :alt="value.name"
              :src="$bf.getImgUrl(value.img)"
              style="height: 120px; width: 80px"
            />
            <div
              class="title"
              style="padding-top: 5px"
              @click="toDetail('membersShow', value.id)"
            >
              {{ value.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="min_plane">
        <div class="heading_title">
          <div class="text">古建今谈</div>
          <div class="more" @click="$router.push({ name: 'newTopicListPage' })">
            更多
          </div>
        </div>
        <div v-for="(value, index) in newTopicData" :key="index" class="item">
          <div
            class="title"
            @click="toDetail('newTopic', value.id)"
            :title="value.title"
          >
            {{ value.title }}
          </div>
          <!-- <div class="desc">{{value.desc}}</div>                     -->
          <div class="create_time" style="font-size: 14px">
            {{ $bf.timestampToDate(value.createTime) }}
          </div>
        </div>
      </div>
      <div class="min_plane">
        <div class="heading_title">
          <div class="text">会员企业</div>
          <div
            class="more"
            @click="$router.push({ name: 'enterpriseListPage' })"
          >
            更多
          </div>
        </div>
        <div v-for="(value, index) in enterpriseData" :key="index" class="item">
          <div
            class="title"
            @click="toDetail('enterprise', value.id)"
            :title="value.title"
          >
            {{ value.title }}
          </div>
          <!-- <div class="desc">{{value.desc}}</div>                     -->
          <div class="create_time" style="font-size: 14px">
            {{ $bf.timestampToDate(value.createTime) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "capitalPage",
  data() {
    return {
      banner: null,
      industryData: null,
      informationData: null,
      partyData: null,
      notifyData: null,
      policyData: null,
      specialistData: null,
      newTopicData: null,
      enterpriseData: null,
      membersData: null,
      mainBanners: [
        {
          img: "",
        },
      ],
      mainBannerIndex: 0,
      mainBannerTitle: "",
      bannerTimer: null,
      switch: {},
      data: {
        banner: {
          mainBanner: "",
          leftBanner: "",
          right1Banner: "",
          right2Banner: "",
        },
        timer: null,
      },
      matchStatus: null,
      matchImg: "",
      matchNo: "",
      canEnroll: null,
      rollDiv: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
    if (this.bannerTimer) clearInterval(this.bannerTimer);
  },
  methods: {
    init() {
      var _this = this;
      this.getAdverts();
    },
    startRolling() {
      this.rollDiv = document.getElementsByClassName("roll_main_content")[0];
      var _this = this;
      this.rollDiv.onpointerenter = function () {
        _this.stopInterval();
      };
      this.rollDiv.onpointerleave = function () {
        _this.startInterval();
      };
      this.startInterval();
    },
    getAdverts() {
      var _this = this;
      this.$service.get("/advert/adverts").then(function (e) {
        var adverts = e.data.data;
        // _this.matchStatus = e.data.data.matchStatus
        _this.industryData = adverts.industryData;
        _this.informationData = adverts.informationData;
        _this.partyData = adverts.partyData;
        _this.notifyData = adverts.notifyData;
        _this.policyData = adverts.policyData;
        _this.specialistData = adverts.specialistData;
        // _this.specialistData.push(adverts.specialistData[0])
        // _this.specialistData.push(adverts.specialistData[0])
        // _this.specialistData.push(adverts.specialistData[0])
        _this.membersData = adverts.membersData;
        // _this.membersData.push(adverts.membersData[0]);
        _this.newTopicData = adverts.newTopicData;
        _this.enterpriseData = adverts.enterpriseData;
        _this.matchNo = adverts.matchNo;
        _this.canEnroll = adverts.canEnroll;
        _this.matchStatus = adverts.matchStatus;
        _this.matchImg = adverts.matchImg;
        _this.banner = adverts.banner;
        _this.mainBanners = adverts.mainBanners;
        _this.mainBannerTitle = _this.mainBanners[0].title;
        _this.$emit("getTopBanner", _this.banner[4]);
        _this.startRolling();
        // console.log()
      });
    },

    toDetail(prefix, id) {
      this.$router.push({ name: prefix + "DetailPage", params: { id: id } });
    },
    toMatchDetail() {
      this.$router.push({
        name: "matchDetailPage",
        params: { matchNo: this.matchNo },
      });
    },
    imgClick(banner) {
      if (banner == null) {
        return;
      }
      if (banner.url === "" || banner.url === null) {
        return;
      }
      if (banner.type == 4) {
        // 跳转页面详情
        this.$router.push({
          name: "informationDetailPage",
          params: { id: banner.url },
        });
      } else {
        window.open(banner.url, "_blank");
      }
    },
    startInterval() {
      var _this = this;
      var rollInnerDom =
        document.getElementsByClassName("roll_inner_content")[0];
      this.timer = setInterval(() => {
        // console.log(document.getElementsByClassName("roll_main_content")[0])
        // console.log(_this.$('.roll_main_content')[0].scrollTop,(_this.$('.roll_inner_content')[0].offsetHeight))
        // 当滚动高度大于列表内容高度时恢复为0
        if (this.rollDiv.scrollTop >= rollInnerDom.offsetHeight / 2) {
          // console.log(1)
          this.rollDiv.scrollTop = 0;
        } else {
          this.rollDiv.scrollTop += 2;
        }
      }, 100);
    },
    stopInterval() {
      clearInterval(this.timer);
    },
    getTopBanner() {
      this.$emit("getTopBanner", "xxxxx");
    },
    changeBanner(type) {
      // this.$('img[class^=main-banner-item]').removeClass('active')
      var _this = this;
      var nextIndex = 0;
      // if (type == 1) {
      //     nextIndex = _this.mainBannerIndex == _this.mainBanners.length - 1 ? 0:_this.mainBannerIndex + 1
      // }
      // if (type == 0) {
      //     nextIndex = _this.mainBannerIndex == 0 ? _this.mainBanners.length - 1:_this.mainBannerIndex - 1
      // }
      _this.$(".main-banner")[0].scrollLeft = 450;
      _this.$(".main-banner")[0].animate(
        {
          scrollLeft: "450",
        },
        100
      );
    },
  },
};
</script>
<style scoped>
#capitalPageContainer {
  width: 100%;
  background: white;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin: 20px 0px; */
  padding: 10px 10px;
  /* border-left: 1px solid lightgray;
    border-right: 1px solid lightgray; */

  /* padding: 8px 0px; */
}

.home-middle-banner {
  width: 1400px;
  /* background:black; */
  height: 160px;
  margin: 10px 0px;
}

.min_plane {
  flex: 1;
  padding: 0px 10px;
}
.members_plane {
  flex: 1;
  padding: 0px 10px;
}
.main-bottom {
  display: flex;
  flex-direction: row;
}

/* #mainMidBanner{
    width:1300px;
    height: 120px;
    border-radius: 3px;
} */
.main_page_plane {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  background-color: white;
  /* padding: 10px 15px; */
  /* flex:1;     */
}
.module-plane {
  padding-top: 5px;
}

.information_plane {
}

.information_plane .item {
  display: flex;
  /* flex-direction: column; */
  margin: 10px 0px;
  /* height: 100px; */
}

/* .information_plane .item .title {
    font-size: 16px;
    font-weight: bold;    
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
} */

/* .information_plane .item .desc {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 14px
} */

/* .information_plane .item .create_time {
    font-size: 14px;
    width: 100%;
    text-align: right;
    color: gray;
} */

.specialist_plane {
}

.specialist_plane .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px;
  /* height:60px; */
}

/* .specialist_plane .item .title {
    font-size: 16px
} */

/* .specialist_plane .item .create_time {
    white-space: nowrap;
    color: gray;
    font-size: 14px;
} */

.industry_plane {
}

.industry_plane .item {
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  align-items: center;
  /* height: 35px; */
}

.industry_plane .first_item {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  /* height: 130px; */
}

.industry_plane .first_item .content {
  padding-left: 10px;
}

.industry_plane .first_item .content .create_time {
  width: 100%;
  color: gray;
  text-align: right;
}

.party_plane {
}

.party_plane .first_item {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.party_plane .first_item .content {
  padding-left: 10px;
}
.party_plane .item {
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  align-items: center;
  /* height: 30px; */
}

/* .party_plane .item .title {
    width: 100%;
    font-size: 16px;
    color: cornflowerblue;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
} */

.party_plane .item .desc {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

/* .party_plane .item .create_time {    
    white-space: nowrap;
    font-size: 14px;
    text-align: right;
    color: grey;
} */

.law_plane {
}

.law_plane .item {
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  padding: 3px 0px;
  /* height: 65px; */
}

/* .law_plane .item .title {
    width: 100%;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
} */

/* .law_plane .item .desc {
    width: 100%;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
} */

/* .law_plane .item .create_time {
    white-space: nowrap;
    font-size: 14px;
    text-align: right;
    color: grey;
} */

.min_plane .first_item {
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .min_plane .first_item .title {
    width: 100%;
    font-size: 16px;    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
} */

/* .min_plane .first_item .desc {
    width: 100%;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
} */

/* .min_plane .first_item .create_time {
    white-space: nowrap;
    font-size: 14px;
    text-align: right;
    color: grey;
} */

.min_plane .item {
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  padding: 4px 0px;
}

/* .min_plane .item .title {
    margin: 5px 0px;
    font-size: 16px;    
} */

/* .min_plane .item .create_time {
    margin: 5px 0px;
    color: grey;

} */

.heading_title {
  white-space: nowrap;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  font-size: 16px;
  white-space: nowrap;
  /* background: lightgray; */
  color: #003366;
}

.heading_title .text {
  font-weight: bold;
  font-size: 17px;
}

.heading_title .more {
  color: #6699cc;
  cursor: pointer;
}

.title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .roll_main_content {
  height: 300px;
  overflow: hidden;
  font-size: 16px;
}

.main_page_plane .roll_main_content .roll_inner_content {
  width: 100%;
}

.main_page_plane .roll_main_content .roll_inner_content .list_item {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* height:80px */
  padding: 5px 0px;
}

/* .main_page_plane .roll_main_content .roll_inner_content .list_item .title { */
/* white-space: nowrap; */
/* width: 100%; */
/* color: cornflowerblue; */
/* padding-left:10px; */
/* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-weight: bold; */
/* } */

/* .main_page_plane .roll_main_content .roll_inner_content .list_item .desc {
    font-size: 14px;
    color: grey;    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
} */

/* .main_page_plane .roll_main_content .roll_inner_content .list_item .create_time {
    white-space: nowrap;
    text-align: right;
    font-size: 14px;
    color: grey
} */

.main_page_plane .heading_title {
  /* width: 100%; */
  /* background: lightgray; */
  white-space: nowrap;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px;
  font-size: 16px;
}

.main_page_plane .heading_title .text {
  white-space: nowrap;
  font-weight: bold;
  /* background: lightgray; */
  color: #003366;
}

.main_page_plane .heading_title .more {
  color: #6699cc;
  cursor: pointer;
}

.main_page_plane .main_content {
  font-size: 16px;
}

.main_page_plane .main_content .industry_list_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0px;
  border-bottom: 1px dashed lightgray;
}

.main_page_plane .main_content .industry_list_item .item_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.main_page_plane .main_content .industry_list_item .item_content .title {
  word-break: break-all;
  /* color: cornflowerblue */
}

.main_page_plane .main_content .industry_list_item .item_content .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .main_content .industry_list_item .title {
  /* color: cornflowerblue; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.main_page_plane .main_content .industry_list_item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .main_content .industry_list_item .item_content .desc {
  color: gray;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-size: 14px;
}

.main_page_plane .main_content .industry_list_item .item_content .create_time {
  width: 100%;
  text-align: right;
  color: gray;
}

.main_page_plane .main_content .industry_list_item .create_time {
  white-space: nowrap;
  padding-left: 10px;
  font-size: 14px;
  color: grey;
  /* margin-left:5px */
}

.main_page_plane .main_content .info_list_item {
  padding: 10px 0px;
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
}

.main_page_plane .main_content .info_list_item .title {
  /* padding-left:5px; */
  font-size: 18px;
  font-weight: bold;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.main_page_plane .main_content .info_list_item .desc {
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 14px;
}

.main_page_plane .main_content .info_list_item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .main_content .info_list_item .create_time {
  white-space: nowrap;
  padding-left: 5px;
  width: 100%;
  text-align: right;
  color: gray;
}

.main_page_plane .main_content .law_item {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main_page_plane .main_content .law_item .title {
  /* color: cornflowerblue; */
  /* padding-left:10px; */
  /* width: 100%;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; */
}

.main_page_plane .main_content .law_item .desc {
  /* padding-left:10px; */
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.main_page_plane .main_content .law_item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .main_content .law_item .create_time {
  height: auto;
  text-align: right;
  color: grey;
}

.main_page_plane .main_content .party_list_item {
  padding: 5px 0px;
  border-bottom: 1px dashed lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* display: inline; */
}

.main_page_plane .main_content .party_list_item .title {
  /* color: cornflowerblue; */
  /* padding-left:10px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.main_page_plane .main_content .party_list_item .desc {
  /* padding-left:10px; */
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.main_page_plane .main_content .party_list_item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main_page_plane .main_content .party_list_item .create_time {
  height: 100%;
  text-align: right;
  color: grey;
  /* white-space: nowrap; */
  /* padding-left:5px; */
}

.main_page_plane .main_content .industry_list_item {
  display: flex;
  flex-direction: row;
}

.main_page_plane .main_content .industry_list_item .item_content .title {
  /* font-weight: bold; */
  /* color: cornflowerblue; */
}

.main_page_plane .main_content .industry_list_item .item_content .desc {
  color: gray;
}

.main_page_plane .main_content .industry_list_item .item_content .create_time {
  width: 100%;
  text-align: right;
  color: gray;
}

.content-col {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.main-banner {
  width: 700px;
  max-height: 450px;
  /* min-width:700px; */
  min-height: 450px;
  background-color: lightgray;
  /* overflow: hidden; */
}

.main-banner-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.main-banner-item {
  /* display: none; */
  width: 100%;
  height: 100%;
  /* position:relative;
    top:0px; */
}

.main-banner-item.active {
  display: block;
  /* top: 0px;
    position:relative;     */
}

.main-banner-title-bg {
  /* float: bottom; */
  bottom: 60px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  /* opacity: 0.6; */
  font-size: 24px;
  color: white;
  text-align: center;
  /* z-index: 100; */
  width: 100%;
  height: 60px;
  padding: 5px 10px;
}

.main-banner-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-banner-bt-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 5px;
  bottom: 70%;
  position: relative;
}

.title {
  display: -webkit-box;
  color: black;
  /* font-weight: bold; */
  font-size: 16px;
  width: 100%;
  /* white-space: nowrap; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc {
  color: gray;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-side-banner {
  width: 100%;
  height: 120px;
  margin-top: 5px;
}

.create_time {
  font-size: 12px;
  padding: 0px 10px;
  white-space: nowrap;
  color: gray;
  text-align: right;
}

.change-banner-bt {
  /* height: 30px; */
  background: black;
  opacity: 0.4;
  color: white;
  padding: 30px 10px;
}
</style>