<template>
    <div>
        <div id="bannerDetailContainer">
            <form role="form" class="form-horizontal" id="bannerEditForm" style="width:100%">
            <div class="form-group">
                <label for="exampleInputEmail1" class="col-sm-1">标题</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" placeholder="标题" 
                    name="title"
                    v-model="pageParams.title">
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1" class="col-sm-1">广告类型</label>
                <div class="col-sm-4">
                    <select class="form-control " id="advertTypeSelector" placeholder="广告类型" v-model="pageParams.type">                                           
                        <option value="2" >左侧1号图</option>
                        <option value="7" >右侧3号图</option>
                        <option value="1" >焦点图</option>
                        <option value="4" >顶部banner</option>
                        <option value="3" >右侧1号图</option> 
                        <option value="5" >右侧2号图</option>
                        <option value="6" >腰部banner</option>
                    </select>
                </div>                
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"  class="col-sm-1">跳转链接</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" placeholder="链接url，必须http://或者https://开头" 
                    name="url"
                    maxlength="200"
                    v-model="pageParams.url">
                </div>
            </div>
            <!-- <div class="form-group">
                <label for="exampleInputPassword1"  class="col-sm-1">页面参数</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" placeholder="页面参数"
                    name="params"
                    v-model="pageParams.params">
                </div>
            </div> -->
            <div class="form-group">
                <label for="exampleInputFile" class="col-sm-1">图片{{ pageParams.type==1?'(450*700)':pageParams.type==2 || pageParams.type==3 || pageParams.type==5?'(330*120)':pageParams.type==4?'(1400*72)':pageParams.type==6?'(1400*160)':'(330*120)' }}</label>
                <div class="col-sm-4">
                    <input type="file" id="exampleInputFile" 
                    name="upload"
                    accept=".jpg"
                    ref="bannerFile" @change="uploadBanner"/>
                </div>                                
            </div>
            <div class="form-group">                
                    <img style='width:400px;height:400px' v-if= "pageParams.img!=''"                     
                    :src='$bf.getImgUrl(pageParams.img)' name="img">                 
            </div>
            <div style="width:100%; display:flex;flex-direction:row;justify-content:space-around;padding:15px">
                <button type="button" class="btn btn-primary" @click="create()">保存</button>
                <button type="button" class="btn btn-default" @click="backToPage()" >取消</button>
            </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
#bannerDetailContainer{
    padding: 30px;
}
</style>
<script>
export default {
    name: "bannerEditPage",
    data () {
        return {
            pageParams: {
                id: 0,
                title: '',
                url: '',
                // params: '',
                type: 1,
                img: '',
                // updateById: this.$store.getters.userId
            },            
            prePageName: ''
        }
    },
    mounted () {
        var _this=this
        this.pageParams.id = this.$route.params.id > 0? this.$route.params.id : this.pageParams.id
        this.prePageName = 'bannerAdminPage'
        if (this.pageParams.id >0){
            this.$('#advertTypeSelector').attr('disabled',true)
            this.getDetail()
        }
       
    },
    methods: {
        create() {
            var _this = this             
            if (this.pageParams.title == ""){
                this.$bf.warningAlert("标题不能为空")
                return
            }
            if (this.pageParams.url == "" || !(this.pageParams.url.startsWith('http://')||this.pageParams.url.startsWith('https://'))){
                this.$bf.warningAlert("链接需要http://或者https://开头")
                return
            }
            if (this.pageParams.img == ""){
                this.$bf.warningAlert("图片不能为空")
                return
            }
            if(this.pageParams.id >0 ) {
                this.$service.post('/advert/update',this.pageParams).then(function (e){
                    _this.$router.push({name: _this.prePageName})
                })
            }  else {
                this.$service.post('/advert/create',this.pageParams).then(function (e){
                    _this.$router.push({name: _this.prePageName})                    
                })
            }          
        },
        getDetail(){
            var _this = this
            this.$service.get('/advert/detail?id='+this.pageParams.id).then(function (e){                    
                    _this.pageParams.title = e.data.data.title
                    _this.pageParams.url = e.data.data.url
                    // _this.pageParams.params = e.data.data.params
                    _this.pageParams.type = e.data.data.type
                    _this.pageParams.img = e.data.data.img
                    _this.$router.push({name: this.prePageName})
                })
        },
        backToPage(){
            this.$router.push({name: this.prePageName})
        },
        uploadBanner(){            
            var file = this.$refs.bannerFile.files[0]
            var reason = this.$bf.checkImg(file)
            if (reason != null) {
                this.$bf.warningAlert(reason)
                this.$refs.bannerFile.value = ""
                this.articalInfo.img = ""
                return
            }
            let formData = new FormData()
            formData.set("fileName", file.name)
            formData.set("file", file)
            var _this=this
            this.$service.post("/utils/upload",formData).then(function(e){
                _this.pageParams.img = e.data.data.name
                _this.$refs.bannerFile.value = ""
                // _this.articalInfo.img = ""
            })
        }
    }
}
</script>