<template>
    <div style="display:flex;flex-direction:column;align-items:center;justify-content: center;width: 100%;margin-top:20px">
        <div v-if="rewardRecords.length>0"> 
            <img style="width:90px;height: 90px;" 
            alt="广东省城市建筑学会"
            :src="logo!=''?logo:'../../../public/static/assiation_logo.jpg'">
            <div style="display:flex;flex-direction:row;padding:15px 0px">
                恭喜您在本次比赛中获得名次，获奖项目如下：
            </div>                    
            <table class="table table-bordered">
                <thead>
                    <th>报名编号</th>
                    <th>报名人名称</th>
                    <th>作品名称</th>
                    <th>项目名称</th>
                    <th>项目规模</th>
                    <th>项目结构</th>
                    <th>项目时间</th>
                    <th>项目耗资</th>
                    <th>名次</th>
                </thead>
                <tbody style="text-align:left">
                    <tr v-for="(data,index) in rewardRecords" :key="index">
                        <td>{{data.enrollNo}}</td>
                        <!-- <td>
                            <div>{{data.applyUserName}}</div>
                            <div>{{data.enUserName}}</div>
                        </td> -->
                        <td>{{data.applyUserName}}</td>
                        <td>
                            <div>{{data.workName}}</div>
                            <div>{{data.enWorkName}}</div>
                        </td>
                        <td>{{data.projectName}}</td>
                        <td>{{data.projectSize}}</td>
                        <td>{{data.projectStructure}}</td>
                        <td>
                            <div>{{data.projectStartDate}}</div>
                            <div>{{data.projectEndDate}}</div>
                        </td>
                        <td>{{ data.projectCost }}</td>
                        <td>
                            <div>{{data.prize}}</div>
                            <div>{{data.enPrize}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>请前往<a @click="$router.push({name:'personalCertSubPage'})" style="cursor:pointer">个人中心</a>查询获得的比赛证书</div>
            <a style="cursor:pointer" @click="$router.push({name:'certificateSearchPage'})">前往查询证书</a>
        </div>
        <div v-else class="no-data-text" style="width:100%">查询暂无数据</div>
    </div>
</template>
<script>
export default{
    name: 'matchResultPage',
    data(){
        return {
            rewardRecords:[],
            logo: ""
        }
    },
    mounted(){
        this.getReward()
        this.getLogo()
    },
    methods: {
        getLogo(){
            var _this = this
            this.$service.get('/config/get?key=BIM_MATCH_REWARD_LOGO').then(function(e){
                _this.logo = e.data.data.value
            })
        },
        getReward(){
            var _this = this
            this.$service.get('/match/my-reward').then(function(e){
                _this.rewardRecords = e.data.data
            })
        }
    }
}
</script>