<template>
    <div>
        <ul class="nav nav-pills" style="padding:10px 0px">
            <li role="presentation2" class="active" id="tab2" @click="changeTab(2)"><a>个人证书</a></li>
            <li role="presentation2" id="tab1" @click="changeTab(1)"><a>单位证书</a></li>
        </ul>
        <table v-if="data.records.length > 0" class="table table-bordered">
            <thead>
                <th>比赛名称</th>
                <th>证书号</th>
                <th>证书类型</th>
                <th>组别</th>
                <th>作品名称</th>
                <th>名次</th>
                <th v-if="type==2">任职单位</th>
                <th v-if="type==2">获奖人</th>
                <th v-if="type==1">获奖单位</th>
            </thead>
            <tbody>
                <tr v-for="(value, index) in data.records" :key="index">
                    <td>{{ value.matchName }}</td>
                    <td>{{ value.certNo }}</td>
                    <td>{{ value.typeStr }}</td>
                    <td>
                        <div>{{ value.group }}</div>
                        <div>{{ value.enGroup }}</div>
                    </td>
                    <td>
                        <div>{{ value.workName }}</div>
                        <div>{{ value.enWorkName }}</div>
                    </td>
                    <td>
                        <div>{{ value.prize }}</div>
                        <div>{{ value.enPrize }}</div>
                    </td>
                    <td v-if="type==2">
                        <div>{{ value.company }}</div>
                        <div>{{ value.enCompany }}</div>
                    </td>
                    <td v-if="type==2">
                        <div>{{ value.userName }}</div>
                        <div>{{ value.enUserName }}</div>
                    </td>
                    <td v-if="type==1">
                        <div>{{ value.enterprise }}</div>
                        <div>{{ value.enEnterprise }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else class="no-data-text">查询暂无数据</div>
        <base-pagination :data="data" @method="search"></base-pagination>
    </div>
</template>
<script>
import basePagination from '../base/pagination.vue'
export default {
    name: "personalCertSubPage",
    components: {
        basePagination
    },
    data() {
        return {
            data: [],
            type: 2,
            pageData: {}
        }
    },
    mounted() {
        this.search(1)
    },
    methods: {
        search(page) {
            var _this = this
            var json = {
                type: this.type,
                pageNo: page,
                pageSize: 5
            }
            this.$service.post("/user/certs", json).then(function (e) {
                _this.data = e.data.data
                // _this.$refs.certSubPagination.render(page, e.data.data.total)
            })
        },
        changeTab(type){
            this.$("li[role^=presentation2]").removeClass('active')
            this.$("#tab"+type).addClass('active')
            this.type = type
            this.search(1)
        }
    }
}
</script>