<template>
  <div id="unMemberContainer">
    <img
      style="width: 90px; height: 90px"
      alt="广东省城市建筑学会"
      src="../../../public/static/assiation_logo.jpg"
    />
    <div class="text">您刚刚访问的内容仅会员可见，请先申请会员后再访问</div>
    <button
      class="btn btn-primary"
      type="button"
      style="font-size: 16px"
      @click="$router.push({ name: 'applyMemberPage' })"
    >
      前往申请会员
    </button>
  </div>
</template>
<script>
export default {
  name: "unMemberPage",
  mounted() {
    this.$bf.calHeight(this, this.$("#unMemberContainer"));
  },
  method: {},
};
</script>
<style scoped>
#unMemberContainer {
  width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  padding: 20px;
  font-size: 16px;
}
</style>