<template>
  <div id="matchRewardAdminContainer">
    <!-- <submit-work-modal :id="'sumbitWorkModal'"   title="提交作品" :data="modalData" @submit="submitWork"></submit-work-modal>             -->
    <reward-confirm-modal
      :id="'rewardConfirmModal'"
      title="评选名次"
      :data="modalData"
      @submit="selectPrize"
      :prizes="matchPrizes"
    ></reward-confirm-modal>
    <notice-modal :id="'finishConfirmModal'" @confirm="finish()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认公示结果吗？公示后将结束比赛</template>
    </notice-modal>      
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="text-align: right; padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group">
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="比赛编号"
              v-model="searchFilter.match"
            >
              <option
                :value="value"
                v-for="(value, index) in matchs"
                :key="index"
              >
                {{ value.matchNo }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="组别"
              v-model="searchFilter.group"
              @change="search(1)"
            >
              <option :value="null">全部组别</option>
              <option
                :value="value"
                v-for="(value, index) in matchGroups"
                :key="index"
              >
                {{ value }}
              </option>
              <!-- <option value="workGroup">施工组</option>                          -->
            </select>
          </div>
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="评分人精确搜索"
            v-model="searchFilter.submitUserName"
            @blur="search(1)"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名编号"
            v-model="searchFilter.enrollNo"
            @blur="search(1)"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名人名称精准查询"
            v-model="searchFilter.name"
            @blur="search(1)"
          />
        </div>
        <!-- <button type="button" class="btn btn-primary" style="margin-left:10px" @click="search(1)">
                      <span class="glyphicon glyphicon-search"></span>&nbsp;查找</button> -->
        <button
          v-if="searchFilter.match.state == 0"
          type="button"
          class="btn btn-warning"
          style="margin-left: 10px"
          @click="showFinishModal()"
        >
          公示结果
        </button>
        <!-- <button type="submit" class="btn btn-primary" style="margin-left:10px" @click="create()">
                      新建</button> -->
      </form>
    </div>
    <div>
      <table v-if="listData.records.length != 0" class="table table-bordered">
        <thead>
          <!-- <th>Id</th> -->
          <th>报名编号</th>
          <th>报名人</th>
          <!-- <th>单位名称</th>                -->
          <th>组别</th>
          <th>作品名称</th>
          <!-- <th>单位证书</th>
                  <th>个人证书</th> -->
          <!-- <th>评分人</th> -->
          <th>分数</th>
          <th>总分</th>
          <!-- <th>评级</th> -->
          <th>名次</th>
          <th>操作</th>
        </thead>
        <tbody>
          <tr v-for="(value, index) in listData.records" :key="index">
            <td>{{ value.enrollNo }}</td>
            <td>{{ value.applyUserName }}</td>
            <td>{{ value.group }}</td>
            <td>
              <div>{{ value.workName }}</div>
              <div>{{ value.enWorkName }}</div>
            </td>
            <td>
              <div
                v-for="(data, index) in value.scoreStr.split(',')"
                :key="index"
              >
                <div>
                  第{{ index + 1 }}轮:{{ data }}
                </div>
              </div>
            </td>
            <td>{{ value.score }}</td>
            <!-- <td>{{value.grade}}</td> -->
            <td v-if="value.prize">
              <div>{{ value.prize }}</div>
              <div>{{ value.enPrize }}</div>
            </td>
            <td v-else>-</td>
            <td>
              <button
                v-if="searchFilter.match.state == 0"
                type="button"
                class="btn btn-primary"
                @click="showModal(value.enrollNo)"
              >
                评选名次
              </button>
              <!-- <button v-if="value.canApproved&&value.score>0 && value.order !== orders[orders.length-1] &&!value.hasApproved" type="button" class="btn btn-primary" @click="showApproveModal(value.id)">晋级</button>                         -->
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    </div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>
<script>
import rewardConfirmModal from "../base/rewardConfirmModal.vue";
import noticeModal from "../base/noticeModal.vue";
import basePagination from "../base/pagination.vue";
export default {
  name: "matchRewardAdminPage",
  components: {
    basePagination,
    noticeModal,
    rewardConfirmModal,
  },
  data() {
    return {
      title: "比赛结果管理",
      //用于存储当前页码数
      currentPage:1,
      searchFilter: {
        matchNo: "",
        enrollNo: null,
        name: null,
        group: null,
        match: {
          state: 0,
        },
        submitUserName: null
      },
      listData: [],
      matchs: [],
      matchGroups: [],
      matchPrizes: [],
      modalData: {
        id: 0,
        prize: null,
        enPrize: "",
      },
      data: {},
    };
  },
  mounted() {
    this.getMatchList();
  },
  watch: {
    "searchFilter.match": {
      handler(newval, oldval) {
        this.search(1);
      },
    },
  },
  methods: {
    getMatchList() {
      var _this = this;
      var url = "/match/list";
      var body = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$service.post(url, body).then(function (e) {
        _this.matchs = e.data.data.records;
        //   for(var i =1; i<=_this.matchs[0].matchStageNum;i++  ) {
        //     _this.orders.push(i)
        //   }
        _this.searchFilter.match = _this.matchs[0];
        _this.getMatchGroup();
      });
    },
    getMatchGroup() {
      var _this = this;
      var url = "/match/config";
      this.$service.get(url).then(function (e) {
        _this.matchGroups = e.data.data.matchGroupType;
        _this.searchFilter.group = null;
        _this.matchPrizes = e.data.data.matchReward;
        // _this.search(1)
      });
    },
    search(page) {
      var _this = this;
      var body = {};
      body.matchNo = this.searchFilter.match.matchNo;
      body.enrollNo = this.searchFilter.enrollNo
        ? this.searchFilter.enrollNo
        : null;
      body.group = this.searchFilter.group;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.submitUserName = this.searchFilter.submitUserName
      //   body.isApprove = true
      //   body.isAll = 1
      body.pageNo = page;
      this.currentPage = page;
      body.pageSize = 10;
      var url = "/match/reward-result";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        //   _this.$refs.infoPagination.render(page,e.data.data.total)
      });
    },
    finish() {
      var _this = this;
      var body = {};
      body.matchNo = this.searchFilter.match.matchNo;
      var url = "/match/finish";
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        // _this.$('#rewardConfirmModal').modal('hide')
        _this.getMatchList();
      });
    },
    showFinishModal() {
      this.$("#finishConfirmModal").modal("show");
    },
    showModal(id) {
      // console.log(this.result)

      // if (this.result.score == 0){
      //     this.$bf.warningAlert("评分不能为0")
      //     return
      // }
      // if (this.result.comment == ""){
      //     this.$bf.warningAlert("评论不能为空")
      //     return
      // }
      this.modalData.prize = "";
      this.modalData.enPrize = "";
      this.modalData.enrollNo = id;
      this.$("#rewardConfirmModal").modal("show");
    },
    selectPrize() {
      var _this = this;
      // console.log(this.modalData)
      if (this.modalData.prize == "" || this.modalData.prize == null) {
        this.$bf.warningAlert("必须选择名次");
        return;
      }
      for (var prizeIndex in this.matchPrizes) {
        if (this.matchPrizes[prizeIndex] == this.modalData.prize) {
          this.modalData.enPrize = prizeIndex;
          break;
        }
      }
      this.$service.post("match/reward", this.modalData).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#rewardConfirmModal").modal("hide");
        _this.search(_this.currentPage);
      });
    },
  },
};
</script>
<style scoped>
#matchRewardAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>