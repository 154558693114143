<template>
  <div id="activityMobileContainer" @scroll="scroll">
    <div id="activityInnerMobileContainer">
      <div v-if="listData.length">
        <div class="act-item" v-for="(value, index) in listData" :key="index" @click="toDetail(value.actNo)">
          <img style="text-align: center; width: 100px; height: 80px" :src="value.img == ''
            ? '../../../public/static/defaultpic.gif'
            : $bf.getImgUrl(value.img)
            " />
          <div class="content">
            <div class="title">{{ value.title }}</div>
            <div class="desc">
              {{ value.desc }}
            </div>
          </div>
        </div>
      </div>
      <div v-else style="color: grey;text-align: center;padding: 10px;">暂无活动数据</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "activityMobilePage",
  data() {
    return {
      listData: [],
      height: 0,
      scrollTop: 0,
      page: 1,
      isEnd: false,
    };
  },
  watch: {
    scrollTop: function () {
      this.height =
        this.$("#activityInnerMobileContainer")[0].clientHeight -
        this.$("#activityMobileContainer")[0].clientHeight;
      if (this.scrollTop == this.height) {
        this.page += 1;
        if (!this.isEnd) {
          this.getList();
        }
      }
      //   console.log(1)
    },
  },
  mounted() {
    if (!this.$store.getters.isLogin) {
      this.$router.push({
        name: "loginMobilePage"
      });
    }
    this.$("#activityMobileContainer").css(
      "height",
      document.body.clientHeight
    );
    this.height =
      this.$("#activityInnerMobileContainer")[0].clientHeight -
      this.$("#activityMobileContainer")[0].clientHeight;
    this.scrollTop = this.$("#activityMobileContainer")[0].scrollTop;
    this.getList();
  },
  methods: {
    scroll(e) {
      // console.log(33333)
      this.scrollTop = this.$("#activityMobileContainer")[0].scrollTop;
      //   console.log(this.$("#activityMobileContainer")[0].scrollTop);
    },
    getList() {
      var _this = this;
      var json = {
        pageNo: this.page,
        pageSize: 10,
        state: 0,
      };
      this.$service.post("/activity/list", json).then(function (e) {
        if (e.data.data.records.length > 0) {
          if (_this.listData.length == 0) {
            _this.listData = e.data.data.records;
          } else {
            _this.listData.concat(e.data.data.records);
          }
          _this.isEnd = false;
        } else {
          _this.isEnd = true;
        }
      });
    },
    toDetail(value) {
      this.$router.push({
        name: "activityEnrollMobilePage",
        params: { actNo: value },
      });
    },
  },
};
</script>
<style scoped>
#activityMobileContainer {
  /* display: flex;
  flex-direction: column; */
  padding: 0px 10px;
  /* height:600px; */
  /* background: black; */
  overflow: auto;
}

#activityInnerMobileContainer {
  /* height:500px; */

  /* background: red; */
}

.act-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}

.title {
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.desc {
  /* width:100%; */
  word-break: break-all;
  color: gray;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* height: 300px; */
}

.content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>