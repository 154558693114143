import { render, staticRenderFns } from "./capitalPage.vue?vue&type=template&id=544f9077&scoped=true&"
import script from "./capitalPage.vue?vue&type=script&lang=js&"
export * from "./capitalPage.vue?vue&type=script&lang=js&"
import style0 from "./capitalPage.vue?vue&type=style&index=0&id=544f9077&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544f9077",
  null
  
)

export default component.exports