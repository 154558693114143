<template>
  <div style="padding-top: 20px">
    <apply-receipt-modal
      :id="'applyReceiptModal2'"
      @submit="applyReceipt"
      :data="receiptInfo"
    ></apply-receipt-modal>
    <receipt-info-modal
      :id="'receiptInfoModal2'"
      :data="receiptInfo"
    ></receipt-info-modal>
    <!-- <div class="msg_filter-area">  -->
    <!-- <div class="act_title">报名记录</div> -->
    <!-- <ul class="nav nav-pills msg_filter-area">
                <li role="presentation" class="active" @click="changeType($event,1)"><a href="#">进行中</a></li>
                <li role="presentation" @click="changeType($event,2)"><a href="#">已结束</a></li>
            </ul> -->
    <table v-if="data.records.length > 0" class="table table-bordered">
      <thead>
        <th>活动名称</th>
        <th>描述</th>
        <th>报名时间</th>
        <th>报名费用</th>
        <th>发票申请</th>
        <th>操作</th>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data.records" :key="index">
          <td>{{ value.actName }}</td>
          <td>{{ value.actDesc }}</td>
          <td>{{ value.createTime }}</td>
          <td v-if="value.fee > 0">{{ value.feeStr }}</td>
          <td v-else>-</td>
          <td v-if="value.fee > 0">
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="value.receiptEntity == null"
              @click="showApplyReceiptModal(value.orderNo, value.receiptEntity)"
              >[申请发票]</a
            >
            <a
              style="font-size: 12px"
              v-if="value.receiptEntity && value.receiptEntity.state == 0"
              >[发票审批中]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="value.receiptEntity && value.receiptEntity.state == 1"
              @click="showReceiptInfoModal(value.receiptEntity)"
              >[发票信息]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="value.receiptEntity && value.receiptEntity.state == -1"
              @click="showApplyReceiptModal(null,value.receiptEntity)"
              >[发票被驳回]</a
            >
          </td>
          <td v-else>-</td>
          <td>
            <button
              @click="toPage(value.actNo)"
              type="button"
              class="btn btn-primary"
            >
              详情
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="no-data-text">查询暂无数据</div>
    <base-pagination :data="data" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import applyReceiptModal from "../base/applyReceiptModal.vue";
import receiptInfoModal from "../base/receiptInfoModal.vue";
export default {
  name: "personalActSubPage",
  components: {
    basePagination,
    applyReceiptModal,
    receiptInfoModal,
  },
  data() {
    return {
      searchFilter: {
        matchState: 1,
      },
      data: [
        {
          receiptEntity: {},
        },
      ],
      receiptInfo: {},
      pageData: {}
    };
  },
  mounted() {
    this.search(1);
  },
  methods: {
    changeType(event, state) {
      this.$("li[role=presentation]").removeClass("active");
      this.$(event.target).parent().addClass("active");
      this.searchFilter.matchState = state;
      this.search(1);
    },
    search(page) {
      var body = {
        pageNo: page,
        pageSize: 5,
        isSelf: true
      };
      // body.matchState = this.searchFilter.matchState

      var _this = this;
      this.$service.post("/activity/records", body).then(function (e) {
        _this.data = e.data.data
        // _this.$refs.pagination.render(page, e.data.data.total);
      });
    },
    toPage(actNo) {
      this.$router.push({
        name: "activityDetailPage",
        params: { actNo: actNo },
      });
    },
    showApplyReceiptModal(orderNo, info) {
      // console.log(orderNo,info)
      if (info == null) {
        this.receiptInfo = {
          type: 1,
          identification: "",
          address: "",
          phone: "",
          bank: "",
          bankNo: "",
          orderNo: orderNo,
          isSend: 1,
          sendAddress: "",
          receiptor: "",
          sendPhone: "",
        };
      } else {
        this.receiptInfo = info;
        
      }

      this.$("#applyReceiptModal2").modal("show");
    },
    showReceiptInfoModal(info) {
      this.receiptInfo = info;
      // this.receiptId = id
      // console.log(this.receiptId)
      this.$("#receiptInfoModal2").modal("show");
    },
    applyReceipt() {
      var _this = this;
      if (
        this.receiptInfo.identification == "" ||
        this.receiptInfo.address == "" ||
        this.receiptInfo.phone == "" ||
        this.receiptInfo.bank == "" ||
        this.receiptInfo.bankNo == ""
      ) {
        this.$bf.warningAlert("关键信息不能为空");
        return;
      }
      if (
        this.receiptInfo.isSend == 1 &&
        (this.receiptInfo.sendAddress == "" ||
          this.receiptInfo.receiptor == "" ||
          this.receiptInfo.sendPhone == "")
      ) {
        this.$bf.warningAlert("邮寄信息不能为空");
        return;
      }
      // this.receiptInfo.orderNo = orderNo
      this.$service.post("/receipt/apply", this.receiptInfo).then(function (e) {
        _this.$bf.sucAlert("提交成功");
        _this.$("#applyReceiptModal2").modal("hide");
        _this.search(1);
      });
    },
  },
};
</script>
<style scoped>
#personalMsgSubContainer {
  padding: 0px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.msg_filter-area {
  width: 100%;
  /* padding: 0px 20px; */
  /* display: flex; */
  white-space: nowrap;
  margin: 15px 0px;
}
.act_title {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px dashed grey;
}
.msg_list {
  border: 1px solid lightgray;
  border-radius: 5px;
  /* padding: 0px 15px; */
  margin: 10px 0px;
}
.msg_list_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid lightgray;
}
.msg_list_item_text {
  color: gray;
}
.msg_list_item_tag {
  color: red;
  font-weight: bold;
  text-align: center;
  padding: 5px;
}
.no_bottom_border {
  border-bottom: unset;
}
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>
