import { render, staticRenderFns } from "./activityDetailPage.vue?vue&type=template&id=4b1c2204&scoped=true&"
import script from "./activityDetailPage.vue?vue&type=script&lang=js&"
export * from "./activityDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./activityDetailPage.vue?vue&type=style&index=0&id=4b1c2204&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1c2204",
  null
  
)

export default component.exports