<template>
    <div>
        <div id="activityAdminContainer">
            <div style="text-align: left;font-size: large;font-weight: bold;border-left:10px solid cornflowerblue">
                &nbsp;{{ title }}
            </div>
            <div style="text-align: right;padding:15px 0px">
                <form class="form-inline">
                    <div class="form-group" style="padding:0px 10px">
                        <input type="text" class="form-control" placeholder="用户名称搜索" v-model="searchFilter.userName">
                    </div>

                    <div class="input-group">
                        <button type="button" class="btn btn-primary" style="margin-left:10px" @click="search(1)">
                            <span class="glyphicon glyphicon-search"></span>&nbsp;查找</button>
                    </div>
                </form>
            </div>
            <div>
                <table v-if="listData.records.length > 0" class="table table-bordered">
                    <thead>
                        <th>Id</th>
                        <th>活动编号</th>
                        <th>用户名称</th>
                        <th>联系方式</th>
                        <th>邮箱地址</th>
                        <th>报名时间</th>
                    </thead>
                    <tbody>
                        <tr v-for="(value, index) in listData.records" :key="index">
                            <td>{{ value.id }}</td>
                            <td>{{ value.actNo }}</td>
                            <td>{{ value.userName }}</td>
                            <td>{{ value.phone }}</td>
                            <td>{{ value.mail }}</td>
                            <td>{{ value.createTime }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
            </div>
            <base-pagination :data="listData" @method="search"></base-pagination>
        </div>
    </div>
</template>
<style scoped>
#activityAdminContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* width: 100%; */
}
</style>
<script>
import basePagination from '../base/pagination.vue'
export default {
    name: "actEnrollAdminPage",
    components: {
        basePagination
    },
    data() {
        return {
            searchFilter: {
                actNo: null,
                userName: '',
                title: '',
                type: '全部',
            },
            pageInfo: {
                adminPage: '',
                editPage: '',
                detailPage: '',
                createPage: ''
            },
            listData: {
                records: [],
                current: 1, total: 0, size: 10
            },
            title: '',
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.pageInfo.adminPage = this.$route.name
            this.searchFilter.actNo = this.$route.params.actNo
            this.title = "活动报名记录"
            // this.pageInfo.createPage = 'activityCreatePage'
            // this.pageInfo.editPage = 'activityEditPage'                
            this.search(1)
        },
        search(page) {
            var _this = this
            var url = '/activity/records'
            var body = {
                pageNo: page,
                pageSize: 10,
                actNo: this.searchFilter.actNo,
                userName: this.searchFilter.userName,
            }
            this.$service.post(url, body).then(function (e) {
                _this.listData = e.data.data;
                // _this.$refs.actEnrollPagination.render(page,e.data.data.total)                
            })
        },
        toDetail(id) {
            this.$router.push({ name: this.pageInfo.detailPage, params: { id: id } })
        },
        toUpdate(id) {
            if (id <= 0) {
                this.$router.push({ name: this.pageInfo.createPage })
            } else {
                this.$router.push({ name: this.pageInfo.editPage, params: { id: id } })
            }
        },
    }
}
</script>
<style scoped>
.no-data-text {
    padding: 20px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: lightslategrey;
}
</style>