<template>
  <base-modal :id="id" :confirmBtnStr="'导出'" @confirm="output" @close="closeMethod">
    <template #title>{{ '证书导出' }}</template>
    <template v-slot:contentDom>
      <form role="form" class="form-horizontal" style="width: 100%">
        <div class="form-group">
          <label for="exampleInputPassword1" class="col-sm-2">证书类型</label>
          <div class="col-sm-6">
            <select class="form-control" placeholder="证书类型" v-model="data.type">
              <option value="2">个人证书</option>
              <option value="1">企业证书</option>
            </select>
          </div>
        </div>
      </form>
    </template>
  </base-modal>
</template>
<script>
import baseModal from "./baseModal.vue";
export default {
  name: "outputCertModal",
  components: {
    baseModal,
  },
  data() {
    return {
      data: {
        type: 2
      }
    }
  },
  props: {
    id: {}
  },
  mounted() { },
  methods: {
    closeMethod() {
      this.$(this).modal("hide");
    },
    output() {
      this.$emit("output", this.data);
      this.$(this).modal("hide");
    },
  },
};
</script>
