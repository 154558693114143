<template>
  <div>
    <notice-modal :id="'actEditConfirmModal'" @confirm="create()">
      <template v-slot:title>提示</template>
      <template v-slot:content>确认新建活动吗？</template>
      <template v-slot:confirmBtnStr>新建</template>
    </notice-modal>
    <div id="bannerDetailContainer">
      <form class="form-horizontal" role="form" method="post" id="activityEditForm" style="width: 100%">
        <div class="form-group">
          <label class="col-sm-1">标题</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" placeholder="标题" maxlength="100" name="title"
              v-model="pageParams.title" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1">描述（最多200字）</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" placeholder="描述" maxlength="200" name="desc"
              v-model="pageParams.desc" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1">活动图片</label>
          <div class="col-sm-4">
            <input type="file" id="exampleInputFile" ref="bannerFile" accept=".jpg" @change="uploadBanner" />
          </div>
          <div>
            <img style="width: 400px; height: 400px" v-if="pageParams.img != ''" :src="$bf.getImgUrl(pageParams.img)" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1">报名时间</label>
          <div class="col-sm-4">
            <input type="datetime-local" name="enrollStartTime" v-model="pageParams.enrollStartTime" />
            <input type="datetime-local" name="enrollEndTime" v-model="pageParams.enrollEndTime" />
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputName2" class="col-sm-1">仅限会员</label>
          <select type="text" class="form-control" style="width: 80px" name="isMember" v-model="pageParams.isMember">
            <option value="1">是</option>
            <option value="0">否</option>
          </select>
        </div>
        <div class="form-group" style="padding-top: 20px">
          <label for="exampleInputPassword1" class="col-sm-1">报名费用</label>
          <div class="col-sm-2" style="display: flex; flex-direction: row">
            <input type="number" class="form-control" placeholder="单位为分" :disabled="pageParams.id > 0"
              v-model="pageParams.fee" />分
          </div>
        </div>
        <!-- 新增二维码页面 -->
        <div style="width:100%;text-align:center">
          <button type="button" class="btn btn-primary" @click="add()">新增二维码</button>
          <div style="color:red">注意：如果有效期为空，则没有有效期限制，可以随时加载读取，请按需填写</div>
        </div>
        <div v-for="(value, index) in pageParams.qrcodeTemplates" :key="index" class="form-group" style="padding-top:20px">
          <label for="exampleInputPassword1" class="col-sm-1">名称</label>
          <div class="col-sm-1">
            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="二维码名称" v-model="value.name">
          </div>
          <label for="exampleInputPassword1" class="col-sm-1">限制使用次数</label>
          <div class="col-sm-1">
            <input type="number" class="form-control" placeholder="最大是999" 
              v-model="value.limit" />
          </div>
          <label class="col-sm-1">有效开始时间</label>
          <div class="col-sm-2">
            <input type="datetime-local" name="startTime" v-model="value.startTime" />
          </div>
          <label class="col-sm-1" style="padding-left:15px">有效结束时间</label>
          <div class="col-sm-2">
            <input type="datetime-local" name="endTime" v-model="value.endTime" />
          </div>
          <div class="col-sm-1">
            <button type="button" class="btn btn-danger" @click="remove(index)">删除</button>
          </div>
        </div>
        <div style="width:100%; display:flex;flex-direction:row;justify-content:space-around;padding:15px">
          <button type="button" class="btn btn-primary" @click="showModal()">保存</button>
          <button type="button" class="btn btn-default" @click="backToPage()">取消</button>
        </div>
        <tinymce-edit-model ref="actTem"></tinymce-edit-model>
        <div style="
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 15px;
          ">
          <button type="button" class="btn btn-primary" @click="showModal">
            保存
          </button>
          <button class="btn btn-default" @click="backToPage()">取消</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
#bannerDetailContainer {
  padding: 30px;
}
</style>
<script>
// import Editor from "@tinymce/tinymce-vue";
import noticeModal from "../base/noticeModal.vue";
import tinymceEditModel from "../base/tinymceEditModel.vue"
export default {
  name: "activityEditPage",
  data() {
    return {
      pageParams: {
        id: 0,
        title: "",
        desc: "",
        content: "",
        startTime: "",
        endTime: "",
        enrollStartTime: "",
        enrollEndTime: "",
        img: "",
        updateById: this.$store.getters.userId,
        isMember: 0,
        fee: 0,
        qrcodeTemplates: []
      },
    };
  },
  components: {
    // editor: Editor,
    tinymceEditModel,
    noticeModal,
  },
  mounted() {
    var _this = this;
    this.pageParams.actNo = this.$route.params.actNo;
    if (this.pageParams.actNo != null) {
      this.getDetail();
    }
  },
  methods: {
    create() {
      if (
        this.pageParams.title == null ||
        this.pageParams.title == "" ||
        this.pageParams.desc == null ||
        this.pageParams.desc == "" ||
        this.pageParams.img == null ||
        this.pageParams.img == "" ||
        this.pageParams.enrollStartTime == null ||
        this.pageParams.enrollStartTime == "" ||
        this.pageParams.enrollEndTime == null ||
        this.pageParams.enrollEndTime == ""
      ) {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      if (this.pageParams.fee < 0) {
        this.$bf.warningAlert("报名金额非法");
        return;
      }
      for(var index in this.pageParams.qrcodeTemplates) {
        if(this.pageParams.qrcodeTemplates[index].limit >999 || this.pageParams.qrcodeTemplates[index].limit<0) {
          this.$bf.warningAlert("二维码次数非法");
          return;
        }
      }
      var _this = this;
      var body = Object.assign(this.pageParams)
      body.content =  this.$refs.actTem.getContent()
      if (this.pageParams.id > 0) {
        body.id = this.pageParams.id;
        this.$service.post("/activity/update", body).then(function (e) {
          _this.$router.push({ name: "activityAdminPage" });
        });
      } else {
        this.$service.post("/activity/create", body).then(function (e) {
          _this.$router.push({ name: "activityAdminPage" });
        });
      }
    },
    getDetail() {
      var _this = this;
      this.$service
        .get("/activity/detail?actNo=" + this.pageParams.actNo)
        .then(function (e) {
          _this.pageParams = e.data.data;
          _this.pageParams.enrollStartTime = _this.$bf.dtFormatter(
            _this.pageParams.enrollStartTime
          );
          _this.pageParams.enrollEndTime = _this.$bf.dtFormatter(
            _this.pageParams.enrollEndTime
          );
          _this.$refs.actTem.setContent(_this.pageParams.content)
          // _this.$router.push({name: _this.prePageName})
        });
    },
    backToPage() {
      this.$router.push({ name: "activityAdminPage" });
    },
    uploadBanner() {
      var file = this.$refs.bannerFile.files[0];
      var reason = this.$bf.checkImg(file);
      if (reason != null) {
        this.$bf.warningAlert(reason);
        this.$refs.bannerFile.value = "";
        this.pageParams.img = "";
        return;
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      var _this = this;
      this.$service.post("/utils/upload", formData).then(function (e) {
        _this.pageParams.img = e.data.data.name;
        _this.$refs.bannerFile.value = "";
      });
    },
    showModal() {
      this.$("#actEditConfirmModal").modal("show");
    },
    add() {
      var stage = {
        name: '',
        startTime: '',
        endTime: '',
        limit: 999
      }
      this.pageParams.qrcodeTemplates.push(stage)
    },
    remove(index) {
      var temp = []
      for (var i = 0; i < this.pageParams.qrcodeTemplates.length; i++) {
        if (i == index) {
          continue
        }
        temp.push(this.pageParams.qrcodeTemplates[i])
      }
      this.pageParams.qrcodeTemplates = temp
    },
  },
};
</script>