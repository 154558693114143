<template>
  <div id="matchEnrollAdminContainer">
    <submit-work-modal
      :id="'sumbitWorkModal'"
      title="提交作品"
      :data="modalData"
      @submit="submitWork"
    ></submit-work-modal>
    <match-member-modal
      :id="'matchMemberModal'"
      :data="modalData"
    ></match-member-modal>
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group">
          <!-- <div class="col-sm-12"> -->
            <label>
              <input type="checkbox" value="true" v-model="searchFilter.isSubmit" @blur="search(1)"/>
              仅显示未提交记录
            </label>
          <!-- </div> -->
        </div>
        <div class="form-group">
          <!-- <label style="white-space:nowrap" class="col-sm-4">比赛编号</label> -->
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="比赛编号"
              v-model="searchFilter.matchNo"
              @change="search(1)"
            >
              <option
                :value="value.matchNo"
                v-for="(value, index) in matchNos"
                :key="index"
              >
                {{ value.matchNo }}
              </option>
              <!-- <option value="workGroup">施工组</option>                          -->
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="组别"
              v-model="searchFilter.group"
              @change="search(1)"
            >
              <option :value="null">全部组别</option>
              <option
                :value="value"
                v-for="(value, index) in matchGroups"
                :key="index"
              >
                {{ value }}
              </option>
              <!-- <option value="workGroup">施工组</option>                          -->
            </select>
          </div>
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名编号"
            v-model="searchFilter.enrollNo"
            @blur="search(1)"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名人名称模糊查询"
            v-model="searchFilter.name"
            @blur="search(1)"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="number"
            class="form-control"
            placeholder="报名ID"
            v-model="searchFilter.id"
            @blur="search(1)"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="output()"
        >
          导出记录
        </button>
      </form>
    </div>
    <div>
      <table v-if="listData.records.length != 0" class="table table-bordered">
        <thead>
          <!-- <th>Id</th> -->
          <th>id</th>
          <th>报名编号</th>
          <th>组别</th>
          <th>报名人</th>
          <th>参赛人员</th>
          <th>单位/企业名称</th>
          <!-- <th>报名类型</th> -->
          <th>作品名称</th>
          <th>网盘路径</th>
          <th>工程名称</th>
          <th>工程规模</th>
          <th>结构形式</th>
          <th>工程时间</th>
          <th>建造费用</th>
          <th>授权文件</th>
          <th>报名时间</th>
          <!-- <th>总分</th> -->
          <!-- <th>操作</th> -->
        </thead>
        <tbody>
          <tr v-for="(value, index) in listData.records" :key="index">
            <td>{{value.id}}</td>
            <td>{{ value.enrollNo }}</td>
            <td>{{ value.group }}</td>
            <td>{{ value.applyUserName }}</td>
            <td>
              <button
                @click="showMemberModal(value.enrollNo)"
                type="button"
                class="btn btn-primary"
              >
                查看
              </button>
            </td>
            <td>
              <div
                v-for="(data, index) in value.matchEnrollCompanyEntities"
                :key="index"
              >
                <div style="white-space: nowrap">{{ data.name }}</div>
                <div style="white-space: nowrap">{{ data.enName }}</div>
                <span class="label label-default">{{ data.type }}</span>
              </div>
            </td>

            <td v-if="value.workName">
              <div style="white-space: nowrap">{{ value.workName }}</div>
              <div style="white-space: nowrap">{{ value.enWorkName }}</div>
            </td>
            <td v-else style="color: red">未提交</td>
            <td>{{ value.baiduUrl ? value.baiduUrl : "-" }}</td>
            <td>{{ value.projectName ? value.projectName : "-" }}</td>
            <td>{{ value.projectSize ? value.projectSize : "-" }}</td>
            <td>{{ value.projectStructure ? value.projectStructure : "-" }}</td>
            <td>
              <div>{{ value.projectStartDate }}</div>
              <div>{{ value.projectEndDate }}</div>
            </td>
            <td>{{ value.projectCost ? value.projectCost : "0" }}万元</td>
            <td style="cursor: pointer" @click="openPDF(value.announceFile)">
              <a>{{
                value.announceFile
                  ? "https://guas.org.cn/static/file/" + value.announceFile
                  : "无"
              }}</a>
            </td>
            <td style="white-space: nowrap">{{ value.createTime }}</td>
            <!-- <td>{{ value.score }}</td> -->
            <!-- <td> -->
            <!-- <button v-if="value.canSubmit" @click="showModal(value)" type="button" class="btn btn-primary">录入作品</button> -->
            <!-- <button v-else @click="showModal(value)" type="button" class="btn btn-primary">修改作品</button> -->
            <!-- </td> -->
          </tr>
        </tbody>
      </table>
      <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    </div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>

<script>
import basePagination from "../base/pagination.vue";
import submitWorkModal from "../base/submitWorkModal.vue";
import matchMemberModal from "../base/matchMemberModal.vue";
export default {
  name: "matchEnrollAdminPage",
  components: {
    basePagination,
    submitWorkModal,
    matchMemberModal,
  },
  data() {
    return {
      title: "报名记录管理",
      searchFilter: {
        id: null,
        matchNo: "",
        enrollNo: "",
        name: "",
        isSubmit:false
      },
      matchNos: [],
      listData: {},
      matchGroups: [],
      modalData: {
        enrollNo: "",
      },
      data: {},
    };
  },
  mounted() {
    this.getMatchList();
    this.getMatchGroup();
  },
  methods: {
    openPDF(url) {
      window.open("https://guas.org.cn/static/file/" + url, "_blank");
    },
    getMatchList() {
      var _this = this;
      var url = "/match/list";
      var body = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$service.post(url, body).then(function (e) {
        _this.matchNos = e.data.data.records;
        _this.searchFilter.matchNo = _this.matchNos[0].matchNo;
        // _this.$refs.infoPagination.render(1,e.data.data.total)
        _this.search(1);
      });
    },
    getMatchGroup() {
      var _this = this;
      var url = "/match/config";
      this.$service.get(url).then(function (e) {
        _this.matchGroups = e.data.data.matchGroupType;
        _this.searchFilter.group = null;
        // _this.search(1)
      });
    },
    showMemberModal(enrollNo) {
      this.modalData = {
        id: enrollNo,
      };
      this.$("#matchMemberModal").modal("show");
    },
    search(page) {
      var _this = this;
      var body = {};
      body.id = this.searchFilter.id
      body.matchNo = this.searchFilter.matchNo
        ? this.searchFilter.matchNo
        : null;
      body.enrollNo = this.searchFilter.enrollNo
        ? this.searchFilter.enrollNo
        : null;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.group = this.searchFilter.group;
      body.page = page;
      body.pageSize = 10;
      body.isSubmit = this.searchFilter.isSubmit
      var url = "/match/records";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.data = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
      });
    },
    output() {
      var _this = this;
      var body = {};
      body.matchNo = this.searchFilter.matchNo
        ? this.searchFilter.matchNo
        : null;
      body.enrollNo = this.searchFilter.enrollNo
        ? this.searchFilter.enrollNo
        : null;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.group = this.searchFilter.group;
      var url = "/match/output";
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        var path = _this.$bf.getExcelUrl(e.data.data);
        window.open(path, "_blank");
      });
    },
    create() {
      this.$router.push({ name: "matchCreatePage" });
    },
    showModal(data) {
      this.modalData = {
        enrollNo: data.enrollNo,
        applyUserName: data.applyUserName,
        companyName: data.company,
        group: data.group,
        workName: data.workName,
        enWorkName: data.enWorkName,
      };
      this.$("#sumbitWorkModal").modal("show");
    },
    submitWork() {
      var _this = this;
      if (this.modalData.workName == "" || this.modalData.workName == null) {
        this.$bf.warningAlert("必须填写作品名称");
        return;
      }
      this.$service.post("match/submit", this.modalData).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#sumbitWorkModal").modal("hide");
        _this.search(1);
      });
    },
  },
};
</script>

<style scoped>
#matchEnrollAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>