var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('apply-group-receipt-modal',{attrs:{"id":'applyGroupReceiptModal',"data":_vm.groupReceiptInfo},on:{"submit":_vm.applyGroupReceipt}}),_c('group-receipt-info-modal',{attrs:{"id":'groupReceiptInfoModal',"data":_vm.groupReceiptInfo}}),_c('ul',{staticClass:"nav nav-pills",staticStyle:{"padding":"10px 0px"}},[_c('li',{staticClass:"active",attrs:{"role":"presentation2","id":"tab1"},on:{"click":function($event){return _vm.changeTab(1)}}},[_c('a',[_vm._v("报名记录")])]),_c('li',{attrs:{"role":"presentation2","id":"tab2"},on:{"click":function($event){return _vm.changeTab(2)}}},[_c('a',[_vm._v("参与记录")])])]),(_vm.data.records.length > 0)?_c('table',{staticClass:"table table-bordered"},[_vm._m(0),_c('tbody',_vm._l((_vm.data.records),function(value,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(value.matchEntity.name))]),_c('td',[_vm._v(_vm._s(value.enrollNo))]),_c('td',[_vm._v(_vm._s(value.applyUserName))]),_c('td',[_c('div',[_vm._v(_vm._s(value.group))]),_c('div',[_vm._v(_vm._s(value.enGroup))])]),(value.workName)?_c('td',[_c('div',[_vm._v(_vm._s(value.workName))]),_c('div',[_vm._v(_vm._s(value.enWorkName))])]):_c('td',[_vm._v("暂无")]),(_vm.type == 1)?_c('td',[(value.groupReceiptEntity == null)?_c('a',{staticStyle:{"font-size":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.showApplyGroupReceiptModal(value)}}},[_vm._v("[申请发票]")]):_vm._e(),(
              value.groupReceiptEntity && value.groupReceiptEntity.state == 0
            )?_c('a',{staticStyle:{"font-size":"12px"}},[_vm._v("[发票审批中]")]):_vm._e(),(
              value.groupReceiptEntity && value.groupReceiptEntity.state == 1
            )?_c('a',{staticStyle:{"font-size":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.showGroupReceiptInfoModal(value)}}},[_vm._v("[发票信息]")]):_vm._e(),(
              value.groupReceiptEntity && value.groupReceiptEntity.state == -1
            )?_c('a',{staticStyle:{"font-size":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.showApplyGroupReceiptModal(value)}}},[_vm._v("[发票被驳回]")]):_vm._e()]):_vm._e()])}),0)]):_c('div',{staticClass:"no-data-text"},[_vm._v("查询暂无数据")]),_c('base-pagination',{attrs:{"data":_vm.data},on:{"method":_vm.search}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',[_vm._v("比赛名称")]),_c('th',[_vm._v("报名编号")]),_c('th',[_vm._v("申请人")]),_c('th',[_vm._v("组别")]),_c('th',[_vm._v("作品名称")]),_c('th',[_vm._v("操作")])])
}]

export { render, staticRenderFns }