<template>
  <div class="information_container">
    <notice-modal :id="'enrollActModal'" @confirm="enroll()">
        <template v-slot:title>提示</template>
        <template v-slot:content>你确认报名活动吗？</template>
    </notice-modal>   
    <page-nav :current="data.title"> </page-nav>    
    <div class="info-detail-container">
      <div style="width: 100%">
        <h3 class="information_title">
          {{ data.title }}
        </h3>
        <div class="info-info-detail">
          发布时间：{{ data.createTime }}
          <div v-if="isActDetail" class="activity_info">
            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              "
            >
              <!-- <div style="font-weight:bold">活动时间：</div>{{data.startTime}} 至  {{data.endTime}} &nbsp;  -->
              <div style="font-weight: bold">报名时间：</div>
              {{ data.enrollStartTime }} 至 {{ data.enrollEndTime }} &nbsp;
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              "
            >
              <button
                v-if="data.canEnroll && !data.enroll"
                type="button"
                class="btn btn-primary"
                @click="showEnrollModal()"
              >
                报名参与
              </button>
              <div
                v-if="data.canEnroll && data.enroll"
                style="color: cornflowerblue; cursor: pointer"
                @click="$router.push({ name: 'personalActSubPage' })"
              >
                您已经报名，前往查看报名记录
              </div>
            </div>
          </div>
          <div v-if="isMatchDetail" class="activity_info">
            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              "
            >
              <div style="font-weight: bold">比赛时间：</div>
              {{ data.startTime }} 至 {{ data.endTime }}
            </div>
          </div>
        </div>
        <div class="information_detail" id="infoContentDetail"></div>
        <div
          v-if="isMatchDetail == true"
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="
              font-weight: bold;
              color: cornflowerblue;
              padding-bottom: 10px;
            "
          >
            报名时间：{{ data.enrollStartTime }} 至 {{ data.enrollEndTime }}
          </div>
          <button
            style="width: 200px"
            v-if="data.enrollState !== 'END_UP_ENROLL'"
            :disabled="data.enrollState == 'UN_ENROLL'"
            type="button"
            class="btn btn-primary"
            @click="jumpToEnrollPage()"
          >
            {{ data.enrollState == "UN_ENROLL" ? "未到报名时间" : "前往报名" }}
          </button>
          <button
            style="width: 200px"
            v-else
            type="button"
            class="btn btn-primary"
            @click="jumpToEnrollPage()"
          >
            '进入比赛'
          </button>
        </div>
      </div>
      <div
        v-if="isFromList"
        style="
          width: 350px;
          padding-left: 10px;
          border-left: 1px solid lightgrey;
        "
      >
        <div>
          <div
            style="font-weight: bold; color: cornflowerblue; font-size: 16px"
          >
            最近更新
          </div>
          <div
            style="
              height: 200px;
              display: flex;
              flex-direction: column;
              padding: 5px 0px;
            "
          >
            <div
              class="recommand-list-item"
              v-for="(value, index) in recommandList"
              :key="index"
            >
              <div class="title" :title="value.title" @click="toDetail(value)">
                {{ value.title }}
              </div>
              <div class="create-time">{{ value.showCreateTime }}</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="divider-group">
            <div class="divider"></div>
            <div class="divider"></div>
          </div>
        </div>
        <div>
          <div style="text-align: center">
            <img 
            alt="广东省城市建筑学会"
            src="../../../public/static/weixin_bg.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noticeModal from "../base/noticeModal.vue";
import pageNav from "../base/pageNav.vue";
export default {
  name: "informationDetailPage",
  components: {
    noticeModal,
    pageNav,
  },
  data() {
    return {
      data: {},
      beforeData: null,
      nextData: null,
      isFromList: false,
      isActDetail: false,
      isMatchDetail: false,
      navPage: {
        title: null,
        page: null,
      },
      articalType: null,
      articalPrefix: null,
      recommandList: [],
      subNavs: [],
      pageJson: {},
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    this.init();
    this.getRecommand();
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$(".information_container"));
      // var pageName = this.$route.name
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.subNavs = this.$bf.calPageNavs(this.pageJson.prefix);
      // this.articalType = this.page
      // this.articalPrefix = this.$bf.getArticalPrefix(this.articalType)
      // console.log(this.articalType)
      this.isActDetail = this.pageJson.type == 4;
      this.isFromList = true;
      this.isMatchDetail = this.pageJson.type == undefined;

      // if (this.$route.name =="infoDetailPage"){
      //     this.isFromList = true
      //     this.isActDetail = false
      //     this.isMatchDetail = false
      // }
      // if(this.$route.name == "actDetailPage") {
      //     this.isFromList = true
      //     this.isActDetail = true
      //     this.isMatchDetail = false
      // }
      // if(this.$route.name == "matchDetailPage") {
      //     this.isFromList = true
      //     this.isMatchDetail = true
      //     this.isActDetail = false
      // }
      this.id = this.$route.params.id;
      this.matchNo = this.$route.params.matchNo;
      // console.log(this.matchNo)
      this.getDetail();
    },
    getRecommand() {
      var _this = this;
      this.$service.get("/advert/recommand").then(function (e) {
        _this.recommandList = e.data.data;
      });
    },
    getDetail() {
      var _this = this;
      var url =
        this.pageJson.type == 4
          ? "/activity/detail?id=" + this.id
          : this.isMatchDetail
          ? "match/detail?matchNo=" + this.matchNo
          : "/artical/detail?id=" + this.id;
      this.$("#infoContentDetail").empty();
      if (this.id > 0 || this.matchNo) {
        this.$service.get(url).then(function (e) {
          _this.data = e.data.data;
          if (e.data.code == 405) {
            _this.$router.push({ name: "unMemberPage" });
          }
          if (!_this.isActDetail && !_this.isMatchDetail) {
          // if(e.data.data.type == 1) {
            //     _this.navPage.title =  "学会新闻"
            //     _this.navPage.page =  "informationListPage"
            // } else if (e.data.data.type == 2) {
            //     _this.navPage.title =     "行业动态"
            //     _this.navPage.page =  "industryListPage"
            // }
            // else if (e.data.data.type == 3) {
            //     _this.navPage.title =     "党群信息"
            //     _this.navPage.page =  "partyListPage"
            // }
          } else if (_this.isActDetail) {
            // _this.navPage.title = "协会活动"
            // _this.navPage.page =  "activityListPage"
          } else if (_this.isMatchDetail) {
            _this.data.title = _this.data.name;
            _this.navPage.title = "BIM比赛";
            _this.navPage.page = "matchPage";
          }
          _this.$("#infoContentDetail").append(_this.data.content);
        });
      }
    },
    clickNavItem(pageName) {
      if (pageName) {
        this.$router.push({ name: pageName });
      }
    },
    jumpToEnrollPage() {
      var now = new Date();
      var start = new Date(this.data.enrollStartTime);
      var end = new Date(this.data.enrollEndTime);
      if (!(start < now && now < end)) {
        this.$bf.warningAlert("还没有到报名时间");
        return;
      }
      this.$router.push({ name: "matchPage" });
    },
    enroll() {
      var _this = this;
      var url = "/activity/enroll";
      var body = {
        actNo: this.data.actNo,
      };
      if (this.id > 0) {
        this.$service.post(url, body).then(function (e) {
          _this.$bf.sucAlert("报名成功");
          _this.getDetail();
          // this.$route.go(-1)
        });
      }
    },
    toDetail(item) {
      var pageName = this.$bf.getArticalPrefix(item.type)
      this.$router.push({ name: pageName + "DetailPage", params: { id: item.id } });
    },
    showEnrollModal() {
      this.$("#enrollActModal").modal("show");
    },
  },
};
</script>
<style scoped>
.information_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  /* padding:20px; */
  height: 100%;
  width: 100%;
  background: white;
}
.info-detail-container {
  width: 1400px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
}
.recommand-list-item {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.recommand-list-item .title {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recommand-list-item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}
.recommand-list-item .create-time {
  color: grey;
  font-size:14px;
  white-space: nowrap;
}
.information_detail {
  font-size: 16px;
  padding: 15px 30px;
  line-height: 26px;
  width: 100%;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 20x;
  /* border-bottom: 1px solid lightgray; */
}
.information_detail_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.information_title {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  /* font-weight: bold;
  font-size: 20px; */
}
.info-info-detail {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: grey;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}
.activity_info {
  width: 100%;
  text-align: center;
  font-size: 14px;
  /* color:cornflowerblue */
}
</style>