<template>
  <div>
    <baseModal ref='enrollMatchModal' :id="'enrollMatchModal'" :content="'确认报名吗？(在报名期间仍然可以修改报名信息)'" @confirm="enroll">
      <template #title>{{ '报名' }}</template>
    </baseModal>
    <noticeModal ref='enrollSuccessModal' :id="'enrollSuccessModal'" :type="'notice'" :confirmBtnStr="'我知道了'"
      @confirm="$router.go(0);">
      <template #title>
        {{ "报名成功" }}
      </template>
      <template #content>
        {{ "恭喜您~您已报名成功！" }}
      </template>
    </noticeModal>
    <noticeModal ref='submitSuccessModal' :id="'submitSuccessModal'" :type="'notice'" :confirmBtnStr="'我知道了'"
      @confirm="$router.go(0);">
      <template #title>
        {{ "提交成功" }}
      </template>
      <template #content>
        {{ "恭喜您~您已提交作品！" }}
      </template>
    </noticeModal>
    <div style="
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 10px;
      ">
      <button v-if="!isEditEnroll" type="button" :class="enrollStatus != 'ENROLLING' || enrollSize >= 3
      ? 'btn btn-warning'
      : 'btn btn-warning'
      " :disabled="enrollStatus != 'ENROLLING' || enrollSize >= 3" @click="showEnrollPlane">
        {{
      enrollStatus != "ENROLLING"
        ? "当前非报名时间"
        : enrollSize >= 3
          ? "已达报名上限"
          : "前往报名"
    }}
      </button>
      <button v-if="isEditEnroll" type="button" class="btn btn-default" @click="showEnrollRecordPlane">
        返回
      </button>
    </div>
    <div style="width: 100%; display: flex; flex-direction: row">
      <ul class="nav nav-pills nav-stacked" style="padding-bottom: 10px" v-if="!isEditEnroll">
        <li v-for="(value, index) in enrollRecords" :key="index" :id="'myEnrollRecordItem' + index" role="presentation"
          :class="index == 0 ? 'active' : ''" style="
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          " @click="selectProject(index)">
          <a>{{ value.enrollNo }}</a>
        </li>
      </ul>
      <div id="enrollRecordPlane" style="width: 100%">
        <div v-if="enrollRecords.length > 0">
          <div class="enroll-grid">
            <div class="enroll-grid-item info-tag">
              报名信息
              <div>
                {{ match.enrollStartTime }} 至 {{ match.enrollEndTime }}
              </div>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">报名编号</div>
            </div>
            <div class="enroll-grid-item three-col" style="display: flex; justify-content: space-between">
              {{ enrollRecord.enrollNo }}
              <button type="button" class="btn btn-primary" v-if="enrollStatus == 'ENROLLING'"
                @click="editEnrollRecord(enrollRecord.id)">
                修改报名信息
              </button>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">申请人</div>
            </div>
            <div class="enroll-grid-item three-col">
              {{ enrollRecord.applyUserName }}
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                参赛组别
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <div>{{ enrollRecord.group }}</div>
              <div>{{ enrollRecord.enGroup }}</div>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                参赛单位
              </div>
              <div style="color: red">注：最多只能有3个单位参赛</div>
            </div>
            <div class="enroll-grid-item three-col">
              <table class="table table-bordered">
                <thead>
                  <th>单位名称</th>
                  <th>单位英文名称</th>
                  <th>单位类型</th>
                </thead>
                <tbody>
                  <tr v-for="(
                      data, index
                    ) in enrollRecord.matchEnrollCompanyEntities" :key="index">
                    <td>{{ data.name }}</td>
                    <td>{{ data.enName }}</td>
                    <td>{{ data.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                成员信息
              </div>
              <div style="color: red">
                注：仅限会员参赛，最多只能有15位成员（若本人参赛，请填写本人信息）
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <table class="table table-bordered">
                <thead>
                  <th>名称</th>
                  <th>英文名称</th>
                  <th>职务</th>
                  <th>联系电话</th>
                  <th>邮箱地址</th>
                </thead>
                <tbody>
                  <tr v-for="(
                      data, index
                    ) in enrollRecord.matchEnrollUserEntities" :key="index">
                    <td>{{ data.userName }}</td>
                    <td>{{ data.enUserName }}</td>
                    <td>{{ data.job }}</td>
                    <td>{{ data.phone }}</td>
                    <td>{{ data.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="enroll-grid-item info-tag">
              作品信息
              <div>
                {{ match.submitStartTime }} 至 {{ match.submitEndTime }}
              </div>
              <div v-if="submitStatus == 'SUBMITING'" style="font-size: 14px; color: red">
                请通过百度云盘上传作品后，再在本页面完善作品和工程信息
              </div>
              <div v-if="submitStatus == 'UN_SUBMIT'" style="font-size: 14px; color: red">
                目前还未到作品上传时间
              </div>
              <div v-if="submitStatus == 'END_UP_SUBMIT'" style="font-size: 14px; color: red">
                已经截止到作品上传时间
              </div>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                作品名称
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <input type="text" class="form-control" style="width: 100%" :disabled="!canEditWork"
                v-model="enrollRecord.workName" maxlength="300" placeholder="作品名称（中文）" />
              <input type="text" class="form-control" style="width: 100%; margin-top: 10px" :disabled="!canEditWork"
                v-model="enrollRecord.enWorkName" maxlength="300" placeholder="作品名称（英文）" />
              <input type="text" class="form-control" style="width: 100%; margin-top: 10px" :disabled="!canEditWork"
                v-model="enrollRecord.baiduUrl" maxlength="300" placeholder="百度网盘路径" />
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                工程名称
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <input type="text" class="form-control" style="width: 100%" :disabled="!canEditWork"
                v-model="enrollRecord.projectName" maxlength="100" placeholder="工程名称" />
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                工程规模
              </div>
            </div>
            <div class="enroll-grid-item">
              <input type="text" class="form-control" style="width: 100%" :disabled="!canEditWork"
                v-model="enrollRecord.projectSize" maxlength="50" placeholder="工程规模" />
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                结构形式
              </div>
            </div>
            <div class="enroll-grid-item">
              <input type="text" class="form-control" style="width: 100%" :disabled="!canEditWork"
                v-model="enrollRecord.projectStructure" maxlength="50" placeholder="结构形式" />
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                工程时间
              </div>
            </div>
            <div class="enroll-grid-item">
              <div style="display: flex; flex-direction: row">
                <div style="white-space: nowrap">开始时间</div>
                <input type="date" class="form-control" :disabled="!canEditWork" v-model="enrollRecord.projectStartDate"
                  name="startTime" />
              </div>
              <div style="display: flex; flex-direction: row">
                <div style="white-space: nowrap">竣工时间</div>
                <input type="date" class="form-control" :disabled="!canEditWork" v-model="enrollRecord.projectEndDate"
                  name="startTime" />
              </div>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                <div style="color: red; padding-right: 5px">*</div>
                建造费用
              </div>
            </div>
            <div class="enroll-grid-item">
              <div style="display: flex; flex-direction: row">
                <input type="number" class="form-control" :disabled="!canEditWork" v-model="enrollRecord.projectCost"
                  style="width: 100%" maxlength="50" placeholder="建造费用" />
                <div style="white-space: nowrap; text-align: center">万元</div>
              </div>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                该成果关于参加其他BIM竞赛的介绍
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <textarea class="form-control" style="resize: none" :disabled="!canEditWork" maxlength="500" rows="10"
                v-model="enrollRecord.otherBimDesc"></textarea>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                软件名称和版本
              </div>
            </div>
            <div class="enroll-grid-item">
              <textarea class="form-control" style="resize: none" :disabled="!canEditWork" maxlength="200" rows="5"
                v-model="enrollRecord.appName"></textarea>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                软件在工作中的支持
              </div>
            </div>
            <div class="enroll-grid-item">
              <textarea class="form-control" style="resize: none" :disabled="!canEditWork" maxlength="300" rows="5"
                v-model="enrollRecord.appUsed"></textarea>
            </div>
            <div class="enroll-grid-item title">
              <div style="display: flex; flex-direction: row">
                bim应用的主要成果
              </div>
            </div>
            <div class="enroll-grid-item three-col">
              <textarea class="form-control" style="resize: none" :disabled="!canEditWork" maxlength="500" rows="10"
                v-model="enrollRecord.bimResult"></textarea>
            </div>
            <div class="enroll-grid-item info-tag" style="background: unset" v-if="canEditWork">
              <div class="form-group">
                <button type="button" class="btn btn-primary" @click="submitWork()">
                  提交作品
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{
      enrollStatus == "UN_ENROLL"
        ? "还没有到达报名的时候喔~"
        : enrollStatus == "ENROLLING"
          ? "您尚未有报名/参与的项目"
          : enrollStatus == "END_UP_ENROLL"
            ? "已经过了报名截止时间了"
            : "暂不能报名"
    }}
        </div>
      </div>
      <div id="enrollPlane" style="width: 100%; display: none">
        <div style="color: red; padding-bottom: 10px">
          注：每个人报名以及参赛最多3个项目，当前还能报名或参与{{
        3 - enrollSize
      }}个项目
        </div>
        <div class="enroll-grid">
          <div class="enroll-grid-item info-tag">报名信息</div>
          <div class="enroll-grid-item title">
            <div style="display: flex; flex-direction: row; white-space: nowrap">
              <div style="color: red; padding-right: 5px">*</div>
              参赛单位
            </div>
            <div style="color: red">注：最多只能有3个单位参赛</div>
          </div>
          <div class="enroll-grid-item three-col">
            <div class="form-group">
              <button type="button" class="btn btn-primary" @click="addCompany()">
                新增企业单位
              </button>
            </div>
            <div v-for="(value, index) in enrollParams.companys" :key="index" class="form-group" style="
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                justify-content: flex-start;
              ">
              <input type="text" class="form-control" style="width: 360px; margin-right: 10px" maxlength="100"
                v-model="value.name" placeholder="企业/单位名称（中文）" />
              <input type="text" class="form-control" style="width: 360px; margin-right: 10px" v-model="value.enName"
                maxlength="100" placeholder="企业/单位名称（英文）" />
              <select class="form-control" placeholder="单位类型" style="width: auto; margin-right: 10px"
                v-model="value.type">
                <option value="建设">建设类型</option>
                <option value="设计">设计类型</option>
                <option value="施工">施工类型</option>
                <option value="咨询">咨询类型</option>
                <option value="院校">院校类型</option>
                <option value="其他">其他类型</option>
              </select>
              <button v-if="index !== 0" type="button" class="btn btn-danger" @click="removeCompany(index)">
                删除
              </button>
            </div>
          </div>
          <div class="enroll-grid-item title">
            <div style="display: flex; flex-direction: row">
              <div style="color: red; padding-right: 5px">*</div>
              参赛组别
            </div>
          </div>
          <div class="enroll-grid-item three-col">
            <select class="form-control" placeholder="参与组别" v-model="enrollParams.group" style="width: auto">
              <option v-for="(value, index) in config.matchGroupType" :key="index" :value="value">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="enroll-grid-item title">
            <div style="display: flex; flex-direction: row">
              <div style="color: red; padding-right: 5px">*</div>
              成员信息
            </div>
            <div style="color: red">
              注：仅限会员参赛，最多只能有15位成员（若本人参赛，请填写本人信息）
            </div>
          </div>
          <div class="enroll-grid-item three-col">
            <div class="form-group" style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              ">
              <button type="button" class="btn btn-info" @click="toBatchMember()">
                批量注册账号成为会员
              </button>
              <button type="button" class="btn btn-primary" @click="addMember()">
                新增参赛人员
              </button>
            </div>
            <div v-for="(value, index) in enrollParams.members" :key="index" class="form-group"
              style="margin-bottom: 10px">
              <div style="height: 100%; text-align: left; color: red">
                {{ !value.result ? value.result : "匹配失败：" + value.result }}
              </div>
              <div style="
                  display: flex;
                  flex-direction: row;
                  white-space: nowrap;
                  justify-content: flex-start;
                  border-bottom: 1px dashed lightgray;
                  padding-bottom: 10px;
                ">
                <div>
                  <input type="text" class="form-control" style="width: 220px; margin-right: 10px" placeholder="成员中文名"
                    v-model="value.userName" />
                  <input type="text" class="form-control" style="width: 220px; margin-right: 10px; margin-top: 10px"
                    max="100" placeholder="成员英文名，如：Zhang San" v-model="value.enUserName" />
                </div>
                <input type="text" class="form-control" style="width: 160px; margin-right: 10px" placeholder="联系方式"
                  v-model="value.phone" />
                <input type="text" class="form-control" style="width: 180px; margin-right: 10px" max="50"
                  placeholder="联系邮箱" v-model="value.email" />
                <input type="text" class="form-control" style="width: 80px; margin-right: 10px" max="50"
                  placeholder="职务" v-model="value.job" />
                <div>
                  <input type="text" class="form-control" style="width: 220px; margin-right: 10px" max="100"
                    placeholder="工作单位" v-model="value.company" />
                  <input type="text" class="form-control" style="width: 220px; margin-right: 10px; margin-top: 10px"
                    max="100" placeholder="工作单位（英文）" v-model="value.enCompany" />
                </div>
                <button type="button" class="btn btn-primary" :disabled="value.userName == '' || value.phone == ''"
                  style="height: 40px" @click="checkMember(value)">
                  匹配
                </button>
                <button v-if="index !== 0" type="button" class="btn btn-danger" style="height: 40px; margin-left: 10px"
                  @click="removeMember(index)">
                  删除
                </button>
              </div>
            </div>
          </div>
          <div class="enroll-grid-item title">
            <div style="display: flex; flex-direction: row">
              <div style="color: red; padding-right: 5px">*</div>
              版权声明及授权
            </div>
          </div>
          <div class="enroll-grid-item three-col" v-if="enrollParams.id == null">
            <div style="
                display: flex;
                flex-direction: row;
                width: 800px;
                justify-content: space-around;
              ">
              <a href="../../../public/static/版权声明及授权.docx" download="版权声明及授权.docx" style="cursor: pointer">下载模板</a>
              <div style="color: red">仅支持pdf文件格式</div>
              <input type="file" id="annouceFile" name="upload" accept=".pdf" ref="annouceFile" @change="uploadFile" />
            </div>
          </div>
          <div class="enroll-grid-item three-col" v-else>
            <div style="display: flex;flex-direction: row;justify-content: space-between;">
              <div>{{ enrollParams.annouceFile }}</div>
              <input type="file" id="annouceFile" name="upload" accept=".pdf" ref="annouceFile" @change="uploadFile" />
            </div>
          </div>
        </div>
        <div class="enroll-grid-item info-tag" v-if="enrollParams.id == null" style="
            background: unset;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          ">
          <div class="form-group">
            <button type="button" class="btn btn-warning" @click="saveEnroll()">
              保存
            </button>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary" @click="enrollConfirm()">
              报名
            </button>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-danger" @click="deleteEnroll()">
              清空信息
            </button>
          </div>
        </div>
        <div class="enroll-grid-item info-tag" v-else style="
            background: unset;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          ">
          <div class="form-group">
            <button type="button" class="btn btn-warning" @click="updateEnrollRecord()">
              修改
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baseModal from "../base/baseModal.vue";
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "matchEnrollPage",
  components: { baseModal, noticeModal },
  data() {
    return {
      isEditEnroll: false,
      enrollSize: 0,
      enrollStatus: null,
      submitStatus: null,
      enrollParams: {
        id: null,
        companys: [
          {
            name: "",
            enName: "",
            type: "建设",
          },
        ],
        members: [
          {
            id: null,
            userName: "",
            enUserName: "",
            phone: "",
            job: "",
            email: "",
            result: "",
          },
        ],
        group: "",
        enGroup: "",
      },
      enrollRecord: null,
      enrollRecords: [],
      config: {},
      match: null,
      canEditWork: false,
      editId: null,
    };
  },
  mounted() {
    this.showEnrollRecordPlane();
    this.getMatchConfig();
  },
  methods: {
    updateEnrollRecord() {
      var _this = this
      if (!this.checkEnrollParam()) {
        return
      }
      var enGroup = "";
      for (var group in this.config.matchGroupType) {
        if (this.config.matchGroupType[group] == this.enrollParams.group) {
          enGroup = group;
          break;
        }
      }
      var body = {
        id: this.enrollParams.id,
        matchNo: this.match.matchNo,
        companys: JSON.stringify(this.enrollParams.companys),
        members: JSON.stringify(this.enrollParams.members),
        group: this.enrollParams.group,
        enGroup: enGroup,
        announceFile: this.enrollParams.annouceFile,
      };
      // console.log(body)
      this.$service.post("/match/enroll", body).then(function (e) {
        // _this.enrollRecord = e.data.data
        // _this.deleteEnroll();
        _this.$router.go(0);
      });
    },
    getEnrollRecordById(id) {
      var _this = this;
      this.$service.get("/match/match-record?id=" + id).then(function (e) {
        _this.initEnrollParams(e.data.data);
      });
    },
    getEnrollStatus() {
      var _this = this;
      this.$service.get("/match/enroll-status").then(function (e) {
        _this.enrollStatus = e.data.data.enrollStatus;
        _this.submitStatus = e.data.data.submitStatus;
        _this.enrollSize = e.data.data.enrollSize;
        _this.match = e.data.data.match;
        _this.enrollRecords = e.data.data.enrollRecords;
        if (_this.enrollRecords.length > 0) {
          _this.enrollRecord = _this.enrollRecords[0];
          _this.canEditWork =
            _this.submitStatus == "SUBMITING" &&
            _this.$store.getters.userInfo.id == _this.enrollRecord.applyUserId;
        }
      });
    },
    toBatchMember() {
      var _this = this;
      var json = {
        matchNo: this.match.matchNo,
        data: JSON.stringify(this.enrollParams),
      };
      this.$service.post("/match/save-enroll", json).then(function (e) {
        _this.$router.push({ name: "batchApplyMemberPage" });
      });
    },
    getMatchConfig() {
      var _this = this;
      this.$service.get("/match/config").then(function (e) {
        _this.config = e.data.data;
      });
    },
    getMyRecord() {
      var _this = this;
      this.$service.get("/match/my-record").then(function (e) {
        _this.enrollRecords = e.data.data;
        if (_this.enrollRecords.length > 0) {
          _this.enrollRecord = _this.enrollRecords[0];
        }
      });
    },
    selectProject(index) {
      this.$("li[id^=myEnrollRecordItem]").removeClass("active");
      this.$("#myEnrollRecordItem" + index).addClass("active");
      this.enrollRecord = this.enrollRecords[index];
      this.canEditWork =
        this.submitStatus == "SUBMITING" &&
        this.$store.getters.userInfo.id == this.enrollRecord.applyUserId;
      this.enrollRecord.index = index;
    },
    checkMember(value) {
      // console.log(value);
      if (value.userName != "" && value.phone != "") {
        var json = {
          matchNo: this.match.matchNo,
          name: value.userName,
          approveUserId: this.enrollRecord==null?null:this.enrollRecord.applyUserId,
          phone: value.phone,
        };
        this.$service.post("match/memberCheck", json).then(function (e) {
          value.id = e.data.data.id;
          value.result = e.data.data.resultStr;
          value.job = e.data.data.job;
          value.email = e.data.data.email;
          value.company = e.data.data.enterprise;
        });
      } else {
        this.$bf.warningAlert("需要填写名称和电话号码才能匹配用户");
      }
    },
    addMember() {
      if (this.enrollParams.members.length >= 15) {
        this.$bf.warningAlert("参赛人员最多15人");
        return;
      }
      this.enrollParams.members.push({
        id: null,
        userName: "",
        enUserName: "",
        company: "",
        enCompany: "",
        phone: "",
        job: "",
        email: "",
        result: "",
      });
    },
    addCompany() {
      if (this.enrollParams.companys.length >= 3) {
        this.$bf.warningAlert("参赛单位最多3个");
        return;
      }
      this.enrollParams.companys.push({
        name: "",
        enName: "",
        type: "建设",
      });
    },
    removeMember(index) {
      var temp = [];
      for (var i = 0; i < this.enrollParams.members.length; i++) {
        if (i == index) {
          continue;
        }
        temp.push(this.enrollParams.members[i]);
      }
      this.enrollParams.members = temp;
    },
    removeCompany(index) {
      var temp = [];
      for (var i = 0; i < this.enrollParams.companys.length; i++) {
        if (i == index) {
          continue;
        }
        temp.push(this.enrollParams.companys[i]);
      }
      this.enrollParams.companys = temp;
    },
    editEnrollRecord(id) {
      this.$("#enrollPlane").show();
      this.$("#enrollRecordPlane").hide();
      this.isEditEnroll = true;
      this.getEnrollRecordById(id);
    },
    showEnrollRecordPlane() {
      this.$("#enrollPlane").hide();
      this.$("#enrollRecordPlane").show();
      this.isEditEnroll = false;
      this.getEnrollStatus();
      // this.getMyRecord()
    },
    showEnrollPlane() {
      this.$("#enrollPlane").show();
      this.$("#enrollRecordPlane").hide();
      this.isEditEnroll = true;
      this.getSavedEnroll();
    },
    initEnrollParams(data) {
      if (data == null) {
        this.enrollParams = {
          id: null,
          companys: [
            {
              name: "",
              enName: "",
              type: "建设",
            },
          ],
          members: [
            {
              id: null,
              userName: "",
              enUserName: "",
              company: "",
              enCompany: "",
              phone: "",
              job: "",
              email: "",
              result: "",
            },
          ],
          group: "",
          enGroup: "",
          result: null
        };
      } else {
        this.enrollParams = {
          id: data.id,
          companys: [],
          members: [],
          group: data.group,
          enGroup: data.enGroup,
          annouceFile: data.announceFile,
        };
        for (var cindex in data.matchEnrollCompanyEntities) {
          this.enrollParams.companys.push(
            {
              name: data.matchEnrollCompanyEntities[cindex].name,
              enName: data.matchEnrollCompanyEntities[cindex].enName,
              type: data.matchEnrollCompanyEntities[cindex].type,
            },
          )
        }
        for (var uindex in data.matchEnrollUserEntities) {
          this.enrollParams.members.push({
            id: data.matchEnrollUserEntities[uindex].userId,
            userName: data.matchEnrollUserEntities[uindex].userName,
            enUserName: data.matchEnrollUserEntities[uindex].enUserName,
            phone: data.matchEnrollUserEntities[uindex].phone,
            job: data.matchEnrollUserEntities[uindex].job,
            company: data.matchEnrollUserEntities[uindex].company,
            enCompany: data.matchEnrollUserEntities[uindex].enCompany,
            email: data.matchEnrollUserEntities[uindex].email,
            result: null,
          }
          )
        }
      }
    },
    getSavedEnroll() {
      var _this = this;
      this.$service
        .get("/match/saved-enroll?matchNo=" + this.match.matchNo)
        .then(function (e) {
          if (e.data.data != null) {
            _this.enrollParams = e.data.data;
          } else {
            _this.initEnrollParams(null);
          }
        });
    },
    saveEnroll() {
      // console.log(this.enrollParams);
      var _this = this;
      var json = {
        matchNo: this.match.matchNo,
        data: JSON.stringify(this.enrollParams),
      };
      this.$service.post("/match/save-enroll", json).then(function (e) {
        _this.$bf.sucAlert("保存成功");
      });
    },
    deleteEnroll() {
      // console.log(this.enrollParams);
      var _this = this;
      this.$service
        .get("/match/delete-enroll?matchNo=" + this.match.matchNo)
        .then(function (e) {
          _this.initEnrollParams(null);
          _this.$bf.sucAlert("清空成功");
        });
    },
    uploadFile() {
      var file = this.$refs.annouceFile.files[0];
      if (file.name.split(".")[1] != "pdf") {
        this.$bf.warningAlert("文件格式不是pdf");
        this.$refs.annouceFile.value = "";
        return;
      }
      if (file.size / (1024 * 1024) > 20) {
        this.$bf.warningAlert("文件大小不能大于20M");
        this.$refs.annouceFile.value = "";
        return;
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      var _this = this;
      this.$service.post("/utils/uploadFile", formData).then(function (e) {
        _this.enrollParams.annouceFile = e.data.data.name;
        // _this.$refs.annouceFile.value = ""
        // _this.articalInfo.img = ""
      });
      // this.$refs.annouceFile.value = "已上传"
    },
    submitWork() {
      var _this = this;
      if (
        this.enrollRecord.workName == "" ||
        this.enrollRecord.workName == null ||
        this.enrollRecord.enWorkName == "" ||
        this.enrollRecord.enWorkName == null ||
        this.enrollRecord.baiduUrl == "" ||
        this.enrollRecord.baiduUrl == null
      ) {
        this.$bf.warningAlert("必须填写作品信息");
        return;
      }
      if (
        this.enrollRecord.projectName == "" ||
        this.enrollRecord.projectName == null ||
        this.enrollRecord.projectSize == "" ||
        this.enrollRecord.projectSize == null ||
        this.enrollRecord.projectStartDate == "" ||
        this.enrollRecord.projectStartDate == null ||
        this.enrollRecord.projectEndDate == "" ||
        this.enrollRecord.projectEndDate == null ||
        this.enrollRecord.projectCost == "" ||
        this.enrollRecord.projectCost == null ||
        this.enrollRecord.projectStructure == "" ||
        this.enrollRecord.projectStructure == null
      ) {
        this.$bf.warningAlert("必须填写工程信息");
        return;
      }
      this.$refs.submitSuccessModal.open()
      this.$service.post("match/submit", this.enrollRecord).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$refs.submitSuccessModal.open();
      });
    },
    checkEnrollParam() {
      // console.log(1111)
      if (this.enrollParams.group == "") {
        this.$bf.warningAlert("组别不能为空");
        return false;
      }
      if (
        this.enrollParams.annouceFile == "" ||
        this.enrollParams.annouceFile == null
      ) {
        this.$bf.warningAlert("授权文件不能为空");
        return false;
      }
      for (var company in this.enrollParams.companys) {
        if (this.enrollParams.companys[company].name == null || this.enrollParams.companys[company].name == "") {
          this.$bf.warningAlert("第" + (parseInt(company) + 1) + "单位未填写中文名称");
          return false;
        }
        if (this.enrollParams.companys[company].enName == null || this.enrollParams.companys[company].enName == "") {
          this.$bf.warningAlert("第" + (parseInt(company) + 1) + "单位未填写英文名称");
          return false;
        }
      }
      for (var member in this.enrollParams.members) {
        if (this.enrollParams.members[member].id == null) {
          this.$bf.warningAlert(
            "第" + (parseInt(member) + 1) + "用户未匹配，请先匹配用户信息"
          );
          return false;
        }
        if (!this.$bf.checkName(this.enrollParams.members[member].userName)) {
          this.$bf.warningAlert("第" + (parseInt(member) + 1) + "参赛者姓名仅支持中文");
          return false;
        }
        if (!this.$bf.isMobilePhone(this.enrollParams.members[member].phone)) {
          this.$bf.warningAlert(
            "第" + (parseInt(member) + 1) + "个参赛人员联系电话格式异常"
          );
          return false;
        }
        if (
          this.enrollParams.members[member].job == null ||
          this.enrollParams.members[member].job == ""
        ) {
          this.$bf.warningAlert("第" + (parseInt(member) + 1) + "职务不能为空");
          return false;
        }
        if (
          this.enrollParams.members[member].company == null ||
          this.enrollParams.members[member].company == "" ||
          this.enrollParams.members[member].enCompany == null ||
          this.enrollParams.members[member].enCompany == ""
        ) {
          this.$bf.warningAlert(
            "第" + (parseInt(member) + 1) + "参赛者的单位中/英名称不能为空"
          );
          return false;
        }
        if (!this.$bf.isMail(this.enrollParams.members[member].email)) {
          this.$bf.warningAlert("第" + (parseInt(member) + 1) + "邮箱地址格式异常");
          return false;
        }
        // console.log(member)
        if (
          this.enrollParams.members[member].result != "" &&
          this.enrollParams.members[member].result != null
        ) {
          this.$bf.warningAlert(
            "第" + (parseInt(member) + 1) + "参赛成员不符合条件，请变更信息重新匹配"
          );
          return false;
        }
        // console.log(this.enrollParams.members[member].result)
      }
      return true;
    },
    enroll() {
      var _this = this;
      if (!this.checkEnrollParam()) {
        return
      }
      var enGroup = "";
      for (var group in this.config.matchGroupType) {
        if (this.config.matchGroupType[group] == this.enrollParams.group) {
          enGroup = group;
          break;
        }
      }
      var body = {
        matchNo: this.match.matchNo,
        companys: JSON.stringify(this.enrollParams.companys),
        members: JSON.stringify(this.enrollParams.members),
        group: this.enrollParams.group,
        enGroup: enGroup,
        announceFile: this.enrollParams.annouceFile,
      };
      // console.log(body)
      // this.$refs.enrollSuccessModal.open()
      this.$service.post("/match/enroll", body).then(function (e) {
        // _this.enrollRecord = e.data.data
        _this.deleteEnroll();
        _this.$refs.enrollSuccessModal.open()
        // _this.$router.go(0);
      });
    },
    enrollConfirm() {
      this.$refs.enrollMatchModal.open()
    }
  },
};
</script>
<style scoped>
.enroll-grid {
  display: grid;
  grid-template-columns: [menu-items] 180px auto auto auto;
  grid-template-rows: auto auto auto;
}

.enroll-grid-item {
  padding: 5px 10px;
  text-align: left;
  border: 1px solid lightgray;
}

.enroll-grid-item.info-tag {
  font-size: 16px;
  font-weight: bold;
  grid-column: 1 / 5;
  text-align: center;
  background-color: cornsilk;
  color: cornflowerblue;
}

.enroll-grid-item.title {
  background-color: whitesmoke;
  /* width:180px */
}

.enroll-grid-item.three-col {
  grid-column: 2 / 5;
}
</style>