<template>
    <div id="personalInfoContainer" >
        <div style="width:100%;background:cornsilk;padding:5px;text-align:center;border-bottom: 1px solid lightgray">前往<a style="cursor:pointer" @click="$router.push({name:'applyMemberPage'})">修改/完善</a>详细个人信息</div>
        <div class="item-row">
                <div class="item">
                    <div class="title">真实名称</div>
                    <input type="text" class="form-control input"                    
                    :value="userInfo.name ? userInfo.name:'-'"
                    readonly
                    >
                </div>
                <div class="item">
                    <div class="title">性别</div>
                    <input type="text" class="form-control input"                    
                    :value="userInfo.sexy? userInfo.sexy == 1? '男':'女' : '-'"
                    readonly
                    >
                </div>
                <div class="item">
                    <div class="title">出生日期</div>
                    <input type="text" class="form-control input"                    
                    :value="userInfo.birth"
                    readonly
                    >
                </div>
        </div>
        <div class="item-row">
                <div class="item">
                    <div class="title">职务</div>
                    <input type="text" class="form-control input"                    
                    :value="userInfo.job ? userInfo.job:'-'"
                    readonly
                    >
                </div>
                <div class="item">
                    <div class="title">最高学历</div>
                    <input type="text" class="form-control input"                    
                    :value="userInfo.qualification ? userInfo.qualification:'-'"
                    readonly
                    >
                </div>
                <div class="item">
                    <div class="title">专业</div>
                    <input type="text" class="form-control input"
                    :value="userInfo.speciality ? userInfo.speciality:'-'"                   
                    readonly
                    >
                </div>
        </div>
        <div class="item-row">
                <div class="item">
                    <div class="title">政治面貌</div>
                    <input type="text" class="form-control input"
                    readonly
                    :value="userInfo.politic ? userInfo.politic:'-'"   
                    >
                </div>
                <div class="item">
                    <div class="title">毕业院校</div>
                    <input type="text" class="form-control input"
                    readonly
                    :value="userInfo.graduated ? userInfo.graduated:'-'"
                    >
                </div>
                <div class="item">
                    <div class="title">所在单位/学校全称</div>
                    <input type="text" class="form-control input"
                    readonly
                    :value="userInfo.enterprise ? userInfo.enterprise:'-'"
                    >
                </div>
        </div>
        <div class="item-row">
                <div class="item">
                    <div class="title">联系地址</div>
                    <input type="text" class="form-control input"
                    readonly
                    :value="userInfo.address ? userInfo.address:'-'"   
                    >
                </div>                
        </div>
    </div>    
</template>
<script>
export default {
    name: "personalInfoSubPage",
    data () {
        return {
            userInfo: {}
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            this.getUserInfo()
        },
        getUserInfo() {
            var _this = this
            this.$service.get('/user/info').then(function (e) {
                _this.userInfo = e.data.data
            })
        }
    }
}
</script>
<style scoped>
#personalInfoContainer{
    padding-bottom:30px;
}
.item-row{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 15px;
}
.item .title{

}
.item .input{
    width:160px;
}
</style>