<template>
  <div id="applyMemberContainer">
    <apply-member-modal :id="'applyMemberModal'" :data="modalData" @confirm="payMember"></apply-member-modal>
    <div class="divider-group" style="padding: 60px 0px; width: 80%">
      <div class="text" style="font-size: 16px; color: cornflowerblue">
        注册账号
      </div>
      <div v-if="stage !== 1" class="divider" style="border-color: cornflowerblue"></div>
      <div v-else class="divider"></div>
      <div v-if="stage !== 1" class="text" style="font-size: 16px; color: cornflowerblue">
        完善资料
      </div>
      <div v-else class="text" style="font-size: 16px">完善资料</div>
      <div v-if="stage >= 3" class="divider" style="border-color: cornflowerblue"></div>
      <div v-else class="divider"></div>
      <div v-if="stage >= 3" class="text" style="font-size: 16px; color: cornflowerblue">
        会员权益
      </div>
      <div v-else class="text" style="font-size: 16px">会员权益</div>
      <div v-if="stage == 4" class="divider" style="border-color: cornflowerblue"></div>
      <div v-else class="divider"></div>
      <div v-if="stage == 4" class="text" style="font-size: 16px; color: cornflowerblue">
        申请会员
      </div>
      <div v-else class="text" style="font-size: 16px">申请会员</div>
    </div>
    <div v-if="stage == 1">
      <div class="form-group">
        <p style="font-size: 14px; padding-bottom: 10px">
          若已有用户账号，请先<a style="cursor: pointer" @click="$router.push({ name: 'loginPage' })">登录</a>
        </p>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            用户名
          </div>
          <input type="text" class="form-control item-input" v-model="userInfo.account" placeholder="请输入用户名" />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            密码
          </div>
          <input type="password" class="form-control item-input" v-model="userInfo.password" placeholder="请输入密码" />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            重复密码
          </div>
          <input type="password" class="form-control item-input" v-model="userInfo.rePassword" placeholder="请重新输入密码" />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            联系电话
          </div>
          <input type="text" class="form-control item-input" placeholder="请输入联系电话" v-model="userInfo.phone" />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            联系邮箱
          </div>
          <input type="text" class="form-control item-input" placeholder="请输入联系邮箱" v-model="userInfo.mail" />
        </div>
        <div class="item" style="padding-top: 10px">
          <button class="btn btn-primary" type="button" @click="register()">
            注册账号
          </button>
        </div>
      </div>
    </div>
    <div v-if="stage == 2">
      <div class="form-group">
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              真实名称
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入真实名称" name="account"
              v-model="userInfo.name" />
          </div>
          <div class="item-inline">
            <div class="item-label">性别</div>
            <select class="form-control item-input" placeholder="请选择性别" v-model="userInfo.sexy">
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              出生日期
            </div>
            <input type="date" class="form-control item-input" placeholder="请选择日期" v-model="userInfo.birth" />
          </div>
        </div>
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              联系地址
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入具体地址" v-model="userInfo.address" />
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              最高学历
            </div>
            <select type="text" class="form-control item-input" placeholder="请选择最高学历" v-model="userInfo.qualification">
              <option>其他</option>
              <option>中职</option>
              <option>高中</option>
              <option>大专</option>
              <option>本科</option>
              <option>硕士</option>
              <option>博士</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              专业
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入专业名称" v-model="userInfo.speciality" />
          </div>
        </div>
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              政治面貌
            </div>
            <select type="text" class="form-control item-input" placeholder="请选择政治面貌" v-model="userInfo.politic">
              <option>无</option>
              <option>群众</option>
              <option>共青团员</option>
              <option>中共党员</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              毕业院校
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入毕业院校" v-model="userInfo.graduated" />
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              所在单位/学校全称
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入所在单位/学校全称" v-model="userInfo.enterprise" />
          </div>
        </div>
        <div class="item-row-inline" style="width: 100%">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              职务
            </div>
            <input type="text" class="form-control item-input" placeholder="请输入职务" v-model="userInfo.job" />
          </div>
        </div>
        <div class="item-row-inline" style="justify-content: space-around">
          <div class="item-inline">
            <button class="btn btn-danger" type="button" @click="saveUserInfo()">
              {{ !$store.getters.isLogin ? "仅注册" : "更新信息" }}
            </button>
          </div>
          <div class="item-inline">
            <button class="btn btn-primary" type="button" @click="updateUserInfo();">
              申请会员
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="stage == 3">
      <div style="width: 100%">
        <p style="font-weight: bold; font-size: 18px; text-align: center">
          {{ rightArtical.title }}
        </p>
        <div id="articalContent" style="width: 1400px"></div>
      </div>
      <div class="item-row-inline" style="width:100%;justify-content: space-around;padding:20px 0px">
        <div class="item-inline">
          <button class="btn btn-danger" type="button" @click="
            $('#articalContent').empty;
          stage = 2">
            返回
          </button>
        </div>
        <div class="item-inline">
          <button class="btn btn-primary" type="button" @click="stage = 4">
            下一步
          </button>
        </div>
      </div>
    </div>
    <div v-if="stage == 4 && !userInfo.isMember">
      <div class="form-group">
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            会员类型
          </div>
          <select type="text" class="form-control item-input" v-model="userInfo.type" @change="selectRule">
            <option value="1">个人会员</option>
            <option value="2">企业会员</option>
            <option value="3">理事单位会员</option>
            <option value="4">常务理事单位会员</option>
            <option value="5">副会长单位会员</option>
            <option value="6">常务副会长单位会员</option>
            <option value="7">会长单位会员</option>
          </select>
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            会员年制
          </div>
          <select type="text" class="form-control item-input" v-model="memberYear" @change="selectRule">
            <option value="1">一年制</option>
            <option value="3">三年制</option>
            <option value="5">五年制</option>
          </select>
        </div>
        <div class="item" style="padding-top: 10px">
          <img style="
              height: 100px;
              border-radius: 10px;
              border: solid 1px lightgray;
            " src="../../../public/static/alipay_logo.jpg" />
          <!-- <p>目前仅支持支付宝支付</p> -->
        </div>
        <div class="item" style="padding-top: 10px">
          <p style="
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              color: cornflowerblue;
            ">
            需支付{{ memberRule.feeStr }}元
          </p>
          <button class="btn btn-primary" type="button" style="font-size: 16px" @click="payMember()">
            成为会员
          </button>
        </div>
      </div>
    </div>
    <div v-if="stage == 4 && userInfo.isMember" style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      ">
      <img style="width: 90px; height: 90px" alt="广东省城市建筑学会" src="../../../public/static/assiation_logo.jpg" />
      <div style="font-size: 16px; padding-top: 20px">
        你已经是会员了，可以前往<a style="cursor: pointer" @click="$router.push({ name: 'personalInfoSubPage' })">个人中心</a>查看到对应的数据
      </div>
    </div>
  </div>
</template>
<script>
import applyMemberModal from "../base/applyMemberModal.vue";
export default {
  name: "applyMemberPage",
  data() {
    return {
      userId: null,
      userInfo: {
        name: null,
        idNo: null,
        type: 1,
      },
      memberRules: null,
      memberYear: 1,
      memberRule: {},
      stage: 1,
      rightArtical: {},
      modalData: {
        name: "",
        desc: "",
      },
    };
  },
  components: {
    applyMemberModal,
  },
  mounted() {
    this.init();
  },
  watch: {
    stage: {
      handler(newval, oldval) {
        if (newval == 2) {
          this.getUserInfo();
        }
        if (newval == 3) {
          this.getMemberRights();
        }
        if (newval == 4) {
          this.getMemberRules();
        }
      },
    },
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#applyMemberContainer"));
      if (this.$store.getters.isLogin) {
        // 新增用户替其他用户注册的流程0407
        this.stage = 2;
      } else {
        this.stage = 1;
      }
    },
    register() {
      if (
        this.userInfo.account == "" ||
        this.userInfo.password == "" ||
        this.userInfo.phone == "" ||
        this.userInfo.rePassword == "" ||
        this.userInfo.mail == "" ||
        this.userInfo.type == "" ||
        this.userInfo.account == null ||
        this.userInfo.password == null ||
        this.userInfo.phone == null ||
        this.userInfo.rePassword == null ||
        this.userInfo.mail == null ||
        this.userInfo.type == null
      ) {
        this.$bf.warningAlert("请填写好表单中的内容，不能为空");
        return;
      }
      if (this.userInfo.password !== this.userInfo.rePassword) {
        this.$bf.warningAlert("两次密码输入不相同");
        return;
      }
      if (!this.$bf.isAccount(this.userInfo.account)) {
        this.$bf.warningAlert("账号格式异常,仅限字母数字和下划线且至少4位");
        return;
      }
      if (!this.$bf.isMobilePhone(this.userInfo.phone)) {
        this.$bf.warningAlert("手机号码格式异常");
        return;
      }
      if (!this.$bf.isMail(this.userInfo.mail)) {
        this.$bf.warningAlert("联系邮箱格式异常");
        return;
      }
      var json = {
        account: this.userInfo.account,
        password: this.userInfo.password,
        phone: this.userInfo.phone,
        mail: this.userInfo.mail,
        type: this.userInfo.type,
      };
      json.password = this.$md5(json.password);
      //    json.confirmPassword = this.$md5(json.confirmPassword)
      var _this = this;
      this.$service.post("/account/create", json).then(function (e) {
        _this.$bf.sucAlert("注册成功");
        _this.userId = e.data.data;
        _this.stage = 3;
      });
    },
    updateUserInfo() {
      var _this = this;
      if (
        this.userInfo.name == "" ||
        this.userInfo.sexy == "" ||
        this.userInfo.enterprise == "" ||
        this.userInfo.graduated == "" ||
        this.userInfo.birth == "" ||
        this.userInfo.job == "" ||
        this.userInfo.politic == "" ||
        this.userInfo.qualification == "" ||
        this.userInfo.speciality == "" ||
        this.userInfo.address == "" ||
        this.userInfo.name == null ||
        this.userInfo.sexy == null ||
        this.userInfo.enterprise == null ||
        this.userInfo.graduated == null ||
        this.userInfo.birth == null ||
        this.userInfo.job == null ||
        this.userInfo.politic == null ||
        this.userInfo.qualification == null ||
        this.userInfo.speciality == null ||
        this.userInfo.address == null
      ) {
        this.$bf.warningAlert("请填写好表单中的内容，不能为空");
        return;
      }
      // if(!this.$bf.checkIdNo(this.userInfo.idNo))  {
      //     this.$bf.warningAlert("身份证格式异常")
      //     return
      // }
      if (!this.$bf.checkName(this.userInfo.name)) {
        this.$bf.warningAlert("真实名称仅支持中文，最多显示8个字");
        return;
      }
      var json = this.userInfo;
      // if(this.userInfo.idNo){
      //     json.idNo = this.$bf.encodeIdNo(json.idNo)
      // }
      if (this.userId) {
        json.id = this.userId;
      }
      json.birth = json.birth.replaceAll(".", "-");
      this.$service.post("/user/update", this.userInfo).then(function (e) {
        _this.$bf.sucAlert("提交成功");
        _this.stage = 3;
      });
    },
    saveUserInfo() {
      var _this = this;
      // if(this.userInfo.idNo && !this.$bf.checkIdNo(this.userInfo.idNo))  {
      //     this.$bf.warningAlert("身份证格式异常")
      //     return
      // }
      if (this.userInfo.name && !this.$bf.checkName(this.userInfo.name)) {
        this.$bf.warningAlert("真实名称仅支持中文，最多显示8个字");
        return;
      }
      var json = {
        name: this.userInfo.name,
        sexy: this.userInfo.sexy,
        birth: this.userInfo.birth,
        politic: this.userInfo.politic,
        graduated: this.userInfo.graduated,
        qualification: this.userInfo.qualification,
        speciality: this.userInfo.speciality,
        enterprise: this.userInfo.enterprise,
        job: this.userInfo.job,
        address: this.userInfo.address,
      };
      // if(this.userInfo.idNo){
      //     json.idNo = this.$bf.encodeIdNo(this.userInfo.idNo)
      // }
      if (this.userId) {
        json.id = this.userId;
      }
      if(json.birth){
        json.birth = json.birth.replaceAll(".", "-");
      }
      this.$service.post("/user/update", json).then(function (e) {
        _this.$bf.sucAlert("注册成功");
        if (_this.$store.getters.isLogin) {
          _this.$router.push({ name: "capitalPage" });
        } else {
          _this.$router.push({ name: "loginPage" });
        }
      });
    },
    getUserInfo() {
      var _this = this;
      var query = _this.userId ? "?id=" + _this.userId : "";
      this.$service.get("/user/info" + query).then(function (e) {
        _this.userInfo = e.data.data;
      });
    },
    getMemberRights() {
      var _this = this;
      _this.$("#articalContent").empty;
      this.$service.get("/artical/detail?id=17").then(function (e) {
        _this.rightArtical = e.data.data
        _this.$("#articalContent").append(_this.rightArtical.content);
      });
    },
    getMemberRules() {
      var _this = this;
      this.$service.get("/member/config").then(function (e) {
        _this.memberRules = e.data.data;
        // console.log(_this.memberRules,_this.memberYear,_this.userInfo.type - 1)
        // console.log(_this.memberRules[_this.memberYear])
        _this.memberRule =
          _this.memberRules[_this.memberYear][_this.userInfo.type - 1];
      });
    },
    selectRule(val) {
      for (
        var index = 0;
        index < this.memberRules[this.memberYear].length;
        index++
      ) {
        if (index == this.userInfo.type - 1) {
          this.memberRule = this.memberRules[this.memberYear][index];
        }
      }
    },
    showModal() {
      this.modalData = this.memberRule;
      this.$("#applyMemberModal").modal("show");
    },
    payMember() {
      this.$router.push({
        name: "payOrderPage",
        params: { skuNo: this.memberRule.skuNo, userId: this.userId, deviceWay: "WEBPAY" },
      });
    },
  },
};
</script>
<style scoped>
#applyMemberContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  justify-content: flex-start;
  align-items: center;
  /* height: inherit; */
  background-color: white;
  width: 1400px;
}

.item-row-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
}

.item-inline {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  padding-top: 10px;
}

.item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding-top: 5px; */
}

.item {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  /* padding-top: 10px; */
}

.item-label {
  margin-right: 10px;
  font-size: medium;
  /* width: 70px; */
  display: inline-flex;
}

.item-need {
  color: red;
  padding: 0px 5px;
}

.item-input {
  width: 240px;
}

.apply-member-left {
  width: 800px;
  padding: 15px 40px;
  /* flex:1; */
  /* margin: 0px 300px; */
}

.apply-member-right {
  /* min-height: 100%; */
  font-size: 16px;
  border-left: 1px solid lightgray;
  padding-left: 90px;
  width: 400px;
  /* flex: 1; */
}
</style>
