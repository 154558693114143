<template>
  <div id="receiptAdminContainer">
    <approve-receipt-modal
      :id="'approveReceiptModal'"
      @approve="approveReceipt"
      :data="approveInfo"
    ></approve-receipt-modal>
    <reject-receipt-modal
      :id="'rejectReceiptModal'"
      @reject="rejectReceipt"
      :data="approveInfo"
    ></reject-receipt-modal>
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="text-align: right; padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group" style="padding: 0px 10px">
          <select
            class="form-control"
            v-model="searchFilter.type"
            @change="search(1)"
          >
            <option value="null">全部类型发票</option>
            <option value="1">增值税专用发票</option>
            <option value="2">增值税普通发票</option>
            <option value="3">增值税电子发票</option>
          </select>
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <select
            class="form-control"
            v-model="searchFilter.state"
            @change="search(1)"
          >
            <option value="null">全部状态</option>
            <option value="0">待审批</option>
            <option value="1">已审批</option>
            <option value="-1">驳回</option>
          </select>
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="按发票电话查询"
            @blur="search(1)"
            v-model="searchFilter.phone"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="按发票号查询"
            @blur="search(1)"
            v-model="searchFilter.receiptNo"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="按快递单号查询"
            @blur="search(1)"
            v-model="searchFilter.sendNo"
          />
        </div>
      </form>
    </div>
    <table v-if="listData.records.length != 0" class="table table-bordered">
      <thead>
        <!-- <th>Id</th> -->
        <!-- <th>批次号</th>   -->
        <th>申请人</th>
        <th>发票类型</th>
        <th>支付订单号</th>
        <th>识别号</th>
        <th>单位</th>
        <th>发票地址</th>
        <th>发票电话</th>
        <th>开户行</th>
        <th>开户账号</th>
        <th>发票号</th>
        <th>是否邮寄</th>
        <th>邮寄地址</th>
        <th>邮寄邮箱</th>
        <th>邮寄电话</th>
        <th>收件人</th>
        <th>快递单号</th>
        <th>状态</th>
        <th>操作</th>
      </thead>
      <tbody>
        <tr v-for="(value, index) in listData.records" :key="index">
          <td>{{ value.userName }}</td>
          <td>{{ value.typeStr }}</td>
          <td>{{ value.orderNo }}</td>
          <td>{{ value.identification }}</td>
          <td>{{ value.company }}</td>
          <td>{{ value.address }}</td>
          <td>{{ value.phone }}</td>
          <td>{{ value.bank }}</td>
          <td>{{ value.bankNo }}</td>
          <td>{{ value.receiptNo }}</td>
          <td>{{ value.isSendStr }}</td>
          <td>{{ value.sendAddress }}</td>
          <td>{{ value.email }}</td>
          <td>{{ value.sendPhone }}</td>
          <td>{{ value.receiptor }}</td>
          <td>{{ value.sendNo }}</td>
          <td>{{ value.stateStr }}</td>
          <td style="white-space: nowrap">
            <div class="btn-group" v-if="value.state == 0">
              <button
                type="button"
                class="btn btn-warning"
                @click="showApproveModal(value)"
              >
                审批
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="showRejectModal(value)"
              >
                驳回
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import approveReceiptModal from "../base/approveReceiptModal.vue";
import rejectReceiptModal from "../base/rejectReceiptModal.vue";
export default {
  name: "receiptAdminPage",
  components: {
    basePagination,
    approveReceiptModal,
    rejectReceiptModal,
  },
  data() {
    return {
      title: "发票管理",
      searchFilter: {
        type: null,
        state: 0,
      },
      listData: [],
      approveInfo: {},
      data: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.search(1);
    },
    search(page) {
      var _this = this;
      var json = {
        pageNo: page,
        pageSize: 10,
        receiptNo: this.searchFilter.receiptNo,
        phone: this.searchFilter.phone,
        sendNo: this.searchFilter.sendNo,
        type: this.searchFilter.type,
        state: this.searchFilter.state,
      };
      this.$service.post("receipt/list", json).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.receiptPagination.render(page, e.data.data.total);
      });
    },
    showApproveModal(value) {
      this.approveInfo = {
        id: value.id,
        isSend: value.isSend,
        sendNo: "",
        receiptNo: "",
        reason: "",
      };
      this.$("#approveReceiptModal").modal("show");
    },
    showRejectModal(value) {
      this.approveInfo = {
        id: value.id,
        isSend: value.isSend,
        sendNo: "",
        receiptNo: "",
        reason: "",
      };
      this.$("#rejectReceiptModal").modal("show");
    },
    rejectReceipt() {
      var _this = this;
      if (this.approveInfo.reason == "") {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      this.$service
        .post("/receipt/reject", this.approveInfo)
        .then(function (e) {
          _this.$bf.sucAlert("驳回成功");
          _this.search(1);
        });
      this.$("#rejectReceiptModal").modal("hide");
    },
    approveReceipt() {
      var _this = this;
      if (this.approveInfo.receiptNo == "") {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      if (this.approveInfo.isSend == 1 && this.approveInfo.sendNo == "") {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      this.$service
        .post("/receipt/approve", this.approveInfo)
        .then(function (e) {
          _this.$bf.sucAlert("审批成功");
          _this.search(1);
        });
      this.$("#approveReceiptModal").modal("hide");
    },
  },
};
</script>
<style scoped>
#receiptAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
