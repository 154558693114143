<template>
  <div id="activityDetailContainer">
    <apply-activity-modal
      :id="'enrollActModal'"
      :data="modalData"
      @pay="enroll()"
    ></apply-activity-modal>
    <page-nav :current="data.title"></page-nav>  
    <div class="info-detail-container">
      <div style="width: 100%">
        <div class="information_title">
          {{ data.title }}
        </div>
        <div class="info-info-detail">
          <p>发布时间：{{ data.createTime }}</p>
          <div class="activity_info">
            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              "
            >
              <!-- <div style="font-weight:bold">活动时间：</div>{{data.startTime}} 至  {{data.endTime}} &nbsp;  -->
              <p style="font-weight: bold">报名时间：</p>
              {{ data.enrollStartTime }} 至 {{ data.enrollEndTime }} &nbsp;
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
              "
            >
              <div
                v-if="!$store.getters.isLogin"
                style="color: cornflowerblue; cursor: pointer"
                @click="$router.push({ name: 'loginPage' })"
              >
                报名活动需要先登录，请前往登录
              </div>
              <div
                v-else-if="!data.canEnroll"
                style="color: cornflowerblue; cursor: pointer"
                @click="$router.push({ name: 'personalActSubPage' })"
              >
                当前并非报名时间
              </div>
              <div
                v-else-if="data.canEnroll && data.enroll"
                style="color: cornflowerblue; cursor: pointer"
                @click="$router.push({ name: 'personalActSubPage' })"
              >
                您已经报名，前往查看报名记录
              </div>
              <div
                v-else-if="data.enroll"
                style="color: cornflowerblue; cursor: pointer"
                @click="$router.push({ name: 'personalActSubPage' })"
              >
                您已经报过名了，前往查看报名记录
              </div>
              <div v-else-if="data.isMember && !data.hasMember">
                本次活动仅支持会员参加，请先<a
                  style="cursor: pointer"
                  @click="$router.push({ name: 'applyMemberPage' })"
                  >申请会员</a
                >
              </div>
              <button
                v-else-if="data.canEnroll && !data.enroll"
                type="button"
                class="btn btn-primary"
                @click="showEnrollModal"
              >
                报名参与
              </button>
            </div>
          </div>
        </div>
        <div class="information_detail" id="infoContentDetail"></div>
      </div>
    </div>
  </div>
</template>
<script>
import applyActivityModal from "../base/applyActivityModal.vue";
import pageNav from "../base/pageNav.vue";
export default {
  name: "activityDetailPage",
  components: {
    applyActivityModal,
    pageNav
  },
  data() {
    return {
      subNavs: [],
      pageJson: {},
      data: {},
      modalData: {
        id: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#activityDetailContainer"));
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.subNavs = this.$bf.calPageNavs(this.pageJson.prefix);
      this.getDetail();
    },
    getDetail() {
      var _this = this;
      var url = "/activity/detail?actNo=" + this.$route.params.actNo;
      this.$("#infoContentDetail").empty();
      if (this.$route.params.actNo) {
        this.$service.get(url).then(function (e) {
          _this.data = e.data.data;
          if (e.data.code == 405) {
            _this.$router.push({ name: "unMemberPage" });
          }
          _this.$("#infoContentDetail").append(_this.data.content);
        });
      }
    },
    clickNavItem(pageName) {
      if (pageName) {
        this.$router.push({ name: pageName });
      }
    },
    showEnrollModal() {
      this.modalData.id = this.data.id;
      this.$("#enrollActModal").modal("show");
    },
    enroll() {
      var _this = this;
      var url = "/activity/enroll";
      var body = {
        actNo: this.data.actNo,
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert("报名成功");
        _this.getDetail();
        _this.$("#enrollActModal").modal("hide");
        // this.$route.go(-1)
      });
    },
  },
};
</script>
<style scoped>
#activityDetailContainer {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.info-detail-container {
  width: 1400px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
}
.recommand-list-item {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.recommand-list-item .title {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recommand-list-item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}
.recommand-list-item .create-time {
  white-space: nowrap;
}
.information_detail {
  font-size: 16px;
  padding: 15px 30px;
  line-height: 26px;
  width: 100%;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 20x;
  /* border-bottom: 1px solid lightgray; */
}
.information_detail_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.information_title {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
}
.info-info-detail {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: grey;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}
.activity_info {
  width: 100%;
  text-align: center;
  font-size: 14px;
  /* color:cornflowerblue */
}
</style>
