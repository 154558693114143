<template>
  <div id="batchApplyMemberContainer">
    <div v-if="usersInfo.length > 0">
      <div v-for="(value, index) in usersInfo" :key="index">
        <div class="form-group">
          <div class="form-group">
            <div class="divider-group">
              <div class="divider"></div>
              <div class="text">新增用户{{ index + 1 }}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="item-row-inline">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                用户名
              </div>
              <input
                type="text"
                class="form-control item-input"
                v-model="value.account"
                placeholder="请输入用户名"
              />
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                联系电话
              </div>
              <input
                type="text"
                class="form-control item-input"
                @blur="checkAccount(value)"
                placeholder="请输入联系电话"
                v-model="value.phone"
              />
            </div>
            <div
              class="item-inline item-input"
              style="display: flex; justify-content: center"
            >
              <button
                type="button"
                class="btn btn-primary"
                :disabled="value.phone == ''"
                style="width: 120px"
                @click="checkAccount(value)"
              >
                匹配
              </button>
            </div>
          </div>
          <div class="item-row-inline">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                联系邮箱
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入联系邮箱"
                v-model="value.mail"
              />
            </div>
            <div class="item-inline" v-if="value.id == null">
              <div class="item-label">
                <div class="item-need">*</div>
                密码
              </div>
              <input
                type="password"
                class="form-control item-input"
                v-model="value.password"
                placeholder="请输入密码"
              />
            </div>
            <div class="item-inline" v-if="value.id == null">
              <div class="item-label">
                <div class="item-need">*</div>
                重复密码
              </div>
              <input
                type="password"
                class="form-control item-input"
                v-model="value.rePassword"
                placeholder="请重新输入密码"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="divider-group">
            <div class="divider"></div>
            <div class="text">会员类型</div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="form-group">
          <div class="item">
            <div class="item-label">
              <div class="item-need">*</div>
              会员类型
            </div>
            <select
              type="text"
              class="form-control item-input"
              v-model="value.type"
            >
              <option value="1">个人会员</option>
              <option value="2">企业会员</option>
              <option value="3">理事单位会员</option>
              <option value="4">常务理事单位会员</option>
              <option value="5">副会长单位会员</option>
              <option value="6">常务副会长单位会员</option>
              <option value="7">会长单位会员</option>
            </select>
          </div>
          <div class="item">
            <div class="item-label">
              <div class="item-need">*</div>
              会员年制
            </div>
            <select
              type="text"
              class="form-control item-input"
              v-model="value.year"
            >
              <option value="1">一年制</option>
              <option value="3">三年制</option>
              <option value="5">五年制</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="divider-group">
            <div class="divider"></div>
            <div class="text">个人信息</div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="form-group">
          <div class="item-row-inline">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                真实名称
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入真实名称"
                name="account"
                v-model="value.name"
              />
            </div>
            <div class="item-inline">
              <div class="item-label">性别</div>
              <select
                class="form-control item-input"
                placeholder="请选择性别"
                v-model="value.sexy"
              >
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                出生日期
              </div>
              <input
                type="date"
                class="form-control item-input"
                placeholder="请选择日期"
                v-model="value.birth"
              />
            </div>
          </div>
          <div class="item-row-inline">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                联系地址
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入具体地址"
                v-model="value.address"
              />
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                最高学历
              </div>
              <select
                type="text"
                class="form-control item-input"
                placeholder="请选择最高学历"
                v-model="value.qualification"
              >
                <option>其他</option>
                <option>中职</option>
                <option>高中</option>
                <option>大专</option>
                <option>本科</option>
                <option>硕士</option>
                <option>博士</option>
              </select>
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                专业
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入专业名称"
                v-model="value.speciality"
              />
            </div>
          </div>
          <div class="item-row-inline">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                政治面貌
              </div>
              <select
                type="text"
                class="form-control item-input"
                placeholder="请选择政治面貌"
                v-model="value.politic"
              >
                <option>无</option>
                <option>群众</option>
                <option>共青团员</option>
                <option>中共党员</option>
              </select>
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                毕业院校
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入毕业院校"
                v-model="value.graduated"
              />
            </div>
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                所在单位/学校全称
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入所在单位/学校全称"
                v-model="value.enterprise"
              />
            </div>
          </div>
          <div class="item-row-inline" style="width: 100%">
            <div class="item-inline">
              <div class="item-label">
                <div class="item-need">*</div>
                职务
              </div>
              <input
                type="text"
                class="form-control item-input"
                placeholder="请输入职务"
                v-model="value.job"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
            class="btn btn-danger"
            type="button"
            style="font-size: 16px"
            @click="removeUser(index)"
          >
            删除用户
          </button>
        </div>
      </div>
    </div>
    <div v-else class="no-data-text" style="width: 1000px; height: 100px">
      查询暂无数据
    </div>
    <div
      style="
        margin: 0px 10px;
        padding: 0px 10px;
        width: 200px;
        border-left: 1px solid lightgray;
      "
    >
      <div style="position: fixed; display: flex; flex-direction: column">
        <button
          class="btn btn-primary"
          type="button"
          style="font-size: 16px"
          @click="addUser()"
        >
          新增用户
        </button>
        <button
          class="btn btn-success"
          type="button"
          style="font-size: 16px; margin-top: 10px"
          @click="applyMember()"
        >
          批量申请会员
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "batchApplyMemberPage",
  data() {
    return {
      usersInfo: [],
      memberRules: [],
      order: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#batchApplyMemberContainer"));
      this.getMemberRules();
    },
    addUser() {
      if (this.usersInfo.length < 15) {
        this.usersInfo.push({
          id: null,
          account: "",
          password: "",
          rePassword: "",
          mail: "",
          phone: "",
          name: "",
          sexy: "",
          enterprise: "",
          graduated: "",
          birth: "",
          job: "",
          politic: "",
          qualification: "",
          speciality: "",
          address: "",
          type: 1,
          year: 1,
        });
      } else {
        this.$bf.warningAlert("最多同时申请15个会员");
        return;
      }
    },
    removeUser(index) {
      var temp = [];
      for (var i = 0; i < this.usersInfo.length; i++) {
        if (i == index) {
          continue;
        }
        temp.push(this.usersInfo[i]);
      }
      this.usersInfo = temp;
    },
    applyMember() {
      var _this = this;
      var requestData = [];
      if (this.usersInfo.length == 0) {
        this.$bf.warningAlert("请至少有一个用户注册");
        return;
      }
      for (var i = 0; i < this.usersInfo.length; i++) {
        // console.log(this.usersInfo[i])
        if (
          this.usersInfo[i].account == "" ||
          this.usersInfo[i].password == "" ||
          this.usersInfo[i].rePassword == "" ||
          this.usersInfo[i].phone == "" ||
          this.usersInfo[i].mail == "" ||
          this.usersInfo[i].name == "" ||
          this.usersInfo[i].sexy == "" ||
          this.usersInfo[i].enterprise == "" ||
          this.usersInfo[i].graduated == "" ||
          this.usersInfo[i].birth == "" ||
          this.usersInfo[i].job == "" ||
          this.usersInfo[i].politic == "" ||
          this.usersInfo[i].qualification == "" ||
          this.usersInfo[i].speciality == "" ||
          this.usersInfo[i].address == "" ||
          this.usersInfo[i].type == "" ||
          this.usersInfo[i].year == "" ||
          this.usersInfo[i].account == null ||
          this.usersInfo[i].password == null ||
          this.usersInfo[i].rePassword == null ||
          this.usersInfo[i].phone == null ||
          this.usersInfo[i].mail == null ||
          this.usersInfo[i].name == null ||
          this.usersInfo[i].sexy == null ||
          this.usersInfo[i].enterprise == null ||
          this.usersInfo[i].graduated == null ||
          this.usersInfo[i].birth == null ||
          this.usersInfo[i].job == null ||
          this.usersInfo[i].politic == null ||
          this.usersInfo[i].qualification == null ||
          this.usersInfo[i].speciality == null ||
          this.usersInfo[i].address == null ||
          this.usersInfo[i].type == null ||
          this.usersInfo[i].year == null
        ) {
          this.$bf.warningAlert(
            "请填写好新增用户" + parseInt(i + 1) + "中的内容，不能为空"
          );
          return;
        }
        if (this.usersInfo[i].password !== this.usersInfo[i].rePassword) {
          this.$bf.warningAlert(
            "请填写好新增用户" + parseInt(i + 1)+ "两次密码输入不相同"
          );
          return;
        }
        if (!this.$bf.isAccount(this.usersInfo[i].account)) {
          this.$bf.warningAlert(
            "请填写好新增用户" +
              (i + 1) +
              "账号格式异常,仅限字母数字和下划线且至少4位"
          );
          return;
        }
        if (!this.$bf.isMobilePhone(this.usersInfo[i].phone)) {
          this.$bf.warningAlert(
            "请填写好新增用户" + parseInt(i + 1) + "手机号码格式异常"
          );
          return;
        }
        if (!this.$bf.isMail(this.usersInfo[i].mail)) {
          this.$bf.warningAlert(
            "请填写好新增用户" + parseInt(i + 1)+ "联系邮箱格式异常"
          );
          return;
        }
        if (
          this.usersInfo[i].name &&
          !this.$bf.checkName(this.usersInfo[i].name)
        ) {
          this.$bf.warningAlert(
            "请填写好新增用户" + parseInt(i + 1) + "真实名称仅支持中文，最多显示8个字"
          );
          return;
        }
        var userData = {
          id: this.usersInfo[i].id,
          account: this.usersInfo[i].account,
          password:
            this.usersInfo[i].id == null || this.usersInfo[i].id == 0
              ? this.$md5(this.usersInfo[i].password)
              : this.usersInfo[i].password,
          rePassword: this.usersInfo[i].rePassword,
          mail: this.usersInfo[i].mail,
          phone: this.usersInfo[i].phone,
          name: this.usersInfo[i].name,
          sexy: this.usersInfo[i].sexy,
          enterprise: this.usersInfo[i].enterprise,
          graduated: this.usersInfo[i].graduated,
          birth: this.usersInfo[i].birth,
          job: this.usersInfo[i].job,
          politic: this.usersInfo[i].politic,
          qualification: this.usersInfo[i].qualification,
          speciality: this.usersInfo[i].speciality,
          address: this.usersInfo[i].address,
          type: 1,
          year: 1,
        };
        requestData.push(userData);
      }

      var json = {
        usersInfo: requestData,
      };
      // console.log(json);
      this.$service.post("/account/batchCreate", json).then(function (e) {
        _this.order = e.data.data;
        window.location.replace(
          e.data.data.alipayUrl + "?" + _this.order.alipayResponseBody
        );
      });
    },
    getMemberRules() {
      var _this = this;
      this.$service.get("/member/config").then(function (e) {
        _this.memberRules = e.data.data;
      });
    },
    checkAccount(value) {
      if (value.phone != "") {
        // console.log(value);
        var json = {
          //   name: value.account,
          phone: value.phone,
        };
        this.$service.post("account/check", json).then(function (e) {
          if (e.data.data != null) {
            value.id = e.data.data.id;
            value.account = e.data.data.account;
            value.password = e.data.data.password;
            value.rePassword = e.data.data.password;
            value.mail = e.data.data.mail;
            value.phone = e.data.data.phone;
            value.name = e.data.data.name;
            value.sexy = e.data.data.sexy;
            value.enterprise = e.data.data.enterprise;
            value.graduated = e.data.data.graduated;
            value.birth = e.data.data.birth;
            value.job = e.data.data.job;
            value.politic = e.data.data.politic;
            value.qualification = e.data.data.qualification;
            value.speciality = e.data.data.speciality;
            value.address = e.data.data.address;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
#batchApplyMemberContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
}
.item-row-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1000px;
}
.item-inline {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  padding-top: 10px;
}
.item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding-top: 5px; */
}
.item {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  /* padding-top: 10px; */
}
.item-label {
  margin-right: 10px;
  font-size: medium;
  /* width: 70px; */
  display: inline-flex;
}
.item-need {
  color: red;
  padding: 0px 5px;
}
.item-input {
  width: 240px;
}
</style>