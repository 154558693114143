<template>
  <div id="membersDetailContainer">
    <div class="info-detail-container">
      <div style="width: 100%">
        <h3 class="information_title">
          {{ data.title }}
        </h3>
        <div class="info-info-detail">
          发布时间：{{ data.createTime }}
        </div>
        <div class="information_detail" id="infoContentDetail"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "membersDetailPage",
  data() {
    return {
      data: {},
      pageJson: {},
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#membersDetailContainer"));
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.id = this.$route.params.id;
      this.getDetail();
    },
    getDetail() {
      var _this = this;
      var url ="/artical/detail?id=" + this.id;
      this.$("#infoContentDetail").empty();
      if (this.id > 0 || this.matchNo) {
        this.$service.get(url).then(function (e) {
          _this.data = e.data.data;
          if (e.data.code == 405) {
            _this.$router.push({ name: "unMemberPage" });
          }
          _this.$("#infoContentDetail").append(_this.data.content);
        });
      }
    },
    clickNavItem(pageName) {
      if (pageName) {
        this.$router.push({ name: pageName });
      }
    },
    toDetail(item) {
      var pageName = this.$bf.getArticalPrefix(item.type)
      this.$router.push({ name: pageName + "DetailPage", params: { id: item.id } });
    },
  },
};
</script>
<style scoped>
#membersDetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  font-size: 16px;
  /* padding:20px; */
  height: 100%;
  width: 100%;
  background: white;
}
.info-detail-container {
  /* width: 1400px; */
  /* padding: 10px 20px; */
  display: flex;
  flex-direction: row;
}
.information_detail {
  font-size: 16px;
  padding: 15px 30px;
  line-height: 26px;
  width: 100%;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 20x;
  /* border-bottom: 1px solid lightgray; */
}
.information_detail_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.information_title {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  /* font-weight: bold;
  font-size: 20px; */
}
.info-info-detail {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: grey;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}
.activity_info {
  width: 100%;
  text-align: center;
  font-size: 14px;
  /* color:cornflowerblue */
}
</style>