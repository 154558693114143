import Vue from 'vue'
import App from './App.vue'
import router from './router'


import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.js'
// import "../base.css"
import md5 from 'js-md5'
// import 'bootstrapVa'

import service from './js/request.js'

import baseFunc from './js/base.js'
import store from './store/store.js'
import $ from 'jquery'
// import bv from 'bootstrapvalidator'
// import 
// import 'bootstrap'
import fonts from 'bootstrap'
// 引入bootstrap样式
// import '../public/tinymce/tinymce.min.js'


Vue.config.productionTip = false
Vue.prototype.$ = $
Vue.prototype.$bf = baseFunc
// Vue.prototype.$bv = bv
Vue.prototype.$service = service
Vue.prototype.$md5 = md5

new Vue({
  render: h => h(App),
  router,
  store,
  fonts,
}).$mount('#app')
