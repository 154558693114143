<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <h4 class="modal-title" style="text-align:center;font-weight:bold">{{title}}</h4>
            </div>
            <div class="modal-body" style="text-align:center">
                <form role="form" class="form-horizontal" style="width:100%">
                    <div class="form-group">
                        评分员可选1~2名，若是两名，则计分时算平均总分
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-sm-2">评分员1</label>
                        <div class="col-sm-4">
                            <select class="form-control" placeholder="评分员" v-model="data.submitUserId1">                           
                                    <option :value="value.id" v-for="(value,index) in remarkUsers" :key="index">{{value.name?value.name:value.account}}</option>  
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-sm-2">评分员2</label>
                        <div class="col-sm-4">
                            <select class="form-control" placeholder="评分员2" v-model="data.submitUserId2">                           
                                    <option :value="value.id" v-for="(value,index) in remarkUsers" :key="index">{{value.name?value.name:value.account}}</option>  
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="text-align:center">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
                <button type="button" class="btn btn-primary" @click="submitMethod">选择</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal -->
</div>
</template>
<script>
export default {
    name:"remarkUserModal",
    mounted() {
        this.getRemarkUsers()
    },
    data (){
        return {
            title: "选择评分员",
            remarkUsers: []
        }
    },
    props:{
        id: {},  
        data: {
            default: {                
                submitUserId1: "",
                submitUserId2: "",
                id: 0
            }
        }
    },
    methods: {
        closeMethod() {                
            this.$(this).modal('hide')
        },
        submitMethod() {
            this.$emit('submit', this.userInfo)           
        },
        getRemarkUsers() {
          var _this = this    
          var url = '/user/suggest?roleId=3'
          this.$service.get(url).then(function (e){
            _this.remarkUsers = e.data.data
          })  
      }
        // fillContent(content) {
        //     this.$('#modalBodyContent').append(content)
        // }
    }
}
</script>
<style scoped>

</style>
