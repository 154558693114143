<template>
  <div id="designerAdminContainer">
    <notice-modal :id="'designerAdminModal'" @confirm="approve()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
    </notice-modal>    
    <notice-modal :id="'designerCancelAdminModal'" @confirm="cancel()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认禁用用户的设计师资格吗？配置将立即生效</template>
    </notice-modal>
    <notice-modal :id="'designerOpenAdminModal'" @confirm="open()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认开启用户的设计师资格吗？配置将立即生效</template>
    </notice-modal>
    <reject-designer-modal
      :id="'designerRejectModal'"
      @reject="reject"
      :data="updateInfo"
    ></reject-designer-modal>
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="text-align: right; padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group">
              <select class="form-control" placeholder="设计师类型" v-model="searchFilter.type">
                <option>全部类型</option>
                <option v-for="(value,index) in $bf.DESIGNER_TYPE" :key="index">{{ value }}</option>                
            </select>
        </div> 
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="按照用户名称查询"
            v-model="searchFilter.name"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="search(1)"
        >
          查询
        </button>
      </form>
    </div>
    <table v-if="listData.records.length != 0" class="table table-bordered">
      <thead>
        <!-- <th>Id</th> -->
        <!-- <th>批次号</th>   -->
        <th>用户ID</th>
        <th>账号注册时间</th>
        <th>姓名</th>
        <th>专家类型</th>
        <th>单位</th>
        <th>职务</th>
        <th>描述</th>
        <th>理由</th>
        <th>状态</th>
        <th>操作</th>
      </thead>
      <tbody>
        <tr v-for="(value, index) in listData.records" :key="index">
          <td>{{ value.userEntity.id }}</td>
          <td>{{ value.userEntity.createTime }}</td>
          <td>{{ value.userEntity.name }}</td>
          <td>{{ value.type }}</td>
          <td>{{ value.userEntity.enterprise }}</td>
          <td>{{ value.userEntity.job }}</td>
          <td>{{ value.desc }}</td>
          <td>{{ value.reason }}</td>
          <td>{{ value.stateStr }}</td>
          <td style="white-space: nowrap">
            <div class="btn-group" v-if="value.state == 0">
              <button
                type="button"
                class="btn btn-warning"
                @click="showApproveModal(value)"
              >
                审批
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="showRejectModal(value)"
              >
                驳回
              </button>
            </div>
            <div>
              <button
                v-if="value.state == 1"
                type="button"
                class="btn btn-danger"
                @click="showCancelModal(value)"
              >
                禁用
              </button>
              <button
                v-if="value.state == 1"
                type="button"
                class="btn btn-primary"
                @click="updateUpdateTime(value)"
              >
                置顶
              </button>
              <button
                v-if="value.state == -2"
                type="button"
                class="btn btn-success"
                @click="showOpenModal(value)"
              >
                开启
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>

<script>
import noticeModal from "../base/noticeModal.vue";
import basePagination from "../base/pagination.vue";
import rejectDesignerModal from "../base/rejectDesignerModal.vue";
export default {
  name: "designerAdminPage",
  components: {
    noticeModal,
    basePagination,
    rejectDesignerModal,
  },
  data() {
    return {
      title: "设计师管理",
      searchFilter: {
        name: "",
        type: "全部类型"
      },
      listData: [],
      currentPage:1,
      updateInfo: {
        reason: "",
      },
      data: {},
    };
  },
  mounted() {
    this.search(1);
  },
  watch: {
    'searchFilter.type': function () {
      this.search(1)
    }
  },
  methods: {
    search(page) {
      var _this = this;
      this.currentPage = page;
      var json = {
        pageNo: page,
        pageSize: 10,
        name: this.searchFilter.name,
        type: this.searchFilter.type == "全部类型"? null: this.searchFilter.type 
      };
      this.$service.post("designer/list", json).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.designerPagination.render(page, e.data.data.total);
      });
    },
    showApproveModal(value) {
      this.updateInfo = {
        id: value.id,
        newState: 1,
        oldState: 0,
        reason: "",
      };
      this.$("#designerAdminModal").modal("show");
    },
    showCancelModal(value) {
      this.updateInfo = {
        id: value.id,
        newState: -2,
        oldState: 1,
        reason: "",
      };
      this.$("#designerCancelAdminModal").modal("show");
    },
    showRejectModal(value) {
      this.updateInfo = {
        id: value.id,
        newState: -1,
        oldState: 0,
        reason: value.reason,
      };
      this.$("#designerRejectModal").modal("show");
    },
    showOpenModal(value) {
      this.updateInfo = {
        id: value.id,
        newState: 1,
        oldState: -2
      };
      this.$("#designerOpenAdminModal").modal("show");
    },
    approve() {
      var _this = this;
      this.$service
        .post("/designer/update", this.updateInfo)
        .then(function (e) {
          _this.$bf.sucAlert("审批成功");
          _this.search(_this.currentPage);
        });
    },
    reject() {
      var _this = this;
      this.$service
        .post("/designer/update", this.updateInfo)
        .then(function (e) {
          _this.$bf.sucAlert("拒绝申请");
          _this.search(_this.currentPage);
        });
    },
    updateUpdateTime(value){
      var _this = this;
      this.updateInfo = {
        id: value.id,
        newState: null,
        oldState: null,
        reason: "",
      };
      this.$service
        .post("/designer/update", this.updateInfo)
        .then(function (e) {
          _this.$bf.sucAlert("置顶成功");
          _this.search(_this.currentPage);      
        });
    },
    cancel() {
      var _this = this;
      this.$service
        .post("/designer/update", this.updateInfo)
        .then(function (e) {
          _this.$bf.sucAlert("取消成功");
          _this.search(_this.currentPage);
        });
    },
    open() {
      var _this = this;
      this.$service
        .post("/designer/update", this.updateInfo)
        .then(function (e) {
          _this.$bf.sucAlert("取消成功");
          _this.search(_this.currentPage);
        });
    },
  },
};
</script>
<style scoped>
#designerAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
