<template>
  <div style="padding:20px">
    <notice-modal :id="'infoEditConfirmModal'" @confirm="submit()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认修改吗？</template>
    </notice-modal>   
    <form role="form" style="padding-bottom: 20px" id="informationEditForm">
      <div class="form-group" style="width:30%">
        <label for="exampleInputName2">对外标题</label>
        <input type="text" class="form-control" placeholder="对外标题，最多100字" name="title" maxlength="100"
          v-model="articalInfo.title">
      </div>
      <div class="form-group" style="width:60%">
        <label for="exampleInputName2">对外简述</label>
        <input type="text" class="form-control" placeholder="对外简述，最多200字" name="desc" maxlength="200"
          v-model="articalInfo.desc">
      </div>
      <div class="form-group" style="width:80px">
        <label for="exampleInputName2">类型</label>
        <div>{{ articalInfo.typeStr }}</div>
      </div>
      <div class="form-group" style="width:80px">
        <label for="exampleInputName2">仅限会员</label>
        <select type="text" class="form-control" name="isMember" v-model="articalInfo.isMember">
          <option value=1>是</option>
          <option value=0>否</option>
        </select>
      </div>
      <div class="form-group" style="width:60%">
        <label for="exampleInputName2">文章时间（选填）</label>
        <input type="datetime-local" class="form-control" name="startTime" v-model="articalInfo.showCreateTime" />
      </div>
      <div class="form-group">
        <label for="exampleInputFile" style="white-space:nowrap">显示头图
         <div> {{$route.name == "industryCreatePage" || $route.name == "informationCreatePage" ||
          $route.name == "industryEditPage" || $route.name == "informationEditPage"
          ?"(100*160)":""}}</div>
          <div> {{$route.name == "partyCreatePage" || $route.name == "partyEditPage"
          ?"(100*160)":""}}</div>
          <div> {{$route.name == "membersCreatePage" || $route.name == "membersEditPage"
          ?"(120*80)":""}}</div>
        </label>
        <input type="file" name="upload" ref="bannerFile" @change="uploadBanner" />
      </div>
      <div class="form-group">
        <div>
          <img style="width:400px;height:400px" v-if="articalInfo.img != ''" :src="$bf.getImgUrl(articalInfo.img)"
            name="img">
        </div>
      </div>
      <div class="form-group">
        <!-- <editor name="content" id='uuid' v-model="articalInfo.content" api-key="no-api-key" output-format='html' :init="{
          plugins: 'preview autolink directionality visualchars fullscreen image link template code table pagebreak nonbreaking anchor insertdatetime advlist lists autoresize imagetools',
          menubar: false,
          statusbar: false,
          language: 'zh-Hans',
          images_upload_url: $bf.EDITOR_UPLOAD_URL,
          images_upload_base_path: $bf.STATIC_IMG_URL,
          branding: false,
          toolbar: 'undo redo | fontsizeselect fontselect forecolor bold italic underline strikethrough | alignleft aligncenter alignright outdent indent | bullist numlist | link table image | preview ',
          //原生鼠标右键菜单开关 true 编辑区域鼠标无法右键
          contextmenu_never_use_native: false,
          //编辑器鼠标右键菜单
          contextmenu: false,
          height: '100%'
        }" value="" /> -->
        <tinymce-edit-model ref="infoTem"></tinymce-edit-model>
      </div>
      <div class="btn-div">
        <button type="button" class="btn btn-primary" @click="showModal">保存</button>
        <button v-if="this.pageJson.adminPage" class="btn btn-default" @click="backToPage()">取消</button>
        <button class="btn btn-danger" @click="clear">清空</button>
      </div>
    </form>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import tinymceEditModel from "../base/tinymceEditModel.vue"
import noticeModal from '../base/noticeModal.vue'
export default {
  name: 'informationEditPage',
  components: {
    // 'editor': Editor,
    tinymceEditModel,
    noticeModal
  },
  data() {
    return {
      articalInfo: {
        id: 0,
        desc: '',
        content: '',
        type: 0,
        title: "",
        img: "",
        isMember: 0
      },
      paginInfo: {
        prePage: "",
        currentPage: ""
      },
      pageJson: {}
    }
  },

  watch: {
    '$route': function () {
      this.init()
    }
  },
  mounted() {
    // var _this =this  
    this.init()
  },
  methods: {
    init() {
      this.articalInfo.id = this.$route.params.id ? this.$route.params.id : this.articalInfo.id
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name)
      // this.paginInfo.currentPage = this.$route.name
      // if (this.paginInfo.currentPage == 'informationEditPage' || this.paginInfo.currentPage == 'informationCreatePage'){
      //   this.paginInfo.prePage  = "informationAdminPage"
      //   this.articalInfo.type = 1
      // } else if (this.paginInfo.currentPage == 'industryEditPage'  || this.paginInfo.currentPage == 'industryCreatePage'){
      //   this.paginInfo.prePage  = "industryAdminPage"
      //   this.articalInfo.type = 2
      // }
      // else if (this.paginInfo.currentPage == 'partyEditPage'  || this.paginInfo.currentPage == 'partyCreatePage'){
      //   this.paginInfo.prePage  = "partyAdminPage"
      //   this.articalInfo.type = 3
      // }
      if (this.articalInfo.id > 0) {
        var _this = this
        this.$service.get("/artical/detail?id=" + this.articalInfo.id).then(
          function (e) {
            _this.articalInfo = e.data.data
            _this.$refs.infoTem.setContent(_this.articalInfo.content)
          }
        )
      }
    },
    submit() {
      this.articalInfo.content = this.$refs.infoTem.getContent()
      if (this.articalInfo.title == '' || this.articalInfo.title == null
        || this.articalInfo.content == '' || this.articalInfo.content == null
        || this.articalInfo.desc == '' || this.articalInfo.desc == null
      ) {
        this.$bf.warningAlert("关键参数不能为空")
        return
      }
      if (this.articalInfo.img == "") {
        this.$bf.warningAlert("图片不能为空")
        return
      }
      var body = {}
      var _this = this
      body['content'] = this.articalInfo.content
      body['title'] = this.articalInfo.title
      body['type'] = this.pageJson.type
      body['desc'] = this.articalInfo.desc
      body['img'] = this.articalInfo.img
      body['isMember'] = this.articalInfo.isMember
      body['createTime'] = this.articalInfo.showCreateTime 
      
      if (this.articalInfo.id > 0) {
        body['id'] = this.articalInfo.id
        this.$service.post("/artical/update", body).then(function (e) {
          _this.content = ""
          _this.backToPage()
        })
      } else {
        this.$service.post("/artical/create", body).then(function (e) {
          _this.content = ""
          _this.backToPage()
        })
      }
    },
    clear() {
      this.articalInfo.title = ""
      this.articalInfo.desc = ""
      this.articalInfo.content = ""
    },
    backToPage() {
      console.log(this.pageJson)
      if (this.pageJson.adminPage) {
        this.$router.push({ name: this.pageJson.adminPage })
      } else {
        this.$bf.sucAlert("修改成功")
      }
    },
    uploadBanner() {
      // 每次上传都要清空数据            
      var file = this.$refs.bannerFile.files[0]
      var reason = this.$bf.checkImg(file)
      if (reason != null) {
        this.$bf.warningAlert(reason)
        this.$refs.bannerFile.value = ""
        this.articalInfo.img = ""
        return
      }
      let formData = new FormData()
      formData.set("fileName", file.name)
      formData.set("file", file)
      var _this = this
      this.$service.post("/utils/upload", formData).then(function (e) {
        _this.articalInfo.img = e.data.data.name
        _this.$refs.bannerFile.value = ""
      })
    },
    showModal() {
      this.$('#infoEditConfirmModal').modal('show')
    }
  }
}
</script>
<style scoped>
.btn-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  padding: 15px;
}
</style>