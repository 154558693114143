<template>
  <div class="mobileContainer">
    <div v-if="!$store.isLogin">
      <div style="padding-bottom: 10px; text-align: center">
        <img
          style="width: 90px; height: 90px"
          alt="广东省城市建筑学会"
          src="../../../public/static/assiation_logo.jpg"
        />
      </div>
      <p>报名前，请先登录学会账号</p>
      <div v-if="way == 0">
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon glyphicon glyphicon-user"></span>
            <input
              type="text"
              class="form-control"
              name="account"
              placeholder="请输入账号"
              v-model="params.account"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon glyphicon glyphicon-lock"></span>
            <input
              type="password"
              class="form-control"
              placeholder="请输入密码"
              name="password"
              v-model="params.password"
            />
          </div>
        </div>
      </div>
      <div v-if="way == 1">
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon glyphicon glyphicon-phone"></span>
            <input
              type="num"
              class="form-control"
              placeholder="请输入电话号码"
              name="phone"
              v-model="params.phoneNum"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon glyphicon glyphicon-envelope"></span>
            <input
              type="num"
              class="form-control"
              placeholder="短信验证码"
              name="smsCode"
              v-model="params.smsCode"
            />
            <span class="input-group-btn">
              <button
                class="btn btn-primary"
                :disabled="lastTime > 0"
                type="button"
                @click="sendCode()"
              >
                {{ lastTime > 0 ? lastTime + "秒后再试" : "发送验证码" }}
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button
          v-if="way == 0"
          type="button"
          class="btn btn-default btn-lg"
          style="width: 100%"
          @click="changeWay(1)"
        >
          短信验证码登录
        </button>
        <button
          v-if="way == 1"
          type="button"
          class="btn btn-default btn-lg"
          style="width: 100%"
          @click="changeWay(0)"
        >
          账号密码登录
        </button>
      </div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          style="width: 100%"
          @click="login()"
        >
          登录
        </button>
      </div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-warning btn-lg"
          style="width: 100%"
          @click="register()"
        >
          注册
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loginMobilePage",
  data() {
    return {
      params: {
        account: null,
        password: null,
        smsCode: null,
        phoneNum: null
      },
      way: 0,
      id: 0,
    };
  },
  mounted() {
    // this.actNo = this.$route.params.actNo;
  },
  methods: {
    changeWay(way) {
      this.params = {};
      this.way = way;
    },
    register() {
      this.$router.push({ name: "registerMobilePage"});
    },
    login() {
      var json = null;
      if (this.way == 1) {
        if (this.params.phoneNum == "" || this.params.smsCode == "") {
          this.$bf.warningAlert("电话号码和短信验证码不能为空");
          return;
        }
        json = JSON.parse(JSON.stringify(this.params));
      } else {
        if (this.params.account == "" || this.params.password == "") {
          this.$bf.warningAlert("账号和密码不能为空");
          return;
        }
        if (!this.$bf.isAccount(this.params.account)) {
          this.$bf.warningAlert("账号格式异常");
          return;
        }
        json = JSON.parse(JSON.stringify(this.params));
        json.password = this.$md5(json.password);
      }
      var _this = this;
      this.$service.post("/account/login", json).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$store.commit("saveUserInfo", e.data.data);
        _this.$router.push({ name: "activityMobilePage"});
      });
    },
    sendCode() {
      if (
        this.params.phoneNum == "" ||
        this.params.phoneNum == null
      ) {
        this.$bf.warningAlert("请先输入登录号码");
        return;
      }
      if (!this.$bf.isMobilePhone(this.params.phoneNum)) {
        this.$bf.warningAlert("非法格式登录号码");
        return;
      }
      var _this = this;
      this.$service
        .get("/account/sendSms?phoneNum=" + this.params.phoneNum)
        .then(function (e) {
          _this.lastTime = e.data.data.lastTime;
          _this.startInterval();
        });
    },
    startInterval() {
      var _this = this;
      this.timer = setInterval(() => {
        if (_this.lastTime <= 0) {
          _this.stopInterval();
        }
        _this.lastTime -= 1;
      }, 1000);
    },
    stopInterval() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="postcss" scoped>
.mobileContainer {
  /* width:100%;
    height:100%; */
  /* background: black; */
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
}
</style>
