<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            &times;
          </button>
          <h4 class="modal-title">
            <slot name="title">默认标题</slot>
          </h4>
        </div>
        <div class="modal-body">
          <slot name="content">默认内容</slot>
        </div>
        <div class="modal-footer" :style="type === 'notice'?'text-align: center;':''">
          <button type="button" class="btn btn-default" data-dismiss="modal" @click="close" v-if="type !== 'notice'">
            关闭
          </button>
          <button type="button" class="btn btn-primary" @click="confirm">
            <slot name="confirmBtnStr">
              {{confirmBtnStr}}
            </slot>
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal -->
  </div>
</template>
<script>
export default {
  name: "noticeModal",
  props: {
    id: {},
    type: null,
    confirmBtnStr: {
      default: "确认",
    }
  },
  methods: {
    open() {
      this.$("#" + this.id).modal("show");
    },
    confirm() {
      this.$emit('confirm', this.data);
      this.$("#" + this.id).modal("hide");
    },
    close() {
      this.$emit('close', this.data);
      this.$("#" + this.id).modal("hide");
    },
  }
}
</script>
