import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        userInfo: null,
        isLogin: false
    },
    mutations:{
        // 保存当前用户的信息
        saveUserInfo(state,data){
            console.log("存入缓存")
            state.userInfo = {
                id: data.id,
                token: data.token,
                name: data.name,
                account: data.account,
                role: data.roleId
            }
            state.isLogin = true
            localStorage.setItem('BaUserInfo',JSON.stringify(state.userInfo))
            localStorage.setItem('BaIsLogin',JSON.stringify(state.isLogin))
        },
        removeUserInfo(state){
            console.log("清空缓存")
            state.isLogin = false          
            state.userInfo = null;
            localStorage.setItem('BaIsLogin',false)
            localStorage.setItem('BaUserInfo',null)
            // localStorage.removeItem('BaIsLogin')
            // localStorage.removeItem('BaUserInfo')
        }
    },
    getters: {
        userInfo(state) {
            return state.userInfo == null ?  JSON.parse(localStorage.getItem('BaUserInfo')) : state.userInfo
        },
        isLogin(state) {
            return !state.isLogin?  JSON.parse(localStorage.getItem('BaIsLogin')) : state.isLogin
        }
    }
  })