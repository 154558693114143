<template>
  <div id="pushEditContainer">
    <notice-modal :id="'pushEditConfirmModal'" @confirm="create()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认创建推送任务吗？</template>
    </notice-modal>         
    <form
      class="form-horizontal"
      role="form"
      id="pushEditForm"
      style="width: 100%"
    >
      <div class="form-group">
        <label class="col-sm-1">标题</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            placeholder="标题"
            name="title"
            v-model="pageParams.title"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1">推送内容</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            placeholder="推送内容"
            name="content"
            v-model="pageParams.content"
          />
        </div>
        <!-- <div>支持替换词：用户姓名：{$name}，用户账号：{$account}</div> -->
      </div>
      <div class="form-group">
        <label class="col-sm-1">推送时间</label>
        <div class="col-sm-4">
          <input
            type="datetime-local"
            name="pushTime"
            v-model="pageParams.pushTime"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1">推送类型</label>
        <div class="col-sm-4">
          <select
            class="form-control"
            placeholder="推送类型"
            v-model="pageParams.type"
          >
            <option value="1">站内推送</option>
            <!-- <option value="2">短信推送</option>
            <option value="3">邮箱</option> -->
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1">推送目标</label>
        <div class="col-sm-4">
          <select
            class="form-control"
            placeholder="推送目标"
            v-model="pageParams.targetType"
          >
            <option value="1">全站目标</option>
            <option value="2">特定活动</option>
            <option value="3">指定用户</option>
            <option value="4">特定比赛参赛用户</option>
            <option value="5">特定比赛申请用户</option>
          </select>
        </div>
        <div class="col-sm-4" v-if="pageParams.targetType!=1">
          <input
            type="text"
            class="form-control"
            maxlength="200"
            :placeholder="pageParams.targetType==2?'活动id，英文逗号分隔的id':pageParams.targetType==3?'用户id，英文逗号分隔的id':pageParams.targetType==4?'输入比赛编号':pageParams.targetType==5?'输入比赛编号':'不需要填写'"
            v-model="pageParams.targetParams"
          />
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 15px;
        "
      >
        <button type="button" class="btn btn-primary" @click="showModal">
          创建
        </button>
        <button class="btn btn-default" @click="backToPage()">取消</button>
      </div>
    </form>
  </div>
</template>
<style scoped>
#pushEditContainer {
  padding: 30px;
}
</style>
<script>
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "activityEditPage",
  components: {
    noticeModal,
  },
  data() {
    return {
      pageParams: {
        title: "",
        content: "",
        pushTime: "",
        type: 1,
        targetType: 1,
        targetParams: "",
      },
      prePageName: "",
    };
  },
  mounted() {
    var _this = this;
    this.prePageName = "pushAdminPage";
  },
  methods: {
    create() {
      if (
        this.pageParams.title == "" ||
        this.pageParams.title == null ||
        this.pageParams.content == "" ||
        this.pageParams.content == null ||
        this.pageParams.pushTime == "" ||
        this.pageParams.pushTime == null
      ) {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      var _this = this;
      var body = this.pageParams;
      this.$service.post("/push/create", body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$router.push({ name: _this.prePageName });
      });
    },
    backToPage() {
      this.$router.push({ name: this.prePageName });
    },
    showModal() {
      this.$("#pushEditConfirmModal").modal("show");
    },
  },
};
</script>