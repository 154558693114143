<template>
  <div class="login-page-container">
    <div class="login-page-top"></div>
    <div class="login-page-main">
      <div>
        <p>
          <img
            class="img-rounded"
            alt="广东省城市建筑学会"
            src="../../../public/static/logo.png"
            style="padding-right: 30px; height: 90px"
          />
        </p>
        <div style="color: grey; width: 100%; text-align: center">
          智慧重构城市 科技赋能建筑 畅享美好生活
        </div>
      </div>
      <div class="login-plane">
        <div class="login-plane-title">
          <div>用户登录</div>
          <div
            style="
              text-align: right;
              cursor: pointer;
              font-size: 14px;
              font-weight: unset;
              letter-spacing: 0px;
            "
            @click="goToHome"
          >
            先去浏览>>
          </div>
        </div>
        <div class="login-type">
          <div
            class="login-type-item active"
            id="loginTypeItem0"
            style="cursor:pointer"
            @click="loginWay = 0"
          >
            <span class="glyphicon glyphicon-user" aria-hidden="true"></span
            >账号登录
          </div>
          <div
            class="login-type-item"
            id="loginTypeItem1"
            style="cursor:pointer"
            @click="loginWay = 1"
          >
            <span class="glyphicon glyphicon-phone" aria-hidden="true"></span
            >短信登录
          </div>
        </div>
        <div class="login-plane-body">
          <div v-show="this.loginWay <= 0">
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon glyphicon glyphicon-user"></span>
                <input
                  type="text"
                  class="form-control"
                  name="account"
                  placeholder="请输入账号"
                  v-model="accountParams.account"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon glyphicon glyphicon-lock"></span>
                <input
                  type="password"
                  class="form-control"
                  placeholder="请输入密码"
                  name="password"
                  v-model="accountParams.password"
                />
              </div>
            </div>
          </div>
          <div v-show="this.loginWay > 0">
            <div class="form-group">
              <div class="input-group">
                <span
                  class="input-group-addon glyphicon glyphicon-phone"
                ></span>
                <input
                  type="num"
                  class="form-control"
                  placeholder="请输入电话号码"
                  name="phone"
                  v-model="phoneParams.phoneNum"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span
                  class="input-group-addon glyphicon glyphicon-envelope"
                ></span>
                <input
                  type="num"
                  class="form-control"
                  placeholder="短信验证码"
                  name="smsCode"
                  v-model="phoneParams.smsCode"
                />
                <span class="input-group-btn">
                  <button
                    class="btn btn-primary"
                    :disabled="lastTime > 0"
                    type="button"
                    @click="sendCode()"
                  >
                    {{ lastTime > 0 ? lastTime + "秒后再试" : "验证码" }}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="login-plane-link" @click="changePlane()">
                    未有账号，先去注册>>>
                </div> -->
        <div
          class="login-plane-link"
          @click="$router.push({ name: 'applyMemberPage' })"
        >
          未有账号，先去注册>>>
        </div>
        <div class="login-plane-bottom" @click="login">登录</div>
      </div>
    </div>
    <div class="login-page-bottom">
      <p>
        CopyRight © 2013-2022 GUAS,All Rights Reserved 版权所有 广东省城市建筑学会 粤ICP备17024150-1号
      </p>
      <!-- <p>技术支持：北京中科辅龙信息技术有限公司</p> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "loginPage",
  data() {
    return {
      certCode: [],
      accountParams: {
        account: "",
        password: "",
      },
      phoneParams: {
        phoneNum: "",
        assertCode: "",
        smsCode: "",
      },
      loginWay: 0,
      nextPage: "registerPage",
      lastTime: 0,
      timer: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    loginWay: function (newval, oldval) {
      // console.log(newval,oldval)
      this.$(".login-type-item").removeClass("active");
      this.$("#loginTypeItem" + newval).addClass("active");
      this.accountParams = {
        account: "",
        password: "",
      };
      this.phoneParams = {
        phoneNum: "",
        assertCode: "",
        smsCode: "",
      };
    },
  },
  methods: {
    init() {
      this.loginWay = 0;
      this.$(".login-page-main").css(
        "height",
        document.body.clientHeight -
          this.$(".login-page-top")[0].offsetHeight -
          this.$(".login-page-bottom")[0].offsetHeight +
          "px"
      );
      var _this = this;
      window.addEventListener("resize", function () {
        _this
          .$(".login-page-main")
          .css(
            "height",
            document.body.clientHeight -
              _this.$(".login-page-top")[0].offsetHeight -
              _this.$(".login-page-bottom")[0].offsetHeight +
              "px"
          );
      });
    },
    login() {
      var json = null;
      if (this.loginWay == 1) {
        if (this.phoneParams.phoneNum == "" || this.phoneParams.smsCode == "") {
          this.$bf.warningAlert("电话号码和短信验证码不能为空");
          return;
        }
        json = JSON.parse(JSON.stringify(this.phoneParams));
      } else {
        if (
          this.accountParams.account == "" ||
          this.accountParams.password == ""
        ) {
          this.$bf.warningAlert("账号和密码不能为空");
          return;
        }
        if (!this.$bf.isAccount(this.accountParams.account)) {
          this.$bf.warningAlert("账号格式异常");
          return;
        }
        json = JSON.parse(JSON.stringify(this.accountParams));
        json.password = this.$md5(json.password);
      }
      var _this = this;
      this.$service.post("/account/login", json).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$store.commit("saveUserInfo", e.data.data);
        _this.$router.push({ name: "capitalPage" });
      });
    },
    goToHome() {
      this.$router.push({ name: "capitalPage" });
    },
    sendCode() {
      if (
        this.phoneParams.phoneNum == "" ||
        this.phoneParams.phoneNum == null
      ) {
        this.$bf.warningAlert("请先输入登录号码");
        return;
      }
      if (!this.$bf.isMobilePhone(this.phoneParams.phoneNum)) {
        this.$bf.warningAlert("非法格式登录号码");
        return;
      }
      var _this = this;
      this.$service
        .get("/account/sendSms?phoneNum=" + this.phoneParams.phoneNum)
        .then(function (e) {
          _this.lastTime = e.data.data.lastTime;
          _this.startInterval();
        });
    },
    startInterval() {
      var _this = this;
      this.timer = setInterval(() => {
        if (_this.lastTime <= 0) {
          _this.stopInterval();
        }
        _this.lastTime -= 1;
      }, 1000);
    },
    stopInterval() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style scoped>
.login-page-container {
  height: 100%;
  width: 100%;
}
.login-plane {
  background: white;
  height: auto;
  min-width: 300px;
  /* padding: 0px 20px; */
  /* border: 1px solid dodgerblue; */
  box-shadow: 0 0 15px rgb(1, 41, 82);
}
.login-plane-title {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 20px;
  letter-spacing: 10px;
  font-weight: bold;
  width: 100%;
  background: #336699;
  color: white;
  /* text-align:center; */
}
.login-plane-body {
  margin: 20px;
}
.login-plane-link {
  /* margin:10px; */
  padding: 5px 0px;
  cursor: pointer;
  text-align: center;
  color: whitesmoke;
  background: goldenrod;
}
.login-plane-bottom {
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
  cursor: pointer;
  color: whitesmoke;
  background: #336699;
  width: 100%;
  text-align: center;
  letter-spacing: 18px;
}
.login-type {
  display: flex;
  flex-direction: row;
}
.login-type-item {
  flex: 1;
  text-align: center;
  padding: 10px 0px;
  background: gray;
  color: whitesmoke;
  font-size: 14px;
}
.login-type-item.active {
  background: white;
  color: black;
}
.login-page-top {
  /* background:url("../../../public/static/headerbg.jpg"); */
  background-color: #336699;
  width: 100%;
  height: 100px;
}
.login-page-main {
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: url("../../../public/static/certBg.png");
  justify-content: space-around;
}
.login-page-bottom {
  /* position: fixed;
    bottom:0px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  color: whitesmoke;
  background-color: #336699;
  height: 160px;
}
.input-group {
  /* padding: 5px 20px; */
  width: 100%;
}
</style>
