<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
          <h4 class="modal-title" style="text-align: center; font-weight: bold">
            {{ title }}
          </h4>
        </div>
        <div class="modal-body" style="text-align: center">
          <form role="form" class="form-horizontal" style="width: 100%">
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >会员类型</label
              >
              <div class="col-sm-6">
                <select
                  type="text"
                  class="form-control item-input"
                  v-model="data.type"
                >
                  <option value="1">个人会员</option>
                  <option value="2">企业会员</option>
                  <option value="3">理事单位会员</option>
                  <option value="4">常务理事单位会员</option>
                  <option value="5">副会长单位会员</option>
                  <option value="6">常务副会长单位会员</option>
                  <option value="7">会长单位会员</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >会员类型</label
              >
              <div class="col-sm-6">
                <select
                  type="text"
                  class="form-control item-input"
                  v-model="data.year"
                >
                  <option value="1">一年制</option>
                  <option value="3">三年制</option>
                  <option value="5">五年制</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer" style="text-align: center">
          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            @click="closeMethod"
          >
            关闭
          </button>
          <button type="button" class="btn btn-primary" @click="submitMethod">
            选择
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import QRcode from "qrcodejs2";
export default {
  name: "applyMemberModal",
  mounted() {
    // this.createOrder();
  },
  data() {
    return {
      title: "选择会员",
    };
  },
  props: {
    id: {},
    data: {
      default: function () {
        return {
          type: "1",
          year: "1",
        };
      },
    },
  },
  methods: {
    closeMethod() {
      this.$(this).modal("hide");
    },
    submitMethod() {
      this.$emit("confirm", this.userInfo);
    },
  },
};
</script>
<style scoped>
.qrcode {
  display: inline-block;
}
</style>
