<template>
  <div class="mobileContainer">
    <apply-activity-modal
      :id="'enrollActModal'"
      :data="modalData"
      :deviceWay="'MOBILEPAY'"
      @pay="enroll()"
    ></apply-activity-modal>
    <div class="title">{{ data.title }}</div>
    <div class="create-time">{{ data.createTime }}</div>
    <div class="content" id="contentDetail"></div>
    <div class="btn-bg">
      <div
        v-if="!data.canEnroll"
        style="color: cornflowerblue; cursor: pointer"
      >
        当前并非报名时间
      </div>
      <div
        v-else-if="data.canEnroll && data.enroll"
        style="color: cornflowerblue; cursor: pointer"
      >
        您已经报名，前往查看报名记录
      </div>
      <div
        v-else-if="data.enroll"
        style="color: cornflowerblue; cursor: pointer"
      >
        您已经报过名了，前往查看报名记录
      </div>
      <div v-else-if="data.isMember && !data.hasMember">
        本次活动仅支持会员参加，请先申请会员
      </div>
      <button
        v-else-if="data.canEnroll && !data.enroll"
        type="button"
        class="btn btn-primary btn-lg"
        style="width: 100%; margin: 10px 0px; padding: 10px 0px"
        @click="showEnrollModal"
      >
        报名{{ data.fee > 0 ? data.fee / 100+"元" : "" }}
      </button>
    </div>
  </div>
</template>
<script>
import applyActivityModal from "../base/applyActivityModal.vue";
export default {
  name: "activityEnrollMobilePage",
  components: {
    applyActivityModal
  },
  data() {
    return {
      id: 0,
      data: {},
      modalData: {
        id:0
      }
    };
  },
  mounted() {
    this.actNo = this.$route.params.actNo;
    // console.log(this.$store.getters.isLogin)
    // 滑动到文章底部出现了按钮覆盖文章文本的情况，增加间距防止遮挡
    // console.log(this.$(".btn-bg").height())
    this.$("#contentDetail").css("padding-bottom","80px");
    if (!this.$store.getters.isLogin) {
      this.$router.push({
        name: "loginMobilePage",
        params: { actNo: this.actNo },
      });
    }
    this.getActDetail();
  },
  methods: {
    getActDetail() {
      var _this = this;
      this.$("#contentDetail").empty();
      this.$service
        .get("/activity/detail?actNo=" + this.actNo)
        .then(function (e) {
          _this.data = e.data.data;
          _this.$("#contentDetail").append(_this.data.content);
          // 这里需要单独处理下图片尺寸，因为手机显示很大尺寸导致显示异常，所以动态计算图片尺寸比例，再缩放
          var imgs = _this.$('#contentDetail').find("img");
          for(var i=0;i<imgs.length;i++) {
            var width = _this.$(imgs[i]).width();
            var height = _this.$(imgs[i]).height();
            var rate = width/height;
            _this.$(imgs[i]).css('max-width','100%');
            width = _this.$(imgs[i]).width();
            height = width/rate;
            _this.$(imgs[i]).css('max-height',height + 'px');
          }
        });
    },
    enroll() {
      var _this = this;
      var url = "/activity/enroll";
      var body = {
        actNo: this.data.actNo
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert("报名成功");
        _this.getActDetail();
        // _this.$("#enrollActModal").modal("hide");
        this.$route.go(0);
      });
    },
    showEnrollModal() {
      this.modalData.id = this.data.id;
      // console.log(this.modalData)
      this.$("#enrollActModal").modal("show");
    },
  },
};
</script>
<style lang="postcss" scoped>
.mobileContainer {
  /* width:100%;
    height:100%; */
  /* background: black; */
  display: flex;
  flex-direction: column;
  /* padding: 20px 15px; */
}
.title {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
}
.create-time {
  color: gray;
  width: 100%;
  text-align: center;
}
.content {
  padding: 0px 10px;
  width:100%;
}
.btn-bg {
  width: 100%;
  position: fixed;
  text-align: center;
  bottom: 0px;
  padding: 10px 10px;
}
</style>
