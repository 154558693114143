<template>
  <editor
    name="content"
    id="uuid"
    v-model="content"
    api-key="no-api-key"
    output-format="html"
    :init="init"
  />
  <!-- <div></div> -->
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "tinymceEditModel",
  components: {
    editor: Editor,
  },
  data() {
    return {
      content: "",
      init: {
        plugins:
          "preview autolink directionality visualchars fullscreen image media link template code table pagebreak nonbreaking anchor insertdatetime advlist lists  imagetools",
        height: 550,
        menubar: false,
        statusbar: false,
        language: "zh-Hans",
        images_upload_url: this.$bf.EDITOR_UPLOAD_URL,
        images_upload_base_path: this.$bf.STATIC_IMG_URL,
        resize: false,
        branding: false,
        toolbar:
          "undo redo | fontsizeselect fontselect forecolor fontsize bold italic underline strikethrough lineheight | alignleft aligncenter alignright outdent indent blocks| bullist numlist | link table image media| preview ",
        //原生鼠标右键菜单开关 true 编辑区域鼠标无法右键
        contextmenu_never_use_native: false,
        //编辑器鼠标右键菜单
        contextmenu: false,
        
      },
    };
  },
  methods:  {
      getContent () {
          return this.content
      },
      setContent(content){
          this.content = content
      }
  }
};
</script>
