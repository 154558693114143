//引入依赖及组件
import Vue from 'vue'
import VueRouter from 'vue-router'
import mainPage from '../components/mainPage.vue'
import personalPage from '../components/personal/personalPage.vue'
import personalMsgSubPage from '../components/personal/personalMsgSubPage.vue'
import personalCertSubPage from '../components/personal/personalCertSubPage.vue'
import personalMatchSubPage from '../components/personal/personalMatchSubPage.vue'
import capitalPage from '../components/capitalPage.vue'
import certificateSearchPage from '../components/certificate/certificateSearchPage.vue'
import loginMobilePage from '../components/mobile/loginMobilePage.vue'
import registerMobilePage from '../components/mobile/registerMobilePage.vue'
import activityMobilePage from '../components/mobile/activityMobilePage.vue'
import groupReceiptAdminPage from '../components/receipt/groupReceiptAdminPage.vue'
import activityEnrollMobilePage from '../components/mobile/activityEnrollMobilePage.vue'
import certificateAdminPage from '../components/certificate/certificateAdminPage.vue'
import certificateImportPage from '../components/certificate/certificateImportPage.vue'
import informationListPage from '../components/information/informationListPage.vue'
import fileAdminPage from '../components/file/fileAdminPage.vue'
import memberAdminPage from '../components/member/memberAdminPage.vue'
import applyMemberPage from '../components/member/applyMemberPage.vue'
import batchApplyMemberPage from '../components/member/batchApplyMemberPage.vue'
import adminMainPage from '../components/adminMainPage.vue'
import bannerEditPage from '../components/advert/bannerEditPage.vue'
import informationAdminPage from '../components/information/informationAdminPage.vue'
import loginPage from '../components/login/loginPage.vue'
import informationEditPage from '../components/information/informationEditPage.vue'
import informationDetailPage from '../components/information/informationDetailPage.vue'
import userAdminPage from '../components/user/userAdminPage.vue'
import bannerAdminPage from '../components/advert/bannerAdminPage.vue'
import userTrackAdminPage from '../components/user/userTrackAdminPage.vue'
import activityAdminPage from '../components/activity/activityAdminPage.vue'
import membersListPage from '../components/members/membersListPage.vue'
import memberContainerPage from '../components/members/memberContainerPage.vue'
import membersDetailPage from '../components/members/membersDetailPage.vue'
import memberDataAdminPage from '../components/member/memberDataAdminPage.vue'
import activityEditPage from '../components/activity/activityEditPage.vue'
import pushAdminPage from '../components/push/pushAdminPage.vue'
import receiptAdminPage from '../components/receipt/receiptAdminPage.vue'
import pushEditPage from '../components/push/pushEditPage.vue'
import configAdminPage from '../components/config/configAdminPage.vue'
import matchPage from '../components/match/matchPage.vue'
import matchAdminPage from '../components/match/matchAdminPage.vue'
import matchDetailPage from '../components/match/matchDetailPage.vue'
import matchEnrollAdminPage from '../components/match/matchEnrollAdminPage.vue'
import matchEditPage from '../components/match/matchEditPage.vue'
import actEnrollAdminPage from '../components/activity/actEnrollAdminPage.vue'
import personalActSubPage from '../components/personal/personalActSubPage.vue'
import personalInfoSubPage from '../components/personal/personalInfoSubPage.vue'
import designerApplyPage from '../components/designer/designerApplyPage.vue'
import matchMarkScorePage from '../components/match/matchMarkScorePage.vue'
import specialistEditPage from '../components/specialist/specialistEditPage.vue'
import specialistAdminPage from '../components/specialist/specialistAdminPage.vue'
import specialistListPage from '../components/specialist/specialistListPage.vue'
import activityListPage from '../components/activity/activityListPage'
import activityDetailPage from '../components/activity/activityDetailPage'
import applyActivityPage from '../components/activity/applyActivityPage'
import payOrderPage from '../components/pay/payOrderPage.vue'
import designerAdminPage from '../components/designer/designerAdminPage'
import designersListPage from '../components/designer/designersListPage.vue'
import designerListPage from '../components/designer/designerListPage.vue'
import payResultPage from '../components/pay/payResultPage.vue'
import matchResultAdminPage from '../components/match/matchResultAdminPage.vue'
import unMemberPage from '../components/member/unMemberPage.vue'
import matchRewardAdminPage from '../components/match/matchRewardAdminPage.vue'
import matchEnrollPage from '../components/match/matchEnrollPage.vue'
import matchResultPage from '../components/match/matchResultPage.vue'
import matchRewardPage from '../components/match/matchRewardPage.vue'
import specialistDetailPage from '../components/specialist/specialistDetailPage.vue'
Vue.use(VueRouter);
//设置组件映射规则
const routes = [
  {
    path: '/login',
    component: loginPage,
    name: 'loginPage'
  },
  {
    path: '/mobile/register',
    component: registerMobilePage,
    name: 'registerMobilePage',
  },
  {
    path: '/mobile/login',
    component: loginMobilePage,
    name: 'loginMobilePage',
  },
  {
    path: '/mobile/enroll/:actNo',
    component: activityEnrollMobilePage,
    name: 'activityEnrollMobilePage',
  },
  {
    path: '/mobile/activity',
    component: activityMobilePage,
    name: 'activityMobilePage'
  },
  {
    path: '',
    component: mainPage,
    name: 'mainPage',
    redirect: '/',
    children: [
      {
        path: '/',
        component: capitalPage,
        name: 'capitalPage'
      },
      {
        path: 'match',
        component: matchPage,
        name: 'matchPage',
        children: [
          {
            path: 'enroll',
            component: matchEnrollPage,
            name: 'matchEnrollPage'
          },
          {
            path: 'result',
            component: matchResultPage,
            name: 'matchResultPage'
          },
          {
            path: 'reward',
            component: matchRewardPage,
            name: 'matchRewardPage'
          }
        ]
      },
      {
        path: 'certificate',
        component: certificateSearchPage,
        name: 'certificateSearchPage'
      },
      {
        path: 'activity',
        component: activityListPage,
        name: 'activityListPage'
      },
      {
        path: 'designerNews',
        component: informationListPage,
        name: 'designerNewsListPage'
      },
      {
        path: 'judgement',
        component: matchMarkScorePage,
        name: 'matchMarkScorePage',
      },
      {
        path: 'designerApply',
        component: designerApplyPage,
        name: 'designerApplyPage'
      },
      {
        path: 'user',
        component: personalPage,
        name: 'personalPage',
        children: [
          {
            path: 'msg',
            component: personalMsgSubPage,
            name: 'personalMsgSubPage'
          },
          {
            path: 'cert',
            component: personalCertSubPage,
            name: 'personalCertSubPage'
          },
          {
            path: 'act',
            component: personalActSubPage,
            name: 'personalActSubPage'
          },
          {
            path: 'match',
            component: personalMatchSubPage,
            name: 'personalMatchSubPage'
          },
          {
            path: 'self',
            component: personalInfoSubPage,
            name: 'personalInfoSubPage'
          }
        ]
      },
      {
        path: 'information',
        component: informationListPage,
        name: 'informationListPage'
      },
      {
        path: 'industry',
        component: informationListPage,
        name: 'industryListPage'
      },
      {
        path: 'notify',
        component: informationListPage,
        name: 'notifyListPage'
      },
      // {
      //   path: 'members',
      //   component: membersListPage,
      //   name: 'membersListPage'
      // },
      {
        path: 'party',
        component: informationListPage,
        name: 'partyListPage'
      },
      {
        path: 'scienceListPage',
        component: informationListPage,
        name: 'scienceListPage'
      },
      {
        path: 'media',
        component: informationListPage,
        name: 'mediaListPage'
      },
      {
        path: 'policy',
        component: informationListPage,
        name: 'policyListPage'
      },

      {
        path: 'scholarship',
        component: informationListPage,
        name: 'scholarshipListPage'
      }, {
        path: 'scholarshipInfo',
        component: informationListPage,
        name: 'scholarshipInfoListPage'
      }, {
        path: 'subjectResearch',
        component: informationListPage,
        name: 'subjectResearchListPage'
      }, {
        path: 'train',
        component: informationListPage,
        name: 'trainListPage'
      }, {
        path: 'teamStandard',
        component: informationListPage,
        name: 'teamStandardListPage'
      },
      {
        path: 'designers',
        component: designersListPage,
        name: 'designersListPage'
      },
      {
        path: 'designer',
        component: designerListPage,
        name: 'designerListPage'
      },
      {
        path: 'trainStatus',
        component: informationListPage,
        name: 'trainStatusListPage'
      }, {
        path: 'newTopic',
        component: informationListPage,
        name: 'newTopicListPage'
      },
      {
        path: 'subjectLecture',
        component: informationListPage,
        name: 'subjectLectureListPage'
      },
      {
        path: 'specialist',
        component: specialistListPage,
        name: 'specialistListPage'
      },
      {
        path: 'council',
        component: informationListPage,
        name: 'councilListPage'
      },
      {
        path: 'branch',
        component: informationListPage,
        name: 'branchListPage'
      },
      {
        path: 'associationNews',
        component: informationListPage,
        name: 'associationNewsListPage'
      },
      {
        path: 'publication',
        component: informationListPage,
        name: 'publicationListPage'
      },
      {
        path: 'painter',
        component: informationListPage,
        name: 'painterListPage'
      },
      {
        path: 'commission',
        component: informationListPage,
        name: 'commissionListPage'
      },
      {
        path: 'trainCourse',
        component: informationListPage,
        name: 'trainCourseListPage'
      },
      {
        path: 'study',
        component: informationListPage,
        name: 'studyListPage'
      },
      {
        path: 'forum',
        component: informationListPage,
        name: 'forumListPage'
      },
      {
        path: 'member',
        component: applyMemberPage,
        name: 'applyMemberPage'
      },
      {
        path: 'member/batch',
        component: batchApplyMemberPage,
        name: 'batchApplyMemberPage'
      },
      {
        path: 'order/pay',
        component: payOrderPage,
        name: 'payOrderPage'
      },
      {
        path: 'order/result',
        component: payResultPage,
        name: 'payResultPage'
      },
      {
        path: 'unMember',
        component: unMemberPage,
        name: 'unMemberPage'
      },
      {
        path: 'info/:id',
        component: informationDetailPage,
        name: 'informationDetailPage'
      },
      {
        path: 'designerNews/:id',
        component: informationDetailPage,
        name: 'designerNewsDetailPage'
      },
      {
        path: 'industry/:id',
        component: informationDetailPage,
        name: 'industryDetailPage'
      },
      {
        path: 'activity/apply/:id',
        component: applyActivityPage,
        name: 'applyActivityPage'
      },
      {
        path: 'science/:id',
        component: informationDetailPage,
        name: 'scienceDetailPage'
      },
      {
        path: 'activity/:actNo',
        component: activityDetailPage,
        name: 'activityDetailPage'
      },
      {
        path: 'notify/:id',
        component: informationDetailPage,
        name: 'notifyDetailPage'
      },
      {
        path: 'painter/:id',
        component: informationDetailPage,
        name: 'painterDetailPage'
      },
      {
        path: 'party/:id',
        component: informationDetailPage,
        name: 'partyDetailPage'
      },
      {
        path: 'media/:id',
        component: informationDetailPage,
        name: 'mediaDetailPage'
      },
      {
        path: 'policy/:id',
        component: informationDetailPage,
        name: 'policyDetailPage'
      },
      {
        path: 'enterprise/:id',
        component: informationDetailPage,
        name: 'enterpriseDetailPage'
      },
      {
        path: 'scholarship/:id',
        component: informationDetailPage,
        name: 'scholarshipDetailPage'
      }, {
        path: 'scholarshipInfo/:id',
        component: informationDetailPage,
        name: 'scholarshipInfoDetailPage'
      }, {
        path: 'subjectResearch/:id',
        component: informationDetailPage,
        name: 'subjectResearchDetailPage'
      }, {
        path: 'train/:id',
        component: informationDetailPage,
        name: 'trainDetailPage'
      }, {
        path: 'teamStandard/:id',
        component: informationDetailPage,
        name: 'teamStandardDetailPage'
      }, {
        path: 'trainStatus/:id',
        component: informationDetailPage,
        name: 'trainStatusDetailPage'
      }, {
        path: 'newTopic/:id',
        component: informationDetailPage,
        name: 'newTopicDetailPage'
      },
      {
        path: 'subjectLecture/:id',
        component: informationDetailPage,
        name: 'subjectLectureDetailPage'
      },
      {
        path: 'specialist/:id',
        component: specialistDetailPage,
        name: 'specialistDetailPage'
      },
      {
        path: 'trainCourse/:id',
        component: informationDetailPage,
        name: 'trainCourseDetailPage'
      },
      {
        path: 'study/:id',
        component: informationDetailPage,
        name: 'studyDetailPage'
      },
      {
        path: 'forum/:id',
        component: informationDetailPage,
        name: 'forumDetailPage'
      },
      {
        path: 'associationInfo/:id',
        component: informationDetailPage,
        name: 'associationInfoDetailPage'
      },
      {
        path: 'regulate/:id',
        component: informationDetailPage,
        name: 'regulateDetailPage'
      },
      {
        path: 'secretariat/:id',
        component: informationDetailPage,
        name: 'secretariatDetailPage'
      },
      {
        path: 'council/:id',
        component: informationDetailPage,
        name: 'councilDetailPage'
      },
      {
        path: 'case/:id',
        component: informationDetailPage,
        name: 'caseDetailPage'
      },
      {
        path: 'associationNews/:id',
        component: informationDetailPage,
        name: 'associationNewsDetailPage'
      },
      {
        path: 'branch/:id',
        component: informationDetailPage,
        name: 'branchDetailPage'
      },
      {
        path: 'commission/:id',
        component: informationDetailPage,
        name: 'commissionDetailPage'
      },
      {
        path: 'publication/:id',
        component: informationDetailPage,
        name: 'publicationDetailPage'
      },
      {
        path: 'membersShow/:id',
        component: informationDetailPage,
        name: 'membersShowDetailPage'
      },
      {
        path: 'memberCompany/:id',
        component: informationDetailPage,
        name: 'memberCompanyDetailPage'
      },
      {
        path: 'memberNotice/:id',
        component: informationDetailPage,
        name: 'memberNoticeDetailPage'
      },
      {
        path: 'members',
        component: memberContainerPage,
        name: 'membersListPage',
        children: [
          {
            path: 'announce',
            component: membersListPage,
            name: 'memberAnnounceListPage'
          },
          {
            path: 'notice',
            component: membersListPage,
            name: 'memberNoticeListPage'
          },
          {
            path: 'rule',
            component: membersListPage,
            name: 'memberRuleListPage'
          },
          {
            path: 'enterprise',
            component: membersListPage,
            name: 'enterpriseListPage'
          },
          {
            path: 'fee',
            component: membersListPage,
            name: 'memberFeeRuleListPage'
          },
          {
            path: 'show',
            component: membersListPage,
            name: 'membersShowListPage'
          },
          {
            path: 'memberRule/:id',
            component: membersDetailPage,
            name: 'memberRuleDetailPage'
          },
          {
            path: 'memberAnnounce/:id',
            component: membersDetailPage,
            name: 'memberAnnounceDetailPage'
          },

          {
            path: 'memberRights/:id',
            component: membersDetailPage,
            name: 'memberRightsDetailPage'
          },
          {
            path: 'memberFeeRule/:id',
            component: membersDetailPage,
            name: 'memberFeeRuleDetailPage'
          },
        ]
      },
      {
        path: 'members/:id',
        component: informationDetailPage,
        name: 'membersDetailPage'
      },

      // {
      //   path: 'activity/detail/:id',
      //   component: informationDetailPage,
      //   name: 'actDetailPage'
      // },
      {
        path: 'match/:matchNo',
        component: matchDetailPage,
        name: 'matchDetailPage'
      },
    ]
  },
  {
    path: '/admin',
    component: adminMainPage,
    name: 'adminMainPage',
    children: [
      {
        path: 'banner',
        component: bannerAdminPage,
        name: 'bannerAdminPage'
      },
      {
        path: 'receipt',
        component: receiptAdminPage,
        name: 'receiptAdminPage'
      },
      {
        path: 'banner/edit/:id',
        component: bannerEditPage,
        name: 'bannerEditPage'
      },
      {
        path: 'banner/create',
        component: bannerEditPage,
        name: 'bannerCreatePage'
      },
      {
        path: 'information',
        component: informationAdminPage,
        name: 'informationAdminPage',
      },
      {
        path: 'groupReceipt',
        component: groupReceiptAdminPage,
        name: 'groupReceiptAdminPage',
      },
      {
        path: 'information/edit/:id',
        component: informationEditPage,
        name: 'informationEditPage',
      },
      {
        path: 'information/create',
        component: informationEditPage,
        name: 'informationCreatePage',
      },
      {
        path: 'industry',
        component: informationAdminPage,
        name: 'industryAdminPage'
      },
      {
        path: 'file',
        component: fileAdminPage,
        name: 'fileAdminPage'
      },
      {
        path: 'designerNews',
        component: informationAdminPage,
        name: 'designerNewsAdminPage'
      },
      {
        path: 'designerNews/edit/:id',
        component: informationEditPage,
        name: 'designerNewsEditPage',
      },
      {
        path: 'designerNews/create',
        component: informationEditPage,
        name: 'designerNewsCreatePage',
      },
      {
        path: 'member',
        component: memberAdminPage,
        name: 'memberAdminPage'
      },
      {
        path: 'industry/edit/:id',
        component: informationEditPage,
        name: 'industryEditPage',
      },
      {
        path: 'industry/create',
        component: informationEditPage,
        name: 'industryCreatePage',
      },
      {
        path: 'notify',
        component: informationAdminPage,
        name: 'notifyAdminPage'
      },
      // {
      //   path: 'notify/detail/:id',
      //   component: informationDetailPage,
      //   name: 'notifyDetailPage',
      // },
      {
        path: 'notify/edit/:id',
        component: informationEditPage,
        name: 'notifyEditPage',
      },
      {
        path: 'notify/create',
        component: informationEditPage,
        name: 'notifyCreatePage',
      },
      {
        path: 'media',
        component: informationAdminPage,
        name: 'mediaAdminPage'
      },
      // {
      //   path: 'media/detail/:id',
      //   component: informationDetailPage,
      //   name: 'mediaDetailPage',
      // },
      {
        path: 'media/edit/:id',
        component: informationEditPage,
        name: 'mediaEditPage',
      },
      {
        path: 'media/create',
        component: informationEditPage,
        name: 'mediaCreatePage',
      },
      {
        path: 'council',
        component: informationAdminPage,
        name: 'councilAdminPage'
      },
      {
        path: 'designer',
        component: designerAdminPage,
        name: 'designerAdminPage'
      },
      {
        path: 'painter',
        component: informationAdminPage,
        name: 'painterAdminPage'
      },
      {
        path: 'painter/create',
        component: informationEditPage,
        name: 'painterCreatePage',
      },
      {
        path: 'painter/edit/:id',
        component: informationEditPage,
        name: 'painterEditPage',
      },
      {
        path: 'memberRule/edit/:id',
        component: informationEditPage,
        name: 'memberRuleEditPage',
      },
      {
        path: 'memberAnnounce/edit/:id',
        component: informationEditPage,
        name: 'memberAnnounceEditPage',
      },
      {
        path: 'memberFeeRule/edit/:id',
        component: informationEditPage,
        name: 'memberFeeRuleEditPage',
      }, {
        path: 'memberRights/edit/:id',
        component: informationEditPage,
        name: 'memberRightsEditPage',
      },
      {
        path: 'associationNews',
        component: informationAdminPage,
        name: 'associationNewsAdminPage'
      },
      {
        path: 'commission',
        component: informationAdminPage,
        name: 'commissionAdminPage'
      },
      {
        path: 'council/edit/:id',
        component: informationEditPage,
        name: 'councilEditPage',
      },
      {
        path: 'council/create',
        component: informationEditPage,
        name: 'councilCreatePage',
      },
      {
        path: 'associationNews/create',
        component: informationEditPage,
        name: 'associationNewsCreatePage',
      }, {
        path: 'branch/create',
        component: informationEditPage,
        name: 'branchCreatePage',
      }, {
        path: 'commission/create',
        component: informationEditPage,
        name: 'commissionCreatePage',
      },
      {
        path: 'publication/create',
        component: informationEditPage,
        name: 'publicationCreatePage',
      },
      {
        path: 'policy',
        component: informationAdminPage,
        name: 'policyAdminPage'
      },
      // {
      //   path: 'policy/detail/:id',
      //   component: informationDetailPage,
      //   name: 'policyDetailPage',
      // },
      {
        path: 'policy/edit/:id',
        component: informationEditPage,
        name: 'policyEditPage',
      },
      {
        path: 'policy/create',
        component: informationEditPage,
        name: 'policyCreatePage',
      },
      {
        path: 'enterprise',
        component: informationAdminPage,
        name: 'enterpriseAdminPage'
      },
      {
        path: 'case',
        component: informationAdminPage,
        name: 'caseAdminPage'
      },
      {
        path: 'case/edit/:id',
        component: informationEditPage,
        name: 'caseEditPage',
      },
      {
        path: 'case/create',
        component: informationEditPage,
        name: 'caseCreatePage',
      },
      {
        path: 'memberData',
        component: memberDataAdminPage,
        name: 'memberDataAdminPage'
      },
      // {
      //   path: 'enterprise/detail/:id',
      //   component: informationDetailPage,
      //   name: 'enterpriseDetailPage',
      // },
      {
        path: 'enterprise/edit/:id',
        component: informationEditPage,
        name: 'enterpriseEditPage',
      },
      {
        path: 'enterprise/create',
        component: informationEditPage,
        name: 'enterpriseCreatePage',
      },
      {
        path: 'party',
        component: informationAdminPage,
        name: 'partyAdminPage'
      },
      // {
      //   path: 'party/detail/:id',
      //   component: informationDetailPage,
      //   name: 'partyDetailPage',
      // },
      {
        path: 'party/edit/:id',
        component: informationEditPage,
        name: 'partyEditPage',
      },
      {
        path: 'party/create',
        component: informationEditPage,
        name: 'partyCreatePage',
      },
      {
        path: 'scholarship',
        component: informationAdminPage,
        name: 'scholarshipAdminPage'
      },
      // {
      //   path: 'scholarship/detail/:id',
      //   component: informationDetailPage,
      //   name: 'scholarshipDetailPage',
      // },
      {
        path: 'scholarship/edit/:id',
        component: informationEditPage,
        name: 'scholarshipEditPage',
      },
      {
        path: 'scholarship/create',
        component: informationEditPage,
        name: 'scholarshipCreatePage',
      },
      {
        path: 'scholarshipInfo',
        component: informationAdminPage,
        name: 'scholarshipInfoAdminPage'
      },
      // {
      //   path: 'scholarshipInfo/detail/:id',
      //   component: informationDetailPage,
      //   name: 'scholarshipInfoDetailPage',
      // },
      {
        path: 'scholarshipInfo/edit/:id',
        component: informationEditPage,
        name: 'scholarshipInfoEditPage',
      },
      {
        path: 'scholarshipInfo/create',
        component: informationEditPage,
        name: 'scholarshipInfoCreatePage',
      },
      {
        path: 'subjectResearch',
        component: informationAdminPage,
        name: 'subjectResearchAdminPage'
      },
      // {
      //   path: 'subjectResearch/detail/:id',
      //   component: informationDetailPage,
      //   name: 'subjectResearchDetailPage',
      // },
      {
        path: 'subjectResearch/edit/:id',
        component: informationEditPage,
        name: 'subjectResearchEditPage',
      },
      {
        path: 'subjectResearch/create',
        component: informationEditPage,
        name: 'subjectResearchCreatePage',
      },
      {
        path: 'specialist',
        component: specialistAdminPage,
        name: 'specialistAdminPage'
      },
      // {
      //   path: 'specialist/detail/:id',
      //   component: informationDetailPage,
      //   name: 'specialistDetailPage',
      // },
      {
        path: 'specialist/edit/:id',
        component: specialistEditPage,
        name: 'specialistEditPage',
      },
      {
        path: 'specialist/create',
        component: specialistEditPage,
        name: 'specialistCreatePage',
      },
      {
        path: 'train',
        component: informationAdminPage,
        name: 'trainAdminPage'
      },
      // {
      //   path: 'train/detail/:id',
      //   component: informationDetailPage,
      //   name: 'trainDetailPage',
      // },
      {
        path: 'train/edit/:id',
        component: informationEditPage,
        name: 'trainEditPage',
      },
      {
        path: 'train/create',
        component: informationEditPage,
        name: 'trainCreatePage',
      },
      {
        path: 'teamStandard',
        component: informationAdminPage,
        name: 'teamStandardAdminPage'
      },
      // {
      //   path: 'teamStandard/detail/:id',
      //   component: informationDetailPage,
      //   name: 'teamStandardDetailPage',
      // },
      {
        path: 'teamStandard/edit/:id',
        component: informationEditPage,
        name: 'teamStandardEditPage',
      },
      {
        path: 'teamStandard/create',
        component: informationEditPage,
        name: 'teamStandardCreatePage',
      },
      {
        path: 'science',
        component: informationAdminPage,
        name: 'scienceAdminPage'
      },
      {
        path: 'science/edit/:id',
        component: informationEditPage,
        name: 'scienceEditPage',
      },
      {
        path: 'science/create',
        component: informationEditPage,
        name: 'scienceCreatePage',
      },
      {
        path: 'trainStatus',
        component: informationAdminPage,
        name: 'trainStatusAdminPage'
      },
      // {
      //   path: 'trainStatus/detail/:id',
      //   component: informationDetailPage,
      //   name: 'trainStatusDetailPage',
      // },
      {
        path: 'trainStatus/edit/:id',
        component: informationEditPage,
        name: 'trainStatusEditPage',
      },
      {
        path: 'trainStatus/create',
        component: informationEditPage,
        name: 'trainStatusCreatePage',
      },
      {
        path: 'newTopic',
        component: informationAdminPage,
        name: 'newTopicAdminPage'
      },
      // {
      //   path: 'newTopic/detail/:id',
      //   component: informationDetailPage,
      //   name: 'newTopicDetailPage',
      // },
      {
        path: 'newTopic/edit/:id',
        component: informationEditPage,
        name: 'newTopicEditPage',
      },
      {
        path: 'newTopic/create',
        component: informationEditPage,
        name: 'newTopicCreatePage',
      },
      {
        path: 'trainCourse',
        component: informationAdminPage,
        name: 'trainCourseAdminPage'
      },
      // {
      //   path: 'trainCourse/detail/:id',
      //   component: informationDetailPage,
      //   name: 'trainCourseDetailPage',
      // },
      {
        path: 'trainCourse/edit/:id',
        component: informationEditPage,
        name: 'trainCourseEditPage',
      },
      {
        path: 'trainCourse/create',
        component: informationEditPage,
        name: 'trainCourseCreatePage',
      },
      {
        path: 'subjectLecture',
        component: informationAdminPage,
        name: 'subjectLectureAdminPage'
      },
      // {
      //   path: 'subjectLecture/detail/:id',
      //   component: informationDetailPage,
      //   name: 'subjectLectureDetailPage',
      // },
      {
        path: 'subjectLecture/edit/:id',
        component: informationEditPage,
        name: 'subjectLectureEditPage',
      },
      {
        path: 'subjectLecture/create',
        component: informationEditPage,
        name: 'subjectLectureCreatePage',
      },
      {
        path: 'study',
        component: informationAdminPage,
        name: 'studyAdminPage'
      },
      // {
      //   path: 'study/detail/:id',
      //   component: informationDetailPage,
      //   name: 'studyDetailPage',
      // },
      {
        path: 'study/edit/:id',
        component: informationEditPage,
        name: 'studyEditPage',
      },
      {
        path: 'study/create',
        component: informationEditPage,
        name: 'studyCreatePage',
      },
      {
        path: 'forum',
        component: informationAdminPage,
        name: 'forumAdminPage'
      },
      {
        path: 'branch',
        component: informationAdminPage,
        name: 'branchAdminPage'
      },
      {
        path: 'publication',
        component: informationAdminPage,
        name: 'publicationAdminPage'
      },
      // {
      //   path: 'forum/detail/:id',
      //   component: informationDetailPage,
      //   name: 'forumDetailPage',
      // },
      {
        path: 'forum/edit/:id',
        component: informationEditPage,
        name: 'forumEditPage',
      },
      {
        path: 'forum/create',
        component: informationEditPage,
        name: 'forumCreatePage',
      },
      {
        path: 'associationInfo/edit/:id',
        component: informationEditPage,
        name: 'associationInfoEditPage',
      },
      {
        path: 'regulate/edit/:id',
        component: informationEditPage,
        name: 'regulateEditPage',
      },
      {
        path: 'secretariat/edit/:id',
        component: informationEditPage,
        name: 'secretariatEditPage',
      },
      {
        path: 'associationNews/edit/:id',
        component: informationEditPage,
        name: 'associationNewsEditPage',
      },
      {
        path: 'branch/edit/:id',
        component: informationEditPage,
        name: 'branchEditPage',
      },
      {
        path: 'commission/edit/:id',
        component: informationEditPage,
        name: 'commissionEditPage',
      },
      {
        path: 'publication/edit/:id',
        component: informationEditPage,
        name: 'publicationEditPage',
      },
      {
        path: 'memberNotice/create',
        component: informationEditPage,
        name: 'memberNoticeCreatePage',
      },
      {
        path: 'memberNotice',
        component: informationAdminPage,
        name: 'memberNoticeAdminPage',
      },
      {
        path: 'memberNotice/edit/:id',
        component: informationEditPage,
        name: 'memberNoticeEditPage',
      },
      {
        path: 'memberCompany/create',
        component: informationEditPage,
        name: 'memberCompanyCreatePage',
      },
      {
        path: 'memberCompany',
        component: informationAdminPage,
        name: 'memberCompanyAdminPage',
      },
      {
        path: 'memberCompany/edit/:id',
        component: informationEditPage,
        name: 'memberCompanyEditPage',
      },
      {
        path: 'membersShow/create',
        component: informationEditPage,
        name: 'membersShowCreatePage',
      },
      {
        path: 'membersShow',
        component: informationAdminPage,
        name: 'membersShowAdminPage',
      },
      {
        path: 'membersShow/edit/:id',
        component: informationEditPage,
        name: 'membersShowEditPage',
      },
      {
        path: 'activity',
        component: activityAdminPage,
        name: 'activityAdminPage',
      },
      {
        path: 'activity/enroll/:actNo',
        component: actEnrollAdminPage,
        name: 'actEnrollAdminPage',
      },
      {
        path: 'activity/create',
        component: activityEditPage,
        name: 'activityCreatePage',
      },
      {
        path: 'activity/edit/:id',
        component: activityEditPage,
        name: 'activityEditPage',
      },
      {
        path: 'match',
        component: matchAdminPage,
        name: 'matchAdminPage',
      },
      {
        path: "match/enroll",
        component: matchEnrollAdminPage,
        name: 'matchEnrollAdminPage'
      },
      {
        path: "match/rewards",
        component: matchRewardAdminPage,
        name: 'matchRewardAdminPage'
      },
      {
        path: 'match/create',
        component: matchEditPage,
        name: 'matchCreatePage',
      },

      {
        path: 'match/result',
        component: matchResultAdminPage,
        name: 'matchResultAdminPage',
      },
      {
        path: 'cert/list',
        component: certificateAdminPage,
        name: 'certificateAdminPage'
      },
      {
        path: 'cert/import',
        component: certificateImportPage,
        name: 'certificateImportPage'
      },
      {
        path: 'user',
        component: userAdminPage,
        name: 'userAdminPage'
      },
      {
        path: 'user/track',
        component: userTrackAdminPage,
        name: 'userTrackAdminPage'
      },
      {
        path: 'config',
        component: configAdminPage,
        name: 'configAdminPage'
      },
      {
        path: 'push',
        component: pushAdminPage,
        name: 'pushAdminPage'
      },
      {
        path: 'push/create',
        component: pushEditPage,
        name: 'pushEditPage'
      }
    ]
  },
  // {
  //   path: '/mobile',
  //   redirect: '/mobile/login',
  //   children: [
  //     {
  //       path: '/login',
  //       component: loginMobilePage,
  //       name: 'loginMobilePage',
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  routes
})
//导出
export default router;
