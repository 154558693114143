<template>
    <div class="mobileContainer">
        <form class="form-horizontal" style="width:100%">
            <div style="padding-bottom: 10px; text-align: center">
                <img style="width: 90px; height: 90px" alt="广东省城市建筑学会" src="../../../public/static/assiation_logo.jpg" />
            </div>
            <div class="form-group">
                <label for="inputEmail3" class="col-sm-2 control-label">用户名</label>
                <div class="col-sm-10">
                    <input type="email" class="form-control" placeholder="请输入用户名" v-model="userInfo.account">
                </div>
            </div>
            <div class="form-group">
                <label for="inputPassword3" class="col-sm-2 control-label">密码</label>
                <div class="col-sm-10">
                    <input type="password" class="form-control" placeholder="请输入密码" v-model="userInfo.password">
                </div>
            </div>
            <div class="form-group">
                <label for="inputPassword3" class="col-sm-2 control-label">重复密码</label>
                <div class="col-sm-10">
                    <input type="password" class="form-control" placeholder="请再次输入密码" v-model="userInfo.rePassword">
                </div>
            </div>
            <div class="form-group">
                <label for="inputPassword3" class="col-sm-2 control-label">联系电话</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" placeholder="请输入联系电话" v-model="userInfo.phone">
                </div>
            </div>
            <div class="form-group">
                <label for="inputPassword3" class="col-sm-2 control-label">联系邮箱</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" placeholder="请输入联系邮箱" v-model="userInfo.mail">
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                    <button type="button" class="btn btn-warning btn-lg" style="width: 100%;" @click="register">注册</button>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                    <button type="button" class="btn btn-primary btn-lg" style="width: 100%;" @click="login">已有账号，去登录</button>
                </div>
            </div>
        </form>
        <!-- <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon glyphicon glyphicon-lock"></span>
                <input type="password" class="form-control" placeholder="请输入密码" name="password" v-model="params.password" />
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    name: "registerMobilePage",
    data() {
        return {
            userInfo: {
                type:1
            }
        }
    },
    methods: {
        login: function () {
            this.$router.push({ name: "loginMobilePage"});
        },
        register: function () {

            if (
                this.userInfo.account == "" ||
                this.userInfo.password == "" ||
                this.userInfo.phone == "" ||
                this.userInfo.rePassword == "" ||
                this.userInfo.mail == "" ||
                this.userInfo.type == "" ||
                this.userInfo.account == null ||
                this.userInfo.password == null ||
                this.userInfo.phone == null ||
                this.userInfo.rePassword == null ||
                this.userInfo.mail == null ||
                this.userInfo.type == null
            ) {
                this.$bf.warningAlert("请填写好表单中的内容，不能为空");
                return;
            }
            if (this.userInfo.password !== this.userInfo.rePassword) {
                this.$bf.warningAlert("两次密码输入不相同");
                return;
            }
            if (!this.$bf.isAccount(this.userInfo.account)) {
                this.$bf.warningAlert("账号格式异常,仅限字母数字和下划线且至少4位");
                return;
            }
            if (!this.$bf.isMobilePhone(this.userInfo.phone)) {
                this.$bf.warningAlert("手机号码格式异常");
                return;
            }
            if (!this.$bf.isMail(this.userInfo.mail)) {
                this.$bf.warningAlert("联系邮箱格式异常");
                return;
            }
            var json = {
                account: this.userInfo.account,
                password: this.userInfo.password,
                phone: this.userInfo.phone,
                mail: this.userInfo.mail,
                type: this.userInfo.type,
            };
            json.password = this.$md5(json.password);
            //    json.confirmPassword = this.$md5(json.confirmPassword)
            var _this = this;
            // console.log(json)
            this.$service.post("/account/create", json).then(function (e) {
                _this.$bf.sucAlert("注册成功");
                _this.userId = e.data.data;
            _this.$router.push({
                    name: "loginMobilePage"
                });
            });
        }
    }
}
</script>
<style lang="postcss" scoped>
.mobileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
}
</style>