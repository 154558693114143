<template>
  <div id="matchEnrollAdminContainer">
    <!-- <submit-work-modal :id="'sumbitWorkModal'"   title="提交作品" :data="modalData" @submit="submitWork"></submit-work-modal>             -->
    <remark-user-modal
      :id="'remarkUserModal'"
      title="选择评分员"
      :data="modalData"
      @submit="selectRemarker"
    ></remark-user-modal>
    <notice-modal :id="'approveConfirmModal'" @confirm="approve()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认晋级吗？</template>
    </notice-modal>  
    <notice-modal :id="'resultConfirmModal'" @confirm="confirmApprove()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认成绩吗？确认后将公示给用户查看</template>
    </notice-modal>      
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="text-align: right; padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group">
          <!-- <label style="white-space:nowrap" class="col-sm-4">比赛编号</label> -->
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="比赛编号"
              v-model="searchFilter.match"
            >
              <option
                :value="value"
                v-for="(value, index) in matchs"
                :key="index"
              >
                {{ value.matchNo }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <!-- <label style="white-space:nowrap" class="col-sm-4">阶段</label> -->
          <div class="col-sm-4">
            <select
              class="form-control"
              placeholder="阶段"
              v-model="searchFilter.order"
              @change="search(1)"
            >
              <option
                :value="value.order"
                v-for="(value, index) in orders"
                :key="index"
              >
                {{ value.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="组别"
              v-model="searchFilter.group"
              @change="search(1)"
            >
              <option value="全部组别">全部组别</option>
              <option
                :value="value"
                v-for="(value, index) in matchGroups"
                :key="index"
              >
                {{ value }}
              </option>
              <!-- <option value="workGroup">施工组</option>                          -->
            </select>
          </div>
        </div>
         <div class="form-group">
          <div class="col-sm-6">
            <select
              class="form-control"
              placeholder="等级"
              v-model="searchFilter.grade"
              @change="search(1)"
            >
              <option value=null>全部等级</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
          </div>
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名编号"
            v-model="searchFilter.enrollNo"
            @blur="search(1)"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <input
            type="text"
            class="form-control"
            placeholder="报名人名称精准查询"
            v-model="searchFilter.name"
            @blur="search(1)"
          />
        </div>
        <button
          type="button"
          class="btn btn-warning"
          style="margin-left: 10px"
          @click="showResultsComfirmModal()"
        >
          批量确认成绩
        </button>
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="output()"
        >
          导出记录
        </button>
        <!-- <button type="button" class="btn btn-primary" style="margin-left:10px" @click="search(1)">
                      <span class="glyphicon glyphicon-search"></span>&nbsp;查找</button> -->
        <!-- <button type="submit" class="btn btn-primary" style="margin-left:10px" @click="create()">
                      新建</button> -->
      </form>
    </div>
    <div>
      <table v-if="listData.records.length != 0" class="table table-bordered">
        <thead>
          <th>ID</th>
          <th>报名编号</th>
          <th>阶段</th>
          <th>评分方式</th>
          <!-- <th>参赛单位</th>
                  <th>参赛成员</th> -->
          <th>组别</th>
          <th>作品名称</th>
          <th>报名人</th>
          <th>评分人</th>
          <th>分别评分</th>
          <th>平均分</th>
          <th>等级</th>
          <th>状态</th>
          <th>操作</th>
        </thead>
        <tbody>
          <tr v-for="(value, index) in listData.records" :key="index">
            <td>{{ value.id }}</td>
            <td>{{ value.enrollNo }}</td>
            <td>{{ orders[searchFilter.order - 1].name }}</td>
            <td>
              <div>
                <select v-model="value.type" :disabled="!editWay">
                  <option value="线上">线上</option>
                  <option value="线下">线下</option>
                </select>
                <input
                  max="100"
                  :disabled="!editWay"
                  placeholder="输入线下时间地点"
                  v-model="value.location"
                />
                <div v-if="value.score == 0">
                  <button
                    v-if="!editWay"
                    type="button"
                    class="btn btn-primary"
                    @click="editWay = true"
                  >
                    更改
                  </button>
                  <button
                    v-if="editWay"
                    type="button"
                    class="btn btn-primary"
                    @click="changeType(value)"
                  >
                    确认
                  </button>
                  <button
                    v-if="editWay"
                    type="button"
                    class="btn btn-default"
                    @click="editWay = false"
                  >
                    取消
                  </button>
                </div>
              </div>
            </td>
            <!-- <td>
                        <div>{{value.company1}}</div>
                        <div v-if="value.company2">{{value.company2}}</div>
                        <div v-if="value.company3">{{value.company3}}</div>                    
                      </td>
                      <td></td> -->
            <td>{{ value.group }}</td>
            <!-- <td>{{value.group}}</td> -->
            <td v-if="value.workName">
              <div>{{ value.workName }}</div>
              <div>{{ value.enWorkName }}</div>
            </td>
            <td v-else style="color: red">未提交</td>
            <td>{{ value.applyUserName }}</td>
            <td v-if="value.submitUserName1 || value.submitUserName2">
              <div>{{ value.submitUserName1 }}</div>
              <div>{{ value.submitUserName2 }}</div>
            </td>
            <td v-else style="color: red">未分配</td>
            <td>
              <div v-if="value.submitUserName1">{{ value.score1 }}</div>
              <div v-if="value.submitUserName2">{{ value.score2 }}</div>
            </td>
            <td>
              {{ value.score }}
            </td>
            <td>
              <select v-model="value.grade" :disabled="!value.canConfirm">
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
            </td>
            <td :style="value.state == 0 ? 'color:red' : ''">
              {{ value.stateStr }}
            </td>
            <td>
              <button
                v-if="value.score == 0"
                type="button"
                class="btn btn-primary"
                @click="showModal(value.id)"
              >
                选择评分员
              </button>
              <button
                v-if="value.canApproved && value.order < orders.length"
                type="button"
                class="btn btn-primary"
                @click="showApproveModal(value)"
              >
                晋级
              </button>
              <button
                v-if="value.canConfirm"
                type="button"
                class="btn btn-primary"
                @click="showResultComfirmModal(value)"
              >
                确认成绩
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
    </div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>

<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
// import submitWorkModal from '../base/submitWorkModal.vue'
import remarkUserModal from "../base/remarkUserModal.vue";
export default {
  name: "matchResultAdminPage",
  components: {
    basePagination,
    noticeModal,
    remarkUserModal,
  },
  data() {
    return {
      title: "评选结果管理",
      editWay: false,
      editGrade: false,
      // 这里为了保存操作之后的页面信息
      currentPage:1,
      searchFilter: {
        matchNo: "",
        enrollNo: null,
        order: 0,
        name: null,
        group: null,
        match: null,
        grade: null
      },
      matchs: [],
      orders: [],
      matchGroups: [],
      listData: [],
      modalData: {
        enrollNo: "",
      },
      data: {},
    };
  },
  mounted() {
    this.getMatchList();
  },
  watch: {
    "searchFilter.match": {
      handler(newval, oldval) {
        this.order = [];
        this.orders = newval.stageList;
        this.searchFilter.order = this.orders[0].order;
        this.search(1);
      },
    },
  },
  methods: {
    getMatchList() {
      var _this = this;
      var url = "/match/list";
      var body = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$service.post(url, body).then(function (e) {
        _this.matchs = e.data.data.records;
        //   for(var i =1; i<=_this.matchs[0].matchStageNum;i++  ) {
        //     _this.orders.push(i)
        //   }
        _this.searchFilter.match = _this.matchs[0];
        _this.getMatchGroup();
      });
    },
    getMatchGroup() {
      var _this = this;
      var url = "/match/config";
      this.$service.get(url).then(function (e) {
        _this.matchGroups = e.data.data.matchGroupType;
        _this.searchFilter.group = "全部组别";
        // _this.search(1)
      });
    },
    output() {
      var _this = this;
      var body = {};
      body.matchNo = this.searchFilter.matchNo
        ? this.searchFilter.matchNo
        : null;
      body.enrollNo = this.searchFilter.enrollNo
        ? this.searchFilter.enrollNo
        : null;
      body.name = this.searchFilter.name ? this.searchFilter.name : null;
      body.group = this.searchFilter.group;
      body.order = this.searchFilter.order;
      var url = "/match/output-result";
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        var path = _this.$bf.getExcelUrl(e.data.data);
        window.open(path, "_blank");
      });
    },
    search(page) {
      var _this = this;
      var body = {};
      body.matchNo = this.searchFilter.match.matchNo;
      body.enrollNo = this.searchFilter.enrollNo;
      body.group =
        this.searchFilter.group == "全部组别" ? null : this.searchFilter.group;
      body.grade= this.searchFilter.grade == "null" ? null :this.searchFilter.grade;
      body.name = this.searchFilter.name;
      body.order = this.searchFilter.order;
      body.pageNo = page;
      this.currentPage = page;
      body.pageSize = 10;
      var url = "/match/results";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.data = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
      });
    },
    create() {
      this.$router.push({ name: "matchCreatePage" });
    },
    showModal(id) {
      this.modalData = {
        submitUserId1: "",
        submitUserId2: "",
        id: id,
      };
      this.$("#remarkUserModal").modal("show");
    },
    showApproveModal(value) {
      this.modalData = {
        submitUserId: null,
        id: value.id,
        // grade: value.grade
      };
      this.$("#approveConfirmModal").modal("show");
    },
    showResultComfirmModal(value) {
      this.modalData = {
        id: value.id,
        grade: value.grade,
        order: this.searchFilter.order,
      };
      this.$("#resultConfirmModal").modal("show");
    },
    showResultsComfirmModal() {
      this.modalData = {
        matchNo: this.searchFilter.match.matchNo,
        order: this.searchFilter.order,
      };
      this.$("#resultConfirmModal").modal("show");
    },
    selectRemarker() {
      var _this = this;
      if (
        (this.modalData.submitUserId1 == "" ||
          this.modalData.submitUserId1 == null) &&
        (this.modalData.submitUserId2 == "" ||
          this.modalData.submitUserId2 == null)
      ) {
        this.$bf.warningAlert("至少选择一个评分员");
        return;
      }
      if (this.modalData.submitUserId1 == this.modalData.submitUserId2) {
        this.$bf.warningAlert("评分员不能一致");
        return;
      }
      this.$service.post("match/arrange", this.modalData).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#remarkUserModal").modal("hide");
        _this.search(_this.currentPage);
      });
    },
    changeType(value) {
      var _this = this;
      var json = {
        id: value.id,
        type: value.type,
        location: value.location,
      };
      this.$service.post("match/modify-type", json).then(function (e) {
        _this.editWay = false;
        value.location = "";
        _this.search(_this.currentPage);
      });
    },
    approve() {
      var _this = this;
      this.$service.post("match/approve", this.modalData).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#approveConfirmModal").modal("hide");
        _this.search(_this.currentPage);
      });
    },
    confirmApprove(value) {
      var _this = this;
      this.$service
        .post("match/confirm-result", this.modalData)
        .then(function (e) {
          _this.$("#resultConfirmModal").modal("hide");
          _this.search(_this.currentPage);
        });
    },
  },
};
</script>

<style scoped>
#matchEnrollAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>