<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <h4 class="modal-title" style="text-align:center;font-weight:bold">{{title}}</h4>
            </div>
            <div class="modal-body" style="text-align:center">
                <ul class="list-group">
                    <li v-for="(value,index) in listData"  :key="index" class="list-group-item">
                        <div style="display:flex;flex-direction:row;justify-content: space-around;">
                            <div>{{value.userName}}({{value.enUserName}})</div>
                            <div>{{value.phone}}</div>
                            <div>{{value.email}}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="modal-footer" style="text-align:center">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
            </div>
        </div><!-- /.modal-content -->
    </div>
    </div>
</template>
<script>
export default {
    name: "matchMemberModal",
    data () {
        return {
            title: "参赛成员",
            listData: []
        }
    },
    props:{
        id: {},  
        data: {
            default: {                
                id: null
            }
        }
    },
    watch: {        
        "data.id": function () {
            // console.log(this.data.id)
            this.getMembers()
        }
    },
    methods: {
        closeMethod() {         
            this.data.id = ""       
            this.$(this).modal('hide')
        },
        getMembers(){
            var _this=this
            if(this.data.id == "") {
                return
            }
            this.$service.get('match/member?enrollNo='+this.data.id).then(function (e) {
                _this.listData = e.data.data
            })
        }
    }
}
</script>
