<template>
  <base-modal :id="id" :confirmBtnStr="'提交作品'" @confirm="submitMethod" @close="closeMethod">
    <template #title>{{ '提交作品' }}</template>
    <template v-slot:contentDom>
      <form role="form" class="form-horizontal" style="width: 100%">
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">报名编号</label>
          <div class="col-sm-6">
            {{ data.enrollNo }}
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">申请人</label>
          <div class="col-sm-6">
            {{ data.applyUserName }}
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">组别</label>
          <div class="col-sm-6">
            {{ data.group }}
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1" class="col-sm-2">作品名称</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" placeholder="请输入作品名称" maxlength="100" v-model="data.workName" />
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1" class="col-sm-2">作品名称（英文）</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" placeholder="请输入作品英文名称" maxlength="100" v-model="data.enWorkName" />
          </div>
        </div>
      </form>
    </template>
  </base-modal>
</template>
<script>
import baseModal from "./baseModal.vue";
export default {
  name: "submitWorkModal",
  components: {
    baseModal,
  },
  props: {
    id: {},
    data: {
      default: {
        enrollNo: "",
      },
    },
  },
  methods: {
    closeMethod() {
      this.$(this).modal("hide");
    },
    submitMethod() {
      this.$emit("submit", this.userInfo);
      this.$(this).modal("hide");
    },
    // fillContent(content) {
    //     this.$('#modalBodyContent').append(content)
    // }
  },
};
</script>
<style scoped></style>
