<template>
  <div id="payResultContainer">
    <div>
      <img
        style="width: 90px; height: 90px"
        alt="广东省城市建筑学会"
        src="../../../public/static/assiation_logo.jpg"
      />
      支付成功！请返回主页查看相关内容
    </div>
  </div>
</template>
<script>
export default {
  name: "payResultPage",
  data() {
    return {
      query: "",
    };
  },
  mounted() {
    this.$bf.calHeight(this, this.$("#payResultContainer"));
    this.query = this.$route.fullPath.split("?")[1];
    this.updateOrder();
  },
  methods: {
    updateOrder() {
      var _this = this;
      // console.log(this.query)
      this.$service.get("/order/alipay-check?" + this.query).then(function (e) {
        console.log(e);
      });
    },
  },
};
</script>
<style scoped>
#payResultContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  /* color:lightgreen */
}
</style>

