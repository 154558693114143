import axios from 'axios'
import baseFunc from './base.js'
import store from '../store/store.js'
import router from '../router'

axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: baseFunc.SERVER_URL +"/api/",
  // baseURL: baseFunc.SERVER_URL ,
  timeout: 10000
})

// 请求之前的拦截器
service.interceptors.request.use(
  config => {
    baseFunc.activeLoading('show')
    config.headers = {
      'Content-Type': 'application/json',
    }    
    if (store.getters.isLogin) { 
      config.headers['Authorization'] = store.getters.userInfo.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应之后
service.interceptors.response.use(
  response => {
    if (Object.prototype.hasOwnProperty.call(response.data, 'code')) {
      if (response.data.code == 401){
        baseFunc.activeLoading('hide')
        baseFunc.warningAlert(response.data.msg)
        router.push({name:'loginPage'}) 
        return Promise.reject(response) 
      }
      // 会员不能做
      else if (response.data.code == 405){
        baseFunc.activeLoading('hide')
        // baseFunc.warningAlert(response.data.msg)
        // router.push({name:'loginPage'}) 
        return Promise.resolve(response) 
      }
      else if (response.data.code !== 200) {
        baseFunc.activeLoading('hide')
        // el.Message.error(responseCode.data.msg)
        baseFunc.warningAlert(response.data.msg)
        return Promise.reject(response)
      } else {
//这里是成功的
        baseFunc.activeLoading('hide')
        return Promise.resolve(response)
      }
    } else {
      baseFunc.activeLoading('hide')
      baseFunc.warningAlert('系统异常，请联系开发同事')
      return Promise.reject(response)
    }
  },
  error => {
    baseFunc.activeLoading('hide')
    baseFunc.warningAlert('系统异常，请联系开发同事')
    console.log(error)
    return Promise.reject(error.response)
  }
)




export default service
