<template>
  <div class="root-container">
    <div
      v-if="topBanner != null && $route.name == 'capitalPage'"
      class="root-top-banner"
    >
      <img
        style="max-width: 1400px; height: 100%"
        :alt="topBanner.title"
        :src="$bf.getImgUrl(topBanner.img)"
        @click="imgClick(banner[2])"
      />
    </div>
    <div class="root-top-menu">
      <img
        class="img-rounded"
        alt="广东省城市建筑学会"
        src="../../public/static/logo.png"
        style="padding-right: 30px; height: 72px"
      />
      <div class="menu-user-info">
        <div
          v-if="$store.getters.isLogin"
          style="color: #336699; font-weight: bold"
        >
          欢迎您！{{
            this.$store.getters.userInfo.name !== null &&
            this.$store.getters.userInfo.name !== ""
              ? this.$store.getters.userInfo.name
              : this.$store.getters.userInfo.account
          }}
        </div>
        <div
          v-else
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 160px;
            font-size: 14px;
          "
        >
          <a
            @click="$router.push({ name: 'loginPage' })"
            style="cursor: pointer"
            >登录</a
          >
          <a
            @click="$router.push({ name: 'applyMemberPage' })"
            style="cursor: pointer"
            >注册</a
          >
          <a
            @click="$router.push({ name: 'certificateSearchPage' })"
            style="cursor: pointer"
            >证书查询</a
          >
        </div>
        <div
          v-if="$store.getters.isLogin"
          style="display: flex; flex-direction: row; justify-content: flex-end"
        >
          <a
            class="sub-menu"
            @click="$router.push({ name: 'personalInfoSubPage' })"
            >个人中心<span
              class="label label-danger"
              v-if="msgs > 0"
              style="
                margin-left: 5px;
                border-radius: 50%;
                width: 5px;
                height: 5px;
              "
              >{{ msgs >= 100 ? "99+" : msgs }}</span
            ></a
          >
          <a
            class="sub-menu"
            v-if="
              $store.getters.userInfo &&
              ($store.getters.userInfo.role == 1 ||
                $store.getters.userInfo.role == 2)
            "
            @click="toAdminPage()"
            >管理后台</a
          >
          <a
            class="sub-menu"
            v-if="$store.getters.userInfo && $store.getters.userInfo.role == 3"
            @click="toMarkPage()"
            >评分系统</a
          >
          <a class="sub-menu" @click="logout()">登出</a>
        </div>
      </div>
    </div>
    <div class="menu-nav">
      <div
        class="menu_tab"
        v-for="(value, index) in menus"
        :key="index"
        :id="'infoMenuTab' + index"
        @click="value.page ? changeTab(value) : ''"
        @pointerenter="hoverMenu(index, value.data)"
        @pointerleave="hoverOutMenu()"
      >
        <div class="glyphicon glyphicon-home" v-if="index == 0"></div>
        {{ value.name }}
      </div>
    </div>
    <div
      id="menuSubPlane"
      style="
        background: whitesmoke;
        width: 140px;
        position: absolute;
        z-index: 10;
        display: none;
      "
      @pointerenter="$('#menuSubPlane').show()"
      @pointerleave="hoverOutMenu()"
    >
      <div
        class="menu_sub_tab"
        v-for="(data, index) in subMenu"
        :key="index"
        @click="changeTab(data)"
      >
        {{ data.name }}
      </div>
    </div>
    <div class="root-main-content">
      <router-view @getTopBanner="getTopBanner"></router-view>
    </div>
    <div class="root-bottom-footer">
      <div
        style="
          width: 60%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        "
      >
        <div class="btn-menu" >
          住建部
        </div>
        <div class="btn-menu" >
          中国建筑学会
        </div>
        <div class="btn-menu" >
          广东省住建厅
        </div>
        <div class="btn-menu" >
          广州国规委
        </div>
        <div class="btn-menu" >
          广州发改委
        </div>
        <div class="btn-menu">
          广州人社局
        </div>
      </div>
      <div style="padding-top: 10px; display: flex; flex-direction: row">
        <div class="btn-menu2">关于我们</div>
        |
        <div class="btn-menu2">服务条款</div>
        |
        <div class="btn-menu2">诚聘英才</div>
        |
        <div class="btn-menu2">联系我们</div>
      </div>
      <div style="padding-top: 10px">
        CopyRight © 2013-2022 GUAS,All Rights Reserved 版权所有
        广东省城市建筑学会
        <a
          style="color: white; cursor: pointer"
          @click="openNewTab('https://beian.miit.gov.cn/')"
          >粤ICP备17024150-1号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mainPage",
  data() {
    return {
      userInfo: {},
      matchStatus: null,
      matchNo: "",
      canEnroll: null,
      msgs: 0,
      timer: null,
      topBanner: null,
      menus: [],
      subMenu: [],
      hiddenMenuList: [],
      switch: {},
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    init() {
      this.$("#menuSubPlane").hide();
      this.getSwitch();
      
      this.getMsgs();
      // this.startInterval()
    },
    hoverMenu(index, data) {
      if (this.hiddenMenuList == []) {
        this.subMenu = data;
      } else {
        this.subMenu = this.$bf.menuFilter(data, this.hiddenMenuList);
      }

      if (data.length > 0) {
        this.$("#menuSubPlane").show();
        this.$("#menuSubPlane").css(
          "left",
          this.$("#infoMenuTab" + index).offset().left
        );
      } else {
        this.$("#menuSubPlane").hide();
      }
    },
    hoverOutMenu() {
      this.$("#menuSubPlane").hide();
    },
    imgClick(banner) {
      if (banner == null) {
        return;
      }
      if (banner.url === "" || banner.url === null) {
        return;
      }
      if (banner.type == 4) {
        // 跳转页面详情
        this.$router.push({
          name: "informationDetailPage",
          params: { id: banner.url },
        });
      } else {
        window.open(banner.url, "_blank");
      }
    },
    jumpMatch() {
      if (this.matchStatus == "MATCH_FINISH") {
        this.changeTab("matchPage");
        return;
      }
      if (this.canEnroll) {
        this.changeTab("matchPage");
        return;
      } else {
        this.$router.push({
          name: "matchDetailPage",
          params: { matchNo: this.matchNo },
        });
        return;
      }
    },
    changeTab: function (value) {
      if (value.page && value.page != this.$route.name) {
        if (value.page.includes("DetailPage")) {
          this.$router.push({ name: value.page, params: { id: value.id } });
        } else {
          this.$router.push({ name: value.page });
        }
      }
    },
    toAdminPage() {
      this.$router.push({ name: "adminMainPage" });
    },
    toMarkPage() {
      this.$router.push({ name: "matchMarkScorePage" });
    },
    logout() {
      var _this = this;
      // this.$store.commit('removeUserInfo')
      this.$service.get("/account/logout").then(function (e) {
        // _this.$bf.sucAlert("已经登出")
        _this.$router.push({ name: "loginPage" });
      });
    },
    // getTopBanner() {
    //   var _this = this;
    //   this.$service.get("/advert/top-banner").then(function (e) {
    //     _this.topBanner = e.data.data;
    //     _this.getSwitch();
    //   });
    // },
    getTopBanner(info) {
      // console.log(info)÷
      if (this.$route.name == "capitalPage") {
        this.topBanner = info;
      }
    },
    getSwitch() {
      var _this = this;
      this.$service.get("/config/switch-config").then(function (e) {
        _this.switch = e.data.data;
        _this.hiddenMenuList = _this.switch.HIDDEN_MENUS_LIST;
        if (_this.hiddenMenuList != []) {
          _this.menus = _this.$bf.menuFilter(
            _this.$bf.SUB_MENU,
            _this.hiddenMenuList
          );
        } else {
          _this.menus = _this.$bf.SUB_MENU;
        }

        if (_this.switch.IS_GRAY_HOME == 1 && _this.$route.name=='capitalPage') {      
          _this.$("#capitalPageContainer").addClass("gray-filter");
          _this.$(".root-top-banner").addClass("gray-filter");
          _this.$(".root-top-menu").addClass("gray-filter");
          _this.$(".menu-nav").addClass("gray-filter");
          _this.$(".root-bottom-footer").addClass("gray-filter");
        } else {
          _this.$("#capitalPageContainer").removeClass("gray-filter");
          _this.$(".root-top-banner").removeClass("gray-filter");
          _this.$(".root-top-menu").removeClass("gray-filter");
          _this.$(".menu-nav").removeClass("gray-filter");
          _this.$(".root-bottom-footer").removeClass("gray-filter");
        }
      });
    },
    getMsgs() {
      var _this = this;
      if (this.$store.getters.userInfo) {
        this.$service.get("/push/msg").then(function (e) {
          _this.msgs = e.data.data.total;
        });
      }
    },
    startInterval() {
      var _this = this;
      this.timer = setInterval(() => {
        _this.getMsgs();
      }, 5 * 60000);
    },
    stopInterval() {
      clearInterval(this.timer);
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
/* img{
    filter: grayscale(100);
} */
#capitalPageContainer {
}

.gray-filter {
  filter: grayscale(100);
}

.root-container {
  width: 100%;
  height: 100%;
}

.root-top-banner {
  width: 100%;
  /* display: flex; */
  text-align: center;
  height: 72px;
  /* background-color: black; */
}

.root-top-menu {
  padding: 20px 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid whitesmoke;
}

.root-top-menu .layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.root-main-content {
  /* background-color: #f7f7f7; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding: 15px 300px; */
  /* width:100%;  */
}

.icon-menu {
  border: 1px solid gray;
  border-radius: 10%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  /* color:cornflowerblue */
}

.root-bottom-footer {
  background-color: #336699;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 20px;
  width: 100%;
  color: whitesmoke;
}

.root-bottom-footer .btn-menu {
  white-space: nowrap;
  background: #102d49;
  color: whitesmoke;
  padding: 10px 15px;
  margin: 0px 10px;
  cursor: pointer;
}

.root-bottom-footer .btn-menu2 {
  white-space: nowrap;
  /* background: #102d49; */
  padding: 0px 15px;
  margin: 0px 10px;
  cursor: pointer;
}

.menu-user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  white-space: nowrap;
  font-size: 16px;
}

.sub-menu {
  cursor: pointer;
  padding: 0px 5px;
  font-size: 14px;
}

.menu-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* padding: 5px 0px; */
  background: #336699;
  /* border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray; */
}

.menu_tab {
  /* white-space: nowrap;
    background: lightblue;
    padding: 8px 5px;
    margin: 8px 10px;
    color: gray; */
  white-space: nowrap;
  font-weight: bold;
  color: white;
  cursor: pointer;
  width: 140px;
  text-align: center;
  padding: 5px 5px;
  /* padding-bottom: 1px; */
  font-size: 16px;
  border-right: 1px solid white;
}

.menu_sub_tab {
  text-align: center;
  padding: 10px;
  background: white;
  border-bottom: 1px solid lightgray;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 5px 5px 10px #999;
}

.menu_tab_line {
  border-left: 1px solid white;
  width: 5px;
  padding: 0px 10px;
}

.menu_tab.active {
  color: cornsilk;
  background: #102d49;
}
</style>