<template>
  <div id="applyActivityContainer">
    <page-nav></page-nav>
    <div class="info-detail-container">
      <div style="width: 100%">
        <div class="information_title">
          {{ actInfo.title }}
        </div>
      </div>
      <div class="form-group">
        <div class="item" style="padding-top: 10px">
          <div class="item" style="padding-top: 10px">
            <img
              style="
                height: 100px;
                border-radius: 10px;
                border: solid 1px lightgray;
              "
              src="../../../public/static/alipay_logo.jpg"
            />
            <!-- <p>目前仅支持支付宝支付</p> -->
          </div>
          <p
            style="
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              color: cornflowerblue;
            "
          >
            本次报名费用{{ actInfo.fee }}元
          </p>
          <button
            class="btn btn-primary"
            type="button"
            style="font-size: 16px"
            @click="payMember()"
          >
            成为会员
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageNav from "../base/pageNav.vue";
export default {
  name: "applyActivityPage",
  components: {
    pageNav,
  },
  data() {
    return {
      actInfo: {},
      actEnrollParams: {},
      subNavs: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$bf.calHeight(this, this.$("#applyActivityContainer"));
      this.subNavs = this.$bf.calPageNavs("activity");
      this.getDetail();
    },
    getDetail() {
      var _this = this;
      var url = "/activity/detail?id=" + this.$route.params.id;
      if (this.$route.params.id > 0) {
        this.$service.get(url).then(function (e) {
          _this.actInfo = e.data.data;
        });
      }
    },
  },
};
</script>
<style scoped>
#applyActivityContainer {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.info-detail-container {
  width: 1400px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.information_title {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
}
.form-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.title {
  white-space: nowrap;
  text-align: center;
}
.form-control {
  margin-left: 10px;
}
</style>