<template>
  <div>
    <notice-modal :id="'pushAdminCancelModal'" @confirm="finistopSendsh()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认取消推送任务吗？取消之后的任务将无法恢复！</template>
    </notice-modal>     
    <div id="pushAdminContainer">
      <div
        style="
          text-align: left;
          font-size: large;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group" style="padding-right: 10px">
            <input
              type="number"
              class="form-control"
              placeholder="按推送Id查询"
              v-model="searchFilter.id"
              @blur="search(1)"
            />
          </div>
          <div class="form-group" style="padding-right: 10px">
            <select
              class="form-control"
              placeholder="推送类型"
              v-model="searchFilter.type"
              @change="search(1)"
            >
              <option value="-1">全部推送类型</option>
              <option value="1">站内推送</option>
              <option value="2">短信推送</option>
              <option value="3">邮箱</option>
            </select>
          </div>
          <div class="form-group" style="padding-right: 10px">
            <select
              class="form-control"
              placeholder="推送状态"
              v-model="searchFilter.state"
              @change="search(1)"
            >
              <option value="-3">全部状态</option>
              <option value="0">待发送</option>
              <option value="1">发送中</option>
              <option value="2">成功</option>
              <option value="-1">已取消</option>
              <option value="-2">发送失败</option>
            </select>
          </div>
          <div class="input-group">
            <button type="button" class="btn btn-primary" @click="create()">
              新建任务
            </button>
          </div>
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered">
          <thead>
            <th>Id</th>
            <th>推送时间</th>
            <th>推送内容</th>
            <th>推送目标</th>
            <th>推送类型</th>
            <th>推送状态</th>
            <th>原因</th>
            <th>创建人</th>
            <th>操作</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.pushTime }}</td>
              <td>{{ value.content }}</td>
              <td>{{ value.targetTypeStr }}</td>
              <td>{{ value.typeStr }}</td>
              <td>{{ value.stateStr }}</td>
              <td>{{ value.result }}</td>
              <td>{{ value.updateByStr }}</td>
              <td>
                <div class="btn-group" role="group" v-if="value.state == 0">
                  <button
                    type="button"
                    class="btn btn-default"
                    @click="showModal(value.id)"
                  >
                    撤销推送
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#pushAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "pushAdminPage",
  components: {
    basePagination,
    noticeModal,
  },
  data() {
    return {
      searchFilter: {
        id: "",
        state: -3,
        type: -1,
      },
      paginationData: {
        pageNo: 1,
        maxPage: 1,
        maxShowPage: 5,
        pageSize: 10,
        data: [],
        isLeftEnd: false,
        isRightEnd: false,
      },
      listData: [],
      title: "推送任务管理",
      cancelId: 0,
      data: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search(1);
    },
    search(page) {
      var _this = this;
      var url = "/push/templates";
      var body = {
        id: this.searchFilter.id,
        pageNo: page,
        pageSize: 10,
        state: this.searchFilter.state,
        type: this.searchFilter.type,
      };
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.pushPagination.render(page, e.data.data.total);
      });
    },
    stopSend() {
      var _this = this;
      var url = "/push/cancel";
      var body = {
        id: this.cancelId,
      };
      this.$service.post(url, body).then(function (e) {
        _this.search(_this.paginationData.pageNo);
      });
    },
    create() {
      this.$router.push({ name: "pushEditPage" });
    },
    showModal(id) {
      this.cancelId = id;
      this.$("#pushAdminCancelModal").modal("show");
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>