<template>
  <div id="userTrackAdminContainer">
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <form class="form-inline" style="margin-top: 15px">
      <div class="form-group">
        <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
        <input
          type="number"
          class="form-control"
          placeholder="根据userId搜索"
          v-model="searchFilter.userId"
        />
      </div>
      <div class="form-group" style="padding-left:15px">
        <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
        <select
          class="form-control"
          placeholder="根据userId搜索"
          v-model="searchFilter.type"
        >
        <option value="1">显示全部</option>
        <option value="2">仅显示内部成员</option>
        </select>
      </div>
      <div class="input-group">
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="search(1)"
        >
          <span class="glyphicon glyphicon-search"></span>&nbsp;查找
        </button>
      </div>
    </form>
    <table
      v-if="records.records.length != 0"
      class="table table-bordered"
      style="margin-top: 15px"
    >
      <thead>
        <th>Id</th>
        <th>用户名称</th>
        <th>操作内容</th>
        <th>触发时间</th>
      </thead>
      <tbody>
        <tr v-for="(value, index) in records.records" :key="index">
          <td style="width: 120px">{{ value.id }}</td>
          <td style="width: 120px">{{ value.userName }}</td>
          <td>{{ value.content }}</td>
          <td style="width: 120px">{{ value.createTime }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="records.records.length == 0"
      class="no-data-text"
      style="margin-top: 15px"
    >
      查询暂无数据
    </div>
    <base-pagination :data="records" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
export default {
  name: "userTrackAdminPage",
  components: {
    basePagination,
  },
  data() {
    return {
      title: "用户行为管理",
      searchFilter: {
        userId: null,
        type: 2
      },
      records: [],
      data: {},
    };
  },
  mounted() {
    this.search(1);
  },
  methods: {
    search(page) {
      var _this = this;
      var json = {
        pageNo: page,
        pageSize: 20,
        id: this.searchFilter.userId,
        type:this.searchFilter.type
      };
      this.$service.post("account/track-list", json).then(function (e) {
        _this.records = e.data.data;
        // _this.$refs.userPagination.render(page,e.data.data.total)
      });
    },
  },
};
</script>
<style scoped>
#userTrackAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>