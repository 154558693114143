<template>
    <div id="matchMarkScoreContainer">
        <notice-modal :id="'matchMarkConfirmModal'" @confirm="submit()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认做出以下评分吗？评分后在评选时间内仍可修改</template>
    </notice-modal>   
        <div class="left">
            <div class="info-filter">                
                <div class="form-group" style="display:flex;flex-direction: row;justify-content: flex-start;">
                    <!-- <select v-model="searchFilter.showAll"> 
                        <option value=1>展示全部</option>
                        <option value=0>仅展示未评分</option>
                    </select> -->
                    <input type="text" class="form-control"  placeholder="报名编号精确查询" v-model="searchFilter.enrollNo">
                    <button type="button" class="btn btn-primary" @click="getWorks(1)">查询</button>                    
                </div>
            </div>
            <div class="info-content">
                <ul class="list-group">
                    <li :id="'item_'+index+value.enrollNo" v-for="(value,index) in records" :key="index"
                    @click="clickItem(index,value.enrollNo)"
                    class="list-group-item" >
                    <div style="display:flex;flex-direction:row;justify-content:space-between;white-space: nowrap;width:100%;">
                        <div>报名编号：{{ value.enrollNo }}</div>
                        <span class="label label-primary">{{value.group}}</span>
                    </div>
                        <div>作品名称：{{ value.workName }}</div>
                        <div v-if="value.submitUserIndex == 1" style="color:red;font-weight:bold" >分数：{{value.score1}}分</div>
                        <div v-else style="color:red;font-weight:bold" >分数：{{value.score2}}分</div>
                    </li>
                </ul>
                <base-pagination :data="pageData" @method="getWorks"></base-pagination>
            </div>
        </div>
        <div v-if="record" class="right">
            <h3>第{{stageOrder}}轮评选</h3>
            <div class="enroll-grid">
                <div class="enroll-grid-item info-tag">作品信息</div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">ID</div>
                </div>
                <div class="enroll-grid-item three-col">
                    {{ record.id }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">报名编号</div>
                </div>
                <div class="enroll-grid-item three-col">
                    {{ record.enrollNo }}
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">作品名称</div>
                </div>
                <div class="enroll-grid-item three-col">
                    <div>{{ record.workName }}</div>
                    <div>{{ record.enWorkName }}</div>
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">工程名称</div>
                </div>
                <div class="enroll-grid-item">
                    {{ record.projectName }}                 
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">工程规模</div>
                </div>
                <div class="enroll-grid-item">
                    {{ record.projectSize }}                 
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">建设花费</div>
                </div>
                <div class="enroll-grid-item">
                    <div>{{ record.projectCost }}万元</div>
                </div>
                <div class="enroll-grid-item title">
                    <div style="display:flex;flex-direction:row">结构形式</div>
                </div>
                <div class="enroll-grid-item">
                    {{ record.projectStructure }}                 
                </div>
            </div>
            <form role="form" class="form-horizontal" style="width:100%;padding:0px 15px">
                <div  v-for="(value,index) in rules" :key="index" style="width:100%;display:flex;flex-direction:row;justify-content: space-between;align-items: center;padding:15px 0px">
                    <label >
                        {{value.title}}
                        <div style="color:red">{{value.desc}}</div>
                    </label>
                    <div>
                        <input type="number" style="width: 180px" class="form-control"  :placeholder="'输入分数，最高为'+value.score" 
                        :max="value.score"
                        min="0"
                        @input="checkMaxScore(value)"
                        v-model="value.inputScore" >
                    </div>
                </div>
                <div class="divider-group" style="padding:15px 0px">
                    <div class="divider"></div>
                    <div>评价</div>
                    <div class="divider"></div>
                </div>                        
                <div  style="width:100%;padding:15px 0px">
                    <textarea class="form-control"                    
                    style="resize:none;"
                    maxlength="500"
                    rows="5" v-model="result.comment"></textarea>
                </div>
                <div style="text-align:center;padding:15px 0px">
                    <button @click="showModal" type="button" class="btn btn-primary"> {{result.score}}分 提交</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import basePagination from '../base/pagination.vue'
import noticeModal from '../base/noticeModal.vue'
export default {
    name: "matchMarkScorePage",
    components: {
      noticeModal,
      basePagination
    },
    data () {
        return {
            matchStatus: "",
            matchNo: "",
            stageOrder: 0,
            rules: null,
            searchFilter: {
                enrollNo: null
            },
            order: 0,
            result: {
                id:null,
                score: 0,
                comment: ""
            },
            records:[],
            record:null,
            scoreRecord: null
        }
    },
    mounted() {
        this.$bf.calHeight(this,this.$('#matchMarkScoreContainer'))
        this.getWorks(1)
    },
    methods: {
        getWorks(page) {
            var _this = this
            var data = {
                enrollNo: this.searchFilter.enrollNo ? this.searchFilter.enrollNo:null,
                // isAll: this.searchFilter.showAll,
                page: page,
                pageSize:20
            }
            this.$service.post('/match/works',data).then(function(e){
                _this.records = e.data.data.data
                // _this.records.push(e.data.data.data[0])
                // _this.$refs.infoPagination.render(page,e.data.data.total)
                _this.pageData = e.data.data
                // _this.matchStatus = e.data.data.status
                if(_this.records.length>0){
                    _this.matchNo = _this.records[0].matchNo
                    _this.stageOrder =_this.records[0].order
                    _this.$('li[id^=item_]').removeClass("activeItem")
                }
                _this.record = null
               
            })
        },
        clickItem(index,enrollNo){    
            this.$('li[id^=item_]').removeClass("activeItem")
            this.$('#item_'+index+enrollNo).addClass("activeItem")
            this.record = this.records[index]
            // 每次点击切换item都要清空之前的内容
            this.result.id = this.record.id
            this.result.score = 0
            this.result.comment = this.record.submitUserIndex == 1? this.record.comment1 :this.record.comment2
            this.getRules()
        },
        getRules(){
            var _this =this 
            this.$service.get("match/rules?order="+this.stageOrder).then(function (e) {
                _this.rules = e.data.data
            })
        },
        // getUserRecord(){
        //     if (this.matchStatus != "APPROVE" || this.stageOrder == 0) {
        //            this.$bf.warningAlert("目前不是评选时间")
        //            return 
        //         }
        //     if (this.searchFilter.enrollNo == "") {
        //         return 
        //     }
        //     var _this =this 
        //     var body = {}
        //     body.matchNo = this.matchNo
        //     body.enrollNo = this.searchFilter.enrollNo
        //     this.$service.post("match/record",body).then(function (e) {
        //         _this.record = e.data.data
        //         if(_this.record) {
        //             _this.scoreRecord = _this.record.matchUserScoreRecordEntityMap[_this.stageOrder]
        //         }
        //         // console.log(_this.scoreRecord,_this.stageOrder)
        //     })
        // },
        showModal() {
            // console.log(this.result)

            if (this.result.score == 0){
                this.$bf.warningAlert("评分不能为0")
                return
            }
            if (this.result.comment == ""){
                this.$bf.warningAlert("评论不能为空")
                return
            }
            this.$('#matchMarkConfirmModal').modal('show')
        },
        checkMaxScore(value) {            
            if(value.inputScore > value.score){value.inputScore =value.score;} 
            // 重新计算总分
            this.result.score = 0
            for(var rule in this.rules) {
                if("inputScore" in this.rules[rule]) {
                    this.result.score += parseInt(this.rules[rule].inputScore)
                }                
            }
        },
        submit () {
            var _this =this 
            var body={
                id: this.result.id,
                matchNo: this.matchNo,
                enrollNo: this.record.enrollNo,
                submitUserIndex: this.record.submitUserIndex,
                // order: this.stageOrder,
                score: this.result.score,
                comment: this.result.comment
            }
            this.$service.post("match/mark",body).then(function (e) {
                _this.record = null
                _this.result = {
                    score: 0,
                    comment: ""
                }
                _this.getWorks(1)
                _this.$('li[id^=item_]').removeClass("activeItem")
                // _this.record =  null
            })
        }
    }
}
</script>
<style scoped>
#matchMarkScoreContainer{
    display: flex;
    flex-direction: row;
    width:1400px;
    background: white;
    padding:20px
}
.left{
    border-right: 1px solid lightgray;
    width: 500px;
    padding-right: 15px;
    display:flex;
    flex-direction: column;
}
.right{
    display: flex;
    width:100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.activeItem {
    background:lightgrey;
}
.list-group-item {
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    cursor:pointer;
    width:450px;
    margin:5px 0px
}
.enroll-grid {
    display:grid;
    grid-template-columns: [menu-items] auto auto auto auto;
    grid-template-rows: auto auto auto;
    width:100%;
    padding:0px 15px
}
.enroll-grid-item{
    padding:5px 10px;
    text-align: left;
    border: 1px solid lightgray
}
.enroll-grid-item.info-tag {
	font-size:16px;
	font-weight:bold;
    grid-column: 1 / 5;    
    text-align: center;
    background-color: cornsilk;
    color:cornflowerblue
}
.enroll-grid-item.title {
    background-color: whitesmoke;
}
.enroll-grid-item.three-col {
    grid-column: 2 / 5;
}
</style>
