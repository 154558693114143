<template>
    <div>
        <notice-modal :id="'matchEditConfirmModal'" @confirm="create()">
        <template v-slot:title>提示</template>
        <template v-slot:content>确认创建吗？</template>
        <template v-slot:confirmBtnStr>创建</template>
    </notice-modal>   
        <div id="matchCreateContainer">
            <form role="form" class="form-horizontal" id="matchCreateForm" style="width:100%">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="col-sm-1">比赛名称</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" placeholder="名称" name="name" v-model="pageParams.name">
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1" class="col-sm-1">比赛名称（英文）</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" placeholder="名称" name="name" v-model="pageParams.enName">
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1" class="col-sm-1">描述</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" placeholder="描述" name="desc" v-model="pageParams.desc">
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputFile" class="col-sm-1">入口banner</label>
                    <input type="file" accept=".jpg" name="upload" ref="bannerFile" @change="uploadBanner" />(330*120)
                </div>
                <div class="form-group">
                    <editor name="content" id='uuid' v-model="pageParams.content" api-key="no-api-key" output-format='html'
                        :init="{
                            plugins: 'preview autolink directionality visualchars fullscreen image link template code table pagebreak nonbreaking anchor insertdatetime advlist lists autoresize imagetools',
                            menubar: false,
                            statusbar: false,
                            language: 'zh-Hans',
                            images_upload_url: $bf.EDITOR_UPLOAD_URL,
                            images_upload_base_path: $bf.STATIC_IMG_URL,
                            branding: false,
                            toolbar: 'undo redo | fontsizeselect fontselect forecolor bold italic underline strikethrough | alignleft aligncenter alignright outdent indent | bullist numlist | link table image | preview ',
                            //原生鼠标右键菜单开关 true 编辑区域鼠标无法右键
                            contextmenu_never_use_native: false,
                            //编辑器鼠标右键菜单
                            contextmenu: false,
                            height: '100%'
                        }" value="" />
                </div>
                <div class="form-group">
                    <label class="col-sm-1">比赛时间
                        <div style="color:red">比赛公示时长</div>
                    </label>
                    <div class="col-sm-4">
                        <input type="datetime-local" name="startTime" v-model="pageParams.startTime" />
                        <input type="datetime-local" name="endTime" v-model="pageParams.endTime" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-1">报名时间</label>
                    <div class="col-sm-4">
                        <input type="datetime-local" name="startTime" v-model="pageParams.enrollStartTime" />
                        <input type="datetime-local" name="endTime" v-model="pageParams.enrollEndTime" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-1">提交作品时间</label>
                    <div class="col-sm-4">
                        <input type="datetime-local" name="startTime" v-model="pageParams.submitStartTime" />
                        <input type="datetime-local" name="endTime" v-model="pageParams.submitEndTime" />
                    </div>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" v-model="pageParams.onlyMember"> 仅限会员申请
                    </label>
                </div>
                <!-- <div class="form-group" style="padding-top:20px">
                <label for="exampleInputPassword1"  class="col-sm-1">报名费用（单位元）</label>
                <div class="col-sm-2">
                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="单位分" v-model="pageParams.enrollFee">
                </div>
            </div> -->
                <div class="form-group">
                    <div class="divider-group">
                        <div class="divider"></div>
                        <div class="text">比赛阶段</div>
                        <div class="divider"></div>
                    </div>
                </div>
                <div style="width:100%;text-align:center">
                    <button type="button" class="btn btn-primary" @click="addStage()">新增阶段</button>
                </div>
                <div v-for="(value, index) in matchStages" :key="index" class="form-group" style="padding-top:20px">
                    <label for="exampleInputPassword1" class="col-sm-1">排序：{{ index + 1 }}</label>
                    <label for="exampleInputPassword1" class="col-sm-1">名称</label>
                    <div class="col-sm-2">
                        <input type="text" class="form-control" id="exampleInputPassword1" placeholder="阶段名称"
                            v-model="value.name">
                    </div>
                    <label class="col-sm-1">开始时间</label>
                    <div class="col-sm-2">
                        <input type="datetime-local" name="startTime" v-model="value.startTime" />
                    </div>
                    <label class="col-sm-1" style="padding-left:15px">结束时间</label>
                    <div class="col-sm-2">
                        <input type="datetime-local" name="endTime" v-model="value.endTime" />
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="btn btn-danger" @click="removeStage(index)">删除</button>
                    </div>
                </div>
                <div style="width:100%; display:flex;flex-direction:row;justify-content:space-around;padding:15px">
                    <button type="button" class="btn btn-primary" @click="showModal()">保存</button>
                    <button type="button" class="btn btn-default" @click="backToPage()">取消</button>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
#matchCreateContainer {
    padding: 30px;
}
</style>
<script>
import Editor from '@tinymce/tinymce-vue'
import noticeModal from '../base/noticeModal.vue'
export default {
    name: "matchEditPage",
    components: {
        'editor': Editor,
        noticeModal
    },
    data() {
        return {
            pageParams: {
                id: 0,
                name: '',
                enName: "",
                desc: '',
                content: '',
                enrollStartTime: '',
                enrollEndTime: '',
                submitStartTime: '',
                submitEndTime: '',
                enrollFee: 0,
                onlyMember: 0,
                state: 0
            },
            matchStages: [],
            prePageName: ''
        }
    },
    mounted() {
        var _this = this
        this.pageParams.id = this.$route.params.id > 0 ? this.$route.params.id : this.pageParams.id
        this.prePageName = 'matchAdminPage'
        if (this.pageParams.id > 0) {
            this.getDetail()
        }
    },
    methods: {
        addStage() {
            var stage = {
                order: 0,
                name: '',
                startTime: '',
                endTime: ''
            }
            this.matchStages.push(stage)
        },
        removeStage(index) {
            var temp = []
            for (var i = 0; i < this.matchStages.length; i++) {
                if (i == index) {
                    continue
                }
                temp.push(this.matchStages[i])
            }
            this.matchStages = temp
        },
        create() {
            if (this.pageParams.name == '' ||this.pageParams.enName == '' || this.pageParams.desc == '' ||
                this.pageParams.content == '' || this.pageParams.submitStartTime == '' ||
                this.pageParams.submitEndTime == '' || this.pageParams.enrollStartTime == '' ||
                this.pageParams.enrollEndTime == ''
            ) {
                this.$bf.warningAlert("关键参数不能为空")
                return
            }
            if (this.pageParams.img == '' || this.pageParams.img == null)
            {
                this.$bf.warningAlert("需要上传图片")
                return
            }
            var _this = this
            var body = this.pageParams;
            body.onlyMember = body.onlyMember ? 1 : 0
            body.matchStageVos = this.matchStages
            for (var i = 0; i < this.matchStages.length; i++) {
                body.matchStageVos[i].order = i + 1
            }

            if (body.matchStageVos.length == 0) {
                _this.$bf.warningAlert("比赛阶段不能为空，至少要有一个阶段")
                return
            }
            this.$service.post('/match/create', body).then(function (e) {
                _this.$router.push({ name: _this.prePageName })
            })
        },
        getDetail() {
            var _this = this
            this.$service.get('/advert/detail?id=' + this.pageParams.id).then(function (e) {
                _this.pageParams.title = e.data.data.title
                _this.pageParams.url = e.data.data.url
                _this.pageParams.params = e.data.data.params
                _this.pageParams.type = e.data.data.type
                _this.pageParams.img = e.data.data.img
                _this.$router.push({ name: this.prePageName })
            })
        },
        showModal() {
            this.$('#matchEditConfirmModal').modal('show')
        },
        backToPage() {
            this.$router.push({ name: this.prePageName })
        }
        ,
        uploadBanner() {
            var file = this.$refs.bannerFile.files[0]
            var reason = this.$bf.checkImg(file)
            if (reason != null) {
                this.$bf.warningAlert(reason)
                this.$refs.bannerFile.value = ""
                this.articalInfo.img = ""
                return
            }
            let formData = new FormData()
            formData.set("fileName", file.name)
            formData.set("file", file)
            var _this = this
            this.$service.post("/utils/upload", formData).then(function (e) {
                _this.pageParams.img = e.data.data.name
                _this.$refs.bannerFile.value = ""
                // _this.articalInfo.img = ""
            })
        }
    }
}
</script>