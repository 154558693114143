<template>
  <base-modal :id="id" :confirmBtnStr="'上传'">
    <template #title>{{ '文件上传' }}</template>
    <template v-slot:contentDom>
      <form role="form" class="form-horizontal" style="width: 100%">
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">类型</label>
          <div class="col-sm-4">
            <select class="form-control" v-model="data.type">
              <option value="图片">图片</option>
              <option value="视频">视频</option>
              <option value="文件">文件</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">描述</label>
          <div class="col-sm-9">
            <textarea type="text" class="form-control" style="resize: none" maxlength="100" v-model="data.desc">
            </textarea>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div style="text-align: center">
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          关闭
        </button>
        <input type="file" ref="uploadFile" @change="upload" :accept="data.type == '图片'
            ? imgFile
            : data.type == '视频'
              ? videoFile
              : data.type == '文件'
                ? fileFile
                : ''
          " style="display: none" />
        <button type="button" class="btn btn-primary" @click="clickFile">
          上传
        </button>
      </div>
    </template>
  </base-modal>
</template>
<script>
import baseModal from "./baseModal.vue";
export default {
  name: "uploadFileModal",
  components: {
    baseModal,
  },
  data() {
    return {
      data: {
        type: "图片",
        desc: "",
      },
      imgFile: "image/jpeg,image/png,image/gif",
      fileFile:
        "application/pdf,text/plain text/csv,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      videoFile: "audio/mp4, video/mp4",
    };
  },
  props: {
    id: null,
  },
  methods: {
    closeMethod() {
      //   this.$(this).modal("hide");
    },
    upload() {
      var _this = this;
      var file = this.$refs.uploadFile.files[0];
      var formData = new FormData();
      formData.set("type", this.data.type);
      formData.set("desc", this.data.desc);
      formData.set("file", file);
      this.$service.post("/file/create", formData).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#" + _this.id).modal("hide");
      });
    },
    clickFile() {
      if (this.data.desc == "" || this.data.desc == null) {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      this.$refs.uploadFile.click();
      //   this.$(this).modal("hide");
    },
    // fillContent(content) {
    //     this.$('#modalBodyContent').append(content)
    // }
  },
};
</script>
