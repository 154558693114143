<template>
  <div>
    <div id="memberAdminContainer">
      <div
        style="
          text-align: left;
          font-size: large;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <!-- <div class="form-group">
            <input
              type="number"
              class="form-control"
              placeholder="根据id搜索"
              v-model="searchFilter.id"
              @blur="search(1)"
            />
          </div> -->
          <div class="form-group" style="padding: 0px 10px">
            <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
            <input
              type="text"
              class="form-control"
              placeholder="根据名字搜索"
              v-model="searchFilter.name"
              @blur="search(1)"
            />
          </div>
          <!-- <div class="input-group" >
                    <button type="button" class="btn btn-primary" style="margin-left:10px" @click="search(1)">
                        <span class="glyphicon glyphicon-search"></span>&nbsp;查找</button>                                   
                     </div> -->
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered">
          <thead>
            <th>用户Id</th>
            <th>账号</th>
            <th>姓名</th>
            <th>电话</th>
            <th>邮件</th>
            <th>单位</th>
            <th>职务</th>
            <th>会员类型</th>
            <!-- <th>角色</th> -->
            <th>会费</th>
            <th>实际支付</th>
            <th>持续时间</th>
            <th>状态</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.userEntity.id }}</td>
              <td>{{ value.userEntity.account }}</td>
              <td v-if="value.userEntity.name">{{ value.userEntity.name }}</td>
              <td v-else>-</td>
              <td>{{ value.userEntity.phone }}</td>
              <td>{{ value.userEntity.mail }}</td>
              <td>{{ value.userEntity.enterprise }}</td>
              <td>{{ value.userEntity.job }}</td>
              <td>
                {{ value.typeStr }}
              </td>
              <td>{{ value.skuEntity.fee / 100 }}元</td>
              <td v-if="value.orderEntity==null">手动会员</td>
              <td v-else>{{ value.orderEntity.totalAmount / 100 }}元</td>
              <td>{{ value.startTime }} - {{ value.endTime }}</td>
              <td v-if="value.state == 0">有效</td>
              <td v-else>失效</td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination
        :data="listData"
        @method="search"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
export default {
  name: "memberAdminPage",
  components: {
    basePagination,
  },
  data() {
    return {
      title: "会员管理",
      searchFilter: {
        id: null,
        name: null,
      },
      listData: [],
      data: {
        pageNo: 0,
      },
    };
  },
  mounted() {
    this.search(1);
  },
  methods: {
    search(page) {
      this.pageNo = page;
      var _this = this;
      var body = {
        pageNo: page,
        pageSize: 10,
        id: this.searchFilter.id ? this.searchFilter.id : null,
        name: this.searchFilter.name ? this.searchFilter.name : null,
      };
      var url = "/member/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.userPagination.render(page, e.data.data.total);
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
#memberAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
