<template>
  <div>
    <output-cert-modal
      :id="'outputCertModal'"
      @output="outputCert"
    ></output-cert-modal>
    <div id="certificateAdminContainer">
      <div
        style="
          text-align: left;
          font-size: large;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        "
      >
        &nbsp;{{ title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group">
            <div class="col-sm-6">
              <select
                class="form-control"
                placeholder="比赛编号"
                v-model="searchFilter.matchNo"
              >
                <option
                  :value="value.matchNo"
                  v-for="(value, index) in matchNos"
                  :key="index"
                >
                  {{ value.matchNo }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-6">
              <select
                class="form-control"
                placeholder="证书类型"
                v-model="searchFilter.type"
              >
                <option value="null">全部类型</option>
                <option value="2">个人证书</option>
                <option value="1">企业证书</option>
              </select>
            </div>
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input
              type="text"
              class="form-control"
              placeholder="按证书码查询"
              v-model="searchFilter.certNo"
            />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input
              type="text"
              class="form-control"
              placeholder="按报名编号查询"
              v-model="searchFilter.enrollNo"
            />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input
              type="text"
              class="form-control"
              placeholder="按用户名称查询"
              v-model="searchFilter.userName"
            />
          </div>
          <div class="input-group">
            <button
              type="button"
              class="btn btn-primary"
              style="margin-left: 10px"
              @click="search(1)"
            >
              搜索
            </button>
            <button
              type="button"
              class="btn btn-warning"
              style="margin-left: 10px"
              @click="showOutputCertModal"
            >
              导出证书
            </button>
            <!-- <button type="button" class="btn btn-primary" style="margin-left:10px" @click="outputCert()">
                        导出Excel</button>                 -->
          </div>
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered">
          <thead>
            <!-- <th>Id</th> -->
            <!-- <th>批次号</th>   -->
            <th>比赛编号</th>
            <th>报名编号</th>
            <th>比赛名称</th>
            <th>证书编码</th>
            <th>证书类型</th>
            <th>比赛组别</th>
            <th>作品名称</th>
            <th>获奖单位</th>
            <th>获奖人</th>
            <th>名次</th>
            <!-- <th>操作</th> -->
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.matchNo != -1 ? value.matchNo : "历史数据" }}</td>
              <td>{{ value.enrollNo != -1 ? value.enrollNo : "历史数据" }}</td>
              <td>{{ value.title }}</td>
              <td>{{ value.certNo }}</td>
              <td>{{ value.typeStr }}</td>
              <td>
                <div>{{ value.group }}</div>
                <div>{{ value.enGroup }}</div>
              </td>
              <td>
                <div>{{ value.workName }}</div>
                <div>{{ value.enWorkName }}</div>
              </td>
              <td v-if="value.enterprise">
                <div>{{ value.enterprise }}</div>
                <div>{{ value.enEnterprise }}</div>
              </td>
              <td v-else>-</td>
              <td v-if="value.userName">
                <div>{{ value.userName }}</div>
                <div>{{ value.enUserName }}</div>
              </td>
              <td v-else>-</td>
              <td>
                <div>{{ value.prize }}</div>
                <div>{{ value.enPrize }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>

      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#certificateAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import outputCertModal from "../base/outputCertModal.vue";
import basePagination from "../base/pagination.vue";
export default {
  name: "certificateAdminPage",
  components: {
    basePagination,
    outputCertModal,
  },
  data() {
    return {
      searchFilter: {
        // id: 0,
        certNo: "",
        matchNo: "",
        userName: "",
        enrollNo: "",
        type: null,
        // state: -1
      },
      pageInfo: {
        adminPage: "",
        editPage: "",
        detailPage: "",
        createPage: "",
      },
      listData: [],
      title: "证书管理",
      matchNos: [],
      data: {},
    };
  },
  created() {
    this.init();
  },
  watch: {
    "searchFilter.type":function () {
      this.search(1);
    },
    "searchFilter.matchNo":function () {
      this.search(1);
    }
  },
  methods: {
    init() {
      this.pageInfo.adminPage = this.$route.name;
      this.getMatchList(1);
    },
    getMatchList() {
      var _this = this;
      var url = "/match/list";
      var body = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$service.post(url, body).then(function (e) {
        _this.matchNos = e.data.data.records;
        // 兼容历史数据0403
        _this.matchNos.push({ matchNo: "2022年历史数据" });
        _this.matchNos.push({ matchNo: "2021年历史数据" });
        _this.matchNos.push({ matchNo: "2020年历史数据" });
        _this.matchNos.push({ matchNo: "2019年历史数据" });
        _this.searchFilter.matchNo = _this.matchNos[0].matchNo;
      });
    },
    search(page) {
      var _this = this;
      var url = "/cert/list";
      var matchNo = this.searchFilter.matchNo;
      if (matchNo == "2019年历史数据") {
        matchNo = "-2019";
      } else if (matchNo == "2020年历史数据") {
        matchNo = "-2020";
      } else if (matchNo == "2021年历史数据") {
        matchNo = "-2021";
      } else if (matchNo == "2022年历史数据") {
        matchNo = "-2022";
      }
      var body = {
        page: page,
        pageSize: 10,
        // id:"",
        certNo: this.searchFilter.certNo ? this.searchFilter.certNo : null,
        matchNo: matchNo,
        enrollNo: this.searchFilter.enrollNo
          ? this.searchFilter.enrollNo
          : null,
        // state: this.searchFilter.state >= 0? this.searchFilter.state : "",
        userName: this.searchFilter.userName
          ? this.searchFilter.userName
          : null,
        type: this.searchFilter.type,
      };
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.data = e.data.data;
      });
    },
    showOutputCertModal() {
      this.$("#outputCertModal").modal("show");
    },
    outputCert(data) {
      var _this = this;
      var url = "/cert/output";
      var body = {
        matchNo:
          this.searchFilter.matchNo != "历史"
            ? this.searchFilter.matchNo
            : null,
        type: data.type,
      };
      this.$service.post(url, body).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        var path = _this.$bf.getExcelUrl(e.data.data);
        window.open(path, "_blank");
      });
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>