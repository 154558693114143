<template>
  <div id="adminMainPageContainer">
    <div class="admin-main-page-top">
      <img class="img-rounded" src="../../public/static/logo.png" alt="广东省城市建筑学会"
        style="padding-right: 30px; height: 50px" />
      <div class="menu-user-info">
        <div v-if="userInfo.name != '' && userInfo.name != null">
          欢迎您！ {{ userInfo.name }}
        </div>
        <!-- <div  v-if="userInfo.name!=''&& userInfo.name!=null" class="dropdown" style="padding-left: 10px">
                <button class="btn btn-default dropdown-toggle btn-sm" type="button" id="userInfoMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="userInfoMenu">                    
                    <li  @click="$router.push({name:'mainPage'})"><a href="#">前往网页</a></li>
                    <li @click="logout()"><a href="#">登出</a></li>
                </ul> 
            </div>        -->
        <div v-if="userInfo.name != '' && userInfo.name != null" style="
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 10px;
          ">
          <a class="sub-menu" @click="$router.push({ name: 'mainPage' })">前往网页</a>
          <a class="sub-menu" @click="logout()">登出</a>
        </div>
      </div>
    </div>
    <div id="adminMainPageContent">
      <ul class="nav nav-pills nav-stacked" id="adminMainMenu">
        <li role="presentation" @click="changeTab('adminMainTab0', null)" id="adminMainTab0">
          <a><span class="glyphicon glyphicon-th-large"></span>&nbsp;广告位管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'bannerAdminPage')">
              banner管理
            </div>
            <!-- <div class="admin_menu_sub_item" @click="changeSubTab($event,'bannerAdminPage')">网站备案管理</div> -->
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab1', null)" id="adminMainTab1">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;新闻动态</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'informationAdminPage', null)">
              学会新闻
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'industryAdminPage', null)">
              行业动态
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'notifyAdminPage', null)">
              通知公告
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'mediaAdminPage', null)">
              媒体报道
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'policyAdminPage', null)">
              政策法规
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'partyAdminPage', null)">
              党史党建
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab2', null)" id="adminMainTab2">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;学术交流</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'scholarshipAdminPage', null)">
              学术会议
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'scholarshipInfoAdminPage', null)">
              学术动态
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'subjectResearchAdminPage', null)">
              课题研究
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'specialistAdminPage', null)">
              专家智库
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab13', null)" id="adminMainTab13">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;科普展览</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'scienceAdminPage', null)">
              科普展览
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab3', null)" id="adminMainTab3">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;科技培训</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'trainStatusAdminPage', null)">
              培训动态
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'newTopicAdminPage', null)">
              古建今谈
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'painterAdminPage', null)">
              小筑画匠
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'trainCourseAdminPage', null)">
              培训课程
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'subjectLectureAdminPage', null)">
              专题讲座
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'studyAdminPage', null)">
              研讨研究
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'forumAdminPage', null)">
              论坛沙龙
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab17', null)" id="adminMainTab17">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;学会奖项</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'caseAdminPage', null)">
              建筑信息化案例征集
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab16', null)" id="adminMainTab16">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;科技服务</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'trainAdminPage', null)">
              人才培养
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'teamStandardAdminPage', null)">
              团队标准
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab4', null)" id="adminMainTab4">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;会员之家</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'enterpriseAdminPage', null)">
              会员单位
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberRuleEditPage', 187)">
              会员行规行约
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberNoticeAdminPage', null)">
              会员公告
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberAnnounceEditPage', 189)">
              入会须知
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberRightsEditPage', 17)">
              会员权益
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberFeeRuleEditPage', 188)">
              会费标准
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'membersShowAdminPage', null)">
              会员风采
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab15', null)" id="adminMainTab15">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;设计师俱乐部</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'designerAdminPage', null)">
              设计师管理
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'designerNewsAdminPage', null)">
              设计师动态
            </div>
          </div>
        </li>
        <li role="presentation" @click="changeTab('adminMainTab5', null)" id="adminMainTab5">
          <a><span class="glyphicon glyphicon-pencil"></span>&nbsp;关于学会</a>
          <div class="admin_sub_menu">
            <!-- <div
              class="admin_menu_sub_item"
              @click="changeSubTab($event, 'enterpriseAdminPage', null)"
            >
              会员企业
            </div> -->



            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'associationInfoEditPage', 90)">
              学会简介
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'regulateEditPage', 89)">
              章程条例
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'secretariatEditPage', 88)">
              秘书处
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'councilAdminPage', null)">
              理事会
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'associationNewsAdminPage', null)">
              学会会讯
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'branchAdminPage', null)">
              分支机构
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'commissionAdminPage', null)">
              工作委员会
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'publicationAdminPage', null)">
              学会刊物
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab6" @click="changeTab('adminMainTab6', 'activityAdminPage')">
          <a><span class="glyphicon glyphicon-star"></span>&nbsp;活动管理</a>
          <!-- <div class="admin_sub_menu">
                <div class="admin_menu_sub_item" @click="changeSubTab($event,'activityAdminPage')">活动列表</div>
            </div> -->
        </li>
        <li role="presentation" id="adminMainTab7" @click="changeTab('adminMainTab7', null)">
          <a><span class="glyphicon glyphicon-stats"></span>&nbsp;BIM比赛管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'matchAdminPage')">
              比赛记录
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'matchEnrollAdminPage')">
              报名记录管理
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'matchResultAdminPage')">
              评选结果管理
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'matchRewardAdminPage')">
              比赛结果管理
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab8" @click="changeTab('adminMainTab8', null)">
          <a><span class="glyphicon glyphicon-briefcase"></span>&nbsp;证书管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'certificateAdminPage')">
              证书管理
            </div>
          </div>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'certificateImportPage')">
              导入证书
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab9" @click="changeTab('adminMainTab9', 'pushAdminPage')">
          <a><span class="glyphicon glyphicon-envelope"></span>&nbsp;消息通知管理</a>
        </li>
        <li role="presentation" id="adminMainTab10" @click="changeTab('adminMainTab10', null)">
          <a><span class="glyphicon glyphicon-calendar"></span>&nbsp;会务管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'receiptAdminPage')">
              个人发票管理
            </div>
          </div>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'groupReceiptAdminPage')">
              团队发票管理
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab18" @click="changeTab('adminMainTab18', 'fileAdminPage')">
          <a><span class="glyphicon glyphicon-briefcase"></span>&nbsp;文件管理</a>
        </li>
        <li role="presentation" id="adminMainTab11" @click="changeTab('adminMainTab11', null)">
          <a><span class="glyphicon glyphicon-user"></span>&nbsp;用户管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'userAdminPage')">
              用户记录
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'userTrackAdminPage')">
              用户行为记录
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab12" @click="changeTab('adminMainTab12', null)">
          <a><span class="glyphicon glyphicon-star"></span>&nbsp;会员管理</a>
          <div class="admin_sub_menu">
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberAdminPage')">
              会员记录
            </div>
            <div class="admin_menu_sub_item" @click="changeSubTab($event, 'memberDataAdminPage')">
              数据面板
            </div>
          </div>
        </li>
        <li role="presentation" id="adminMainTab14" @click="changeTab('adminMainTab14', 'configAdminPage')">
          <a><span class="glyphicon glyphicon-list"></span>&nbsp;系统配置</a>
        </li>
      </ul>
      <router-view style="width: 100%"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "adminMainPage",
  data() {
    return {
      userInfo: {
        name: "",
      },
    };
  },
  mounted() {
    // this.$bf.calHeight(this,this.$('#adminMainPageContainer'))
    if (this.$store.getters.isLogin) {
      this.userInfo = this.$store.getters.userInfo;
      if (!(this.userInfo.role == 1 || this.userInfo.role == 2)) {
        this.$router.push({ name: "loginPage" })
      }
    } else {
      this.$router.push({ name: "loginPage" })
    }
  },
  methods: {
    logout() {
      var _this = this;
      this.$service.get("/account/logout").then(function (e) {
        _this.$bf.sucAlert("已经登出");
        _this.$router.push({ name: "loginPage" });
      });
    },
    changeTab(id, pageName) {
      this.$("li[id^=adminMainTab]").removeClass("active");
      this.$("#" + id).addClass("active");
      this.$(".admin_sub_menu")
        .not("#" + id + " .admin_sub_menu")
        .slideUp();
      this.$("#" + id + " .admin_sub_menu").slideDown();
      if (pageName != null) {
        this.$router.push({ name: pageName });
      }
    },
    changeSubTab(event, name, id) {
      if (this.$router.history.current.name == name) {
        return;
      }
      this.$(".admin_menu_sub_item").removeClass("active");
      this.$(event.target).addClass("active");
      if (name != null) {
        if (name.includes("EditPage")) {
          this.$router.push({ name: name, params: { id: id } });
        } else {
          this.$router.push({ name: name });
        }
      }
    },
    init() {
      var _this = this;
      this.$("#adminMainPageContainer").css(
        "height",
        this.$(document).height()
      );
      this.$service
        .get("/user/info?id=" + this.$store.getters.userId)
        .then(function (e) {
          _this.userInfo.name = e.data.data.name;
        });
    },
    toMainPage() {
      this.$router.push({ name: "mainPage" });
    },
  },
};
</script>
<style scoped>
#adminMainPageContainer {
  display: flex;
  height: 100%;
  font-size: 14px;
  flex-direction: column;
}

.sub-menu {
  cursor: pointer;
  padding: 0px 5px;
  font-size: 14px;
}

.admin-main-page-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid lightgray;
  padding: 20px 20px;
}

.menu-user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
}

#adminMainPageContent {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#adminMainMenu {
  height: 100%;
  border-right: 1px solid lightgray;
  padding-left: 20px;
  white-space: nowrap;
}

.admin_sub_menu {
  display: none;
  font-size: 14px;
}

.admin_menu_sub_item {
  padding: 5px 10px;
  color: dimgrey;
  text-align: right;
  cursor: pointer;
}

.admin_menu_sub_item.active {
  color: dodgerblue;
  /* background-color: dimgrey; */
}
</style>