<template>
  <div id="infoListContainer">
    <page-nav></page-nav>
    <div class="info-list-container">
      <div style="width: 100%; padding-right: 15px">
        <div
          style="
            padding: 10px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid lightgray;
          "
        >
          <div style="font-weight: bold; color: #336699; font-size: 20px">
            {{ pageJson.title }}
          </div>
          <div class="info-filter-area">
            <input
              type="text"
              style="width: 240px"
              class="form-control"
              placeholder="标题模糊查询"
              v-model="searchFilter.title"
            />
            <button
              type="button"
              class="btn btn-primary"
              style="margin-left: 10px"
              @click="search(1)"
            >
              <span class="glyphicon glyphicon-search"></span>&nbsp;搜索
            </button>
          </div>
        </div>
        <div class="info-list-content">
          <div v-if="listData.records.length > 0">
            <div
              v-for="(value, index) in listData.records"
              :key="index"
              class="list_group_item"
            >
              <div>
                <img
                  v-if="value.img"
                  :alt="value.title"
                  :class="
                    $route.name != 'membersListPage'
                      ? 'list-group-item-img'
                      : 'list-group-member-img'
                  "
                  style="cursor: pointer;"
                  :src="$bf.getImgUrl(value.img)"
                  @click="toDetail(value.id)"
                />
                <img
                  v-else
                  class="list-group-item-img"
                  alt="广东省城市建筑学会"
                  src="../../../public/static/defaultpic.gif"
                />
              </div>
              <div class="list-group-item-content">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                  "
                >
                  <div class="title"
                  style="
                        color: cornflowerblue;
                        cursor: pointer;
                        white-space: nowrap;
                      "
                      @click="toDetail(value.id)"
                  >{{ value.title }}</div>
                  <div class="create_time">
                    <div>{{ value.createTime }}</div>
                    &nbsp;<span
                      v-if="value.isMember"
                      class="label label-success"
                      style="text-align: center"
                      >会员</span
                    >&nbsp;
                    <div
                      style="
                        color: cornflowerblue;
                        cursor: pointer;
                        white-space: nowrap;
                      "
                      @click="toDetail(value.id)"
                    >
                      [详情]
                    </div>
                  </div>
                </div>
                <p class="desc">{{ value.desc }}</p>
              </div>
            </div>
          </div>
          <div v-if="listData.records.length == 0" class="no-data-text">
            查询暂无数据
          </div>
        </div>
        <base-pagination :data="listData" @method="search"></base-pagination>
      </div>
      <div
        style="
          width: 350px;
          padding-left: 10px;
          border-left: 1px solid lightgrey;
        "
      >
        <div v-if="recommandList.length > 0">
          <div
            style="font-weight: bold; color: cornflowerblue; font-size: 16px"
          >
            最近更新
          </div>
          <div
            style="
              height: 200px;
              display: flex;
              flex-direction: column;
              padding: 5px 0px;
            "
          >
            <div
              class="recommand-list-item"
              v-for="(value, index) in recommandList"
              :key="index"
            >
              <div
                class="title"
                :title="value.title"
                @click="toInfoDetail(value)"
              >
                {{ value.title }}
              </div>
              <!-- <div class="create-time">{{ $bf.timestampToDate(value.createTime) }}</div> -->
              <div class="create-time">{{ value.showCreateTime }}</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="divider-group">
            <div class="divider"></div>
            <!-- <div class="text">个人信息</div> -->
            <div class="divider"></div>
          </div>
        </div>
        <div>
          <!-- <div style="font-weight:bold;">微信</div> -->
          <div style="text-align: center">
            <img
              alt="广东省城市建筑学会"
              src="../../../public/static/weixin_bg.jpg"
            />
          </div>
        </div>
      </div>
      <!-- <a href="#infoListPage" class="go-to-top-btn">跳转到底部 </a> -->
    </div>
  </div>
</template>
<style scoped>
#infoListContainer {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.recommand-list-item {
  /* flex: 1; */
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
}

.recommand-list-item .title {
  width: 100%;
  /* white-space: nowrap; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.recommand-list-item .title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.recommand-list-item .create-time {
  white-space: nowrap;
  padding-left: 5px;
  color: grey;
  font-size: 14px;
}

.info-list-container {
  width: 1400px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
}

.info-list-title {
  font-weight: bold;
  font-size: medium;
  padding: 0px 10px;
  border-left: 10px solid cornflowerblue;
}

.info-filter-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* text-align: right; */
  padding: 10px 0px;
}

.info-list-content {
  padding-top: 15px;
}

.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}

.act_list_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px dashed lightgray;
}

.act_list_item .img {
  width: 120px;
  height: 90px;
}

.act_list_item .content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}

.act_list_item .content .title {
  font-size: 16px;
  color: cornflowerblue;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.act_list_item .content .time {
  color: grey;
  width: 100%;
  text-align: right;
  font-size: 14px;
}

.act_list_item .content .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.list_group_item {
  display: flex;
  width: 100%;
  border-bottom: 1px dashed lightgray;
  padding: 8px 0px;
}

.list-group-item-img {
  width: 180px;
  height: 120px;
}
.list-group-member-img {
  width: 120px;
  height: 160px;
}

.list-group-item-content {
  width: 100%;
  padding-left: 10px;
}

.list-group-item-content .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  color: cornflowerblue;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.list-group-item-content .create_time {
  font-size: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  color: grey;
  white-space: nowrap;
}

.list-group-item-content .desc {
  font-size: 16px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.go-to-top-btn {
  /* float:right; */
  position: fixed;
  bottom: 300px;
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import pageNav from "../base/pageNav.vue";
export default {
  name: "informationListPage",
  components: {
    basePagination,
    pageNav,
    // noticeModal
  },
  data() {
    return {
      searchFilter: {
        id: 0,
        type: 1,
        title: "",
      },
      paginationData: {
        pageNo: 1,
        maxPage: 1,
        maxShowPage: 5,
        pageSize: 10,
        data: [],
        isLeftEnd: false,
        isRightEnd: false,
      },
      pageNavs: [],
      prePage: {
        name: "",
        title: "",
      },
      recommandList: [],
      listData: [],
      pageJson: {},
      data: {},
    };
  },
  watch: {
    $route: function () {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
    this.getRecommand();
  },
  methods: {
    initPage() {
      this.$bf.calHeight(this, this.$("#infoListContainer"));
      this.searchFilter = {
        // id: 0,
        type: 1,
        title: null,
      };

      // var urlPath = this.$route.name
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.pageNavs = this.$bf.calPageNavs(this.pageJson.prefix);
      // console.log(this.pageNavs);
      this.searchFilter.type = this.pageJson.type;
      this.search(1);
    },
    search(page) {
      var _this = this;
      this.paginationData.pageNo = page;
      var url = "/artical/list";
      var body = {};
      body.pageNo = this.paginationData.pageNo;
      body.pageSize = this.paginationData.pageSize;
      body.type = this.searchFilter.type;
      body.title = this.searchFilter.title ? this.searchFilter.title : null;
      body.state = 0;
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.infoPagination.render(page, e.data.data.total);
        // _this.initPagination(e.data.data)
      });
    },
    getRecommand() {
      var _this = this;
      this.$service.get("/advert/recommand").then(function (e) {
        _this.recommandList = e.data.data;
      });
    },
    changeTab: function (id) {
      this.$("li[id^=informationListTab]").removeClass("active");
      this.$("#" + id).addClass("active");
    },
    toDetail(id) {
      if (this.searchFilter.type == 4) {
        // console.log(this.$store.getters.isLogin)
        if (!this.$store.getters.isLogin) {
          this.$router.push({ name: "loginPage" });
          return;
        }
        this.$router.push({ name: "actDetailPage", params: { id: id } });
      } else {
        var pageName = this.$bf.getArticalPrefix(this.searchFilter.type);
        if (pageName) {
          this.$router.push({
            name: pageName + "DetailPage",
            params: { id: id },
          });
        }
      }
    },
    toInfoDetail(value) {
      var pageName = this.$bf.getArticalPrefix(value.type);
      this.$router.push({
        name: pageName + "DetailPage",
        params: { id: value.id },
      });
    },
  },
};
</script>
