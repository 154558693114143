<template>
    <div id="certImportContainer">
        <div style="text-align: center;padding:15px 0px;display: flex;flex-direction: row;justify-content: space-between;">
            <a href="../../../public/static/证书导入模板.xlsx" download="证书导入模板.xlsx" style="cursor: pointer">下载模板</a>
            <form class="form-inline">
                <div class="form-group">
                    <label for="exampleInputFile" class="col-sm-3" style="white-space: nowrap;">上传excel</label>
                    <input type="file" accept=".xlsx" name="upload" ref="excelFile" @input="importCert" />
                </div>
            </form>
        </div>
        <div style="color: red;">最多能加载100条数据</div>
        <div style="max-height:600px;overflow-y: auto;">
            <table v-if="listData.length != 0" class="table table-bordered" >
                <thead>
                    <th>序号</th>
                    <!-- <th>批次号</th>   -->
                    <th>比赛编号</th>
                    <th>报名编号</th>
                    <th>比赛名称</th>
                    <th>证书类型</th>
                    <th>分组</th>
                    <th>名次</th>
                    <th>作品名称</th>
                    <th>获奖单位</th>
                    <th>获奖人</th>
                    <th>获奖人单位</th>
                    <!-- <th>操作</th> -->
                </thead>
                <tbody>
                    <tr v-for="(value, index) in listData" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ value.matchNo != -1 ? value.matchNo : "历史数据" }}</td>
                        <td>{{ value.enrollNo != -1 ? value.enrollNo : "历史数据" }}</td>
                        <td>{{ value.title }}</td>
                        <td v-if="value.type == 1"> 企业</td>
                        <td v-if="value.type == 2"> 个人</td>
                        <td>
                            <div>{{ value.group }}</div>
                            <div>{{ value.enGroup }}</div>
                        </td>
                        <td>
                            <div>{{ value.prize }}</div>
                            <div>{{ value.enPrize }}</div>
                        </td>
                        <td>
                            <div>{{ value.workName }}</div>
                            <div>{{ value.enWorkName }}</div>
                        </td>
                        <td v-if="value.enterprise">
                            <div>{{ value.enterprise }}</div>
                            <div>{{ value.enEnterprise }}</div>
                        </td>
                        <td v-else>-</td>
                        <td v-if="value.userName">
                            <div>{{ value.userName }}</div>
                            <div>{{ value.enUserName }}</div>
                        </td>
                        <td v-else>-</td>
                        <td v-if="value.company">
                            <div>{{ value.company }}</div>
                            <div>{{ value.enCompany }}</div>
                        </td>
                        <td v-else>-</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="no-data-text">导入预览：尚未导入任何数据</div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content:space-around;padding: 10px;">
            <button v-if="listData.length" type="button" class="btn btn-primary" @click="confirm()">
                确定导入</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'certificateImportPage',
    data() {
        return {
            listData: [],
            excelFile: '',
            searchParams: {
                batchNo: ''
            },
            paginationData: {
                pageNo: 1,
                maxPage: 1,
                maxShowPage: 5,
                pageSize: 10,
                data: [],
                isLeftEnd: false,
                isRightEnd: false
            },
            data: []
        }
    },
    methods: {
        importCert() {
            var _this = this
            var file = this.$refs.excelFile.files[0]
            let formData = new FormData()
            formData.set("fileName", file.name)
            formData.set("file", file)
            this.$service.post("/cert/import", formData).then(function (e) {
                _this.$bf.sucAlert(e.data.msg)
                _this.listData = e.data.data
            })
            _this.$refs.excelFile.value = ""
            
        },
        confirm() {
            this.$service.post("/cert/importConfirm", {"records":this.listData}).then(function (e) {
                this.$bf.sucAlert("同步成功");
            })
        },
        backToPage() {
            this.$router.push({ name: "certificateAdminPage" })
        }
    }
}
</script>
<style scoped>
#certImportContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* width: 100%; */
}

.no-data-text {
    padding: 20px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: lightslategrey;
}
</style>