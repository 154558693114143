<template>
  <div>
    <div id="informationAdminContainer">
      <notice-modal :id="'infoAdminOnlineModal'" @confirm="changeState()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
      </notice-modal>
      <baseModal :id="'changeTypeModal'" @confirm="changeType">
        <template #title>变更类型</template>
        <template #contentDom>
          <div class="form-group" style="width:100%">
            <label for="exampleInputName2">文章类型</label>
            <select class="form-control" placeholder="类型" v-model="row.type">
              <option value='1'>学会新闻</option>
              <option value="2">行业动态</option>
              <option value="3">党史党建</option>
              <option value="4">协会活动</option>
              <option value="5">通知公告</option>
              <option value="6">媒体报道</option>
              <option value="7">政策法规</option>
              <option value="8">会员单位</option>
              <option value="9">学术会议</option>
              <option value="10">学术动态</option>
              <option value="11">课题研究</option>
              <option value="12">人才培养</option>
              <option value="13">团队标准</option>
              <option value="14">培训动态</option>
              <option value="15">古建今谈</option>
              <option value="16">专题讲座</option>
              <option value="17">专家智库</option>
              <option value="18">培训课程</option>
              <option value="19">研讨研究</option>
              <option value="20">论坛沙龙</option>
            </select>
          </div>
        </template>
      </baseModal>
      <div style="
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          border-left: 10px solid cornflowerblue;
        ">
        &nbsp;{{ pageJson.title }}
      </div>
      <div style="text-align: right; padding: 15px 0px">
        <form class="form-inline">
          <div class="form-group">
            <input type="number" class="form-control" placeholder="根据id搜索" v-model="searchFilter.id" />
          </div>
          <div class="form-group" style="padding: 0px 10px">
            <input type="text" class="form-control" placeholder="标题模糊查询" v-model="searchFilter.title" />
          </div>
          <div class="form-group">
            <select class="form-control" placeholder="状态" v-model="searchFilter.state">
              <option :value='null'>全部状态</option>
              <option value="0">上线</option>
              <option value="1">下线</option>
            </select>
          </div>
          <button type="button" class="btn btn-primary" style="margin-left: 10px" @click="search(1)">
            <span class="glyphicon glyphicon-search"></span>&nbsp;查找
          </button>
          <button type="submit" class="btn btn-warning" style="margin-left: 10px" @click="create()">
            新建
          </button>
        </form>
      </div>
      <div>
        <table v-if="listData.records.length != 0" class="table table-bordered" style="font-size: 14px">
          <thead>
            <th>Id</th>
            <th>对内标题</th>
            <th>会员</th>
            <th>最近更新人</th>
            <th>创建时间</th>
            <th>更新时间</th>
            <th>状态</th>
            <th>操作</th>
          </thead>
          <tbody>
            <tr v-for="(value, index) in listData.records" :key="index">
              <td>{{ value.id }}</td>
              <td>{{ value.title }}</td>
              <td style="white-space: nowrap">
                <span v-if="value.isMember == 1" class="label label-success" style="text-align: center">{{
                  value.isMemberStr }}</span>
                <span v-else class="label label-default" style="text-align: center">{{ value.isMemberStr }}</span>
              </td>
              <td>{{ value.updateByStr }}</td>
              <td>{{ value.createTime }}</td>
              <td>{{ value.updateTime }}</td>
              <td>{{ value.stateStr }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="...">
                  <button v-if="value.state == '1'" type="button" class="btn btn-primary" @click="showModal(value, 0)">
                    上线
                  </button>
                  <button v-if="value.state == '0'" type="button" class="btn btn-danger" @click="showModal(value, 1)">
                    下线
                  </button>
                  <button type="button" class="btn btn-default" @click="update(value.id)">
                    编辑
                  </button>
                  <button type="button" class="btn btn-warning" @click="changeTypeModal(value)">
                    转移
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="listData.records.length == 0" class="no-data-text">查询暂无数据</div>
      </div>
      <base-pagination :data="listData" @method="search"></base-pagination>
    </div>
  </div>
</template>
<style scoped>
#informationAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import noticeModal from "../base/noticeModal.vue";
import baseModal from "../base/baseModal.vue";
export default {
  name: "informationAdminPage",
  components: {
    basePagination,
    noticeModal,
    baseModal
  },
  data() {
    return {
      searchFilter: {
        id: null,
        type: 1,
        title: null,
        state: null,
      },
      changeStateParams: {
        id: 0,
        oldState: "",
        newState: "",
        type: "",
      },
      listData: [],
      title: "",
      pageJson: {
        title: ""
      },
      data: {},
      currentPage: 1,
      row: {}
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init()
    },
    "searchFilter.state": function () {
      this.search(1)
    }
  },
  methods: {
    init() {
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.searchFilter = {
        id: null,
        type: 1,
        title: null,
        state: null,
      };
      this.search(1);
    },
    search(page) {
      var _this = this;
      var body = {};
      body.id = this.searchFilter.id ? this.searchFilter.id : null;
      body.title = this.searchFilter.title ? this.searchFilter.title : null;
      body.pageNo = page;
      body.pageSize = 10;
      body.type = this.pageJson.type;
      body.isAdmin = 1;
      this.currentPage = page;
      body.state =
        this.searchFilter.state == "" ? null : this.searchFilter.state;
      var url = "/artical/list";
      this.$service.post(url, body).then(function (e) {
        _this.listData =  e.data.data;
        // _this.data = e.data.data;
      });
    },
    changeState() {
      var _this = this;
      var body = {
        id: this.changeStateParams.id,
        oldState: this.changeStateParams.oldState,
        newState: this.changeStateParams.newState,
      };
      var url = "/artical/changeState";
      this.$service.post(url, body).then(function (e) {
        // _this.sucAlert(e.data.msg)
        _this.search(_this.currentPage);
      });
    },
    detail(id) {
      this.$router.push({ name: this.pageJson.detailPage, params: { id: id } });
    },
    create() {
      this.$router.push({ name: this.pageJson.createPage });
    },
    update(id) {
      this.$router.push({ name: this.pageJson.editPage, params: { id: id } });
    },
    showModal(value, newState) {
      this.$("#infoAdminOnlineModal").modal("show");
      this.changeStateParams.id = value.id;
      this.changeStateParams.oldState = value.state;
      this.changeStateParams.newState = newState;
      this.changeStateParams.type = value.type;
    },
    changeTypeModal(value) {
      this.row = value
      this.$("#changeTypeModal").modal("show");
    },
    changeType() {
      var _this = this;
      var body = {
        id: this.row.id,
        type: this.row.type
      };
      var url = "/artical/changeType";
      this.$service.post(url, body).then(function (e) {
        // _this.sucAlert(e.data.msg)
        _this.search(_this.currentPage);
      });
    }
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>