<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
          <h4 class="modal-title">
            <slot name="title"></slot>
          </h4>
        </div>
        <div class="modal-body">
          {{ content }}
          <slot name="contentDom"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-default"
              data-dismiss="modal"
              @click="closeMethod"
            >
              关闭
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmMethod"
            >
              {{ confirmBtnStr }}
            </button>
          </slot>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</template>
<script>
export default {
  name: "baseModal",
  data() {
    return {};
  },
  props: {
    id: {},
    title: {
      default: "默认标题",
    },
    content: {
      default: "",
    },
    confirmBtnStr: {
      default: "确认",
    },
  },
  methods: {
    open() {
      this.$("#" + this.id).modal("show");
    },
    confirmMethod() {
      this.$emit("confirm", this.data);
      this.$("#" + this.id).modal("hide");
    },
    closeMethod() {
      this.$emit("close", this.data);
      this.$("#" + this.id).modal("hide");
    },
    // fillContent(content) {
    //     this.$('#modalBodyContent').append(content)
    // }
  },
};
</script>
<style scoped>
</style>
