<template>
  <div>
    <apply-group-receipt-modal
      :id="'applyGroupReceiptModal'"
      @submit="applyGroupReceipt"
      :data="groupReceiptInfo"
    ></apply-group-receipt-modal>
    <group-receipt-info-modal
      :id="'groupReceiptInfoModal'"
      :data="groupReceiptInfo"
    ></group-receipt-info-modal>
    <ul class="nav nav-pills" style="padding: 10px 0px">
      <li role="presentation2" class="active" id="tab1" @click="changeTab(1)">
        <a>报名记录</a>
      </li>
      <li role="presentation2" id="tab2" @click="changeTab(2)">
        <a>参与记录</a>
      </li>
    </ul>
    <table v-if="data.records.length > 0" class="table table-bordered">
      <thead>
        <th>比赛名称</th>
        <th>报名编号</th>
        <th>申请人</th>
        <th>组别</th>
        <th>作品名称</th>
        <th>操作</th>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data.records" :key="index">
          <td>{{ value.matchEntity.name }}</td>
          <td>{{ value.enrollNo }}</td>
          <td>{{ value.applyUserName }}</td>
          <td>
            <div>{{ value.group }}</div>
            <div>{{ value.enGroup }}</div>
          </td>
          <td v-if="value.workName">
            <div>{{ value.workName }}</div>
            <div>{{ value.enWorkName }}</div>
          </td>
          <td v-else>暂无</td>
          <td v-if="type == 1">
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="value.groupReceiptEntity == null"
              @click="showApplyGroupReceiptModal(value)"
              >[申请发票]</a
            >
            <a
              style="font-size: 12px"
              v-if="
                value.groupReceiptEntity && value.groupReceiptEntity.state == 0
              "
              >[发票审批中]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="
                value.groupReceiptEntity && value.groupReceiptEntity.state == 1
              "
              @click="showGroupReceiptInfoModal(value)"
              >[发票信息]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="
                value.groupReceiptEntity && value.groupReceiptEntity.state == -1
              "
              @click="showApplyGroupReceiptModal(value)"
              >[发票被驳回]</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="no-data-text">查询暂无数据</div>
    <base-pagination :data="data" @method="search"></base-pagination>
  </div>
</template>
<script>
import basePagination from "../base/pagination.vue";
import applyGroupReceiptModal from "../base/applyGroupReceiptModal.vue";
import groupReceiptInfoModal from "../base/groupReceiptInfoModal.vue";
export default {
  name: "personalMatchSubPage",
  components: {
    basePagination,
    applyGroupReceiptModal,
    groupReceiptInfoModal
  },
  data() {
    return {
      data: [],
      type: 1,
      groupReceiptInfo: {},
      pageData: {}
    };
  },
  mounted() {
    this.search(1);
  },
  methods: {
    search(page) {
      var _this = this;
      var json = {
        type: this.type,
        pageNo: page,
        pageSize: 5,
      };
      this.$service.post("/match/match-records", json).then(function (e) {
        _this.data = e.data.data;
        // _this.$refs.matchSubPagination.render(page, e.data.data.total);
      });
    },
    changeTab(type) {
      this.$("li[role^=presentation2]").removeClass("active");
      this.$("#tab" + type).addClass("active");
      this.type = type;
      this.search(1);
    },
    showApplyGroupReceiptModal(value) {
      if (value.groupReceiptEntity == null) {
        this.groupReceiptInfo = {
          matchNo: value.matchNo,
          enrollNo: value.enrollNo,
          identification: "",
          address: "",
          phone: "",
          bank: "",
          bankNo: "",
          // orderNo: "",
          // isSend: 1,
          sendAddress: "",
          email: "",
          receiptor: "",
          sendPhone: "",
        };
      } else {
        this.groupReceiptInfo = value.groupReceiptEntity;
      }

      this.$("#applyGroupReceiptModal").modal("show");
    },
    applyGroupReceipt() {
      var _this = this;
      //   console.log(this.groupReceiptInfo)
      if (
        this.groupReceiptInfo.identification == "" ||
        this.groupReceiptInfo.address == "" ||
        this.groupReceiptInfo.phone == "" ||
        this.groupReceiptInfo.bank == "" ||
        this.groupReceiptInfo.bankNo == "" ||
        this.groupReceiptInfo.sendAddress == "" ||
        this.groupReceiptInfo.receiptor == "" ||
        this.groupReceiptInfo.sendPhone == "" ||
        this.groupReceiptInfo.email == ""
      ) {
        this.$bf.warningAlert("关键信息不能为空");
        return;
      }
      if (!this.$bf.isMail(this.groupReceiptInfo.email)) {
        this.$bf.warningAlert("邮箱格式异常");
        return;
      }
      //   console.log("提交");
      this.$service
        .post("/group-receipt/apply", this.groupReceiptInfo)
        .then(function (e) {
          _this.$bf.sucAlert("提交成功");
          _this.$("#applyGroupReceiptModal").modal("hide");
          _this.search(1);
        });
    },
    showGroupReceiptInfoModal(value) {
      this.groupReceiptInfo = value.groupReceiptEntity;
      console.log(this.groupReceiptInfo)
      // this.receiptId = id
      // console.log(this.receiptId)
      this.$("#groupReceiptInfoModal").modal("show");
    },
  },
};
</script>