<template>
  <div id="personalPageContainer">
    <modify-PW-modal
      :id="'pwEditModal'"
      @update="updatePW"
      :data="PWInfo"
    ></modify-PW-modal>
    <apply-receipt-modal
      :id="'applyReceiptModal'"
      @submit="applyReceipt"
      :data="receiptInfo"
    ></apply-receipt-modal>
    <apply-group-receipt-modal
      :id="'applyGroupReceiptModal22'"
      @submit="applyGroupReceipt"
      :data="groupReceiptInfo"
    ></apply-group-receipt-modal>
    <receipt-info-modal
      :id="'receiptInfoModal'"
      :data="status"
    ></receipt-info-modal>
    <user-info-edit-modal
      :id="'userInfoEditModal'"
      :userDetail="userEditInfo"
      @update="updateInfo"
    ></user-info-edit-modal>
    <div
      style="
        width: 80%;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        background: white;
      "
    >
      <div class="user-menu">
        <div style="display: inline-flex">
          <img
            style="width: 80px; height: 100px"
            :src="
              userInfo.img
                ? $bf.getHeadImgUrl(userInfo.img)
                : '../../../public/static/defaultpic.gif'
            "
          />
          <!-- {{ userInfo }} -->
          <!-- <img
            v-else
            style="width: 80px; height: 120px"
            src="../../../public/static/defaultpic.gif"
          /> -->
          <div style="padding-left: 30px; height: 100%">
            <div style="font-size: 16px">账号名称：{{ userInfo.account }}</div>
            <div style="font-size: 16px">
              真实名称：{{ userInfo.name ? userInfo.name : "待完善" }}
            </div>
            <div class="button-group">
              <input
                type="file"
                class="btn btn-success"
                style="display: none"
                id="uploadHeadImg"
                ref="headImgUpload"
                @change="uploadHeadImg"
              />
              <button class="btn btn-warning" type="file" @click="changeImg()">
                变更头像
              </button>
              <button
                class="btn btn-primary"
                type="button"
                @click="showChangePwModal()"
              >
                修改密码
              </button>
            </div>
          </div>
        </div>
        <div>
          <!-- {{userInfo.memberRecordVo}} -->
          <div
            v-if="userInfo.isMember && userInfo.memberRecordVo != null"
            style="font-size: 16px; text-align: right"
          >
            {{ userInfo.memberRecordVo.typeStr }}
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="status == null"
              @click="showApplyReceiptModal(null)"
              >[申请发票]</a
            >
            <a style="font-size: 12px" v-if="status && status.state == 0"
              >[发票审批中]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="status && status.state == 1"
              @click="showReceiptInfoModal()"
              >[发票信息]</a
            >
            <a
              style="font-size: 12px; cursor: pointer"
              v-if="status && status.state == -1"
              @click="showApplyReceiptModal(status)"
              >[发票被驳回]</a
            >
          </div>
          <a
            v-else
            style="cursor: pointer; font-size: 16px"
            @click="$router.push({ name: 'applyMemberPage' })"
            >申请学会会员>></a
          >
          <div
            v-if="userInfo.isMember && userInfo.memberRecordVo"
            style="color: grey"
          >
            {{ userInfo.memberRecordVo.startTime }} 至
            {{ userInfo.memberRecordVo.endTime }}
          </div>
        </div>
      </div>
      <div class="user-plane">
        <ul
          class="nav nav-tabs"
          style="padding-bottom: 20px; width: 100%; font-size: 14px"
        >
          <li role="presentationTab" class="active" id="personalInfoSubPage">
            <a @click="toPage('personalInfoSubPage')">个人信息</a>
          </li>
          <li role="presentationTab" id="personalMsgSubPage">
            <a @click="toPage('personalMsgSubPage')">消息通知</a>
          </li>
          <li role="presentationTab" id="personalActSubPage">
            <a @click="toPage('personalActSubPage')">活动记录</a>
          </li>
          <li role="presentationTab" id="personalMatchSubPage">
            <a @click="toPage('personalMatchSubPage')">比赛记录</a>
          </li>
          <li role="presentationTab" id="personalCertSubPage">
            <a @click="toPage('personalCertSubPage')">证书记录</a>
          </li>
        </ul>
        <router-view class="personal_route_page"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import userInfoEditModal from "../base/userInfoEditModal.vue";
import modifyPWModal from "../base/modifyPWModal.vue";
import applyReceiptModal from "../base/applyReceiptModal.vue";
import applyGroupReceiptModal from "../base/applyGroupReceiptModal.vue";
import receiptInfoModal from "../base/receiptInfoModal.vue";
export default {
  name: "personalPage",
  components: {
    userInfoEditModal,
    modifyPWModal,
    applyReceiptModal,
    receiptInfoModal,
    applyGroupReceiptModal,
  },
  data() {
    return {
      currentTabId: null,
      userInfo: {
        memberRecordVo: {},
        account: "",
        name: "",
        isMember: false,
        img: null,
      },
      userEditInfo: {
        account: "",
        name: "",
        company: "",
        mail: "",
      },
      PWInfo: {
        password: "",
        newPassword: "",
        newPassword2: "",
      },
      receiptInfo: {
        type: 1,
        identification: "",
        address: "",
        orderNo: "",
        phone: "",
        bank: "",
        bankNo: "",
        isSend: 0,
        sendAddress: "",
        receiptor: "",
        sendPhone: "",
      },
      groupReceiptInfo: {
        type: 1,
        identification: "",
        address: "",
        orderNo: "",
        phone: "",
        bank: "",
        bankNo: "",
        isSend: 0,
        sendAddress: "",
        receiptor: "",
        sendPhone: "",
      },
      status: {
        type: 0,
        state: 0,
      },
      receiptId: 0,
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  mounted() {
    this.$bf.calHeight(this, this.$("#personalPageContainer"));
    this.init();
  },
  methods: {
    init() {
      var pathName = this.$route.name;
      this.$("li[role^=presentationTab]").removeClass("active");
      this.$("#" + pathName).addClass("active");
      this.getInfo();
      // this.getReceipt();
    },
    changeTab: function (id) {
      this.$("div[class^=personal_menu_sub_item]").removeClass("active");
      this.$("#" + id).addClass("active");
      // this.currentTabId = id;
      // if(id=="personalTab0"){
      //     this.getInfo(id)
      // }
    },
    getInfo() {
      var _this = this;
      this.$service.get("/user/info").then(function (e) {
        _this.userInfo = e.data.data;
        _this.status = e.data.data.receiptEntity;
      });
    },
    toPage(page) {
      this.$router.push({ name: page });
    },
    showModal(domId) {
      this.userEditInfo.account = this.userInfo.account;
      this.userEditInfo.mail = this.userInfo.mail;
      this.userEditInfo.name = this.userInfo.name;
      this.userEditInfo.company = this.userInfo.company;
      this.$("#" + domId).modal("show");
    },
    updateInfo() {
      var _this = this;
      if (
        this.userEditInfo.name == "" ||
        this.userEditInfo.account == "" ||
        this.userEditInfo.mail == "" ||
        this.userEditInfo.company == ""
      ) {
        this.$bf.sucWarning("关键信息不能为空~");
        return;
      }
      this.$service.post("user/update", this.userEditInfo).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#userInfoEditModal").modal("hide");
        _this.getInfo();
      });
    },
    applyReceipt() {
      var _this = this;
      if (
        this.receiptInfo.identification == "" ||
        this.receiptInfo.address == "" ||
        this.receiptInfo.phone == "" ||
        this.receiptInfo.bank == "" ||
        this.receiptInfo.bankNo == ""
      ) {
        this.$bf.warningAlert("关键信息不能为空");
        return;
      }
      if (
        this.receiptInfo.isSend == 1 &&
        (this.receiptInfo.sendAddress == "" ||
          this.receiptInfo.receiptor == "" ||
          this.receiptInfo.sendPhone == "" ||
          this.receiptInfo.email == "")
      ) {
        this.$bf.warningAlert("邮寄信息不能为空");
        return;
      }
      if (!this.$bf.isMail(this.receiptInfo.email)) {
        this.$warningAlert("邮箱格式异常");
        return;
      }
      // this.receiptInfo.orderNo = orderNo
      // console.log(this.receiptInfo)
      this.$service.post("/receipt/apply", this.receiptInfo).then(function (e) {
        _this.$bf.sucAlert("提交成功");
        _this.$("#applyReceiptModal").modal("hide");
        _this.getInfo();
      });
    },
    applyGroupReceipt() {
      var _this = this;
      if (
        this.receiptInfo.identification == "" ||
        this.receiptInfo.address == "" ||
        this.receiptInfo.phone == "" ||
        this.receiptInfo.bank == "" ||
        this.receiptInfo.bankNo == ""
      ) {
        this.$bf.warningAlert("关键信息不能为空");
        return;
      }
      if (
        this.receiptInfo.isSend == 1 &&
        (this.receiptInfo.sendAddress == "" ||
          this.receiptInfo.receiptor == "" ||
          this.receiptInfo.sendPhone == "" ||
          this.receiptInfo.email == "")
      ) {
        this.$bf.warningAlert("邮寄信息不能为空");
        return;
      }
      if (!this.$bf.isMail(this.receiptInfo.email)) {
        this.$warningAlert("邮箱格式异常");
        return;
      }
      // this.receiptInfo.orderNo = orderNo
      // console.log(this.receiptInfo)
      this.$service.post("/groupReceipt/apply", this.receiptInfo).then(function (e) {
        _this.$bf.sucAlert("提交成功");
        _this.$("#applyReceiptModal").modal("hide");
        _this.getInfo();
      });
    },
    showApplyReceiptModal(info) {
      if (info == null) {
        this.receiptInfo = {
          type: 1,
          identification: "",
          address: "",
          phone: "",
          bank: "",
          bankNo: "",
          orderNo: "",
          isSend: 1,
          sendAddress: "",
          email: this.userInfo.mail,
          receiptor: "",
          sendPhone: "",
        };
      } else {
        this.receiptInfo = info;
      }

      this.$("#applyReceiptModal").modal("show");
    },
    showApplyGroupReceiptModal(info) {
      if (info == null) {
        this.groupReceiptInfo = {
          // type: 1,
          identification: "",
          address: "",
          phone: "",
          bank: "",
          bankNo: "",
          // orderNo: "",
          // isSend: 1,
          sendAddress: "",
          email: this.userInfo.mail,
          receiptor: "",
          sendPhone: "",
        };
      } else {
        this.receiptInfo = info;
      }

      this.$("#applyGroupReceiptModal").modal("show");
    },
    showReceiptInfoModal() {
      // this.receiptId = id
      // console.log(this.receiptId)
      this.$("#receiptInfoModal").modal("show");
    },
    showChangePwModal() {
      this.PWInfo = {
        password: "",
        newPassword: "",
        newPassword2: "",
      };
      this.$("#pwEditModal").modal("show");
    },
    updatePW() {
      if (
        this.PWInfo.password == "" ||
        this.PWInfo.newPassword == "" ||
        this.PWInfo.newPassword2 == ""
      ) {
        this.$bf.warningAlert("关键参数不能为空");
        return;
      }
      if (this.PWInfo.newPassword != this.PWInfo.newPassword2) {
        this.$bf.warningAlert("两次新密码输入不一致");
        return;
      }
      this.PWInfo.password = this.$md5(this.PWInfo.password);
      this.PWInfo.newPassword = this.$md5(this.PWInfo.newPassword);
      this.PWInfo.newPassword2 = this.$md5(this.PWInfo.newPassword2);
      var _this = this;
      this.$service.post("/account/changePW", this.PWInfo).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        _this.$("#pwEditModal").modal("hide");
      });
    },
    changeImg() {
      this.$refs.headImgUpload.value = "";
      this.$("#uploadHeadImg").click();
    },
    uploadHeadImg() {
      var file = this.$refs.headImgUpload.files[0];
      var reason = this.$bf.checkImg(file);
      if (reason != null) {
        this.$bf.warningAlert(reason);
        this.$refs.headImgUpload.value = "";
        return;
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      var _this = this;
      this.$service.post("/utils/uploadHead", formData).then(function (e) {
        // _this.pageParams.img = e.data.data.name;
        _this.$refs.headImgUpload.value = "";
        _this.$router.go(0);
        // _this.articalInfo.img = ""
      });
    },
  },
};
</script>
<style scoped>
#personalPageContainer {
  width: 1400px;
  /* background: whitesmoke; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.user-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;

  /* max-width:400px; */
  /* min-width: 300px */
}

.user-plane {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0px 20px;
}

.user-menu .info-content {
  padding-top: 10px;
  font-size: 16px;
  width: 100%;
}

.user-menu .button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  width: 180px;
}

#personalMenu {
  border-right: 1px solid lightgray;
  white-space: nowrap;
  padding: 10px;
  width: auto;
  /* text-align: center; */
}

#personalContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  padding: 20px;
  border: solid 1px lightgray;
  border-radius: 5px;
}

#personalInfo {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.personal_menu {
  margin-top: 20px;
  white-space: nowrap;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  /* height: 100%; */
}

.personal_menu_item {
  font-size: 16px;
  text-align: center;
}

.personal_route_page {
  /* padding: 0px 15px; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.personal_menu_item .item_text {
  padding: 5px 40px;
  font-weight: bold;
  background: lightgray;
}

.personal_menu_sub_item {
  color: grey;
  padding: 10px 40px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.personal_main_container {
  display: flex;
  flex-direction: row;
  background: white;
  height: 100%;
  /* margin-top:15px; */
  padding: 20px;
}

#userInfoContainer {
  color: grey;
  padding: 0px 30px;
  width: 600px;
}

.user_info_item {
  padding: 10px;
  border: 1px solid lightgrey;
  white-space: nowrap;
  font-size: 16px;
}

.user_info_item .title {
  color: grey;
  padding-right: 10px;
  white-space: nowrap;
  /* font-weight: bold; */
}

.user_info_button_group {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.user_info_item .title {
  color: grey;
}

.personal_tab_plane {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
}

#noticeMsgTable {
  width: 100%;
}
</style>