<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <h4 class="modal-title" style="text-align:center;font-weight:bold">{{title}}</h4>
            </div>
            <div class="modal-body" style="text-align:center">
                <form role="form" class="form-horizontal" style="width:100%">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-sm-2">名次</label>
                        <div class="col-sm-4">
                            <select class="form-control" placeholder="名次" v-model="data.prize">                           
                                    <option :value="value" v-for="(value,index) in prizes" :key="index">{{value}}</option>  
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="text-align:center">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
                <button type="button" class="btn btn-primary" @click="submitMethod">选择</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal -->
</div>
</template>
<script>
export default {
    name:"rewardConfirmModal",
    // mounted() {
    //     this.getPrizes()
    // },
    data (){
        return {
            title: "评选名次",
        }
    },
    props:{
        id: {},  
        data: {
            default: {                
                prize: "",
                id: 0
            }
        },
        prizes: {
            default: []
        }
    },
    methods: {
        closeMethod() {                
            this.$(this).modal('hide')
        },
        submitMethod() {
            this.$emit('submit', this.userInfo)           
        },
        async getPrizes() {
          var _this = this    
          var url = '/match/config'
          await this.$service.get(url).then(function (e){
            _this.prizes = e.data.data["matchReward"]
          })  
      }
        // fillContent(content) {
        //     this.$('#modalBodyContent').append(content)
        // }
    }
}
</script>
<style scoped>

</style>
