var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-modal',{attrs:{"id":_vm.id,"confirmBtnStr":'上传'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s('文件上传'))]},proxy:true},{key:"contentDom",fn:function(){return [_c('form',{staticClass:"form-horizontal",staticStyle:{"width":"100%"},attrs:{"role":"form"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2",attrs:{"for":"exampleInputEmail1"}},[_vm._v("类型")]),_c('div',{staticClass:"col-sm-4"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.type),expression:"data.type"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"图片"}},[_vm._v("图片")]),_c('option',{attrs:{"value":"视频"}},[_vm._v("视频")]),_c('option',{attrs:{"value":"文件"}},[_vm._v("文件")])])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2",attrs:{"for":"exampleInputEmail1"}},[_vm._v("描述")]),_c('div',{staticClass:"col-sm-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.desc),expression:"data.desc"}],staticClass:"form-control",staticStyle:{"resize":"none"},attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.data.desc)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "desc", $event.target.value)}}})])])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" 关闭 ")]),_c('input',{ref:"uploadFile",staticStyle:{"display":"none"},attrs:{"type":"file","accept":_vm.data.type == '图片'
          ? _vm.imgFile
          : _vm.data.type == '视频'
            ? _vm.videoFile
            : _vm.data.type == '文件'
              ? _vm.fileFile
              : ''},on:{"change":_vm.upload}}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.clickFile}},[_vm._v(" 上传 ")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }