<template>
  <div id="memberContainer">
    <page-nav></page-nav>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px 0px;
      "
    >
      <div style="width: 350px; position: relative; top: 0px">
        <div class="title">会员之家</div>
        <div class="item-group">
          <div
            :id="'menuItem' + data.prefix"
            class="item"
            v-for="(data, index) in menus.data"
            :key="index"
            @click="clickItem(data.page, data.id)"
          >
            <span class="glyphicon glyphicon-grain"></span>
            {{ data.name }}
          </div>
        </div>
        <div class="title" style="margin-top: 10px">在线服务</div>
        <img
          class="service-img"
          v-for="(data, index) in services"
          :key="index"
          style="height: 120px; width: 350px"
          :alt="data.title"
          :src="
            data.img
              ? $bf.getImgUrl(data.img)
              : '../../public/static/defaultpic.gif'
          "
          @click="imgClick(data)"
        />
      </div>

      <div style="width: 1050px; padding: 0px 10px">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import pageNav from "../base/pageNav.vue";
export default {
  name: "memberContainerPage",
  components: {
    pageNav,
  },
  data() {
    return {
      menus: this.$bf.SUB_MENU[this.$bf.SUB_MENU.length - 3],
      services: [],
      type: 0,
      pageJson: null,
    };
  },
  watch: {
    $route: function () {
        this.init()
    },
  },
  mounted() {
    this.$bf.calHeight(this, this.$("#memberContainer"));
    this.init()
  },
  methods: {
    init() {
      this.getServices();
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      // console.log(this.pageJson.prefix)
      this.$("div[id^=menuItem]").removeClass("active");
      this.$("#menuItem" + this.pageJson.prefix).addClass("active");
      this.type = this.pageJson.type;
    },
    getServices() {
      var json = {};
      var _this = this;
      this.$service.post("/advert/services", json).then(function (e) {
        _this.services = e.data.data;
      });
    },
    clickItem(page, idNo) {
      //   console.log(page, idNo);
      if (idNo) {
        this.$router.push({ name: page, params: { id: idNo } });
      } else {
        this.$router.push({ name: page });
      }
    },
    imgClick(banner) {
      if (banner == null) {
        return;
      }
      if (banner.url === "" || banner.url === null) {
        return;
      }
      if (banner.type == 4) {
        // 跳转页面详情
        this.$router.push({
          name: "informationDetailPage",
          params: { id: banner.url },
        });
      } else {
        window.open(banner.url, "_blank");
      }
    },
  },
};
</script>
<style scoped>
#memberContainer {
  width: 100%;
}
.item-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.item {
  border-bottom: 1px dashed lightgray;
  padding: 10px 5px;
  font-size: 16px;
  cursor: pointer;
}
.active {
  color: cornflowerblue;
  font-weight: bold;
}
.title {
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  background: #336699;
}
.service-img {
  width: 100%;
  padding: 5px 0px;
  cursor: pointer;
}
</style>