<template>
  <div id="loadingContainer">
    <div id="loadingText">
      Loading...
      <!-- <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div> -->
    </div>
    <div id="loadingBg"></div>
  </div>
</template>
<script>
export default {
  name: "loading",
  mounted() {
    this.$("#loadingContainer").hide();
  },
  methods: {
    show() {
      this.$("#loadingContainer").fadeIn();
    },
    hide() {
      this.$("#loadingContainer").fadeOut();
    },
    active() {
      // 最多显示10s
      this.show();
      var t1 = window.setTimeout(this.hide, 10000);
      // window.clearTimeout(t1)
    },
  },
};
</script>
<style scoped>
#loadingText {
  z-index: 3;
  font-weight: bold;
  font-size: large;
  color: dodgerblue;
}
#loadingContainer {
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
#loadingBg {
  height: 100%;
  width: 100%;
  position: fixed;
  background: whitesmoke;
  opacity: 0.6;
}
</style>
