<template>
  <base-modal :id="id" :confirmBtnStr="'驳回'" @confirm="reject" @close="closeMethod">
    <template #title>{{ '驳回' }}</template>
    <template v-slot:contentDom>
      <form role="form" class="form-horizontal" style="width: 100%">
        <div class="form-group">
          <label for="exampleInputPassword1" class="col-sm-2">驳回理由</label>
          <div class="col-sm-6">
            <textarea type="text" class="form-control" style="resize: none; height: 120px" maxlength="200"
              v-model="data.reason">
            </textarea>
          </div>
        </div>
      </form>
    </template>
  </base-modal>
</template>
<script>
import baseModal from "./baseModal.vue";
export default {
  name: "rejectDesignerModal",
  components: {
    baseModal,
  },
  props: {
    id: {},
    data: {
      default: {},
    },
  },
  mounted() { },
  methods: {
    closeMethod() {
      this.$(this).modal("hide");
    },
    reject() {
      this.$emit("reject");
      this.$(this).modal("hide");
    },
  },
};
</script>
