<template>
  <div id="memberDataContainer">
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div class="chart-item-group">
      <div style="font-weight: bold; font-size: 18px; padding: 10px 0px">
        年龄分布
      </div>
      <div id="ageChartTable"></div>
      <div style="font-weight: bold; font-size: 18px; padding: 10px 0px">
        性别比例
      </div>
      <div id="sexyChartTable">
        <div class="sexy-item">总人数：{{ sexyData.total }}</div>
        <div class="sexy-item">
          男生：{{ sexyData.boyNum }}个-{{ sexyData.boyRate }}
        </div>
        <div class="sexy-item">
          女生：{{ sexyData.girlNum }}个-{{ sexyData.girlRate }}
        </div>
      </div>
      <div style="font-weight: bold; font-size: 18px; padding: 10px 0px">
        职业
      </div>
      <div id="jobChartTable"></div>
      <div style="font-weight: bold; font-size: 18px; padding: 10px 0px">
        单位
      </div>
      <div id="companyChartTable"></div>
    </div>
  </div>
</template>
<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);
export default {
  name: "memberDataAdminPage",
  data() {
    return {
      title: "会员数据统计",
      ageData: {},
      sexyData: {
        total: 0,
      },
      nameList: [],
      companyList: [],
    };
  },
  mounted() {
    this.getData();
    // this.initEcharts();
  },
  methods: {
    getData() {
      var _this = this;
      this.$service.get("/data/collect").then(function (e) {
        _this.ageData = e.data.data.age;
        _this.sexyData = e.data.data.sexy;
        _this.nameList = e.data.data.job;
        _this.companyList = e.data.data.company;
        _this.initEcharts();
        _this.initJobEcharts();
        _this.initCompanyEcharts();
        // console.log(_this.ageData.params)
      });
    },
    initEcharts() {
      // 接下来的使用就跟之前一样，初始化图表，设置配置项
      var myChart = echarts.init(this.$("#ageChartTable")[0]);
      var option = {
        xAxis: {
          type: "category",
          data: this.ageData.params,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.ageData.value,
            type: "bar",
          },
        ],
      };
      myChart.setOption(option);
    },
    initJobEcharts() {
      // const data = this.genData(50);
      var myChart = echarts.init(this.$("#jobChartTable")[0]);
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 10,
          top: 20,
          bottom: 20,
          data: this.nameList,
        },
        series: [
          {
            name: "职务",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            data: this.nameList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initCompanyEcharts() {
      // const data = this.genData(50);
      var myChart = echarts.init(this.$("#companyChartTable")[0]);
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 10,
          top: 20,
          bottom: 20,
          data: this.companyList,
        },
        series: [
          {
            name: "单位",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            data: this.companyList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="postcss" scoped>
#memberDataContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
#ageChartTable {
  width: 100%;
  height: 400px;
  border: 1px solid lightgray;
  margin-top: 10px;
  border-radius: 5px;
}
#sexyChartTable {
  display: flex;
  flex-direction: row;
}
#jobChartTable {
  width: 100%;
  height: 400px;
  border: 1px solid lightgray;
  margin-top: 10px;
  border-radius: 5px;
}
#companyChartTable {
  width: 100%;
  height: 400px;
  border: 1px solid lightgray;
  margin-top: 10px;
  border-radius: 5px;
}
.sexy-item {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin-left: 15px;
}
</style>