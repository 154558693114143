<template>
  <base-modal :id="id" :title="''" :confirmBtnStr="'修改'" @confirm="updateMethod" @close="closeMethod">
    <template #title>{{ '配置修改' }}</template>
    <template v-slot:contentDom>
      <form role="form" class="form-horizontal" style="width: 100%">
        <div class="form-group">
          <label for="exampleInputEmail1" class="col-sm-2">值</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" name="value" v-model="data.value" />
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1" class="col-sm-2">描述</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" name="desc" v-model="data.desc" />
          </div>
        </div>
      </form>
    </template>
  </base-modal>
</template>
<script>
import baseModal from "./baseModal.vue";
export default {
  name: "configModifyModal",
  components: {
    baseModal,
  },
  props: {
    id: {},
    data: {
      default: "",
    },
  },
  methods: {
    closeMethod() {
      this.$(this).modal("hide");
    },
    updateMethod() {
      this.$emit("update", this.userInfo);
      this.$(this).modal("hide");
    },
  },
};
</script>
<style scoped></style>
