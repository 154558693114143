<template>
  <div id="app">
    <loading ref="loading"></loading>
    <div id="warningTips" class="alert alert-danger fade in" role="alert"><div class="tips-text">系统异常</div></div>
    <div id="sucTips" class="alert alert-success fade in" role="alert" ><div class="tips-text">成功</div></div>
    <!-- <div id="sucTips" class="alert alert-success fade in" role="alert" ><div class="tips-text">成功</div></div> -->
    <!-- <div style="background:black;height:20px;">
      <div style="height:20px;width:1200px"></div>

    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import '../base.css'
import loading from './components/base/loading.vue'
export default {
  name: 'App',
  components: {
    loading
  },
  watch: {
    '$route':  function () {
        if(this.$route.name == 'loginPage') {          
          this.$store.commit('removeUserInfo')
          this.$router.go(0)
        }        
      }
  }
}
</script>
<style>
/* #app{
  height:100%;
  width:100%;
} */
.alert{
  float: right;
  position:absolute;
  right:20px;
  top:20px;
  display: none;
  width: auto;
  text-align: center;
}

.alert{
  z-index:10000;
  position:fixed
}
.divider-group{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.divider-group .text{
  color: grey;
  padding: 0px 5px;
  font-size: 14px;
  font-size: small;
  font-weight: bold;
}
.divider{
  width: 100%; 
  border-bottom: 1px dashed lightgray;
}

.no-data-text{
    padding: 20px;
    text-align:  center;
    font-size:16px;
    border:1px solid lightgray;
    border-radius: 5px;
    color:lightslategrey;
}
</style>
