<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title" style="text-align:center;font-weight:bold">{{ title }}</h4>
                </div>
                <div class="modal-body" style="text-align:center">
                    <form role="form" class="form-horizontal" style="width:100%">
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">快递单号</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" v-model="data.sendNo">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">发票单号</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" v-model="data.receiptNo">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer" style="text-align:center">
                    <button type="button" class="btn btn-primary" @click="approve">审批</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal -->
    </div>
</template>
<script>
export default {
    name: "approveReceiptModal",
    data() {
        return {
            title: "发票审批"
        }
    },
    props: {
        id: {
            default: function () {
                return ""
            }
        },
        data: {
            default: function () {
                return {}
            }
        }
    },
    mounted() { },
    methods: {
        closeMethod() {
            this.$(this).modal('hide')
        },
        approve() {
            this.$emit('approve', this.userInfo)
        }
    }
}
</script>
