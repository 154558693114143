<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title" style="text-align:center;font-weight:bold">{{ title }}</h4>
                </div>
                <div class="modal-body" style="text-align:center">
                    <form role="form" class="form-horizontal" style="width:100%">
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="col-sm-2">姓名</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" name="name" v-model="userDetail.name">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">昵称</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" name="account" v-model="userDetail.account">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">单位</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" name="company" v-model="userDetail.company">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1" class="col-sm-2">邮箱</label>
                            <div class="col-sm-6">
                                <input type="mail" class="form-control" name="mail" v-model="userDetail.mail">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer" style="text-align:center">
                    <button type="button" class="btn btn-primary" @click="updateMethod">修改</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeMethod">关闭</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal -->
    </div>
</template>
<script>
export default {
    name: "userInfoEditModal",
    data() {
        return {
            title: "用户信息修改"
        }
    },
    props: {
        id: {},
        userDetail: {}
    },
    mounted() {
        // console.log(this.$('#modalBodyContent'))
        // this.$('#modalBodyContent').append(this.contentHtml)
    },
    methods: {
        closeMethod() {
            this.$(this).modal('hide')
        },
        updateMethod() {
            this.$emit('update', this.userInfo)
        }
        // fillContent(content) {
        //     this.$('#modalBodyContent').append(content)
        // }
    }
}
</script>
<style scoped></style>
