<template>
  <div id="designerApplyContainer">
    <page-nav></page-nav>
    <div class="divider-group" style="padding: 60px 0px; width: 60%">
      <div class="text" style="font-size: 16px; color: cornflowerblue">
        注册账号
      </div>
      <div
        class="divider"
        :style="stage !== 1 ? 'border-color: cornflowerblue' : ''"
      ></div>
      <div
        class="text"
        :style="
          stage !== 1
            ? 'font-size: 16px; color: cornflowerblue'
            : 'font-size: 16px'
        "
      >
        设计师申报
      </div>
      <div
        class="divider"
        :style="stage == 3 ? 'border-color: cornflowerblue' : ''"
      ></div>
      <div
        class="text"
        :style="
          stage == 3
            ? 'font-size: 16px; color: cornflowerblue'
            : 'font-size: 16px;'
        "
      >
        申报进度
      </div>
    </div>
    <div v-if="stage == 1">
      <div class="form-group">
        <p style="font-size: 14px">
          若已有用户账号，请先<a
            style="cursor: pointer"
            @click="$router.push({ name: 'loginPage' })"
            >登录</a
          >
        </p>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            用户名
          </div>
          <input
            type="text"
            class="form-control item-input"
            v-model="userInfo.account"
            placeholder="请输入用户名"
          />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            密码
          </div>
          <input
            type="password"
            class="form-control item-input"
            v-model="userInfo.password"
            placeholder="请输入密码"
          />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            重复密码
          </div>
          <input
            type="password"
            class="form-control item-input"
            v-model="userInfo.rePassword"
            placeholder="请重新输入密码"
          />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            联系电话
          </div>
          <input
            type="text"
            class="form-control item-input"
            placeholder="请输入联系电话"
            v-model="userInfo.phone"
          />
        </div>
        <div class="item">
          <div class="item-label">
            <div class="item-need">*</div>
            联系邮箱
          </div>
          <input
            type="text"
            class="form-control item-input"
            placeholder="请输入联系邮箱"
            v-model="userInfo.mail"
          />
        </div>
        <div class="item" style="padding-top: 10px">
          <button class="btn btn-primary" type="button" @click="register()">
            注册账号
          </button>
        </div>
      </div>
    </div>
    <div v-if="stage == 2">
      <div style="display: flex; flex-direction: row">
        <div
          class="form-group"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <img
            style="width: 80px; height: 100px"
            :src="
              userInfo.img
                ? $bf.getHeadImgUrl(userInfo.img)
                : '../../../public/static/defaultpic.gif'
            "
          />
          <div style="width: 80px; padding-top: 10px">
            <input
              type="file"
              class="btn btn-success"
              style="display: none"
              id="uploadHeadImg"
              ref="headImgUpload"
              @change="uploadHeadImg"
            />
            <button class="btn btn-primary" type="file" @click="changeImg()">
              上传头像
            </button>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              专家类型
            </div>
            <select
              type="text"
              class="form-control item-input"
              style="width: 140px"
              placeholder="请选择专家类型"
              v-model="designerInfo.type"
            >
              <option v-for="(value,index) in $bf.DESIGNER_TYPE" :key="index">{{value}}</option>
              <!-- <option>建筑师</option>
              <option>建造师</option>
              <option>室内设计师</option>
              <option>空间设计师</option>
              <option>工业设计师</option>
              <option>家具设计师</option>
              <option>景观设计师</option> -->
            </select>
          </div>
        </div>
        <div
          class="form-group"
          style="padding-left: 30px; width: 100%; height: 120px"
        >
          <div class="item-label">
            <div class="item-need">*</div>
            自我简介
            <div style="color: red">（注意：头像和简介将会在网站中展示）</div>
          </div>
          <textarea
            type="text"
            class="form-control item-input"
            placeholder="请输入自我简介最多300字"
            style="resize: none; width: 100%; height: 100%"
            maxlength="300"
            v-model="designerInfo.desc"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="divider-group">
          <div class="divider"></div>
          <div class="text">个人信息</div>
          <div class="divider"></div>
        </div>
      </div>
      <div class="form-group">
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              真实名称
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入真实名称"
              name="account"
              v-model="userInfo.name"
            />
          </div>
          <div class="item-inline">
            <div class="item-label">性别</div>
            <select
              class="form-control item-input"
              placeholder="请选择性别"
              v-model="userInfo.sexy"
            >
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              出生日期
            </div>
            <input
              type="date"
              class="form-control item-input"
              placeholder="请选择日期"
              v-model="userInfo.birth"
            />
          </div>
        </div>
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              联系地址
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入具体地址"
              v-model="userInfo.address"
            />
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              最高学历
            </div>
            <select
              type="text"
              class="form-control item-input"
              placeholder="请选择最高学历"
              v-model="userInfo.qualification"
            >
              <option>其他</option>
              <option>中职</option>
              <option>高中</option>
              <option>大专</option>
              <option>本科</option>
              <option>硕士</option>
              <option>博士</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              专业
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入专业名称"
              v-model="userInfo.speciality"
            />
          </div>
        </div>
        <div class="item-row-inline">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              政治面貌
            </div>
            <select
              type="text"
              class="form-control item-input"
              placeholder="请选择政治面貌"
              v-model="userInfo.politic"
            >
              <option>无</option>
              <option>群众</option>
              <option>共青团员</option>
              <option>中共党员</option>
            </select>
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              毕业院校
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入毕业院校"
              v-model="userInfo.graduated"
            />
          </div>
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              所在单位/学校全称
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入所在单位/学校全称"
              v-model="userInfo.enterprise"
            />
          </div>
        </div>
        <div class="item-row-inline" style="width: 100%">
          <div class="item-inline">
            <div class="item-label">
              <div class="item-need">*</div>
              职务
            </div>
            <input
              type="text"
              class="form-control item-input"
              placeholder="请输入职务"
              v-model="userInfo.job"
            />
          </div>
        </div>
        <div class="item-row-inline" style="justify-content: space-around">
          <div class="item-inline">
            <button
              class="btn btn-primary"
              type="button"
              @click="applyDesigner()"
            >
              申报设计师
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="stage == 3">
      <div style="text-align: center" >
        <img
          style="width: 90px; height: 90px"
          alt="广东省城市建筑学会"
          src="../../../public/static/assiation_logo.jpg"
        />
        <div class="form-group" v-if="designerInfo.state == -1">
          <p>您上次的设计师申请未能通过，请重新申请</p>
          <p>原因如下：</p>
          <p>{{ designerInfo.reason }}</p>
        </div>
        <p style="padding-top: 10px">
          {{
            designerInfo.state == 0
              ? "您的设计师申请正在审批中..."
              : designerInfo.state == -2
              ? "您的设计师申请已经被禁用，请联系管理员开启"
              : designerInfo.state == 1?"您的设计师已经审核通过，可在设计师库查看对应信息":
              ""
          }}
        </p>
        <button
          v-if="designerInfo.state == -1"
          class="btn btn-primary"
          type="button"
          @click="stage = 2"
        >
          重新申请
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import pageNav from "../base/pageNav.vue";
export default {
  name: "designerApplyPage",
  components: {
    pageNav,
  },
  data() {
    return {
      stage: 1,
      userInfo: {
        politic: "无",
        qualification: "大专",
        type: 1,
      },
      designerInfo: {},
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.$bf.calHeight(this, this.$("#designerApplyContainer"));
      // console.log(this.$store.getters.isLogin)
      if (this.$store.getters.isLogin) {
        this.getDesignerStatus();
      } else {
        this.stage = 1;
      }
    },
    getDesignerStatus() {
      var _this = this;
      this.$service.get("/designer/status").then(function (e) {
        _this.userInfo = e.data.data.userEntity;
        if (e.data.data.designerEntity) {
          _this.designerInfo = e.data.data.designerEntity ;
          // _this.userInfo.designerType = _this.designerInfo.type;
          // if (_this.designerInfo.state == 1) {
          //   _this.$router.push({ name: "designerListPage" });
          // }
          _this.stage = 3;
        } else {
          _this.stage = 2;
        } 
      });
    },
    register() {
      if (
        this.userInfo.account == "" ||
        this.userInfo.password == "" ||
        this.userInfo.phone == "" ||
        this.userInfo.rePassword == "" ||
        this.userInfo.mail == "" ||
        this.userInfo.type == "" ||
        this.userInfo.account == null ||
        this.userInfo.password == null ||
        this.userInfo.phone == null ||
        this.userInfo.rePassword == null ||
        this.userInfo.mail == null ||
        this.userInfo.type == null
      ) {
        this.$bf.warningAlert("请填写好表单中的内容，不能为空");
        return;
      }
      if (this.userInfo.password !== this.userInfo.rePassword) {
        this.$bf.warningAlert("两次密码输入不相同");
        return;
      }
      if (!this.$bf.isAccount(this.userInfo.account)) {
        this.$bf.warningAlert("账号格式异常,仅限字母数字和下划线且至少4位");
        return;
      }
      if (!this.$bf.isMobilePhone(this.userInfo.phone)) {
        this.$bf.warningAlert("手机号码格式异常");
        return;
      }
      if (!this.$bf.isMail(this.userInfo.mail)) {
        this.$bf.warningAlert("联系邮箱格式异常");
        return;
      }
      var json = {
        account: this.userInfo.account,
        password: this.userInfo.password,
        phone: this.userInfo.phone,
        mail: this.userInfo.mail,
        type: this.userInfo.type,
      };
      json.password = this.$md5(json.password);
      //    json.confirmPassword = this.$md5(json.confirmPassword)
      var _this = this;
      this.$service.post("/account/create", json).then(function (e) {
        _this.$bf.sucAlert("注册成功");
        _this.userInfo.id = e.data.data;
        _this.stage = 2;
      });
    },
    applyDesigner() {
      var _this = this;
      if (
        this.userInfo.name == "" ||
        this.userInfo.sexy == "" ||
        this.userInfo.enterprise == "" ||
        this.userInfo.graduated == "" ||
        this.userInfo.birth == "" ||
        this.userInfo.job == "" ||
        this.userInfo.img == "" ||
        this.designerInfo.type == "" ||
        this.designerInfo.desc == "" ||
        this.userInfo.politic == "" ||
        this.userInfo.qualification == "" ||
        this.userInfo.speciality == "" ||
        this.userInfo.address == "" ||
        this.userInfo.name == null ||
        this.userInfo.img == null ||
        this.designerInfo.desc == null ||
        this.userInfo.sexy == null ||
        this.designerInfo.type == null ||
        this.userInfo.enterprise == null ||
        this.userInfo.graduated == null ||
        this.userInfo.birth == null ||
        this.userInfo.job == null ||
        this.userInfo.politic == null ||
        this.userInfo.qualification == null ||
        this.userInfo.speciality == null ||
        this.userInfo.address == null
      ) {
        this.$bf.warningAlert("请填写好表单中的内容，不能为空");
        return;
      }
      if (!this.$bf.checkName(this.userInfo.name)) {
        this.$bf.warningAlert("真实名称仅支持中文，最多显示8个字");
        return;
      }
      var json = this.userInfo;
      json.id = null;
      json.userId = this.userInfo.id;
      var url = "/designer/create";
      // console.log(this.designerInfo)
      if (this.designerInfo != null && this.designerInfo.id) {
        // url = "/designer/update"
        json.id = this.designerInfo.id;
        json.state = 0;
      }
      json.designerType = this.designerInfo.type;
      json.desc = this.designerInfo.desc;
      // console.log(json)
      this.$service.post(url, json).then(function (e) {
        _this.$bf.sucAlert("提交成功");
        _this.$router.go(0);
      });
    },
    changeImg() {
      this.$refs.headImgUpload.value = "";
      this.$("#uploadHeadImg").click();
    },
    uploadHeadImg() {
      var file = this.$refs.headImgUpload.files[0];
      var reason = this.$bf.checkImg(file);
      if (reason != null) {
        this.$bf.warningAlert(reason);
        this.$refs.headImgUpload.value = "";
        return;
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      formData.set("userId", this.userId);
      var _this = this;
      this.$service.post("/utils/uploadHead", formData).then(function (e) {
        _this.userInfo.img = e.data.data.name;
        _this.$refs.headImgUpload.value = "";
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
#designerApplyContainer {
  width: 100%;
  font-size: 16px;
  /* padding: 20px 0px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.item-row-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
}
.item-inline {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  padding-top: 10px;
}
.item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding-top: 5px; */
}
.item {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  /* padding-top: 10px; */
}
.item-label {
  margin-right: 10px;
  font-size: medium;
  /* width: 70px; */
  display: inline-flex;
}
.item-need {
  color: red;
  padding: 0px 5px;
}
.item-input {
  width: 240px;
}
</style>
