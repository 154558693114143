<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
          <h4 class="modal-title" style="text-align: center; font-weight: bold">
            {{ title }}
          </h4>
        </div>
        <div class="modal-body" style="text-align: center" v-if="data">
          <form role="form" class="form-horizontal" style="width: 100%">
            <div class="form-group">
              <label for="exampleInputEmail1" class="col-sm-2">比赛名称</label>
              <div class="col-sm-6">增值税普通发票</div>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="col-sm-2">报名记录</label>
              <div class="col-sm-6">增值税普通发票</div>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="col-sm-2">发票类型</label>
              <div class="col-sm-6">增值税普通发票</div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >纳税人识别号</label
              >
              <div class="col-sm-6">
                {{ data.identification }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2">地址</label>
              <div class="col-sm-6">
                {{ data.address }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2">电话</label>
              <div class="col-sm-6">
                {{ data.phone }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2">开户行</label>
              <div class="col-sm-6">
                {{ data.bank }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >开户账号</label
              >
              <div class="col-sm-6">
                {{ data.bankNo }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >邮寄地址</label
              >
              <div class="col-sm-6">
                {{ data.sendAddress }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2">收件人</label>
              <div class="col-sm-6">
                {{ data.receiptor }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >收件电话</label
              >
              <div class="col-sm-6">
                {{ data.sendPhone }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >快递单号</label
              >
              <div class="col-sm-6">
                {{ data.sendNo }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2">发票号</label>
              <div class="col-sm-6">
                {{ data.receiptNo }}
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="col-sm-2"
                >驳回理由</label
              >
              <div class="col-sm-6">
                <textarea
                  type="text"
                  class="form-control"
                  disabled="true"
                  name="mail"
                  v-model="data.reason"
                >
                </textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer" style="text-align: center">
          <!-- <button type="button" class="btn btn-primary" @click="reject">驳回</button> -->
          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            @click="closeMethod"
          >
            关闭
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal -->
  </div>
</template>
<script>
export default {
  name: "groupReceiptInfoModal",
  data() {
    return {
      title: "团队发票信息",
      //   data: {},
    };
  },
  props: {
    id: {},
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {},
  methods: {
    init() {
      var _this = this;
      this.$service
        .get("/groupReceipt/info?id=" + this.groupReceiptId)
        .then(function (e) {
          _this.data = e.data.data;
        });
    },
    closeMethod() {
      console.log(this.data);
      this.$(this).modal("hide");
    },
  },
};
</script>
