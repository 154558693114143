<template>
  <div id="specialistDetailPage">
    <page-nav :current="specialist.name"></page-nav>
    <div
      style="
        display: flex;
        flex-direction: row;
        width: 1400px;
        padding: 0px 20px;
      "
    >
      <!-- <div class="left-nav">
        <div class="item">专家信息</div>
        <div class="item">行业经验</div>
        <div class="item">成果业绩</div>
        <div class="item">社会兼职</div>
      </div> -->
      <div class="content">
        <div class="info">
          <img
            v-if="specialist.img"
            :alt="specialist.name"
            class="specialist-img"
            :src="$bf.getImgUrl(specialist.img)"
          />
          <img
            v-else
            class="specialist-img"
            alt="广东省城市建筑学会"
            src="../../../public/static/defaultpic.gif"
          />
          <div class="info-content">
            <div class="name">{{ specialist.name }}</div>
            <div class="detail">
              <div>
                <p>专家类别：{{ specialist.type }}</p>
                <p>所在单位：{{ specialist.company }}</p>
                <p>行&nbsp;&nbsp;业：{{ specialist.industry }}</p>
              </div>
              <div style="padding-left: 30%">
                <p>行业领域：{{ specialist.territory }}</p>
                <p>职&nbsp;&nbsp;务：{{ specialist.job }}</p>
                <p>职&nbsp;&nbsp;称：{{ specialist.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-content">
          {{ specialist.desc }}
        </div>
        <div>
          <div class="tag-banner">行业经验</div>
          <div class="text-content">
            {{ specialist.experience ? specialist.experience : "暂无" }}
          </div>
        </div>
        <div>
          <div class="tag-banner">成果业绩</div>
          <div class="text-content">
            {{ specialist.achievement ? specialist.achievement : "暂无" }}
          </div>
        </div>
        <div>
          <div class="tag-banner">社会兼职</div>
          <div class="text-content">
            {{ specialist.partTime ? specialist.partTime : "暂无" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageNav from "../base/pageNav.vue";
export default {
  name: "specialistDetailPage",
  components: { pageNav },
  data() {
    return {
      subNavs: [],
      specialist: {
        name: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.pageJson = this.$bf.decodeArticalPrefix(this.$route.name);
      this.subNavs = this.$bf.calPageNavs(this.pageJson.prefix);
      this.$bf.calHeight(this, this.$("#specialistDetailPage"));
      this.getSpecialistInfo();
    },
    clickNavItem(pageName) {
      if (pageName) {
        this.$router.push({ name: pageName });
      }
    },
    getSpecialistInfo() {
      var _this = this;
      this.$service
        .get("/specialist/detail?id=" + this.$route.params.id)
        .then(function (e) {
          _this.specialist = e.data.data;
        });
    },
  },
};
</script>
<style scoped>
#specialistDetailPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  /* padding:20px; */
  height: 100%;
  width: 100%;
  background: white;
}
.left-nav {
  padding: 10px;
  border-right: 1px solid lightgray;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.specialist-img {
  height: 180px;
  width: 140px;
}
.info .name {
  font-size: 22px;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
  padding: 15px;
}
.info .detail {
  background: #336699;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
  padding: 15px;
  color: white;
}
.content {
  width: 100%;
  padding: 10px 20px;
}
.info-content {
  width: 100%;
  /* margin-left:10px */
}
.tag-banner {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  color: gold;
  background: #336699;
  padding: 5px 15px;
}
.text-content {
  padding: 20px 0px;
  width: 100%;
  word-break: break-word;
}
</style>
