<template>
  <div id="configAdminContainer">
    <notice-modal :id="'configConfirmModal'" @confirm="changeState()">
        <template v-slot:title>警告</template>
        <template v-slot:content>你确认修改状态吗？配置将立即生效</template>
    </notice-modal>
    <config-modify-modal
      :id="'configUpdateModal'"
      :data="updateInfo"
      @update="update"
    >
    </config-modify-modal>
    <div
      style="
        text-align: left;
        font-size: large;
        font-weight: bold;
        border-left: 10px solid cornflowerblue;
      "
    >
      &nbsp;{{ title }}
    </div>
    <div style="text-align: right; padding: 15px 0px">
      <form class="form-inline">
        <div class="form-group">
          <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
          <input
            type="number"
            class="form-control"
            placeholder="根据id查询"
            v-model="searchFilter.id"
          />
        </div>
        <div class="form-group" style="padding: 0px 10px">
          <!-- <label class="sr-only" for="exampleInputEmail3">标题/内容模糊查询</label> -->
          <input
            type="text"
            class="form-control"
            placeholder="根据键值查询"
            v-model="searchFilter.key"
          />
        </div>
        <div class="input-group">
          <button
            type="button"
            class="btn btn-primary"
            style="margin-left: 10px"
            @click="search(1)"
          >
            <span class="glyphicon glyphicon-search"></span>&nbsp;查找
          </button>
        </div>
      </form>
    </div>
    <div>
      <table v-if="listData.records.length > 0" class="table table-bordered">
        <thead>
          <th>Id</th>
          <th>键值</th>
          <th>数值</th>
          <th>描述</th>
          <th>状态</th>
          <th>操作</th>
        </thead>
        <tbody>
          <tr v-for="(value, index) in listData.records" :key="index">
            <td>{{ value.id }}</td>
            <td>{{ value.name }}</td>
            <td>{{ value.value }}</td>
            <td>{{ value.desc }}</td>
            <td>{{ value.stateStr }}</td>
            <td>
              <div class="btn-group" role="group" aria-label="...">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showUpdateModal(value)"
                >
                  修改
                </button>
                <button
                  v-if="value.state == 1"
                  type="button"
                  class="btn btn-primary"
                  @click="showModal(value, 0)"
                >
                  启用
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-danger"
                  @click="showModal(value, 1)"
                >
                  禁用
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="no-data-text">查询暂无数据</div>
    </div>
    <base-pagination :data="listData" @method="search"></base-pagination>
  </div>
</template>
<style scoped>
#configAdminContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 100%; */
}
</style>
<script>
import basePagination from "../base/pagination.vue";
import configModifyModal from "../base/configModifyModal.vue";
import noticeModal from "../base/noticeModal.vue";
export default {
  name: "configAdminPage",
  components: {
    basePagination,
    noticeModal,
    configModifyModal,
  },
  data() {
    return {
      searchFilter: {
        id: "",
        key: "",
      },
      // paginationData: {
      //   pageNo: 1,
      //   maxPage: 1,
      //   maxShowPage: 5,
      //   pageSize: 10,
      //   data: [],
      //   isLeftEnd: false,
      //   isRightEnd: false,
      // },
      currentPage: 1,
      pageInfo: {
        adminPage: "",
        editPage: "",
        detailPage: "",
        createPage: "",
      },
      listData: [],
      title: "",
      changeInfo: {
        id: 0,
        state: 0,
      },
      updateInfo: {},
      data: {},
    };
  },
  mounted() {
    // console.log(this.listData.length)
    this.init();
  },
  methods: {
    init() {
      this.pageInfo.adminPage = this.$route.name;
      this.title = "系统配置";
      // this.pageInfo.createPage = 'activityCreatePage'
      // this.pageInfo.editPage = 'activityEditPage'
      this.search(1);
    },
    search(page) {
      var _this = this;
      this.currentPage = page;
      // this.paginationData.pageNo = page;
      var url = "/config/list";
      var body = {
        pageNo: page,
        pageSize: 10,
      };
      if ((this.searchFilter.id != null) & (this.searchFilter.id != "")) {
        body.id = this.searchFilter.id;
      }
      if ((this.searchFilter.key != null) & (this.searchFilter.key != "")) {
        body.key = this.searchFilter.key;
      }
      this.$service.post(url, body).then(function (e) {
        _this.listData = e.data.data;
        // _this.$refs.configPagination.render(page, e.data.data.total);
      });
    },
    showModal(value, state) {
      this.changeInfo.id = value.id;
      this.changeInfo.state = state;
      this.$("#configConfirmModal").modal("show");
    },
    showUpdateModal(value) {
      this.updateInfo = {
        id: value.id,
        value: value.value,
        desc: value.desc,
      };
      // this.changeInfo.id = value.id
      // this.changeInfo.state = state
      this.$("#configUpdateModal").modal("show");
    },
    changeState() {
      var _this = this;
      var url = "/config/update";
      var body = {
        id: this.changeInfo.id,
        state: this.changeInfo.state,
      };
      this.$service.post(url, body).then(function (e) {
        // console.log(1111)
        _this.search(_this.currentPage);
        // _this.$bf.sucessAlert("修改成功")

        // this.$("#configConfirmModal").modal("hide")
      });
    },
    update() {
      var _this = this;
      var url = "/config/update";
      var body = {
        id: this.updateInfo.id,
        value: this.updateInfo.value,
        desc: this.updateInfo.desc,
      };
      this.$service.post(url, body).then(function (e) {
        // console.log(1111)
        // _this.$bf.sucessAlert("修改成功")
        _this.$("#configUpdateModal").modal("hide");
        _this.search(_this.currentPage);
        // this.$("#configConfirmModal").modal("hide")
      });
    },
  },
};
</script>
<style scoped>
.no-data-text {
  padding: 20px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: lightslategrey;
}
</style>