
<template>
  <div id="matchContainer">
    <!-- <submit-work-modal :id="'sumbitWorkModal'"   title="提交作品" :data="modalData" @submit="submitWork"></submit-work-modal> -->
    <match-member-modal
      :id="'matchMemberModal'"
      :data="modalData"
    ></match-member-modal>
    <!-- 比赛信息 -->
    <div class="match-info-content">
      <div class="header">
        <div class="title">{{ match.name }}</div>
        <div class="to-detail" @click="toDetail()">[详情]</div>
      </div>
      <!-- <> -->
      <ul class="nav nav-tabs" style="width: 100%; text-align: right">
        <li
          id="matchMenuItem0"
          role="presentation"
          style="white-space: nowrap"
          @click="clickMatchMenu(0)"
        >
          <a>我的报名</a>
        </li>
        <li
          id="matchMenuItem1"
          role="presentation"
          style="white-space: nowrap"
          @click="clickMatchMenu(1)"
        >
          <a>评选记录</a>
        </li>
        <li
          id="matchMenuItem2"
          role="presentation"
          style="white-space: nowrap"
          @click="clickMatchMenu(2)"
        >
          <a>获奖记录</a>
        </li>
      </ul>
      <router-view style="padding-top: 10px"></router-view>     
    </div>
  </div>
</template>
<script>
// import submitWorkModal from '../base/submitWorkModal.vue'
import matchMemberModal from "../base/matchMemberModal.vue";
export default {
  name: "matchPage",
  components: {
    // submitWorkModal,
    matchMemberModal,
  },
  data() {
    return {
      matchStatus: "",
      enrollStatus: "",
      submitStatus: "",
      match: {
        name: "",
        enrollEndTime: "",
        enrollStartTime: "",
      },
      config: {},
      enrollParams: {
        companys: [
          {
            name: "",
            enName: "",
            type: "建设",
          },
        ],
        members: [
          {
            id: null,
            name: "",
            enName: "",
            phone: "",
            job: "",
            email: "",
            result: "",
          },
        ],
        group: "",
        enGroup: "",
      },
      enrollRecords: [],
      enrollRecord: {},
      enrollSize: 0,
      matchStageResult: {},
      matchUserScoreRecordVos: [],
      otherEnrollRecords: [],
      rewardRecords: [],
      modalData: {},
      isEditEnroll: false,
      // matchUserScoreRecords: [],
      // workData: {}
    };
  },
  mounted() {
    this.$bf.calHeight(this, this.$("#matchContainer"));
    this.clickMatchMenu(0);
    this.getMatchInfo();
    // this.showEnrollRecordPlane()
    // this.enrollRecordNav('myEnrollRecordItem0')
  },
  methods: {
    getMatchInfo() {
      var _this = this;
      this.$service.get("/match/info").then(function (e) {
        if (e.data.code == 405) {
          _this.$router.push({ name: "unMemberPage" });
        }
        _this.matchStatus = e.data.data.status;
        _this.match = e.data.data.matchVo;
        // _this.getMatchConfig()
      });
    },
    getEnrollStatus() {
      var _this = this;
      this.$service.get("/match/enroll-status").then(function (e) {
        _this.enrollStatus = e.data.data.enrollStatus;
        _this.submitStatus = e.data.data.submitStatus;
        _this.enrollSize = e.data.data.enrollSize;
        _this.getMatchConfig();
      });
    },
    getMyRecord() {
      var _this = this;
      this.$service.get("/match/my-record").then(function (e) {
        _this.enrollRecords = e.data.data;
        if (_this.enrollRecords.length > 0) {
          _this.enrollRecord = _this.enrollRecords[0];
        }
      });
    },
    getMyReward() {
      var _this = this;
      this.$service.get("/match/my-reward").then(function (e) {
        _this.rewardRecords = e.data.data;
      });
    },
    getMatchConfig() {
      var _this = this;
      this.$service.get("/match/config").then(function (e) {
        _this.config = e.data.data;
      });
    },
    getStageResult(order) {
      var _this = this;
      var json = {
        order: order,
        matchNo: this.match.matchNo,
      };
      this.$service.post("/match/result", json).then(function (e) {
        _this.matchStageResult = e.data.data;
      });
    },
    addMember() {
      if (this.enrollParams.members.length >= 15) {
        this.$bf.warningAlert("参赛人员最多15人");
        return;
      }
      this.enrollParams.members.push({
        name: "",
        enName: "",
      });
    },
    addCompany() {
      if (this.enrollParams.companys.length >= 3) {
        this.$bf.warningAlert("参赛单位最多3个");
        return;
      }
      this.enrollParams.companys.push({
        name: "",
        enName: "",
        phone: "",
        email: "",
        type: "建设",
      });
    },
    removeMember(index) {
      var temp = [];
      for (var i = 0; i < this.enrollParams.members.length; i++) {
        if (i == index) {
          continue;
        }
        temp.push(this.enrollParams.members[i]);
      }
      this.enrollParams.members = temp;
    },
    removeCompany(index) {
      var temp = [];
      for (var i = 0; i < this.enrollParams.companys.length; i++) {
        if (i == index) {
          continue;
        }
        temp.push(this.enrollParams.companys[i]);
      }
      this.enrollParams.companys = temp;
    },
    uploadFile() {
      var file = this.$refs.annouceFile.files[0];
      console.log(1111)
      if(file.size/(1024*1024)>1) {
        console.log(file.size)
        this.$bf.warningAlert("文件大小不能大于1M")
        return 
      }
      let formData = new FormData();
      formData.set("fileName", file.name);
      formData.set("file", file);
      var _this = this;
      this.$service.post("/utils/uploadFile", formData).then(function (e) {
        _this.enrollParams.annouceFile = e.data.data.name;
        // _this.$refs.annouceFile.value = ""
        // _this.articalInfo.img = ""
      });
    },
    showMemberModal(enrollNo) {
      this.modalData = {
        id: enrollNo,
      };
      this.$("#matchMemberModal").modal("show");
    },
    showModal() {
      this.modalData = {
        enrollNo: this.enrollRecord.enrollNo,
        applyUserName: this.enrollRecord.applyUserName,
        // companyName: this.enrollRecord.company,
        group: this.enrollRecord.group,
        workName: this.enrollRecord.workName,
        enWorkName: this.enrollRecord.enWorkName,
      };
      this.$("#sumbitWorkModal").modal("show");
    },
    submitWork() {
      var _this = this;
      if (
        this.enrollRecord.workName == "" ||
        this.enrollRecord.workName == null ||
        this.enrollRecord.enWorkName == "" ||
        this.enrollRecord.enWorkName == null ||
        this.enrollRecord.baiduUrl == "" ||
        this.enrollRecord.baiduUrl == null
      ) {
        this.$bf.warningAlert("必须填写作品信息");
        return;
      }
      if (
        this.enrollRecord.projectName == "" ||
        this.enrollRecord.projectName == null ||
        this.enrollRecord.projectSize == "" ||
        this.enrollRecord.projectSize == null ||
        this.enrollRecord.projectStartDate == "" ||
        this.enrollRecord.projectStartDate == null ||
        this.enrollRecord.projectEndDate == "" ||
        this.enrollRecord.projectEndDate == null ||
        this.enrollRecord.projectCost == "" ||
        this.enrollRecord.projectCost == null ||
        this.enrollRecord.projectStructure == "" ||
        this.enrollRecord.projectStructure == null
      ) {
        this.$bf.warningAlert("必须填写工程信息");
        return;
      }
      this.$service.post("match/submit", this.enrollRecord).then(function (e) {
        _this.$bf.sucAlert(e.data.msg);
        // _this.$('#sumbitWorkModal').modal('hide')
        // _this.selectProject(_this.enrollRecord.index)
      });
    },
    changeType(event, type) {
      this.$("li[role=presentation]").removeClass("active");
      this.$(event.target).parent().addClass("active");
      this.enrollType = type;
      this.enrollParams = {
        companyName: "",
        members: "",
        group: "",
      };
    },
    enrollRecordNav(id) {
      this.$("li[id^=myEnrollRecordItem]").removeClass("active");
      this.$("#" + id).addClass("active");
      if (id == "myEnrollRecordItem0") {
        this.$("#enrollRecordPlane").show();
        this.$("#myEnrollRecordPlane").hide();
        this.$("#rewardRecordPlane").hide();
      } else if (id == "myEnrollRecordItem1") {
        this.$("#enrollRecordPlane").hide();
        this.$("#myEnrollRecordPlane").show();
        this.$("#rewardRecordPlane").hide();
        this.getMyRecord();
      } else {
        this.$("#rewardRecordPlane").show();
        this.$("#enrollRecordPlane").hide();
        this.$("#myEnrollRecordPlane").hide();
        this.getMyReward();
      }
    },
    showEnrollPlane() {
      this.$("#enrollPlane").show();
      this.$("#enrollRecordPlane").hide();
      this.isEditEnroll = true;
    },
    showEnrollRecordPlane() {
      this.$("#enrollPlane").hide();
      this.$("#enrollRecordPlane").show();
      this.isEditEnroll = false;
      // this.getEnrollStatus()
      // this.getMyRecord()
    },
    clickMatchMenu(index) {
      this.$("li[id^=matchMenuItem]").removeClass("active");
      this.$("#matchMenuItem" + index).addClass("active");
      if (index == 0) {
        this.$router.push({ name: "matchEnrollPage" });
      }
      if (index == 1) {
        this.$router.push({ name: "matchResultPage" });
      }
      if (index == 2) {
        this.$router.push({ name: "matchRewardPage" });
      }
    },
    selectProject(index) {
      this.$("li[id^=myEnrollRecordItem]").removeClass("active");
      this.$("#myEnrollRecordItem" + index).addClass("active");
      this.enrollRecord = this.enrollRecords[index];
      this.enrollRecord.index = index;
    },
    otherEnrollItemClick(id) {
      this.$("li[id^=otherEnrollRecordItem]").removeClass("active");
      this.$("div[id^=otherEnrollPlane]").hide();
      this.$("#otherEnrollRecordItem" + id).addClass("active");
      this.$("#otherEnrollPlane" + id).show();
    },
    rewardRecordItemClick(id) {
      this.$("li[id^=rewardRecordItem]").removeClass("active");
      this.$("div[id^=rewardRecordPlane]").hide();
      this.$("#rewardRecordItem" + id).addClass("active");
      this.$("#rewardRecordPlane" + id).show();
    },
    enrollRecordItemClick(id) {
      this.$("li[id^=enrollRecordItem]").removeClass("active");
      this.$("div[id^=enrollRecordPlane]").hide();
      this.$("#enrollRecordItem" + id).addClass("active");
      this.$("#enrollRecordPlane" + id).show();
    },
    toDetail() {
      this.$router.push({
        name: "matchDetailPage",
        params: { matchNo: this.match.matchNo },
      });
    },
    checkMember(value) {
      if (value.name != "" && value.phone != "") {
        var json = {
          name: value.name,
          phone: value.phone,
        };
        this.$service.post("match/memberCheck", json).then(function (e) {
          value.id = e.data.data.id;
          value.result = e.data.data.resultStr;
          value.job = e.data.data.job;
          value.email = e.data.data.email;
          value.company = e.data.data.enterprise;
        });
      }
    },
    enroll() {
      var _this = this;
      if (this.enrollParams.group == "") {
        this.$bf.warningAlert("组别不能为空");
        return;
      }
      if (
        this.enrollParams.annouceFile == "" ||
        this.enrollParams.annouceFile == null
      ) {
        this.$bf.warningAlert("授权文件不能为空");
        return;
      }
      var enGroup = "";
      for (var group in this.config.matchGroupType) {
        if (this.config.matchGroupType[group] == this.enrollParams.group) {
          enGroup = group;
          break;
        }
      }
      for (var member in this.enrollParams.members) {
        // console.log(this.enrollParams.members[member])
        if (!this.$bf.checkName(this.enrollParams.members[member].name)) {
          this.$bf.warningAlert("参赛者姓名仅支持中文");
          return;
        }
        if (!this.$bf.isMobilePhone(this.enrollParams.members[member].phone)) {
          this.$bf.warningAlert("联系电话格式异常");
          return;
        }
        if (
          this.enrollParams.members[member].job == null ||
          this.enrollParams.members[member].job == ""
        ) {
          this.$bf.warningAlert("职务不能为空");
          return;
        }
        if (
          this.enrollParams.members[member].company == null ||
          this.enrollParams.members[member].company == "" ||
          this.enrollParams.members[member].enCompany == null ||
          this.enrollParams.members[member].enCompany == ""
        ) {
          this.$bf.warningAlert("单位名称不能为空");
          return;
        }
        if (!this.$bf.isMail(this.enrollParams.members[member].email)) {
          this.$bf.warningAlert("邮箱地址格式异常");
          return;
        }
        if (
          member.result != "" &&
          this.enrollParams.members[member].result != null
        ) {
          this.$bf.warningAlert("参赛成员不符合条件");
          return;
        }
      }
      var body = {
        matchNo: this.match.matchNo,
        companys: JSON.stringify(this.enrollParams.companys),
        members: JSON.stringify(this.enrollParams.members),
        group: this.enrollParams.group,
        enGroup: enGroup,
        announceFile: this.enrollParams.annouceFile,
      };
      this.$service.post("/match/enroll", body).then(function (e) {
        // _this.enrollRecord = e.data.data
        _this.$router.go(0);
      });
    },
  },
};
</script>
<style scoped>
#matchContainer {
  width: 1400px;
  height: 100%;
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.nagative {
  color: gray;
  background-color: lightgrey;
}
.match-info-content {
  text-align: center;
  width: 100%;
  /* border-bottom: 1px solid lightgray; */
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 20px;
}
.match-main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.match-main-content .match-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px 30px;
  text-align: right;
  flex: 1;
  border-right: 1px solid lightgray;
}
.match-main-content .match-nav .nav-text {
  font-size: 20px;
  color: gray;
}
.match-main-content .match-nav .nav-text .active {
  /* font-size: 20px; */
  color: cornflowerblue;
  font-weight: bold;
}
.enroll-grid {
  display: grid;
  grid-template-columns: [menu-items] 180px auto auto auto;
  grid-template-rows: auto auto auto;
}
.enroll-grid-item {
  padding: 5px 10px;
  text-align: left;
  border: 1px solid lightgray;
}
.enroll-grid-item.info-tag {
  font-size: 16px;
  font-weight: bold;
  grid-column: 1 / 5;
  text-align: center;
  background-color: cornsilk;
  color: cornflowerblue;
}
.enroll-grid-item.title {
  background-color: whitesmoke;
  /* width:180px */
}
.enroll-grid-item.three-col {
  grid-column: 2 / 5;
}
.match-main-content .match-content {
  width: 100%;
  flex: 5;
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.match-main-content .match-content .match-enroll-record {
  width: 100%;
}
.match-main-content .match-content .match-stage-info {
  width: 100%;
}
.match-main-content .match-content .match-stage-info .item {
  width: 100%;
  padding: 15px 0px;
}
.match-info-content .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.match-info-content .header .title {
  font-size: 22px;
  font-weight: bold;
}
.match-info-content .header .to-detail {
  font-size: 14px;
  padding: 0px 15px;
  cursor: pointer;
  color: cornflowerblue;
}
.match-info-content .content {
  font-size: 18px;
  padding: 15px 0px;
}
</style>
